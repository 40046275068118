import { useContext, useState, useEffect } from "react";
import { cargo } from "@api";
import { Opener, Builder } from "@tools";
import { QuadContext } from "@context";
import {
  Editable,
  EmptyLabel,
  Expandable,
  Loadman,
  PopperOptions,
} from "@common/comps";
import { Grid, Box, Button, ThemeProvider } from "@mui/material";
import { buttonTheme } from "@material/themes";
import { PopperEditIcon } from "@material/icons";

import { PreqContext } from "../context";

const Course = ({ c, reinitialize }) => {
  const { subjectCode, courseCode, courseTitle } = c || {};

  const [isEditOpen, setIsEditOpen] = useState(false);

  const endComponent = (
    <Box sx={{ height: "100%", display: "flex", alignItems: "center" }}>
      <ThemeProvider theme={buttonTheme}>
        <Button
          variant="outlined"
          onClick={() => setIsEditOpen(true)}
          sx={{ whiteSpace: "nowrap", padding: "2px 12px" }}
        >
          Edit alternatives
        </Button>
      </ThemeProvider>
      {isEditOpen &&
        Builder.preqAlts({
          optionCourse: c,
          isOpen: isEditOpen,
          setIsOpen: setIsEditOpen,
          onEdit: reinitialize,
        })}
    </Box>
  );

  return (
    <Box p={1} borderBottom="1px solid var(--border-gray)">
      <Editable
        variant="vanilla"
        label={`${subjectCode} ${courseCode}`}
        subLabel={courseTitle}
        endComponent={endComponent}
      />
    </Box>
  );
};

const Option = ({ o, i, reinitialize }) => {
  const { sequence = [] } = o || {};

  const [isEditOpen, setIsEditOpen] = useState(false);

  const isEmpty = sequence.length === 0;

  const menuItemsList = [
    {
      key: "admin-preq-option__edit",
      label: "Edit option",
      icon: <PopperEditIcon />,
      handleClick: (event) => {
        event.stopPropagation();
        setIsEditOpen(true);
      },
    },
  ];

  const endComponent = (
    <>
      <PopperOptions menuItemsList={menuItemsList} />
      {isEditOpen &&
        Builder.preqOption({
          unedited: o,
          isOpen: isEditOpen,
          setIsOpen: setIsEditOpen,
          onEdit: (edited) => {
            setIsEditOpen(false);
            reinitialize();
          },
          onDelete: () => {
            reinitialize();
          },
        })}
    </>
  );

  const childComponent = (
    <Grid container direction="column" wrap="nowrap" p={2} pt={0}>
      {isEmpty ? (
        <EmptyLabel label="No courses" />
      ) : (
        sequence.map((c, i) => (
          <Grid
            item
            key={c.courseId}
            borderTop={i === 0 && "1px solid var(--border-gray)"}
          >
            <Course c={c} reinitialize={reinitialize} />
          </Grid>
        ))
      )}
    </Grid>
  );

  return (
    <Expandable
      label={`Option ${i + 1}`}
      labelSx={{ fontSize: "15px", textUnderlineOffset: "0.8em" }}
      unlinkHoverTransition
      endComponent={endComponent}
      child={childComponent}
    />
  );
};

export const GroupOptionsManager = () => {
  const { quadId } = useContext(QuadContext);
  const { group } = useContext(PreqContext);
  const { preqGroupId } = group || {};

  const [isLoading, setIsLoading] = useState(true);
  const [feed, setFeed] = useState([]);

  const isEmpty = feed.length === 0;
  const noFeed = isLoading || isEmpty;

  const fetchFeed = () => {
    setIsLoading(true);
    cargo
      .get(`/v2/q/${quadId}/program/requirement/group/${preqGroupId}/options`)
      .then((res) => {
        const { ok, payload } = res || {};

        if (ok) {
          setIsLoading(false);
          setFeed(payload.options);
        }
      });
  };

  useEffect(() => {
    fetchFeed();
  }, []);

  const Create = () => (
    <Opener
      variant="filled"
      color="black"
      label="Create option"
      builder={Builder.preqOption({
        preqGroupId,
        onCreate: fetchFeed,
      })}
    />
  );

  return noFeed ? (
    <Loadman
      isLoading={isLoading}
      isEmpty={isEmpty}
      emptyLabel="No options added."
      p={2}
    />
  ) : (
    <Grid container direction="column" wrap="nowrap">
      <Grid item p={2} borderBottom="1px solid var(--border-gray)">
        <Create />
      </Grid>
      {feed.map((o, i) => (
        <Grid item key={o.preqOptionId}>
          <Option o={o} i={i} reinitialize={fetchFeed} />
        </Grid>
      ))}
    </Grid>
  );
};
