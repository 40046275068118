import { Grid, Box, Typography } from "@mui/material";
import { GeneralMoreIcon, GeneralQuillIcon } from "@material/icons";

const helperTextSx = {
  fontSize: "15px",
  fontWeight: "400",
  color: "var(--quad-gray)",
};

export const LoadButton = ({ handleClick }) => {
  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      alignItems="center"
      className="subreply__load"
      gap={2}
      onClick={handleClick}
    >
      <Grid item>
        <Box id="subreply__load__icon-container">
          <GeneralMoreIcon width={18} height={18} />
        </Box>
      </Grid>
      <Grid item>
        <Typography sx={helperTextSx}>Load subreplies</Typography>
      </Grid>
    </Grid>
  );
};

export const WriteButton = ({ handleClick }) => {
  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      alignItems="center"
      className="subreply__load"
      gap={2}
      onClick={handleClick}
    >
      <Grid item>
        <Box id="subreply__load__icon-container--teal">
          <GeneralQuillIcon width={18} height={18} />
        </Box>
      </Grid>
      <Grid item>
        <Typography sx={helperTextSx}>Write subreply</Typography>
      </Grid>
    </Grid>
  );
};
