import { useState } from "react";
import { ActionOptionsIcon, NavMoreIcon } from "@material/icons";
import { PopperMenuItem } from "@common/comps";
import {
  Button,
  Box,
  Popper,
  Paper,
  MenuList,
  Modal,
  ThemeProvider,
} from "@mui/material";
import { buttonTheme, popperTheme } from "@material/themes";

const PopperOptions = ({
  menuItemsList = [],
  type,
  placement,
  offset,
  customIcon,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const openMenu = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
  };

  const optionsButton = (
    <ThemeProvider theme={buttonTheme}>
      <Button variant="options" onClick={openMenu}>
        <Box sx={{ width: "18px", height: "18px", display: "flex" }}>
          {customIcon ? customIcon : <ActionOptionsIcon />}
        </Box>
      </Button>
    </ThemeProvider>
  );

  const navButton = (
    <ThemeProvider theme={buttonTheme}>
      <Button variant="icon" onClick={openMenu}>
        <Box sx={{ width: "30px", height: "30px", display: "flex" }}>
          <NavMoreIcon />
        </Box>
      </Button>
    </ThemeProvider>
  );

  const getOpenButton = () => {
    switch (type) {
      case "nav":
        return navButton;
      default:
        return optionsButton;
    }
  };

  const popper = (
    <Modal
      open={isMenuOpen}
      onClose={(event) => {
        event.stopPropagation();
        setAnchorEl(null);
        setIsMenuOpen(false);
      }}
      BackdropProps={{ invisible: true }}
      disableScrollLock
    >
      <Popper
        open={isMenuOpen}
        anchorEl={anchorEl}
        placement={placement ? placement : "bottom-end"}
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                ...(offset ? offset : { offset: [-5, -25] }),
              },
            },
          ],
        }}
        disablePortal
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Paper>
          <MenuList>
            {menuItemsList.filter((item) => !item.isRestricted || item.auth)
              .length > 0 ? (
              menuItemsList.map((item) => {
                const {
                  key,
                  label,
                  icon,
                  isRed,
                  handleClick,
                  isRestricted,
                  auth,
                } = item || {};

                if (isRestricted) {
                  return (
                    auth && (
                      <PopperMenuItem
                        key={key}
                        label={label}
                        icon={icon}
                        isRed={isRed}
                        handleClick={handleClick}
                      />
                    )
                  );
                } else {
                  return (
                    <PopperMenuItem
                      key={key}
                      label={label}
                      icon={icon}
                      isRed={isRed}
                      handleClick={handleClick}
                    />
                  );
                }
              })
            ) : (
              <PopperMenuItem isEmpty />
            )}
          </MenuList>
        </Paper>
      </Popper>
    </Modal>
  );

  return (
    <ThemeProvider theme={popperTheme}>
      {getOpenButton()}
      {popper}
    </ThemeProvider>
  );
};

export default PopperOptions;
