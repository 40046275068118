import { useContext, useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { cargo } from "@api";
import { Scoreman, Synchronizer } from "@tools";
import { Loading, EmptyLabel } from "@common/comps";
import { CatalogProvider, QuadContext, useCatalog } from "@context";
import { Grid, Button, ThemeProvider } from "@mui/material";
import { buttonTheme } from "@material/themes";
import { ButtonBackwardIcon } from "@material/icons";

import { CatalogMenu } from "./comps/CatalogMenu";
import { CatalogFilters } from "./comps/CatalogFilters";
import { SubjectChoose } from "./comps/SubjectChoose";
import { CourseCard } from "./comps/CourseCard";

const CatalogFeed = () => {
  const { isLoading, lastCardRef, feed, setFeed, isFeedEmpty } = useCatalog();

  if (isFeedEmpty) {
    return <EmptyLabel label="No courses found." p={2} />;
  } else {
    return (
      <Grid container direction="column" wrap="nowrap">
        {feed.map((c, i) => (
          <Grid item key={c.courseId}>
            <CourseCard
              ref={feed.length === i + 1 ? lastCardRef : null}
              course={c}
              onVote={(options) => {
                const { type: actionType } = options || {};
                const newScores = Scoreman.getNewScores({
                  type: actionType,
                  base: c,
                });

                Synchronizer.updateCourseFeed({ ...c, ...newScores }, setFeed);
              }}
            />
          </Grid>
        ))}
        <Grid item>{isLoading && <Loading p={2} />}</Grid>
        <Grid item height={200} />
      </Grid>
    );
  }
};

const Nav = () => {
  const { subject, setSubject } = useCatalog();

  const Choose = () => <SubjectChoose />;

  const { canCatalog } = subject || {};

  const backButton = (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant="icon"
        sx={{ padding: "8px" }}
        onClick={() => setSubject("")}
      >
        <ButtonBackwardIcon width={18} height={18} />
      </Button>
    </ThemeProvider>
  );

  const catalogCenterButton = (
    <ThemeProvider theme={buttonTheme}>
      <Button variant="outlined">Cataloger center</Button>
    </ThemeProvider>
  );

  const top = (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      justifyContent="space-between"
    >
      <Grid item>
        <Grid container direction="row" wrap="nowrap" gap={1}>
          {subject && <Grid item>{backButton}</Grid>}
          <Grid item>
            <Choose />
          </Grid>
        </Grid>
      </Grid>
      {canCatalog && <Grid item>{catalogCenterButton}</Grid>}
    </Grid>
  );

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item p={2} borderBottom="1px solid var(--border-gray)">
        {top}
      </Grid>
      {subject ? (
        <Grid item p={2} borderBottom="1px solid var(--border-gray)">
          <CatalogFilters />
        </Grid>
      ) : (
        <CatalogMenu />
      )}
    </Grid>
  );
};

export const CatalogLanding = () => {
  const { subject } = useCatalog();

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        <Nav />
      </Grid>
      {subject && (
        <Grid item>
          <CatalogFeed />
        </Grid>
      )}
    </Grid>
  );
};

export const Catalog = () => {
  const { quadId } = useContext(QuadContext);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  // Init
  const [isInitializing, setIsInitializing] = useState(true);

  // Options
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [filterOptions, setFilterOptions] = useState({});

  useEffect(() => {
    setIsInitializing(true);
    cargo.get(`/v2/q/${quadId}/catalog`).then((res) => {
      const { ok, payload } = res || {};
      if (ok) {
        setIsInitializing(false);
        setSubjectOptions(payload.subjects);
        setFilterOptions(payload.filters);
      }
    });
  }, []);

  if (isInitializing) {
    return <Loading p={2} />;
  } else {
    return (
      <CatalogProvider
        subjectOptions={subjectOptions}
        filterOptions={filterOptions}
      >
        <Outlet />
      </CatalogProvider>
    );
  }
};
