import { useContext, useReducer, useState, useEffect } from "react";
import { cargo } from "@api";
import { GlobalContext, QuadContext } from "@context";
import { BasicForm, Dropdown, EmptyLabel, Loading } from "@common/comps";
import { Grid, Button, ThemeProvider, Typography } from "@mui/material";
import { ButtonPlusIcon } from "@material/icons";
import { buttonTheme } from "@material/themes";

import { Submitter } from "@tools";

export const CourseCoresBuilder = ({
  course,
  isOpen,
  setIsOpen,
  onEdit = () => {},
}) => {
  const { defaultError } = useContext(GlobalContext);
  const { quadId } = useContext(QuadContext);

  const [isInitializing, setIsInitializing] = useState(true);
  const [coreOptions, setCoreOptions] = useState([]);
  const [baseline, setBaseline] = useState(null);
  const [cores, setCores] = useReducer((state, action) => {
    switch (action.type) {
      case "init":
        return action.value;
      case "add":
        return state.find((d) => d.coreId === action.value.coreId)
          ? state
          : [...state, action.value];
      case "remove":
        return state.filter((d) => d.coreId !== action.value);
      default:
        return state;
    }
  }, null);

  const [stage, setStage] = useState(null);

  const initialize = async () => {
    const promiseA = cargo
      .get(`/v2/q/${quadId}/r/course/${course.courseId}/cores`)
      .then((res) => {
        const { ok, payload } = res || {};
        if (ok) return payload;
      });
    const promiseB = cargo.get(`/v2/q/${quadId}/core/all`).then((res) => {
      const { ok, payload } = res || {};
      if (ok) return payload;
    });

    const [payloadA, payloadB] = await Promise.all([promiseA, promiseB]);

    if (payloadA && payloadB) {
      setIsInitializing(false);
      setBaseline(payloadA.cores);
      setCores({ type: "init", value: payloadA.cores });
      setCoreOptions(payloadB.cores);
    }
  };

  useEffect(() => {
    setIsInitializing(true);
    initialize();
  }, []);

  const content = isInitializing ? (
    <Loading p={2} />
  ) : (
    Array.isArray(cores) && (
      <Grid container direction="column" wrap="nowrap" gap={1}>
        {cores.length === 0 ? (
          <EmptyLabel label="No core requirements added." px={0.5} py={2} />
        ) : (
          cores.map((c) => (
            <Grid item key={c.coreId}>
              <Dropdown
                card
                label={c.coreCode}
                subLabel={c.coreName}
                handleDelete={() => {
                  setCores({ type: "remove", value: c.coreId });
                }}
              />
            </Grid>
          ))
        )}
        {stage && (
          <Grid item>
            <Typography variant="note">{stage.coreName}</Typography>
          </Grid>
        )}
        <Grid item>
          <Grid
            container
            direction="row"
            wrap="nowrap"
            alignItems="center"
            gap={2}
          >
            <Grid item sx={{ width: "100%" }}>
              <Dropdown
                variant="object"
                label="Core requirements"
                state={stage}
                setState={setStage}
                options={coreOptions}
                labelKey="coreCode"
                valueKey="coreId"
              />
            </Grid>
            <Grid item>
              <ThemeProvider theme={buttonTheme}>
                <Button
                  variant="icon"
                  onClick={() => {
                    setCores({ type: "add", value: stage });
                  }}
                >
                  <ButtonPlusIcon width={18} height={18} />
                </Button>
              </ThemeProvider>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  );

  /* Form section */
  const [hot, setHot] = useState(false);
  const [submitState, setSubmitState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState()
  );

  const submitter = new Submitter(submitState, setSubmitState, defaultError);

  useEffect(() => {
    if (hot) setHot(false);

    if (!hot && Array.isArray(baseline) && Array.isArray(cores)) {
      submitter.green(JSON.stringify(baseline) !== JSON.stringify(cores));
      submitter.resetError();
    }
  }, [cores]);

  const handleSubmit = () => {
    submitter.startLoading();
    cargo
      .put(`/v2/q/${quadId}/r/course/${course.courseId}/cores`, {
        cores: cores.map((c) => c.coreId),
      })
      .then((res) => {
        const { ok } = res || {};
        if (ok) {
          onEdit();
        } else {
          setHot(true);
          submitter.takeResponse(res);
        }
      });
  };

  return (
    isOpen && (
      <BasicForm
        title="Edit core requirements"
        isFormOpen={isOpen}
        setIsFormOpen={setIsOpen}
        content={content}
        handleSubmit={handleSubmit}
        submitState={{
          isSubmitLoading: submitState.isLoading,
          isSubmitDisabled: submitState.isDisabled,
          submitError: submitState.error,
        }}
      />
    )
  );
};
