import { useContext, useState, useEffect } from "react";
import { cargo } from "@api";
import { GlobalContext } from "@context";
import { getURL } from "@config";
import { Dropdown, Loading, Submit } from "@common/comps";
import { Grid } from "@mui/material";

export const SettingsDefaultQuad = () => {
  const { session, defaultError } = useContext(GlobalContext);
  const { defaultQuad } = session || {};

  const [isInitializing, setIsInitializing] = useState(true);
  const [quadOptions, setQuadOptions] = useState([]);

  const [quad, setQuad] = useState(defaultQuad);

  useEffect(() => {
    setIsInitializing(true);
    cargo.get(`/v2/settings/quads`).then((res) => {
      setIsInitializing(false);
      const { ok, payload } = res || {};
      if (ok) {
        setQuadOptions(payload.sessOwnerQuads);
      }
    });
  }, []);

  // Submission
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [submitError, setSubmitError] = useState(defaultError);

  const goodForSubmit = quad !== defaultQuad;

  useEffect(() => {
    setIsSubmitDisabled(!goodForSubmit);
    setSubmitError(defaultError);
  }, [quad]);

  const handleSubmit = () => {
    if (quad) {
      setIsSubmitLoading(true);
      cargo.put(`/v2/settings/quad/default`, { quad }).then((res) => {
        const { ok } = res || {};
        if (ok) {
          window.location.href = getURL("/settings/general");
        }
      });
    }
  };

  return (
    <Grid container direction="column" wrap="nowrap" p={2}>
      {isInitializing ? (
        <Loading p={2} />
      ) : (
        <>
          <Grid item>
            <Dropdown
              label="Your quads"
              state={quad}
              setState={setQuad}
              options={quadOptions}
              variant="object"
              disableObjectSelect
              labelKey="quadShort"
              valueKey="quad"
            />
          </Grid>
          <Grid item py={2}>
            <Submit
              alignX="flex-end"
              handleSubmit={handleSubmit}
              submitState={{
                isDisabled: isSubmitDisabled,
                isLoading: isSubmitLoading,
                error: submitError,
              }}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
