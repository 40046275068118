import { Input } from "@common/comps";
import { Grid, Button, ThemeProvider } from "@mui/material";
import { buttonTheme } from "@material/themes";
import { ButtonSearchIcon } from "@material/icons";

const SearchButton = ({ handleSearch }) => (
  <ThemeProvider theme={buttonTheme}>
    <Button
      variant="outlined"
      onClick={handleSearch}
      sx={{
        borderRadius: "9999px",
        padding: "10px",
      }}
    >
      <ButtonSearchIcon width={18} height={18} />
    </Button>
  </ThemeProvider>
);

const Vanilla = ({ label, search, setSearch, handleSearch }) => {
  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      alignItems="center"
      gap={2}
      sx={{ maxHeight: "40px" }}
    >
      <Grid item sx={{ width: "100%" }}>
        <Input
          variant="vanilla"
          placeholder={label || "Search"}
          state={search}
          setState={setSearch}
          handleEnterDown={handleSearch}
        />
      </Grid>
      <Grid item>
        <SearchButton handleSearch={handleSearch} />
      </Grid>
    </Grid>
  );
};

const Filled = ({ label, search, setSearch, handleSearch }) => {
  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      alignItems="center"
      gap={2}
      sx={{ maxHeight: "40px" }}
    >
      <Grid item sx={{ width: "100%" }}>
        <Input
          variant="vanilla"
          placeholder={label || "Search"}
          state={search}
          setState={setSearch}
          handleEnterDown={handleSearch}
          sx={{
            backgroundColor: "var(--search-bg-gray)",
            border: "none",
            borderRadius: "9999px",

            ".MuiInputBase-input": {
              padding: "10px 18px",
            },
          }}
        />
      </Grid>
      <Grid item>
        <SearchButton handleSearch={handleSearch} />
      </Grid>
    </Grid>
  );
};

const SearchBar = (props) => {
  const { variant } = props || {};
  switch (variant) {
    case "vanilla":
      return <Vanilla {...props} />;
    case "filled":
      return <Filled {...props} />;
    default:
      return <Vanilla {...props} />;
  }
};

export default SearchBar;
