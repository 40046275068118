import { useState } from "react";
import { cargo } from "@api";
import { Action } from "@common/comps";
import { Grid, Typography, ThemeProvider, createTheme } from "@mui/material";
import {
  ActionUpvoteIcon,
  ActionUpvoteSelectedIcon,
  ActionDownvoteIcon,
  ActionDownvoteSelectedIcon,
} from "@material/icons";

const actionTheme = createTheme({
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: "score" },
          style: {
            fontSize: "14px",
            fontWeight: "400",
            color: "var(--quad-gray-alt)",
          },
        },
      ],
    },
  },
});

const upvoteSx = {
  backgroundColor: "var(--quad-teal-light)",

  "#action-icon--upvote": {
    fill: "var(--quad-teal)",
    transition: "fill 200ms",
  },
};

const downvoteSx = {
  backgroundColor: "var(--quad-pink-light)",

  "#action-icon--downvote": {
    fill: "var(--quad-pink)",
    transition: "fill 200ms",
  },
};

const postTypes = ["post", "comment", "reply", "subreply"];

export const Ballot = (props) => {
  const {
    totalScore,
    sessOwnerScore,
    gap = 3,
    type,
    id,
    quad,
    alignX,
    onVote = () => {},
    updateActivityScore = () => {},
  } = props || {};

  if (!postTypes.includes(type))
    throw new Error("Invalid type for Ballot component.");
  if (!quad) throw new Error("quad is required to use Ballot component");

  const [scoreT, setScoreT] = useState(totalScore);
  const [upvoted, setUpvoted] = useState(sessOwnerScore === 1);
  const [downvoted, setDownvoted] = useState(sessOwnerScore === -1);

  /*   const fetchScores = () => {
    cargo.get(`/v2/q/${quad}/vote/${type}/${id}`).then((res) => {
      const { ok, payload } = res || {};
      if (ok) {
        const { totalScore, sessOwnerScore } = payload || {};
        setScoreT(totalScore);
      }
    });
  }; */

  const handleUnvote = () => {
    onVote({ type: "unvote" });
    updateActivityScore({ type: "unvote" });
    cargo.put(`/v2/q/${quad}/vote/${type}/${id}?score=0`).then((res) => {
      const { ok } = res || {};
    });
  };

  const handleUpvote = () => {
    onVote({ type: "upvote" });
    updateActivityScore({ type: "upvote" });
    cargo.put(`/v2/q/${quad}/vote/${type}/${id}?score=1`).then((res) => {
      const { ok } = res || {};
    });
  };

  const handleDownvote = () => {
    onVote({ type: "downvote" });
    updateActivityScore({ type: "downvote" });
    cargo.put(`/v2/q/${quad}/vote/${type}/${id}?score=-1`).then((res) => {
      const { ok } = res || {};
    });
  };

  const upvoteButton = (
    <Action
      icon={<ActionUpvoteIcon />}
      iconSelected={<ActionUpvoteSelectedIcon />}
      isSelected={upvoted}
      onHoverSx={{
        iconSx: upvoteSx,
      }}
      handleClick={() => {
        setDownvoted(false);
        setUpvoted((prev) => !prev);
        upvoted ? handleUnvote() : handleUpvote();
      }}
    />
  );

  const downvoteButton = (
    <Action
      icon={<ActionDownvoteIcon />}
      iconSelected={<ActionDownvoteSelectedIcon />}
      isSelected={downvoted}
      onHoverSx={{
        iconSx: downvoteSx,
      }}
      handleClick={() => {
        setUpvoted(false);
        setDownvoted((prev) => !prev);
        downvoted ? handleUnvote() : handleDownvote();
      }}
    />
  );

  return (
    <ThemeProvider theme={actionTheme}>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        alignItems="center"
        justifyContent={alignX || "flex-start"}
        gap={gap}
      >
        <Grid item>{upvoteButton}</Grid>
        <Grid item>
          <Typography variant="score">{scoreT}</Typography>
        </Grid>
        <Grid item>{downvoteButton}</Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Ballot;
