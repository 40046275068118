import { useContext, useEffect, useState, useReducer } from "react";
import { cargo } from "@api";
import { GlobalContext, QuadContext } from "@context";
import {
  BasicForm,
  ConfirmForm,
  Loading,
  Editable,
  EmptyLabel,
  CourseSearch,
  Submit,
} from "@common/comps";
import { Grid, Button, ThemeProvider } from "@mui/material";
import { buttonTheme } from "@material/themes";
import { unique } from "radash";

import { Submitter } from "@tools";

const Add = ({ instrId, reinitialize, setPage, omissions }) => {
  const { defaultError } = useContext(GlobalContext);
  const { quadId } = useContext(QuadContext);

  const [submitState, setSubmitState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState()
  );

  const submitter = new Submitter(submitState, setSubmitState, defaultError);

  const [selected, setSelected] = useState(null);

  useEffect(() => {
    submitter.resetError();
    submitter.green(
      Boolean(selected) && !omissions.includes(selected.courseId)
    );
  }, [selected]);

  const handleSubmit = () => {
    submitter.startLoading();
    const { courseId } = selected || {};
    courseId &&
      cargo
        .post(`/v2/q/${quadId}/r/course/${courseId}/instructor/${instrId}`)
        .then((res) => {
          reinitialize();
          setPage("main");
        });
  };

  const backButton = (
    <Button variant="outlined" onClick={() => setPage("main")}>
      Back to main
    </Button>
  );

  const actionBar = (
    <ThemeProvider theme={buttonTheme}>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        justifyContent="space-between"
      >
        <Grid item>{backButton}</Grid>
        <Grid item>
          <Submit
            buttonLabel="Add"
            submitState={submitState}
            handleSubmit={handleSubmit}
          />
        </Grid>
      </Grid>
    </ThemeProvider>
  );

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item pb={2} borderBottom="1px solid var(--border-gray)">
        {actionBar}
      </Grid>
      <Grid item pt={2}>
        <CourseSearch
          state={selected}
          setState={setSelected}
          omissions={omissions}
        />
      </Grid>
    </Grid>
  );
};

const Course = ({ c, setCourses }) => {
  const { defaultError } = useContext(GlobalContext);
  const { quadId } = useContext(QuadContext);
  const {
    instrAffilId,
    instrId,
    courseId,
    subjectCode,
    courseCode,
    courseTitle,
  } = c || {};

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [deleteState, setDeleteState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState({ isDisabled: false })
  );

  const deleter = new Submitter(deleteState, setDeleteState, defaultError);

  const handleDelete = () => {
    if (instrId) {
      deleter.startLoading();
      cargo
        .delete(`/v2/q/${quadId}/r/course/${courseId}/instructor/${instrId}`)
        .then((res) => {
          const { ok } = res || {};
          if (ok) {
            setIsDeleteOpen(false);
            setCourses((prev) =>
              prev.filter((n) => n.instrAffilId !== instrAffilId)
            );
          } else {
            deleter.takeResponse(res);
          }
        });
    }
  };

  return (
    <>
      <Editable
        label={`${subjectCode} ${courseCode}`}
        subLabel={courseTitle}
        handleDelete={() => setIsDeleteOpen(true)}
      />
      {isDeleteOpen && (
        <ConfirmForm
          title="Delete instruction?"
          subtitle="Deleting an instruction could cause issues to all elements that depend on it."
          isFormOpen={isDeleteOpen}
          setIsFormOpen={setIsDeleteOpen}
          color="red"
          handleSubmit={handleDelete}
          submitLabel="Delete"
          submitState={{
            isSubmitDisabled: deleteState.isDisabled,
            isSubmitLoading: deleteState.isLoading,
            submitError: deleteState.error,
          }}
        />
      )}
    </>
  );
};

const Main = ({ setPage, courses, setCourses }) => {
  const addButton = (
    <ThemeProvider theme={buttonTheme}>
      <Button variant="filled" color="black" onClick={() => setPage("add")}>
        Add course
      </Button>
    </ThemeProvider>
  );

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item pb={2} borderBottom="1px solid var(--border-gray)">
        {addButton}
      </Grid>
      {courses.length === 0 ? (
        <EmptyLabel label="No courses added." py={2} />
      ) : (
        courses.map((c) => (
          <Grid
            item
            key={c.courseId}
            p={1}
            borderBottom="1px solid var(--border-gray)"
          >
            <Course c={c} setCourses={setCourses} />
          </Grid>
        ))
      )}
    </Grid>
  );
};

export const InstructorCoursesBuilder = ({ instrId, isOpen, setIsOpen }) => {
  const { quadId } = useContext(QuadContext);
  const [isInitializing, setIsInitializing] = useState(true);
  const [page, setPage] = useState("main");

  const [courses, setCourses] = useState([]);

  const initialize = () => {
    setIsInitializing(true);
    cargo.get(`/v2/q/${quadId}/instructor/${instrId}/courses`).then((res) => {
      const { ok, payload } = res || {};
      if (ok) {
        setIsInitializing(false);
        setCourses(unique(payload.courses, (c) => c.courseId));
      }
    });
  };

  useEffect(() => {
    initialize();
  }, []);

  const pageRouter = () => {
    switch (page) {
      case "add":
        return (
          <Add
            instrId={instrId}
            reinitialize={initialize}
            setPage={setPage}
            omissions={courses.map((c) => c.courseId)}
          />
        );
      default:
        return (
          <Main setPage={setPage} courses={courses} setCourses={setCourses} />
        );
    }
  };

  const content = isInitializing ? <Loading p={2} mb={2} /> : pageRouter();

  return (
    <BasicForm
      title="Edit courses"
      isFormOpen={isOpen}
      setIsFormOpen={setIsOpen}
      content={content}
      disableFooter
    />
  );
};
