import { useContext, useState, useEffect } from "react";
import { QuadContext } from "@context";
import { cargo } from "@api";
import { Builder, Opener } from "@tools";
import { EmptyLabel, Loading, MoreHeader, Editable } from "@common/comps";
import { Box, Grid } from "@mui/material";
import { unique, replace } from "radash";

const Core = ({ c, setFeed, setFresh }) => {
  const { coreId, coreCode, coreName } = c || {};

  const [isDeleted, setIsDeleted] = useState(false);

  useEffect(() => {
    if (isDeleted) {
      setTimeout(
        () => setFeed((prev) => prev.filter((c) => c.coreId !== coreId)),
        200
      );
    }
  }, [isDeleted]);

  const Edit = () => (
    <Opener
      variant="outlined"
      label="Edit"
      builder={Builder.core({
        unedited: c,
        onEdit: (edited) => {
          setFeed((prev) => replace(prev, edited, (c) => c.coreId === coreId));
          setFresh(0);
        },
        onDelete: () => {
          setIsDeleted(true);
          setFresh(0);
        },
      })}
    />
  );

  return (
    <Box
      px={!isDeleted && 2}
      py={!isDeleted && 1.5}
      borderBottom={!isDeleted && "1px solid var(--border-gray)"}
      className={isDeleted ? "card--empty" : ""}
    >
      {!isDeleted && (
        <Editable label={coreCode} subLabel={coreName} endButton={<Edit />} />
      )}
    </Box>
  );
};

export const AdminCores = () => {
  const { quadId } = useContext(QuadContext);

  const [isLoading, setIsLoading] = useState(true);
  const [feed, setFeed] = useState([]);
  const [isFeedEmpty, setIsFeedEmpty] = useState(false);

  const [fresh, setFresh] = useState(0);

  useEffect(() => {
    if (quadId) {
      setIsLoading(true);
      cargo.get(`/v2/q/${quadId}/core/all`).then((res) => {
        const { ok, payload = [] } = res || {};
        if (ok) {
          setIsLoading(false);
          const { cores } = payload || {};
          setFeed(unique(cores, (c) => c.coreId));
          setIsFeedEmpty(cores.length === 0);
        }
      });
    }
  }, []);

  const Create = () => (
    <Opener
      variant="filled"
      color="black"
      label="Create core"
      builder={Builder.core({
        onCreate: (payload) => {
          setFeed((prev) => [payload, ...prev]);
          setFresh((prev) => prev + 1);
        },
      })}
    />
  );

  const coreFeed = feed.map((c, i) => (
    <Grid
      item
      key={c.coreId}
      className={fresh > 0 && i === 0 ? "card--new" : ""}
    >
      <Core c={c} setFeed={setFeed} setFresh={setFresh} />
    </Grid>
  ));

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        <MoreHeader
          title="Manage cores"
          enableBack
          backRoute={`/q/${quadId}/admin`}
        />
      </Grid>
      <Grid
        item
        p={2}
        borderTop="1px solid var(--border-gray)"
        borderBottom="1px solid var(--border-gray)"
      >
        <Create />
      </Grid>
      {isFeedEmpty ? <EmptyLabel label="No cores found." p={2} /> : coreFeed}
      {isLoading && <Loading p={2} />}
      <Grid item height={200} />
    </Grid>
  );
};
