import { buttonTheme } from "@material/themes";
import {
  Grid,
  Button,
  Typography,
  createTheme,
  ThemeProvider,
} from "@mui/material";

const labelButtonTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          display: "flex",
        },
      },
      variants: [
        {
          props: { variant: "label" },
          style: {
            fontSize: "13px",
            fontWeight: "500",
            color: "var(--quad-gray)",
            letterSpacing: "0.02em",
          },
        },
      ],
    },
  },
});

const LabelButton = ({
  label,
  labelProps = {},
  labelComponent,
  handleClick = () => {},
  enableEnd = false,
  endLabel,
  handleEnd = () => {},
}) => {
  const endButton = (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant="stealth--teal"
        onClick={handleEnd}
        sx={{ padding: "2px 10px", fontSize: "12px" }}
      >
        {endLabel ? endLabel : "Expand"}
      </Button>
    </ThemeProvider>
  );

  return (
    <ThemeProvider theme={labelButtonTheme}>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        px={1.25}
        py={0.5}
        border="1px solid var(--attachment-border-gray)"
        borderRadius={1}
        onClick={handleClick}
        sx={{
          "&:hover": {
            cursor: "pointer",
            backgroundColor: "var(--card-hover-gray)",
          },
        }}
      >
        <Grid item sx={{ width: "100%", position: "relative", top: 3 }}>
          {labelComponent ? (
            labelComponent
          ) : (
            <Typography variant="label" sx={labelProps}>
              {label}
            </Typography>
          )}
        </Grid>
        {enableEnd && <Grid item>{endButton}</Grid>}
      </Grid>
    </ThemeProvider>
  );
};

export default LabelButton;
