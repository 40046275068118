import { useState } from "react";
import { useParams } from "react-router-dom";
import { cargo } from "@api";
import { PopperBinIcon } from "@material/icons";
import { ConfirmForm, PopperOptions } from "@common/comps";
import { Box } from "@mui/material";

const defaultError = {
  isError: false,
  errorText: "",
  type: "",
};

export const RecordOptions = ({ courseRec, onDelete = () => {} }) => {
  const { username } = useParams();
  const { courseRecId, quad: courseRecQuad, canEdit } = courseRec || {};

  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(false);
  const [deleteError, setDeleteError] = useState(defaultError);

  const handleDelete = async (event) => {
    event.stopPropagation();
    if (courseRecId && courseRecQuad && canEdit) {
      setIsDeleteLoading(true);
      cargo
        .delete(
          `/v2/u/${username}/q/${courseRecQuad}/course/record/${courseRecId}`
        )
        .then((res) => {
          const { ok, status, message } = res || {};
          if (ok) {
            onDelete();
          } else {
            setIsDeleteLoading(false);
            setIsDeleteDisabled(true);
            setDeleteError({ isError: true, errorText: message, type: status });
          }
        });
    }
  };

  const menuItemsList = [
    {
      key: "course-record-options__delete",
      label: "Delete record",
      icon: <PopperBinIcon />,
      isRed: true,
      handleClick: (event) => {
        setIsConfirmDeleteOpen(true);
        event.stopPropagation();
      },
      isRestricted: true,
      auth: canEdit,
    },
  ];

  return (
    <>
      <PopperOptions menuItemsList={menuItemsList} />
      {isConfirmDeleteOpen && (
        <Box onClick={(event) => event.stopPropagation()}>
          <ConfirmForm
            title="Delete record?"
            subtitle="You will be able to collect it again after you've deleted it."
            color="red"
            isFormOpen={isConfirmDeleteOpen}
            setIsFormOpen={setIsConfirmDeleteOpen}
            submitLabel="Delete"
            handleSubmit={handleDelete}
            submitState={{
              isSubmitDisabled: isDeleteDisabled,
              isSubmitLoading: isDeleteLoading,
              submitError: deleteError,
            }}
          />
        </Box>
      )}
    </>
  );
};
