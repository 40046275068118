import { Box, Typography, createTheme, ThemeProvider } from "@mui/material";
import { omit } from "radash";

const badgeTheme = createTheme({
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: "label" },
          style: {
            display: "flex",
            whiteSpace: "nowrap",
            fontSize: "12px",
            fontWeight: "500",
            color: "var(--quad-gray)",
            letterSpacing: "0.02em",
          },
        },
      ],
    },
  },
});

const Outlined = ({ label }) => {
  return (
    <Box
      px={1.25}
      py={0.25}
      border="1px solid var(--button-border-gray)"
      borderRadius={9999}
    >
      <Typography variant="label">{label}</Typography>
    </Box>
  );
};

const Badge = (props) => {
  const { variant } = props;

  switch (variant) {
    default:
      return <Outlined {...omit(props, ["variant"])} />;
  }
};

const Styled = (props) => {
  return (
    <ThemeProvider theme={badgeTheme}>
      <Badge {...props} />
    </ThemeProvider>
  );
};

export default Styled;
