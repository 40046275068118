import { useNavigate, useParams } from "react-router-dom";
import { Divider } from "@common/comps";
import { getCardDate } from "@common/utility";
import { Grid, Typography, createTheme, ThemeProvider } from "@mui/material";

const authorTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          letterSpacing: "0.01em",
          display: "flex",
        },
      },
      variants: [
        {
          props: { variant: "name" },
          style: {
            fontSize: "15px",
            fontWeight: "600",
            color: "var(--quad-black)",
          },
        },
        {
          props: { variant: "anon" },
          style: {
            fontSize: "15px",
            fontWeight: "600",
            color: "var(--quad-gray)",
            letterSpacing: "0.02em",
          },
        },
        {
          props: { variant: "username" },
          style: {
            fontSize: "15px",
            fontWeight: "400",
            color: "var(--quad-gray)",
          },
        },
        {
          props: { variant: "credential" },
          style: {
            fontSize: "15px",
            fontWeight: "400",
            color: "var(--quad-gray)",
          },
        },
        {
          props: { variant: "date" },
          style: {
            fontSize: "14px",
            fontWeight: "400",
            color: "var(--quad-gray)",
          },
        },
      ],
    },
  },
});

const getDate = (date) => {
  const cardDate = getCardDate(date);
  if (cardDate) return <Typography variant="date">{cardDate}</Typography>;
};

const Author = ({ data, dateless, clickableName, handleNameClick, size }) => {
  const navigate = useNavigate();
  const { username: paramUsername } = useParams();

  const { type, name, username, createdAt, credential, isAnon } = data || {};

  const hasAuthor = !Boolean(type === "confession" || isAnon);

  const onNameClick = (event) => {
    event.stopPropagation();
    if (clickableName) {
      if (handleNameClick) {
        handleNameClick(event);
      } else {
        if (username === paramUsername) {
          window.scrollTo({ top: 0 });
        } else {
          navigate(`/u/${username}`, { state: { origin: "author" } });
        }
      }
    }
  };

  const defaultContent = (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      alignItems="center"
      gap={0.35}
    >
      <Grid item>
        <Typography
          variant="name"
          onClick={onNameClick}
          sx={{
            ...(size && { fontSize: size }),
            ...(clickableName && {
              ":hover": {
                cursor: "pointer",
                textDecoration: "underline",
                textUnderlineOffset: "0.1em",
              },
            }),
          }}
        >
          {name}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant="username"
          sx={{ ...(size && { fontSize: size }) }}
        >{`/${username}`}</Typography>
      </Grid>
    </Grid>
  );

  const anonContent = <Typography variant="anon">Anon</Typography>;

  return (
    <ThemeProvider theme={authorTheme}>
      <Grid container direction="column" wrap="nowrap" lineHeight={1.5}>
        <Grid item>
          <Grid
            container
            direction="row"
            wrap="nowrap"
            alignItems="center"
            gap={1}
          >
            {hasAuthor ? (
              <Grid item>{defaultContent}</Grid>
            ) : (
              <Grid item>{anonContent}</Grid>
            )}

            {!dateless && <Divider />}

            {!dateless && <Grid item>{getDate(createdAt)}</Grid>}
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="credential">{credential}</Typography>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Author;
