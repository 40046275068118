import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalContext } from "@context";
import { Grid, Button, Box, ThemeProvider, Typography } from "@mui/material";
import { buttonTheme } from "@material/themes";
import {
  QuadIcon,
  QuadSelectedIcon,
  BellIcon,
  BellSelectedIcon,
  ProfileIcon,
  ProfileSelectedIcon,
  SettingsIcon,
  SettingsSelectedIcon,
  NavRootIcon,
  NavRootSelIcon,
  GeneralLogoIcon,
} from "@material/icons";

import { Options } from "./Options";

import "./styles.css";

const NavButton = ({
  icon,
  activeIcon,
  isActive,
  handleClick,
  buttonStyle,
}) => {
  return (
    <ThemeProvider theme={buttonTheme}>
      <Button variant="icon" onClick={handleClick} sx={buttonStyle}>
        <Box sx={{ display: "flex", width: "30px", height: "30px" }}>
          {isActive ? activeIcon : icon}
        </Box>
      </Button>
    </ThemeProvider>
  );
};

export const LeftBar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { session } = useContext(GlobalContext);
  const { sessOwner, defaultQuad, rootAccess } = session || {};

  const handleHome = () => {
    if (pathname !== `/q/${defaultQuad}`) {
      navigate(`/q/${defaultQuad}`);
    } else {
      window.scrollTo({ top: 0 });
    }
  };

  const navItems = [
    {
      icon: <GeneralLogoIcon />,
      buttonStyle: {
        "&:hover": {
          backgroundColor: "var(--quad-teal-light)",
        },
      },
      handleClick: handleHome,
    },
    {
      icon: <QuadIcon />,
      activeIcon: <QuadSelectedIcon />,
      isActive: pathname.startsWith(`/q/${defaultQuad}`),
      handleClick: handleHome,
    },
    {
      icon: <BellIcon />,
      activeIcon: <BellSelectedIcon />,
      isActive: pathname === "/notifs",
      handleClick: () => navigate("/notifs"),
    },
    {
      icon: <SettingsIcon />,
      activeIcon: <SettingsSelectedIcon />,
      isActive: pathname.split("/")[1] === "settings",
      handleClick: () => navigate("/settings"),
    },
    {
      icon: <ProfileIcon />,
      activeIcon: <ProfileSelectedIcon />,
      isActive: pathname.startsWith(`/u/${sessOwner}`),
      handleClick: () => {
        if (!pathname.startsWith(`/u/${sessOwner}`)) {
          navigate(`/u/${sessOwner}`);
        } else {
          window.scrollTo({ top: 0 });
        }
      },
    },
    ...(rootAccess
      ? [
          {
            icon: <NavRootIcon />,
            activeIcon: <NavRootSelIcon />,
            isActive: pathname.startsWith("/root"),
            handleClick: () => navigate("/root"),
          },
        ]
      : []),
  ];

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      alignItems="center"
      id="left-bar"
    >
      {/*       <Grid item py={1}>
        <Box
          px={1.1}
          py={0.5}
          sx={{ backgroundColor: "var(--quad-black)", borderRadius: 2 }}
        >
          <Typography
            sx={{
              fontFamily: "Inter",
              color: "white",
              fontSize: "12px",
              fontWeight: "500",
              letterSpacing: "0.025em",
            }}
          >
            BETA
          </Typography>
        </Box>
      </Grid> */}
      {navItems.map((n, i) => (
        <Grid item key={i}>
          <NavButton {...n} />
        </Grid>
      ))}
      <Grid item>
        <Options />
      </Grid>
    </Grid>
  );
};
