import { createContext, useContext, useState, useEffect } from "react";
import { GlobalContext } from "@context";
import { useTranche2 } from "@common/hooks";
import { unique } from "radash";

export const NotifContext = createContext();

export const NotifProvider = ({ children }) => {
  const {
    session: {
      defaultQuad,
      options: { quadOptions },
    },
  } = useContext(GlobalContext);

  const dfQ = quadOptions.find((q) => q.quad === defaultQuad);

  // Filters
  const [quadF, setQuadF] = useState(dfQ ? dfQ : null);

  // Feed
  const {
    initTranche,
    resetTranche,
    isLoading,
    feed,
    lastCardRef,
    setFirstId,
  } = useTranche2(`/v2/q/${quadF.quad}/notification/tranche`);

  useEffect(() => {
    if (feed.length > 0) {
      setFirstId(feed[0].maxNotifId);
    }
  }, []);

  useEffect(() => {
    if (dfQ) {
      initTranche();
    }
  }, []);

  useEffect(() => {
    if (JSON.stringify(dfQ) !== JSON.stringify(quadF)) {
      resetTranche();
    }
  }, [quadF]);

  return (
    <NotifContext.Provider
      value={{
        quadF,
        setQuadF,
        isLoading,
        lastCardRef,
        feed: unique(feed, (n) => n.notifId),
      }}
    >
      {children}
    </NotifContext.Provider>
  );
};

export const useNotif = () => {
  const context = useContext(NotifContext);
  if (!context) {
    return null;
  } else {
    return context;
  }
};
