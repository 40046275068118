import { useState } from "react";
import { NumberInput, VanillaInput } from "@common/comps";
import { TextField, Typography, Box, Grid, ThemeProvider } from "@mui/material";
import { inputTheme } from "@material/themes";
import { omit } from "radash";

const Counter = ({ count, limit }) => (
  <Box
    px={1}
    sx={{
      width: "100%",
      height: "0px",
      display: "flex",
      justifyContent: "flex-end",
      position: "relative",
      top: 5,
    }}
  >
    <Typography
      variant="counter"
      sx={{ color: count > limit && "var(--quad-red)" }}
    >
      {count}/{limit}
    </Typography>
  </Box>
);

const Default = ({
  label,
  disabled,
  state,
  setState,
  handleChange,
  counted,
  limit,
  error,
  handleEnterDown = () => {},
}) => {
  const { isError = false, errorText = "", type = "" } = error || {};

  const [isFocused, setIsFocused] = useState(false);

  const onChange = handleChange
    ? handleChange
    : (event) => setState(event.target.value);

  return (
    <ThemeProvider theme={inputTheme}>
      {Boolean(state && counted && isFocused) && (
        <Counter count={state.length} limit={limit} />
      )}
      <TextField
        variant="filled"
        label={label || "Input"}
        value={state}
        onChange={onChange}
        disabled={disabled}
        error={isError}
        helperText={errorText}
        InputProps={{
          spellCheck: false,
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onKeyDown={(event) => {
          if (event.key === "Enter") handleEnterDown();
        }}
        sx={{ width: "100%" }}
      />
    </ThemeProvider>
  );
};

const Multiline = ({
  label,
  disabled,
  state,
  setState,
  handleChange,
  rows,
  counted,
  limit,
  error,
}) => {
  const { isError = false, errorText = "", type = "" } = error || {};

  const [isFocused, setIsFocused] = useState(false);

  const onChange = handleChange
    ? handleChange
    : (event) => setState(event.target.value);

  return (
    <ThemeProvider theme={inputTheme}>
      {Boolean(state && counted && isFocused) && (
        <Counter count={state.length} limit={limit} />
      )}
      <TextField
        variant="filled"
        multiline
        rows={rows || 8}
        label={label || "Input"}
        value={state}
        onChange={onChange}
        disabled={disabled}
        error={isError}
        helperText={errorText}
        InputProps={{
          spellCheck: false,
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        sx={{ width: "100%" }}
      />
    </ThemeProvider>
  );
};

const Password = ({
  label,
  disabled,
  isHidden = true,
  state,
  setState,
  handleChange,
  error,
  handleEnterDown = () => {},
}) => {
  const { isError = false, errorText = "", type = "" } = error || {};

  const [isFocused, setIsFocused] = useState(false);

  const onChange = handleChange
    ? handleChange
    : (event) => setState(event.target.value);

  return (
    <ThemeProvider theme={inputTheme}>
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <TextField
            variant="filled"
            label={label || "Password"}
            type={isHidden ? "password" : "text"}
            value={state}
            onChange={onChange}
            disabled={disabled}
            error={isError}
            helperText={errorText}
            InputProps={{
              spellCheck: false,
            }}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onKeyDown={(event) => {
              if (event.key === "Enter") handleEnterDown();
            }}
            sx={{ width: "100%" }}
          />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

const Input = (props) => {
  const { variant, multiline } = props || {};

  const childrenProps = omit(props, ["variant"]);

  if (multiline) {
    return <Multiline {...omit(props, ["variant", "multiline"])} />;
  } else {
    switch (variant) {
      case "vanilla":
        return <VanillaInput {...childrenProps} />;
      case "multiline":
        return <Multiline {...childrenProps} />;
      case "number":
        return <NumberInput {...childrenProps} />;
      case "password":
        return <Password {...childrenProps} />;
      default:
        return <Default {...childrenProps} />;
    }
  }
};

export default Input;
