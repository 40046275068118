import { forwardRef, useEffect, useState } from "react";
import { Author, Description, BasicRating, BasicAvatar } from "@common/comps";
import { Grid, ThemeProvider } from "@mui/material";
import { cardTheme } from "@material/themes";

import { EvaluationOptions } from "./EvaluationOptions";

export const EvaluationCard = forwardRef(({ e, onDelete }, ref) => {
  const { rating, text, username, avatarSrc, isAnon } = e || {};

  const [isDeleted, setIsDeleted] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (isDeleted) onDelete();
    }, 200);
  }, [isDeleted]);

  const content = (
    <Grid container direction="column" wrap="nowrap" gap={0.5}>
      <Grid item>
        <Author data={e} />
      </Grid>
      <Grid item>
        <BasicRating readOnly value={rating} size={18} />
      </Grid>
      <Grid item>
        <Description text={text} />
      </Grid>
    </Grid>
  );

  const card = (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      borderBottom={!isDeleted && "1px solid var(--border-gray)"}
      p={!isDeleted && "12px 16px"}
      className={isDeleted ? "card--empty" : ""}
    >
      {!isDeleted && (
        <>
          <Grid item mr={2}>
            <BasicAvatar
              username={username}
              avatarSrc={avatarSrc}
              isAnon={isAnon}
              disableClick={isAnon}
            />
          </Grid>
          <Grid item sx={{ width: "100%" }}>
            {content}
          </Grid>
          <Grid item>
            <EvaluationOptions e={e} onDelete={onDelete} />
          </Grid>
        </>
      )}
    </Grid>
  );

  return (
    <ThemeProvider theme={cardTheme}>
      <article ref={ref}>{card}</article>
    </ThemeProvider>
  );
});
