import { useNavigate } from "react-router-dom";
import {
  Grid,
  Box,
  Button,
  Typography,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { buttonTheme } from "@material/themes";

const courseButtonTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          display: "flex",
          letterSpacing: "0.02em",
        },
      },
      variants: [
        {
          props: { variant: "subjectCode" },
          style: {
            fontSize: "12px",
            fontWeight: "700",
            color: "var(--quad-teal)",
          },
        },
        {
          props: { variant: "courseCode" },
          style: {
            fontSize: "12px",
            fontWeight: "700",
            color: "var(--quad-gray)",
          },
        },
        {
          props: { variant: "courseTitle" },
          style: {
            fontSize: "13px",
            fontWeight: "500",
            color: "var(--quad-gray)",
            overflowWrap: "anywhere",
          },
        },
      ],
    },
  },
});

const BasicCourseButton = ({
  course,
  handleClick,
  labelColors = {},
  hasEndButton,
  endButtonLabel,
  handleEndButton,
  endButtonVariant,
  endButtonLabelVariant,
  endButtonLabelProps,
}) => {
  const navigate = useNavigate();

  const { subjectCode, courseCode, courseTitle, courseId, quad } = course || {};

  const { subjectCodeColor, courseCodeColor, courseTitleColor } =
    labelColors || {};

  const endButton = (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant={endButtonVariant ? endButtonVariant : "stealth--teal"}
        sx={{ padding: "2px 10px" }}
        onClick={handleEndButton}
      >
        <Typography
          variant={
            endButtonLabelVariant ? endButtonLabelVariant : "stealth--teal"
          }
          sx={endButtonLabelProps ? endButtonLabelProps : { fontSize: "12px" }}
        >
          {endButtonLabel ? endButtonLabel : "Click"}
        </Typography>
      </Button>
    </ThemeProvider>
  );

  const card = (
    <Grid container direction="row" wrap="nowrap" alignItems="center">
      <Grid item mr={0.5}>
        <Typography
          variant="subjectCode"
          sx={{ color: subjectCodeColor ? subjectCodeColor : "" }}
        >
          {subjectCode}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant="courseCode"
          sx={{ color: courseCodeColor ? courseCodeColor : "" }}
        >
          {courseCode}
        </Typography>
      </Grid>
      <Grid item mx={1}>
        <Box
          sx={{
            width: "1px",
            height: "0.8em",
            backgroundColor: "var(--div-gray)",
          }}
        />
      </Grid>
      <Grid item>
        <Typography
          variant="courseTitle"
          sx={{ color: courseTitleColor ? courseTitleColor : "" }}
        >
          {courseTitle}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <ThemeProvider theme={courseButtonTheme}>
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <Grid
            container
            direction="row"
            wrap="nowrap"
            alignItems="center"
            onClick={
              handleClick
                ? handleClick
                : (event) => {
                    event.stopPropagation();
                    navigate(`/q/${quad}/c/${courseId}`);
                  }
            }
            sx={{
              padding: "4px 10px",
              border: "1px solid var(--attachment-border-gray)",
              borderRadius: "5px",
              "&:hover": {
                cursor: "pointer",
                backgroundColor: "var(--card-hover-gray)",
                transition: "background-color 200ms",
              },
            }}
          >
            <Grid item sx={{ width: "100%" }}>
              {card}
            </Grid>
            {hasEndButton && <Grid item>{endButton}</Grid>}
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default BasicCourseButton;
