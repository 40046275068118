import { GeneralNullIcon } from "@material/icons";
import { Grid, Typography, createTheme, ThemeProvider } from "@mui/material";
import { omit } from "radash";

const emptyLabelTheme = createTheme({
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: "label" },
          style: {
            display: "flex",
            fontSize: "15px",
            fontWeight: "400",
            color: "var(--quad-gray-stealth)",
            letterSpacing: "0.02em",
          },
        },
      ],
    },
  },
});

const EmptyLabel = (props) => {
  const { label, labelSx } = props;

  const iconSize = 18;

  return (
    <ThemeProvider theme={emptyLabelTheme}>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        alignItems="center"
        gap={2}
        {...omit(props, ["label", "labelSx"])}
      >
        <Grid item px={0.15} sx={{ display: "flex" }}>
          <GeneralNullIcon width={iconSize} height={iconSize} />
        </Grid>
        <Grid item>
          <Typography variant="label" sx={{ ...labelSx }}>
            {label}
          </Typography>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default EmptyLabel;
