import { useState, useEffect } from "react";
import { cargo } from "@api";
import { BasicAvatar, Search, Spinner } from "@common/comps";
import { Box, Typography } from "@mui/material";
import { DefaultFieldAltAvatar } from "@material/images";
import { unique } from "radash";

const FieldSearch = ({
  initialValue = "",
  label,
  field,
  setField,
  handleInputChange,
  handleChange,
  disabled,
}) => {
  const [search, setSearch] = useState(field ? field.fieldName : initialValue);

  const [isLoading, setIsLoading] = useState(false);
  const [fieldOptions, setFieldOptions] = useState([]);

  const [selected, setSelected] = useState(Boolean(field));

  useEffect(() => {
    if (selected) {
      setSelected(false);
    } else {
      setField(null);
    }

    if (search) {
      setIsLoading(true);
      setFieldOptions([]);
      cargo.get(`/v2/fields?type=name&term=${search}`).then((res) => {
        setIsLoading(false);
        const { ok, payload } = res || {};
        if (ok) {
          const uniques = unique(payload, (f) => f.fieldId);
          const N = uniques.length;
          setFieldOptions(uniques.slice(0, N > 10 ? 10 : N));
        }
      });
    }
  }, [search]);

  const renderOption = (props, option) => {
    if (isLoading) {
      return (
        <Box key={"field-search__spinner"} px={2} py={1}>
          <Spinner />
        </Box>
      );
    } else {
      return (
        <Box component="li" {...props}>
          <Typography variant="field">{option.fieldName}</Typography>
        </Box>
      );
    }
  };

  const onChange = (...args) => {
    const [event, val] = args;

    setField && setField(val);
    setSearch(val.fieldName);
    setSelected(true);

    handleChange && handleChange(...args);
  };

  return (
    <Search
      elementId="field-search__search"
      label={label || "Field"}
      search={search}
      setSearch={setSearch}
      options={fieldOptions}
      getOptionLabel={(option) => option.fieldName}
      renderOption={renderOption}
      handleInputChange={handleInputChange}
      handleChange={onChange}
      disabled={disabled}
      InputProps={{
        ...(field && {
          startAdornment: (
            <Box mx={0.5}>
              <BasicAvatar
                diameter={20}
                defaultSrc={DefaultFieldAltAvatar}
                disableClick
              />
            </Box>
          ),
        }),
      }}
    />
  );
};

export default FieldSearch;
