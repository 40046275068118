import { Grid, Typography, ThemeProvider, createTheme } from "@mui/material";

const feedNoticeCardTheme = createTheme({
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: "label" },
          style: {
            fontSize: "14px",
            fontWeight: "400",
            color: "var(--quad-gray-alt)",
            letterSpacing: "0.01em",
          },
        },
      ],
    },
  },
});

const FeedNoticeCard = ({ label }) => {
  return (
    <ThemeProvider theme={feedNoticeCardTheme}>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        justifyContent="center"
        sx={{
          padding: "12px 15px",
          borderBottom: "1px solid var(--border-gray)",
        }}
      >
        <Grid item>
          <Typography variant="label">{label}</Typography>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default FeedNoticeCard;
