import { useNavigate } from "react-router-dom";
import { CourseLabel, PageLinkButton } from "@common/comps";
import { getScoreDisplay } from "@common/utility";
import { useCourse, useQuad } from "@context";
import { Grid, Box, Typography } from "@mui/material";

import { CourseOptions } from "../options/CourseOptions";
import { CourseDetails } from "./CourseDetails";
import { CourseLinks } from "./CourseLinks";

export const CourseHero = () => {
  const { course } = useCourse();
  const { courseTitle, courseDesc, childCount } = course || {};

  const hero = (
    <Grid container direction="column" wrap="nowrap" gap={0.5}>
      <Grid item>
        <CourseLabel variant="borderless" hideTitle course={course} />
      </Grid>
      <Grid item>
        <Typography variant="course__title">{courseTitle}</Typography>
      </Grid>
      <Grid item>
        <Box
          sx={{
            lineHeight: "22px",
            overflowY: "hidden",
          }}
        >
          {courseDesc ? (
            <Typography variant="course__desc">{courseDesc}</Typography>
          ) : (
            <Typography variant="course__desc--empty">
              No description added.
            </Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  );

  const footer = (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      sx={{
        borderBottom: "1px solid var(--border-gray)",
        padding: "0px 2px 5px",
      }}
    >
      <Grid item sx={{ marginRight: "5px" }}>
        <Typography variant="stats__count">
          {getScoreDisplay(childCount)}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="stats__label">
          {childCount !== 1 ? "comments" : "comment"}
        </Typography>
      </Grid>
    </Grid>
  );

  const content = (
    <Grid container direction="column" wrap="nowrap" gap={1}>
      <Grid item mb={1}>
        {hero}
      </Grid>
      <Grid item mb={2}>
        <CourseDetails />
      </Grid>
      <Grid item mb={2}>
        <CourseLinks />
      </Grid>
      <Grid item>{footer}</Grid>
    </Grid>
  );

  const Options = () => <CourseOptions />;

  return (
    <Grid container direction="row" wrap="nowrap" p={2} pb={0}>
      <Grid item sx={{ width: "100%" }}>
        {content}
      </Grid>
      <Grid
        item
        sx={{ width: "0px", position: "relative", right: 30, bottom: 5 }}
      >
        <Options />
      </Grid>
    </Grid>
  );
};
