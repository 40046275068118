import { Spinner } from "@common/comps";
import { Box, CssBaseline } from "@mui/material";
import { omit } from "radash";
import { GeneralLogoIcon } from "@material/icons";

const Default = (props) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      {...props}
    >
      <Spinner variant="square" size={28} />
    </Box>
  );
};

const Page = (props) => {
  return (
    <>
      <CssBaseline />
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <GeneralLogoIcon width={60} height={60} />
      </Box>
    </>
  );
};

const Loading = (props) => {
  const { variant } = props || {};

  switch (variant) {
    case "page":
      return <Page {...omit(props, ["variant"])} />;
    default:
      return <Default {...omit(props, ["variant"])} />;
  }
};

export default Loading;
