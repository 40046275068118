import { createContext, useContext } from "react";

export const RootContext = createContext();

export const RootProvider = ({ children }) => {
  return <RootContext.Provider value={{}}>{children}</RootContext.Provider>;
};

export const useRoot = () => {
  const context = useContext(RootContext);
  if (!context) {
    return null;
  } else {
    return context;
  }
};
