import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AvatarDefault, AvatarAnon } from "@material/images";
import { Avatar } from "@mui/material";

const BasicAvatar = ({
  avatarSrc,
  defaultSrc,
  username,
  isAnon,
  diameter,
  handleClick,
  disableClick = false,
}) => {
  const navigate = useNavigate();

  const getSrc = () => {
    if (isAnon) {
      return AvatarAnon;
    } else {
      if (avatarSrc) {
        return avatarSrc;
      } else {
        return defaultSrc ? defaultSrc : AvatarDefault;
      }
    }
  };

  const [src, setSrc] = useState(getSrc());

  const defaultClick = (event) => {
    event.stopPropagation();
    username && navigate(`/u/${username}`);
  };

  return (
    <Avatar
      src={src}
      imgProps={{
        onError: (err) => setSrc(AvatarDefault),
      }}
      sx={{
        width: diameter ? diameter : "45px",
        height: diameter ? diameter : "45px",
        ...(!disableClick && {
          "&:hover": {
            cursor: "pointer",
          },
        }),
      }}
      onClick={
        disableClick
          ? (event) => event.stopPropagation()
          : handleClick || defaultClick
      }
    />
  );
};

export default BasicAvatar;
