import {
  ErrorGeneralIcon,
  ErrorForbiddenIcon,
  ErrorConflictIcon,
  ErrorServerIcon,
  ErrorDataIcon,
  ErrorUnauthorizedIcon,
  Error404Icon,
} from "@material/icons";
import {
  Box,
  Grid,
  Typography,
  ThemeProvider,
  createTheme,
} from "@mui/material";

const errorTheme = createTheme({
  components: {
    MuiGrid: {
      styleOverrides: {
        item: {
          marginBottom: "0px",
        },
      },
    },
  },
});

const BasicError = ({ error = {}, isGray = false, hasLabel = false }) => {
  const { isError, type, errorText } = error || {};

  const getIcon = () => {
    switch (parseInt(type)) {
      case 400:
        return <ErrorDataIcon />;
      case 401:
        return <ErrorUnauthorizedIcon />;
      case 403:
        return <ErrorForbiddenIcon />;
      case 404:
        return <Error404Icon />;
      case 409:
        return <ErrorConflictIcon />;
      case 500:
        return <ErrorServerIcon />;
      default:
        return <ErrorGeneralIcon />;
    }
  };

  return (
    <ThemeProvider theme={errorTheme}>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        alignItems="center"
        className={isGray ? "basic-error--gray" : "basic-error"}
      >
        <Grid item>
          {isError && (
            <Box
              sx={{
                width: "25px",
                height: "25px",
                margin: "0px 10px",
              }}
            >
              {getIcon()}
            </Box>
          )}
        </Grid>
        {hasLabel && errorText && (
          <Grid item sx={{ marginLeft: "5px" }}>
            <Typography
              sx={{
                fontSize: "15px",
                fontWeight: "500",
                color: isGray ? "var(--quad-gray-alt)" : "var(--quad-red)",
              }}
            >
              {errorText}
            </Typography>
          </Grid>
        )}
      </Grid>
    </ThemeProvider>
  );
};

export default BasicError;
