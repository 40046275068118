import getMonthName from "./getMonthName";

const getCardDate = (createdAt) => {
  // Takes in timestamptz and returns a formatted date
  if (createdAt) {
    const currentFullDate = new Date().toISOString();
    const currentDateArray = currentFullDate.slice(0, -1).split("T");
    const currentSplitDate = currentDateArray[0].split("-");
    const currentDate = {
      year: parseInt(currentSplitDate[0]),
      month: parseInt(currentSplitDate[1]),
      day: parseInt(currentSplitDate[2]),
    };
    const currentSplitTime = currentDateArray[1].split(":");
    const currentTime = {
      hour: parseInt(currentSplitTime[0]),
      minute: parseInt(currentSplitTime[1]),
      second: parseInt(currentSplitTime[2]),
    };

    const createdDateArray = createdAt.slice(0, -1).split("T");
    const createdSplitDate = createdDateArray[0].split("-");
    const createdDate = {
      year: parseInt(createdSplitDate[0]),
      month: parseInt(createdSplitDate[1]),
      day: parseInt(createdSplitDate[2]),
    };
    const createdSplitTime = createdDateArray[1].split(":");
    const createdTime = {
      hour: parseInt(createdSplitTime[0]),
      minute: parseInt(createdSplitTime[1]),
      second: parseInt(createdSplitTime[2]),
    };

    const sameDateCheck = currentDateArray[0] === createdDateArray[0];

    if (sameDateCheck) {
      // It is posted within the past 24 hours
      // Indicate the number of hours ago

      if (currentTime.hour === createdTime.hour) {
        if (currentTime.minute === createdTime.minute) {
          // Indicate number of seconds ago
          const secondsAgo = currentTime.second - createdTime.second;

          return `${secondsAgo > 0 ? secondsAgo : "1"}s`;
        } else {
          // Indicate number of minutes ago
          return `${currentTime.minute - createdTime.minute}m`;
        }
      } else {
        // Indicate number of hours ago
        return `${currentTime.hour - createdTime.hour}h`;
      }
    } else {
      // It is posted beyond 24 hours
      if (currentDate.year === createdDate.year) {
        // Indicate three-letter month followed by day

        return `${getMonthName(createdDate.month, "short")} ${createdDate.day}`;
      } else {
        // Indicate full date three-letter month followed by day and year
        return `${getMonthName(createdDate.month, "short")} ${
          createdDate.day
        }, ${createdDate.year}`;
      }
    }
  } else {
    return "";
  }
};

export default getCardDate;
