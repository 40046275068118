import { createContext, useEffect, useContext } from "react";
import { useTranche2 } from "@common/hooks";
import { unique } from "radash";

export const CommentContext = createContext();

export const CommentProvider = ({ children, post }) => {
  const { postId, quad } = post || {};

  const {
    initTranche,
    isLoading,
    lastCardRef,
    setFirstId,
    feed,
    setFeed,
    fresh,
    setFresh,
    isFeedEmpty,
  } = useTranche2(`/v2/q/${quad}/p/post/${postId}/comments`);

  useEffect(() => {
    if (feed.length > 0) {
      setFirstId(feed[0].maxCommentId);
    }
  }, [feed]);

  return (
    <CommentContext.Provider
      value={{
        post,
        initTranche,
        isLoading,
        lastCardRef,
        feed: unique(feed, (c) => c.commentId),
        setFeed,
        fresh,
        setFresh,
        isFeedEmpty,
      }}
    >
      {children}
    </CommentContext.Provider>
  );
};

export const useComment = () => {
  const context = useContext(CommentContext);
  if (!context) {
    return null;
  } else {
    return context;
  }
};
