import { Grid, Typography } from "@mui/material";

export const SettingsEmail = () => {
  return (
    <Grid container direction="column" wrap="nowrap" p={2}>
      <Grid item>
        <Typography
          sx={{
            fontSize: "15px",
            fontWeight: "400",
            color: "var(--quad-gray-stealth)",
          }}
        >
          Email can't be changed at the moment.
        </Typography>
      </Grid>
    </Grid>
  );
};
