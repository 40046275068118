import { createTheme } from "@mui/material";

export const settingsTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          letterSpacing: "0.02em",
        },
      },
      variants: [
        {
          props: { variant: "field__footer" },
          style: {
            fontSize: "13px",
            fontWeight: "400",
            color: "var(--quad-gray-alt)",
          },
        },
      ],
    },
  },
});
