import { useState, useContext, useReducer, useEffect } from "react";
import { cargo } from "@api";
import { GlobalContext, QuadContext } from "@context";
import {
  BasicAvatar,
  BasicForm,
  Dropdown,
  EmptyLabel,
  Loading,
  SubjectLabel,
  UserSearch,
} from "@common/comps";
import { Grid, Button, ThemeProvider } from "@mui/material";
import { buttonTheme } from "@material/themes";
import { ButtonPlusIcon } from "@material/icons";

import { Submitter } from "@tools";

export const SubjectCatalogersBuilder = ({
  subject,
  isOpen,
  setIsOpen,
  onEdit = () => {},
}) => {
  const { defaultError } = useContext(GlobalContext);
  const { quadId } = useContext(QuadContext);
  const { subjectId } = subject || {};

  const [isInitializing, setIsInitializing] = useState(true);
  const [options, setOptions] = useState({});
  const [baseline, setBaseline] = useState(null);
  const [catalogers, setCatalogers] = useReducer((state, action) => {
    switch (action.type) {
      case "init":
        return action.value;
      case "add":
        return state.find((d) => d.username === action.value.username)
          ? state
          : [...state, action.value];
      case "remove":
        return state.filter((d) => d.username !== action.value);
      default:
        return state;
    }
  }, null);

  const [stage, setStage] = useState(null);
  const [hot, setHot] = useState(false);

  useEffect(() => {
    setIsInitializing(true);
    cargo
      .get(`/v2/q/${quadId}/r/subject/${subjectId}/catalogers`)
      .then((res) => {
        const { ok, payload } = res || {};
        if (ok) {
          setIsInitializing(false);
          setBaseline(payload.catalogers);
          setCatalogers({ type: "init", value: payload.catalogers });
        }
      });
  }, []);

  const [submitState, setSubmitState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState()
  );

  const submitter = new Submitter(submitState, setSubmitState, defaultError);

  useEffect(() => {
    if (baseline && catalogers) {
      if (hot) setHot(false);

      if (!hot && Array.isArray(baseline) && Array.isArray(catalogers)) {
        const baselineIds = baseline.map((c) => c.username);
        const newIds = catalogers.map((c) => c.username);
        submitter.green(JSON.stringify(baselineIds) !== JSON.stringify(newIds));
        submitter.resetError();
      }
    }
  }, [catalogers]);

  const Search = () => (
    <UserSearch label="Username" user={stage} setUser={setStage} />
  );

  const content = isInitializing ? (
    <Loading p={2} />
  ) : (
    baseline !== null && (
      <Grid container direction="column" wrap="nowrap" gap={1}>
        <Grid
          item
          px={1}
          pb={1}
          borderBottom="1px solid var(--attachment-border-gray)"
        >
          <SubjectLabel subject={subject} />
        </Grid>
        <Grid item>
          <Grid container direction="column" gap={1}>
            {catalogers.length === 0 ? (
              <EmptyLabel label="No catalogers appointed." px={0.5} py={2} />
            ) : (
              catalogers.map((c) => (
                <Grid item key={c.username}>
                  <Dropdown
                    card
                    startAdornment={
                      <BasicAvatar
                        src={c.avatarSrc}
                        diameter={40}
                        disableClick
                      />
                    }
                    label={c.name}
                    subLabel={`/${c.username}`}
                    handleDelete={() =>
                      setCatalogers({ type: "remove", value: c.username })
                    }
                  />
                </Grid>
              ))
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            wrap="nowrap"
            alignItems="center"
            gap={2}
          >
            <Grid item sx={{ width: "100%" }}>
              <Search />
            </Grid>
            <Grid item>
              <ThemeProvider theme={buttonTheme}>
                <Button
                  variant="icon"
                  onClick={() => {
                    stage && setCatalogers({ type: "add", value: stage });
                    stage && setStage(null);
                  }}
                >
                  <ButtonPlusIcon width={18} height={18} />
                </Button>
              </ThemeProvider>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  );

  const handleSubmit = () => {
    submitter.startLoading();
    const payload = catalogers.map((c) => c.username);
    cargo
      .put(`/v2/q/${quadId}/r/subject/${subjectId}/catalogers`, {
        catalogers: payload,
      })
      .then((res) => {
        const { ok } = res || {};
        if (ok) {
          onEdit();
        } else {
          setHot(true);
          submitter.takeResponse(res);
        }
      });
  };

  return (
    isOpen && (
      <BasicForm
        title="Edit catalogers"
        isFormOpen={isOpen}
        setIsFormOpen={setIsOpen}
        content={content}
        submitState={{
          isSubmitLoading: submitState.isLoading,
          isSubmitDisabled: submitState.isDisabled,
          submitError: submitState.error,
        }}
        handleSubmit={handleSubmit}
      />
    )
  );
};
