import { useState, useEffect } from "react";
import { omit } from "radash";
import { BasicError, Spinner } from "@common/comps";
import { ThemeProvider, Button, Box, Grid } from "@mui/material";
import { buttonTheme } from "@material/themes";

const FullWidth = (props) => {
  const { submitState, buttonSx = {}, buttonLabel, handleClick } = props || {};

  const { isDisabled, isLoading, error } = submitState || {};

  const [minWidth, setMinWidth] = useState(0);
  const [minHeight, setMinHeight] = useState(0);

  useEffect(() => {
    const element = document.getElementById("submitter__button-container");
    const width = element ? element.offsetWidth : 0;
    const height = element ? element.offsetHeight : 0;
    setMinWidth(width);
    setMinHeight(height);
  }, []);

  const button = (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant="filled"
        color="black"
        disabled={isDisabled}
        onClick={handleClick}
        sx={{ width: "inherit", ...buttonSx }}
      >
        {buttonLabel || "Submit"}
      </Button>
    </ThemeProvider>
  );

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      gap={1}
      sx={{ width: "inherit !important" }}
    >
      <Grid
        item
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box>
          <BasicError error={error} />
        </Box>
      </Grid>
      <Grid item>
        <Box
          id="submitter__button-container"
          sx={{
            minWidth: minWidth || "",
            minHeight: minHeight || "",
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isLoading ? <Spinner size={25} /> : button}
        </Box>
      </Grid>
    </Grid>
  );
};

const Default = (props) => {};

export const SubmitButton = (props) => {
  const { variant } = props || {};

  switch (variant) {
    case "fullWidth":
      return <FullWidth {...omit(props, ["variant"])} />;
    default:
      return <Default {...omit(props, ["variant"])} />;
  }
};
