import { useState, useEffect, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import { Ballot } from "@tools";
import {
  CourseLabel,
  Description,
  Detail,
  CourseCoreLabel,
  CourseReqLabel,
  Action,
  Divider,
} from "@common/comps";
import { getCourseLevelIcon } from "@common/utility";
import { Grid, Box, Typography, ThemeProvider } from "@mui/material";
import { cardTheme } from "@material/themes";
import { ActionActivityIcon, ActionCommentIcon } from "@material/icons";

const Details = ({ course }) => {
  const {
    creqs,
    courseCores,
    courseUnits,
    courseLevel,
    courseLevelName,
    coursePre,
    courseNotes,
    deptName,
    courseRestricted,
    coursePriority,
    courseConsent,
  } = course || {};

  const reqLabels =
    Array.isArray(creqs) && creqs.length > 0 ? (
      <Grid container direction="row" sx={{ gap: 0.5 }}>
        {creqs.map((req) => {
          return (
            <Grid item key={req.creqId}>
              <CourseReqLabel req={req} />
            </Grid>
          );
        })}
      </Grid>
    ) : (
      <Box>
        <CourseReqLabel isNull />
      </Box>
    );

  const coreLabels =
    Array.isArray(courseCores) && courseCores.length > 0 ? (
      <Grid container direction="row" sx={{ gap: 0.5 }}>
        {courseCores.map((core) => (
          <Grid item key={core.courseCoreId}>
            <CourseCoreLabel core={core} />
          </Grid>
        ))}
      </Grid>
    ) : (
      <Box>
        <CourseCoreLabel isNull />
      </Box>
    );

  const moreLeft = (
    <Grid container direction="column" wrap="nowrap" sx={{ gap: 0.5 }}>
      <Grid item>
        <Detail type="req" labelComponent={reqLabels} labelLineHeight="24px" />
      </Grid>
      <Grid item>
        <Detail
          type="core"
          labelComponent={coreLabels}
          labelLineHeight="24px"
        />
      </Grid>
    </Grid>
  );

  const moreRight = (
    <Grid container direction="column" wrap="nowrap" sx={{ gap: 0.5 }}>
      <Grid item>
        <Detail type="units" label={`${Number(courseUnits)} Units`} />
      </Grid>
      <Grid item>
        <Detail
          icon={getCourseLevelIcon(courseLevel)}
          label={courseLevelName}
        />
      </Grid>
    </Grid>
  );

  const moreDetails = (
    <Grid container direction="row" wrap="nowrap" gap={1.5}>
      <Grid item sx={{ width: "60%" }}>
        {moreLeft}
      </Grid>

      <Divider height="inherit" />

      <Grid item>{moreRight}</Grid>
    </Grid>
  );

  const listItems = [
    {
      type: "dept",
      value: deptName,
      canRender: deptName,
    },
    {
      type: "consent",
      value: courseConsent,
      canRender: courseConsent,
    },
    {
      type: "prereq",
      value: coursePre,
      canRender: coursePre,
    },
    {
      type: "restricted",
      value: courseRestricted,
      canRender: courseRestricted,
    },
    {
      type: "priority",
      value: coursePriority,
      canRender: coursePriority,
    },
    {
      type: "notes",
      value: courseNotes,
      canRender: courseNotes,
    },
  ];

  const toRender = listItems.filter((d) => Boolean(d.canRender));

  const list = toRender.map((d, i) => (
    <Grid item key={i}>
      <Detail type={d.type} label={d.value} />
    </Grid>
  ));

  return (
    <Grid container direction="column" wrap="nowrap" gap={0.5}>
      {list}
      <Grid item mt={1}>
        {moreDetails}
      </Grid>
    </Grid>
  );
};

export const CourseCard = forwardRef(({ course, onVote = () => {} }, ref) => {
  const navigate = useNavigate();

  const [courseData, setCourseData] = useState(course);
  const [isDeleted, setIsDeleted] = useState(false);

  const {
    postId,
    quad,
    totalUpvotes,
    totalDownvotes,
    totalScore,
    sessOwnerScore,
    childCount,
    courseId,
    courseTitle,
    courseDesc,
  } = courseData || {};

  useEffect(() => {
    // Remove from state
  }, [isDeleted]);

  const [commentCount, setCommentCount] = useState(childCount);

  /* Required for activityScore updates */
  const [baseSessOwnerScore, setBaseSessOwnerScore] = useState(sessOwnerScore);
  const [baseActivityScore, setBaseActivityScore] = useState(
    totalUpvotes + totalDownvotes
  );
  const [activityScore, setActivityScore] = useState(baseActivityScore);

  const handleClick = (event) => {
    const selection = window.getSelection();
    if (selection.type === "Range") {
      event.stopPropagation();
    } else {
      navigate(`/q/${quad}/c/${courseId}`);
    }
  };

  const Vote = () => (
    <Ballot
      type="post"
      id={postId}
      quad={quad}
      totalScore={totalScore}
      sessOwnerScore={sessOwnerScore}
      onVote={onVote}
    />
  );

  const Activity = () => (
    <Action
      variant="simple"
      icon={<ActionActivityIcon />}
      helperText={String(activityScore)}
    />
  );

  const Respond = () => (
    <Action
      icon={<ActionCommentIcon />}
      helperText={String(commentCount)}
      handleClick={() => navigate(`/q/${quad}/c/${courseId}`)}
      onHoverSx={{
        iconSx: {
          backgroundColor: "var(--quad-green-light)",

          "#action-icon--comment": {
            fill: "var(--quad-green)",
            transition: "fill 200ms",
          },
        },
        helperTextSx: {
          color: "var(--quad-green)",
        },
      }}
    />
  );

  const actions = (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      gap={1}
      sx={{ position: "relative", right: 8 }}
    >
      <Grid item>
        <Vote />
      </Grid>
      <Grid item>
        <Respond />
      </Grid>
      <Grid item>
        <Activity />
      </Grid>
    </Grid>
  );

  const css = {
    borderBottom: isDeleted ? "" : `1px solid var(--border-gray)`,
    padding: `${isDeleted ? "0px" : "12px 16px"}`,
    gap: 0.5,
    "&:hover": {
      backgroundColor: isDeleted ? "" : "var(--card-hover-gray)",
      cursor: isDeleted ? "" : "pointer",
      transition: "background-color 200ms",
    },
  };

  const card = (
    <ThemeProvider theme={cardTheme}>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        sx={css}
        className={isDeleted ? "card--empty" : ""}
        onClick={handleClick}
      >
        {!isDeleted && (
          <>
            <Grid item>
              <CourseLabel variant="borderless" hideTitle course={course} />
            </Grid>
            <Grid item>
              <Typography variant="courseTitle">{courseTitle}</Typography>
            </Grid>
            <Grid item>
              <Description text={courseDesc} />
            </Grid>
            <Grid item mt={1}>
              <Details course={course} />
            </Grid>
            <Grid item mt={1}>
              {actions}
            </Grid>
          </>
        )}
      </Grid>
    </ThemeProvider>
  );

  return <article ref={ref ? ref : undefined}>{card}</article>;
});

export default CourseCard;
