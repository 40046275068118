import { useNavigate } from "react-router-dom";
import { IconButton } from "@common/comps";
import {
  Grid,
  Box,
  Typography,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { ButtonBackwardIcon } from "@material/icons";

const headerTheme = createTheme({
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: "default" },
          style: {
            fontSize: "20px",
            fontWeight: "700",
            color: "var(--quad-black)",
          },
        },
        {
          props: { variant: "more" },
          style: {
            fontSize: "16px",
            fontWeight: "500",
            color: "var(--quad-black)",
          },
        },
      ],
    },
  },
});

const BasicHeader = ({
  title,
  titleVariant,
  titleProps,
  titleIcon,
  content2,
  enableBack = false,
  backIcon,
  backButtonProps,
  backIconProps,
  handleBack,
  backRoute,
}) => {
  const navigate = useNavigate();

  const hero = (
    <Grid container direction="row" wrap="nowrap" alignItems="center" gap={1}>
      {enableBack && (
        <Grid item sx={{ position: "relative", right: 8 }}>
          <IconButton
            buttonProps={backButtonProps}
            icon={backIcon ? backIcon : <ButtonBackwardIcon />}
            iconProps={backIconProps}
            handleClick={
              handleBack
                ? handleBack
                : () => navigate(backRoute ? backRoute : -1)
            }
          />
        </Grid>
      )}
      {titleIcon && (
        <Grid item sx={{ marginRight: "10px" }}>
          <Box sx={{ width: "20px", height: "20px" }}>{titleIcon}</Box>
        </Grid>
      )}
      <Grid item>
        <Typography
          sx={titleProps}
          variant={titleVariant ? titleVariant : "default"}
        >
          {title}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <ThemeProvider theme={headerTheme}>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "rgba(255, 255, 255, 0.85)",
          backdropFilter: "blur(10px)",
        }}
      >
        <Grid
          item
          px={2}
          sx={{
            height: "50px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {hero}
        </Grid>
        {content2 && <Grid item>{content2}</Grid>}
      </Grid>
    </ThemeProvider>
  );
};

export default BasicHeader;
