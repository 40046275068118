import { useState } from "react";
import { Opener, Builder } from "@tools";
import { Grid } from "@mui/material";

const CategoryManager = () => {};

const TopicManager = () => {};

export const RootTopics = () => {
  const [isCreateOpen, setIsCreateOpen] = useState(false);

  /* Manager states */
  const [page, setPage] = useState("category");
  const [topicCatId, setTopicCatId] = useState("");

  const Create = () => (
    <Opener
      variant="filled"
      color="black"
      label="Create topic category"
      builder={Builder.topicCategory({
        isOpen: isCreateOpen,
        setIsOpen: setIsCreateOpen,
        onCreate: (created) => {},
      })}
    />
  );

  const Manager = () => {
    const defaultManager = <CategoryManager />;

    switch (page) {
      case "category":
        return defaultManager;
      case "topic":
        return topicCatId ? <TopicManager /> : defaultManager;
      default:
        return defaultManager;
    }
  };

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item p={2} borderBottom="1px solid var(--border-gray)">
        <Create />
      </Grid>
      <Grid item>
        <Manager />
      </Grid>
    </Grid>
  );
};
