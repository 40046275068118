import { createContext, useState, useEffect, useContext } from "react";
import { cargo } from "@api";

export const SubreplyContext = createContext();

export const SubreplyProvider = ({ children, reply }) => {
  const { replyId, quad } = reply || {};

  const [isLoaded, setIsLoaded] = useState(false);

  // Loading
  const [isLoading, setIsLoading] = useState(false);
  const [feed, setFeed] = useState([]);
  const [isFeedEmpty, setIsFeedEmpty] = useState(false);

  useEffect(() => {
    if (isLoaded) {
      if (replyId && quad) {
        setIsLoading(true);
        cargo
          .post(`/v2/q/${quad}/p/reply/${replyId}/subreplies`)
          .then((res) => {
            setIsLoading(false);
            const { ok, payload = [] } = res || {};
            if (ok) {
              setFeed(payload);
              setIsFeedEmpty(payload.length === 0);
            }
          });
      }
    }
  }, [isLoaded]);

  return (
    <SubreplyContext.Provider
      value={{
        reply,
        isLoaded,
        setIsLoaded,
        isLoading,
        feed,
        setFeed,
        isFeedEmpty,
        setIsFeedEmpty,
      }}
    >
      {children}
    </SubreplyContext.Provider>
  );
};

export const useSubreply = () => {
  const context = useContext(SubreplyContext);
  if (!context) {
    return null;
  } else {
    return context;
  }
};
