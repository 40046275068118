import { useState, useEffect } from "react";
import { Ballot, Scoreman } from "@tools";
import { BasicAvatar, Author, Description, Action } from "@common/comps";
import { Grid, Box, ThemeProvider } from "@mui/material";
import { cardTheme } from "@material/themes";
import { ActionActivityIcon, ActionForkIcon } from "@material/icons";

import { ReplyOptions } from "../replyOptions/ReplyOptions";

import { SubreplySection } from "features/subreply";

export const ReplyCard = ({ reply, onDelete, hasLink }) => {
  const [isDeleted, setIsDeleted] = useState(false);
  const [isSubreplyOpen, setIsSubreplyOpen] = useState(false);
  const [replyData, setReplyData] = useState(reply);

  const {
    replyId,
    quad,
    scoreUpTotal,
    scoreDownTotal,
    totalScore,
    sessOwnerScore,
    childCount,
    username,
    avatarSrc,
    text,
  } = replyData || {};

  const [subreplyCount, setSubreplyCount] = useState(childCount);

  /* Required for activityScore update */
  const [baseSessOwnerScore, setBaseSessOwnerScore] = useState(sessOwnerScore);
  const [baseActivityScore, setBaseActivityScore] = useState(
    scoreUpTotal + scoreDownTotal
  );
  const [activityScore, setActivityScore] = useState(baseActivityScore);

  useEffect(() => {
    setTimeout(() => {
      if (isDeleted) onDelete();
    }, 200);
  }, [isDeleted]);

  const avatar = (
    <Grid container direction="column" wrap="nowrap" alignItems="center">
      <Grid item sx={{ position: "relative", top: 2 }}>
        <BasicAvatar
          username={username}
          avatarSrc={avatarSrc}
          diameter="40px"
        />
      </Grid>
      {hasLink && (
        <Grid item sx={{ height: "100%" }}>
          <Box
            sx={{
              position: "relative",
              top: 5,
              width: "2px",
              height: "100%",
              borderRadius: "9999px",
              backgroundColor: "var(--avatar-link-gray)",
            }}
          />
        </Grid>
      )}
    </Grid>
  );

  const onVote = (action) => {
    const { type } = action || {};
    if (type) {
      const newScores = Scoreman.getNewScores({
        ...action,
        base: replyData,
      });
      setReplyData((prev) => {
        return { ...prev, ...newScores };
      });
    } else {
      return;
    }
  };

  const updateActivityScore = (action) => {
    setActivityScore((prev) => {
      const newActivityScore = Scoreman.getNewActivityScore({
        ...action,
        baseSessOwnerScore,
        baseScore: baseActivityScore,
      });

      return newActivityScore;
    });
  };

  const Vote = () => (
    <Ballot
      type="reply"
      id={replyId}
      quad={quad}
      totalScore={totalScore}
      sessOwnerScore={sessOwnerScore}
      onVote={onVote}
      updateActivityScore={updateActivityScore}
    />
  );

  const Activity = () => (
    <Action
      variant="simple"
      icon={<ActionActivityIcon />}
      helperText={String(activityScore)}
    />
  );

  const onSubreplyCreate = () => {
    setSubreplyCount((prev) => prev + 1);
  };

  const onSubreplyDelete = () => {
    setSubreplyCount((prev) => (prev > 0 ? prev - 1 : 0));
  };

  const Respond = () => (
    <Action
      icon={<ActionForkIcon />}
      helperText={String(subreplyCount)}
      handleClick={() => setIsSubreplyOpen(true)}
      onHoverSx={{
        iconSx: {
          backgroundColor: "var(--quad-green-light)",

          "#action-icon--fork": {
            fill: "var(--quad-green)",
            transition: "fill 200ms",
          },
        },
        helperTextSx: {
          color: "var(--quad-green)",
        },
      }}
    />
  );

  const actions = (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      gap={1}
      sx={{ position: "relative", right: 8 }}
    >
      <Grid item>
        <Vote />
      </Grid>
      <Grid item>
        <Activity />
      </Grid>
      <Grid item>
        <Respond />
        {isSubreplyOpen && (
          <SubreplySection
            reply={reply}
            isFormOpen={isSubreplyOpen}
            setIsFormOpen={setIsSubreplyOpen}
            onSubreplyCreate={onSubreplyCreate}
            onSubreplyDelete={onSubreplyDelete}
          />
        )}
      </Grid>
    </Grid>
  );

  const content = (
    <Grid container direction="column" wrap="nowrap" gap={0.75}>
      <Grid item>
        <Author data={reply} clickableName />
      </Grid>
      <Grid item>
        <Description text={text} />
      </Grid>
      <Grid item>{actions}</Grid>
    </Grid>
  );

  return (
    <ThemeProvider theme={cardTheme}>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        gap={2}
        className={isDeleted ? "card--empty" : ""}
      >
        {!isDeleted && (
          <>
            <Grid item sx={{ display: "flex" }}>
              {avatar}
            </Grid>
            <Grid item mb={1} sx={{ width: "100%" }}>
              {content}
            </Grid>
            <Grid item sx={{ width: "0px", position: "relative", right: 30 }}>
              <ReplyOptions reply={reply} onDelete={() => setIsDeleted(true)} />
            </Grid>
          </>
        )}
      </Grid>
    </ThemeProvider>
  );
};
