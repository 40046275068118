import { useContext, useState, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { cargo } from "@api";
import { Builder, Opener, Submitter } from "@tools";
import { GlobalContext, QuadContext } from "@context";
import {
  BreadcrumbNav,
  ConfirmForm,
  Editable,
  Loadman,
  PopperOptions,
  Tab,
} from "@common/comps";
import { Grid, Box } from "@mui/material";
import { unique } from "radash";
import { PopperAltIcon, PopperBinIcon } from "@material/icons";

import { AdminCourseContext } from "../../context";

const Requisite = ({ r, onDelete, reinitialize }) => {
  const { defaultError } = useContext(GlobalContext);
  const { quadId } = useContext(QuadContext);
  const { creqId, subjectCode, courseCode, courseTitle, type } = r || {};

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isAltOpen, setIsAltOpen] = useState(false);

  /* Submission */
  const [deleteState, setDeleteState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState({ isDisabled: false })
  );

  const deleter = new Submitter(deleteState, setDeleteState, defaultError);

  const handleDelete = () => {
    deleter.startLoading();
    cargo.delete(`/v2/q/${quadId}/r/course/requisite/${creqId}`).then((res) => {
      const { ok } = res || {};
      if (ok) {
        onDelete();
      } else {
        deleter.takeResponse(res);
      }
    });
  };

  const menuItemsList = [
    {
      key: "admin-course__delete-requisite",
      label: `Delete ${type}`,
      icon: <PopperBinIcon />,
      handleClick: (event) => {
        event.stopPropagation();
        setIsDeleteOpen(true);
      },
      isRed: true,
    },
    {
      key: "admin-course__edit-alternatives",
      label: "Edit alternatives",
      icon: <PopperAltIcon />,
      handleClick: (event) => {
        event.stopPropagation();
        setIsAltOpen(true);
      },
    },
  ];

  const endComponent = (
    <Box sx={{ height: "100%", display: "flex", alignItems: "center" }}>
      <PopperOptions menuItemsList={menuItemsList} />
      {isDeleteOpen && (
        <ConfirmForm
          title={`Delete ${type}?`}
          subtitle="Course requisites can be added again after deleted."
          isFormOpen={isDeleteOpen}
          setIsFormOpen={setIsDeleteOpen}
          color="red"
          submitLabel="Delete"
          handleSubmit={handleDelete}
          submitState={{
            isSubmitDisabled: deleteState.isDisabled,
            isSubmitLoading: deleteState.isLoading,
            submitError: deleteState.error,
          }}
        />
      )}
      {isAltOpen &&
        Builder.creqAlternatives({
          creq: r,
          isOpen: isAltOpen,
          setIsOpen: setIsAltOpen,
          onEdit: (edited) => {
            reinitialize();
            setIsAltOpen(false);
          },
        })}
    </Box>
  );

  return (
    <Box px={2} py={1.5} borderBottom="1px solid var(--border-gray)">
      <Editable
        variant="vanilla"
        label={`${subjectCode} ${courseCode}`}
        subLabel={courseTitle}
        endComponent={endComponent}
      />
    </Box>
  );
};

const Landing = () => {
  const navigate = useNavigate();
  const { quadId } = useContext(QuadContext);
  const { contextCourse } = useContext(AdminCourseContext);

  const { courseTitle, courseId } = contextCourse || {};

  /* Requisite feed */
  const [isLoading, setIsLoading] = useState(true);
  const [requisites, setRequisites] = useState([]);

  /* Type */
  const [requisiteType, setRequisiteType] = useState("prereq");

  const filteredRequisites = requisites.filter((r) => r.type === requisiteType);
  const isEmpty = filteredRequisites.length === 0;
  const noFeed = isLoading || isEmpty;

  const fetchRequisites = () => {
    setIsLoading(true);
    cargo.get(`/v2/q/${quadId}/course/${courseId}/requisites`).then((res) => {
      const { ok, payload } = res || {};

      if (ok) {
        setIsLoading(false);
        setRequisites(unique(payload.requisites, (c) => c.creqId));
      }
    });
  };

  useEffect(() => {
    fetchRequisites();
  }, []);

  const Create = () => (
    <Opener
      variant="filled"
      color="black"
      label={`Add ${requisiteType}`}
      builder={Builder.creq({
        courseId,
        type: requisiteType,
        onCreate: fetchRequisites,
      })}
    />
  );

  const navItemsList = [
    {
      label: "All",
      handleClick: (event) => {
        event.stopPropagation();
        navigate(`/q/${quadId}/admin/courses`);
      },
    },
    {
      label: `${courseTitle}`,
    },
  ];

  const tabs = (
    <Grid container direction="row" wrap="nowrap" gap={1}>
      <Grid item>
        <Tab
          variant="bubble"
          label="Prereq"
          isActive={requisiteType === "prereq"}
          handleClick={() =>
            requisiteType !== "prereq" && setRequisiteType("prereq")
          }
          buttonSx={{
            fontSize: "13px",
          }}
        />
      </Grid>
      <Grid item>
        <Tab
          variant="bubble"
          label="Coreq"
          isActive={requisiteType === "coreq"}
          handleClick={() =>
            requisiteType !== "coreq" && setRequisiteType("coreq")
          }
          buttonSx={{
            fontSize: "13px",
          }}
        />
      </Grid>
    </Grid>
  );

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item p={2} px={2.5} borderBottom="1px solid var(--border-gray)">
        <BreadcrumbNav itemList={navItemsList} />
      </Grid>
      <Grid item p={2} borderBottom="1px solid var(--border-gray)">
        {tabs}
      </Grid>
      <Grid item p={2} borderBottom="1px solid var(--border-gray)">
        <Create />
      </Grid>
      {noFeed ? (
        <Loadman
          isLoading={isLoading}
          isEmpty={isEmpty}
          emptyLabel={`No ${requisiteType}s found.`}
          p={2}
        />
      ) : (
        filteredRequisites.map((r) => (
          <Grid item key={r.creqId}>
            <Requisite
              r={r}
              onDelete={fetchRequisites}
              reinitialize={fetchRequisites}
            />
          </Grid>
        ))
      )}
      <Grid item height={200} />
    </Grid>
  );
};

export const CreqManager = () => {
  const navigate = useNavigate();
  const { quadId } = useContext(QuadContext);
  const { contextCourse } = useContext(AdminCourseContext);

  useEffect(() => {
    if (!contextCourse) navigate(`/q/${quadId}/admin/courses`);
  }, []);

  if (contextCourse) {
    return <Landing />;
  }
};
