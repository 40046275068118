import { useContext, useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Publisher, Scoreman, Synchronizer } from "@tools";
import { TimelineProvider, TimelineContext } from "@context";
import { PostCard } from "features/postcard";
import { Loading, EmptyLabel } from "@common/comps";
import { Grid, ThemeProvider, Typography } from "@mui/material";

import "./styles.css";
import { timelineTheme } from "./themes";

export const TimelineLanding = () => {
  const { initTranche, isLoading, feed, setFeed, isFeedEmpty, lastCardRef } =
    useContext(TimelineContext);

  const [fresh, setFresh] = useState(0);
  const [freshC, setFreshC] = useState(0);

  useEffect(() => initTranche(), []);

  const postFeed = feed.map((post, i) => (
    <Grid
      item
      key={post.postId}
      className={fresh > 0 && i === 0 ? "card--new" : ""}
    >
      <PostCard
        ref={feed.length === i + 1 ? lastCardRef : null}
        post={post}
        onDelete={() => {
          setFresh(0);
          setFreshC(0);
          setFeed((prev) => prev.filter((p) => p.postId !== post.postId));
        }}
        onVote={(options) => {
          const { type: actionType } = options || {};
          const newScores = Scoreman.getNewScores({
            type: actionType,
            base: post,
          });

          Synchronizer.updatePostFeed({ ...post, ...newScores }, setFeed);
        }}
      />
    </Grid>
  ));

  const Create = () => {
    return Publisher.post({
      onPublish: (newPost) => {
        setFresh((prev) => prev + 1);
        setFeed((prev) => [newPost, ...prev]);
      },
      onConfessionPublish: () => {
        setFreshC((prev) => prev + 1);
      },
    });
  };

  /* <PostCreate setFresh={setFresh} setFreshC={setFreshC} />; */

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item p={2} sx={{ borderBottom: "1px solid var(--border-gray)" }}>
        <Create />
      </Grid>

      {freshC > 0 && (
        <Grid
          item
          borderBottom="1px solid var(--border-gray)"
          p={1.5}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Typography variant="notice">
            {`${freshC} confession${freshC > 1 ? "s" : ""} submitted.`}
          </Typography>
        </Grid>
      )}

      <Grid item>
        {isFeedEmpty ? <EmptyLabel label="No posts found." p={2} /> : postFeed}
      </Grid>

      {isLoading && (
        <Grid item>
          <Loading p={2} />
        </Grid>
      )}

      <Grid item height={200} />
    </Grid>
  );
};

export const Timeline = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <TimelineProvider>
      <ThemeProvider theme={timelineTheme}>
        <Outlet />
      </ThemeProvider>
    </TimelineProvider>
  );
};
