import { useContext, useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { QuadContext } from "@context";
import { BreadcrumbNav, Tab } from "@common/comps";
import { Grid } from "@mui/material";

import { PreqContext, PreqProvider } from "./context";
import { AdminProgramContext } from "../../context";

import { CoreGroupsManager } from "./comps/CoreGroupsManager";
import { ElectiveGroupsManager } from "./comps/ElectiveGroupsManager";

export const PreqManagerLanding = () => {
  const { groupType, setGroupType } = useContext(PreqContext);

  const navBar = (
    <Grid container direction="row" wrap="nowrap" gap={1}>
      <Grid item>
        <Tab
          variant="bubble"
          label="Core"
          isActive={groupType === "core"}
          handleClick={() => groupType !== "core" && setGroupType("core")}
        />
      </Grid>
      <Grid item>
        <Tab
          variant="bubble"
          label="Electives"
          isActive={groupType === "elct"}
          handleClick={() => groupType !== "elct" && setGroupType("elct")}
        />
      </Grid>
    </Grid>
  );

  const managerRouter = () => {
    switch (groupType) {
      case "core":
        return <CoreGroupsManager type={groupType} />;
      case "elct":
        return <ElectiveGroupsManager type={groupType} />;
      default:
        return <></>;
    }
  };

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item p={2} borderBottom="1px solid var(--border-gray)">
        {navBar}
      </Grid>
      <Grid item>{managerRouter()}</Grid>
    </Grid>
  );
};

const PreqContainer = ({ children }) => {
  const { quadId } = useContext(QuadContext);
  const { program, group } = useContext(PreqContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location || {};

  const { certName, certCode } = program || {};
  const { groupName } = group || {};

  const itemList = [
    {
      label: "All",
      handleClick: () => navigate(`/q/${quadId}/admin/programs`),
      render: true,
    },
    {
      label: `${certName}`,
      handleClick: () => navigate(`/q/${quadId}/admin/programs/requirement`),
      render: pathname.split("/")[5] === "requirement",
    },
    {
      label: groupName,
      render:
        pathname.split("/")[6] === "options" ||
        pathname.split("/")[6] === "areas",
    },
  ];

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item p={2} px={2.5} borderBottom="1px solid var(--border-gray)">
        <BreadcrumbNav itemList={itemList.filter((p) => p.render)} />
      </Grid>
      <Grid item>{children}</Grid>
    </Grid>
  );
};

export const PreqManager = () => {
  const { quadId } = useContext(QuadContext);
  const { program } = useContext(AdminProgramContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!program) navigate(`/q/${quadId}/admin/programs`);
  }, []);

  return (
    program && (
      <PreqProvider parent={program}>
        <PreqContainer>
          <Outlet />
        </PreqContainer>
      </PreqProvider>
    )
  );
};
