import { useContext } from "react";
import { CatalogContext } from "@context";
import { BubbleDropdown } from "@common/comps";
import { Grid, Button, ThemeProvider } from "@mui/material";
import { buttonTheme } from "@material/themes";

export const CatalogFilters = () => {
  const {
    filterOptions,
    level,
    setLevel,
    units,
    setUnits,
    deptId,
    setDeptId,
    subject,
  } = useContext(CatalogContext);
  const { levelOptions = [], unitOptions = [] } = filterOptions || {};
  const { subjectDepts } = subject || {};

  const clearButton = (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant="outlined--red"
        sx={{ color: "var(--quad-red)" }}
        onClick={() => {
          setLevel("");
          setUnits("");
          setDeptId("");
        }}
      >
        Clear
      </Button>
    </ThemeProvider>
  );

  const deptOptions = subjectDepts.map((item) => {
    return {
      ...item,
      deptNameShort:
        item.deptName.length > 30
          ? `${item.deptName.substring(0, 30)}...`
          : item.deptName,
    };
  });

  return (
    <Grid container direction="row" gap={1}>
      {(level || units || deptId) && <Grid item>{clearButton}</Grid>}
      <Grid item>
        <BubbleDropdown
          variant="object"
          label="Level"
          state={level}
          setState={setLevel}
          options={levelOptions}
          labelKey="courseLevelNameShort"
          valueKey="courseLevel"
          disableObjectSelect
        />
      </Grid>
      <Grid item sx={{ minWidth: "100px" }}>
        <BubbleDropdown
          label="Units"
          state={units}
          setState={setUnits}
          options={unitOptions}
        />
      </Grid>
      {deptOptions.length > 1 && (
        <Grid item>
          <BubbleDropdown
            variant="object"
            label="Department"
            state={deptId}
            setState={setDeptId}
            options={deptOptions}
            labelKey="deptNameShort"
            valueKey="deptId"
            disableObjectSelect
          />
        </Grid>
      )}
    </Grid>
  );
};
