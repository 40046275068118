import { createContext, useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { cargo } from "@api";
import { getURL } from "@config";

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const { pathname } = useLocation();

  const isLoginPage = pathname === "/";

  const defaultError = { isError: false, errorText: "", type: "" }; // DO NOT DELETE

  const [isInitializing, setIsInitializing] = useState(true);
  const [session, setSession] = useState(null);

  const refreshInterval = 30000; // 30 seconds

  useEffect(() => {
    setIsInitializing(true);
    if (isLoginPage) {
      cargo.get(`/v2/session/authorization`).then((res) => {
        const { payload } = res || {};
        const { authorized } = payload || {};
        if (authorized) {
          window.location.href = getURL(`/q/${payload.defaultQuad}`);
        } else {
          setIsInitializing(false);
        }
      });
    } else {
      cargo.get(`/v2/session`).then((res) => {
        const { ok, payload } = res || {};
        if (ok) {
          setIsInitializing(false);
          setSession(payload);
        } else {
          window.location.href = getURL("/");
        }
      });
    }
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        isInitializing,
        session,
        defaultError,
        refreshInterval,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobal = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    return null;
  } else {
    return context;
  }
};
