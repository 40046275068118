import { useContext, useEffect, useState } from "react";
import { QuadContext } from "@context";
import { Opener, Builder } from "@tools";
import { cargo } from "@api";
import {
  MoreHeader,
  Loading,
  Spinner,
  BubbleDropdown,
  EmptyLabel,
  Editable,
  PopperOptions,
} from "@common/comps";
import { useTranche2 } from "@common/hooks";
import { Grid, Box } from "@mui/material";
import { PopperEditIcon } from "@material/icons";
import { replace } from "radash";

const Department = ({ d, setFeed }) => {
  const { deptId, deptName } = d || {};

  const [isEditOpen, setIsEditOpen] = useState(false);

  const menuItemsList = [
    {
      key: "admin-departments__edit",
      label: "Edit department",
      icon: <PopperEditIcon />,
      handleClick: (event) => {
        event.stopPropagation();
        setIsEditOpen(true);
      },
    },
  ];

  const endComponent = (
    <>
      <PopperOptions menuItemsList={menuItemsList} />
      {isEditOpen &&
        Builder.department({
          unedited: d,
          isOpen: isEditOpen,
          setIsOpen: setIsEditOpen,
          onEdit: (edited) => {
            setFeed((prev) =>
              replace(prev, edited, (d) => d.deptId === deptId)
            );
            setIsEditOpen(false);
          },
        })}
    </>
  );

  return (
    <Box borderBottom="1px solid var(--border-gray)" px={2} py={1.5}>
      <Editable
        variant="vanilla"
        label={deptName}
        endComponent={endComponent}
      />
    </Box>
  );
};

const Departments = ({ type }) => {
  const { quadId } = useContext(QuadContext);

  const {
    initTranche,
    isLoading,
    lastCardRef,
    setFirstId,
    feed,
    setFeed,
    isFeedEmpty,
  } = useTranche2(`/v2/q/${quadId}/department/tranche`, { filters: { type } });

  useEffect(() => {
    initTranche();
  }, []);

  useEffect(() => {
    if (feed.length > 0) {
      setFirstId(feed[0].maxDeptId);
    }
  }, [feed]);

  if (isFeedEmpty) {
    return <EmptyLabel label="No departments found." p={2} />;
  } else {
    return (
      <Grid container direction="column" wrap="nowrap">
        {feed.map((d) => (
          <Grid item key={d.deptId}>
            <Department d={d} setFeed={setFeed} />
          </Grid>
        ))}
        <Grid item>{isLoading && <Loading p={2} />}</Grid>
        <Grid item height={200} />
      </Grid>
    );
  }
};

export const AdminDepartments = () => {
  const { quadId } = useContext(QuadContext);

  const [isInitializing, setIsInitializing] = useState(true);
  const [typeOptions, setTypeOptions] = useState([]);
  const [type, setType] = useState("");
  const [fresh, setFresh] = useState(0);

  useEffect(() => {
    setIsInitializing(true);
    cargo.get(`/v2/q/${quadId}/department/types`).then((res) => {
      const { ok, payload } = res || {};
      if (ok) {
        setIsInitializing(false);
        setTypeOptions(payload.types);
      }
    });
  }, []);

  const filters = (
    <Grid container direction="row" wrap="nowrap" gap={1}>
      <Grid item>
        <BubbleDropdown
          variant="object"
          label="Type"
          state={type}
          setState={setType}
          options={typeOptions}
          labelKey="typeName"
          valueKey="typeValue"
          disableObjectSelect
        />
      </Grid>
    </Grid>
  );

  const Create = () => (
    <Opener
      variant="filled"
      color="black"
      label="Create department"
      builder={Builder.department({
        onCreate: () => setFresh((prev) => prev + 1),
      })}
    />
  );

  const Feed = () => <Departments type={type} />;

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        <MoreHeader
          title="Manage departments"
          enableBack
          backRoute={`/q/${quadId}/admin`}
        />
      </Grid>
      <Grid
        item
        borderTop="1px solid var(--border-gray)"
        borderBottom="1px solid var(--border-gray)"
        p={2}
      >
        <Create />
      </Grid>
      <Grid
        item
        borderBottom="1px solid var(--border-gray)"
        p={2}
        sx={{ minHeight: "68px", display: "flex", alignItems: "center" }}
      >
        {isInitializing ? <Spinner /> : filters}
      </Grid>
      <Grid item>
        <Feed />
      </Grid>
    </Grid>
  );
};
