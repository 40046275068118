import { createTheme } from "@mui/material";

export const formTheme = createTheme({
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          maxWidth: "650px",
        },
        container: {
          alignItems: "flex-start",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: "16px",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "0px 0px",
          width: "600px",
          minWidth: "450px",
          borderRadius: "10px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          letterSpacing: "0.01em",
        },
      },
      variants: [
        {
          props: { variant: "title" },
          style: {
            fontSize: "20px",
            fontWeight: "500",
            color: "var(--quad-black)",
          },
        },
        {
          props: { variant: "label" },
          style: {
            fontSize: "15px",
            fontWeight: "400",
            color: "var(--quad-gray)",
            position: "relative",
            left: 5,
          },
        },
        {
          props: { variant: "note" },
          style: {
            fontSize: "13px",
            fontWeight: "400",
            color: "var(--quad-gray)",
            position: "relative",
            left: 5,
            top: 5,
          },
        },
        {
          props: { variant: "stealth" },
          style: {
            fontSize: "14px",
            fontWeight: "400",
            color: "var(--quad-gray-stealth)",
            letterSpacing: "0.02em",
          },
        },
      ],
    },
  },
});

export default formTheme;
