import { useEffect, useState, useReducer } from "react";
import { useGlobal } from "@context";
import { cargo } from "@api";
import { Template, Submitter } from "@tools";
import { BasicForm, Loading } from "@common/comps";
import { isObject } from "radash";
import { Grid } from "@mui/material";

export const TopicCategoryBuilder = ({
  unedited,
  isOpen,
  setIsOpen,
  onCreate = () => {},
  onEdit = () => {},
}) => {
  const isEdit = Boolean(unedited);

  const { defaultError } = useGlobal();

  /* Init */
  const [isInitializing, setIsInitializing] = useState(true);

  /* Template */
  const [baseline, setBaseline] = useState(null);
  const [category, setCategory] = useReducer(Template.createReducer(), null);

  const template = new Template(baseline, category, setCategory);

  const initializeForm = () => {
    setIsInitializing(true);
    cargo.get(`/v2/r/topic/category/form`).then((res) => {
      const { ok, payload } = res || {};
      if (ok) {
        setIsInitializing(false);

        /* Templating */
        const initialTemplate = isEdit
          ? Template.superimpose(payload.template, unedited)
          : Template.createEmpty(payload.template);

        if (isEdit) setBaseline(initialTemplate);
        setCategory({ type: "init", value: initialTemplate });
      }
    });
  };

  useEffect(() => {
    initializeForm();
  }, []);

  /* Form section */
  const { topicCatId } = unedited || {};
  const [hot, setHot] = useState(false);

  /* Submission */
  const [submitState, setSubmitState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState()
  );

  const submitter = new Submitter(submitState, setSubmitState, defaultError);

  useEffect(() => {
    if (hot) setHot(false);

    if (!hot && isObject(category)) {
      const preflight = template.preflight();
      const isChanged = template.isChanged();

      submitter.stock(template.crush());
      submitter.green(isEdit ? preflight && isChanged : preflight);
      submitter.resetError();
    }
  }, [category]);

  const handleCreate = () => {
    template.resetErrors(defaultError);
    submitter.startLoading();

    const payload = { category: template.crush() };
  };

  const handleEdit = () => {};

  const content = isInitializing ? (
    <Loading p={2} />
  ) : (
    isObject(category) && (
      <Grid container direction="column" wrap="nowrap" gap={1}>
        <Grid item>
          {template.prefab().input({ label: "Category name", field: "name" })}
        </Grid>
      </Grid>
    )
  );

  if (isOpen) {
    if (isEdit) {
      return (
        <BasicForm
          title="Edit topic category"
          isFormOpen={isOpen}
          setIsFormOpen={setIsOpen}
          content={content}
          handleSubmit={handleEdit}
          submitState={{
            isSubmitLoading: submitState.isLoading,
            isSubmitDisabled: submitState.isDisabled,
            submitError: submitState.error,
          }}
        />
      );
    } else {
      return (
        <BasicForm
          title="Add topic category"
          isFormOpen={isOpen}
          setIsFormOpen={setIsOpen}
          content={content}
          handleSubmit={handleCreate}
          submitState={{
            isSubmitLoading: submitState.isLoading,
            isSubmitDisabled: submitState.isDisabled,
            submitError: submitState.error,
          }}
        />
      );
    }
  }
};
