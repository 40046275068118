import { createTheme } from "@mui/material";

export const adminCourseTheme = createTheme({
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: "section-title" },
          style: {
            display: "flex",
            fontSize: "14px",
            fontWeight: "500",
            letterSpacing: "0.03em",
          },
        },
        {
          props: { variant: "crud-title" },
          style: {
            display: "flex",
            fontSize: "14px",
            fontWeight: "500",
            letterSpacing: "0.03em",
            color: "var(--quad-gray)",
          },
        },
      ],
    },
  },
});
