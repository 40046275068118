import { useContext, useState, useReducer, useEffect } from "react";
import { cargo } from "@api";
import { GlobalContext, QuadContext } from "@context";
import { Submitter, Publisher } from "@tools";
import {
  BasicAvatar,
  CircularTextCounter,
  ResizingTextField,
  Submit,
} from "@common/comps";
import { Button, Grid, ThemeProvider } from "@mui/material";
import { buttonTheme } from "@material/themes";
import { CreateConfessionIcon } from "@material/icons";

const ToolOpener = ({ icon, isOpen, setIsOpen, publisher }) => {
  const openButton = (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant="icon"
        onClick={() => setIsOpen(true)}
        sx={{
          padding: "8px",

          ":hover": {
            backgroundColor: "var(--quad-teal-light)",
          },
        }}
      >
        {icon}
      </Button>
    </ThemeProvider>
  );

  return (
    <>
      {openButton}
      {isOpen && publisher}
    </>
  );
};

export const PostPublisher = ({
  onPublish = () => {},
  onConfessionPublish = () => {},
}) => {
  const {
    defaultError,
    session: { sessOwnerAvatarSrc, postMax },
  } = useContext(GlobalContext);
  const { quadId } = useContext(QuadContext);

  const [isInitialized, setIsInitialized] = useState(false);
  const [text, setText] = useState("");

  /* Submission */
  const [submitState, setSubmitState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState()
  );

  const submitter = new Submitter(submitState, setSubmitState, defaultError);

  useEffect(() => {
    submitter.resetError();
    submitter.green(text.length > 0);
  }, [text]);

  const handleSubmit = () => {
    submitter.startLoading();
    cargo.post(`/v2/q/${quadId}/p/post`, { text }).then((res) => {
      const { ok, payload } = res || {};
      if (ok) {
        onPublish(payload);
      } else {
        submitter.stopLoading();
        submitter.takeResponse(res);
      }
    });
  };

  /* Additional tools */
  const [isConfessionOpen, setIsConfessionOpen] = useState(false);

  const toolBar = (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      sx={{ position: "relative", right: 8 }}
    >
      <Grid item>
        <ToolOpener
          icon={<CreateConfessionIcon width={20} height={20} />}
          isOpen={isConfessionOpen}
          setIsOpen={setIsConfessionOpen}
          publisher={Publisher.confession({
            isOpen: isConfessionOpen,
            setIsOpen: setIsConfessionOpen,
            onPublish: onConfessionPublish,
          })}
        />
      </Grid>
    </Grid>
  );

  const publish = (
    <Grid container direction="row" wrap="nowrap" alignItems="center" gap={2}>
      {text && (
        <Grid item>
          <CircularTextCounter text={text} limit={postMax} />
        </Grid>
      )}
      <Grid item>
        <Submit
          variant="publish"
          submitState={submitState}
          handleSubmit={handleSubmit}
        />
      </Grid>
    </Grid>
  );

  return (
    <Grid container direction="row" wrap="nowrap" gap={2}>
      <Grid item>
        <BasicAvatar avatarSrc={sessOwnerAvatarSrc} disableClick />
      </Grid>
      <Grid item sx={{ width: "100%", position: "relative", top: 12 }}>
        <Grid container direction="column" wrap="nowrap">
          <Grid
            item
            borderBottom={isInitialized && "1px solid var(--border-gray)"}
            pb={isInitialized && 1.5}
            onClick={() => setIsInitialized(true)}
          >
            <ResizingTextField
              wrapperId="resizing-textfield__create-post__wrapper"
              textareaId="resizing-textfield__create-post__textarea"
              state={text}
              setState={setText}
              placeholder="Say something"
            />
          </Grid>
          <Grid item py={1.5}>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
            >
              <Grid item>{toolBar}</Grid>
              <Grid item>{publish}</Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
