import { SubmitButton } from "./SubmitButton";

class Submitter {
  constructor(state, setState, defaultError) {
    this.state = state;
    this.setState = setState;
    this.defaultError = defaultError;
  }
  static initialState(options) {
    const { isLoading = false, isDisabled = true } = options || {};

    return {
      payload: null,
      isLoading,
      isDisabled,
      error: this.defaultError,
    };
  }
  static createReducer() {
    return (state, value) => {
      return { ...state, ...value };
    };
  }
  static button(props) {
    return <SubmitButton {...props} />;
  }
  stock(payload) {
    this.setState({ payload });
  }
  green(bool) {
    this.setState({ isDisabled: !bool });
  }
  startLoading() {
    this.setState({ isLoading: true });
  }
  stopLoading() {
    this.setState({ isLoading: false });
  }
  setError(error) {
    this.setState({ error });
  }
  resetError() {
    this.setState({ error: this.defaultError });
  }
  takeResponse(res) {
    const { ok, status, message } = res || {};

    if (!ok) {
      this.setState({
        isDisabled: true,
        isLoading: false,
        error: {
          isError: true,
          type: status,
          errorText: message,
        },
      });
    }
  }
}

export { Submitter };
export default Submitter;
