import { useState } from "react";
import { PopperOptions } from "@common/comps";
import { PopperCollectIcon } from "@material/icons";

import { ProgramCollect } from "./ProgramCollect";

export const ProgramOptions = () => {
  const [isCollectOpen, setIsCollectOpen] = useState(false);

  const menuItemsList = [
    {
      key: "program-options__collect",
      label: "Collect program",
      icon: <PopperCollectIcon />,
      handleClick: (event) => {
        setIsCollectOpen(true);
        event.stopPropagation();
      },
    },
  ];

  const onCollect = () => window.location.reload();

  return (
    <>
      <PopperOptions menuItemsList={menuItemsList} />
      {isCollectOpen && (
        <ProgramCollect
          onCollect={onCollect}
          isFormOpen={isCollectOpen}
          setIsFormOpen={setIsCollectOpen}
        />
      )}
    </>
  );
};
