import { CourseLabel } from "@common/comps";
import {
  Grid,
  Typography,
  Popper,
  Paper,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { popperTheme } from "@material/themes";

const coursePopperTheme = createTheme({
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: "courseTitle" },
          style: {
            fontFamily: "Inter",
            fontSize: "13px",
            fontWeight: "600",
            color: "var(--quad-black)",
            letterSpacing: "0.01em",
            overflowWrap: "anywhere",
          },
        },
        {
          props: { variant: "alt" },
          style: {
            fontSize: "12px",
            fontWeight: "500",
            color: "var(--quad-pewter)",
            letterSpacing: "0.02em",
          },
        },
      ],
    },
  },
});

const CoursePopper = ({ anchorEl, course, extraContent = null }) => {
  const isPopperOpen = Boolean(anchorEl);
  const { courseTitle } = course || {};

  const content = (
    <ThemeProvider theme={coursePopperTheme}>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        sx={{ padding: "0px 8px 5px", minWidth: "100px", maxWidth: "200px" }}
      >
        <Grid item>
          <Grid container direction="column" wrap="nowrap">
            <Grid
              item
              sx={{
                borderBottom: "1px solid var(--div-gray)",
                paddingBottom: "2px",
                marginBottom: "5px",
              }}
            >
              <CourseLabel
                variant="borderless"
                course={course}
                hideTitle
                typographyProps={{
                  subjectCodeProps: { fontSize: "12px" },
                  courseCodeProps: { fontSize: "12px" },
                }}
              />
            </Grid>
            <Grid item sx={{ lineHeight: "18px" }}>
              <Typography variant="courseTitle">{courseTitle}</Typography>
            </Grid>
            <Grid item>{extraContent && extraContent}</Grid>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );

  return (
    <ThemeProvider theme={popperTheme}>
      <Popper
        open={isPopperOpen}
        anchorEl={anchorEl}
        placement="top"
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 10],
              },
            },
          ],
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        sx={{ zIndex: "5" }}
      >
        <Paper>{content}</Paper>
      </Popper>
    </ThemeProvider>
  );
};

export default CoursePopper;
