import { useContext, useState, useEffect, useReducer } from "react";
import { GlobalContext, QuadContext } from "@context";
import { BasicForm, MultiCourse } from "@common/comps";
import { cargo } from "@api";

import { Submitter } from "@tools";

export const PreqOptionBuilder = ({
  preqGroupId,
  unedited,
  isOpen,
  setIsOpen,
  onCreate = () => {},
  onEdit = () => {},
  onDelete = () => {},
}) => {
  const { defaultError } = useContext(GlobalContext);
  const { quadId } = useContext(QuadContext);
  const isEdit = Boolean(unedited);

  /* Form section */
  const baseline = unedited && unedited.sequence;
  const [sequence, setSequence] = useState(isEdit ? baseline : []); // Course objects

  const [isFootDisabled, setIsFootDisabled] = useState(false);

  /* Submission */
  const { preqOptionId } = unedited || {};

  const [submitState, setSubmitState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState()
  );
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [deleteState, setDeleteState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState({ isDisabled: false })
  );

  const submitter = new Submitter(submitState, setSubmitState, defaultError);
  const deleter = new Submitter(deleteState, setDeleteState, defaultError);

  useEffect(() => {
    const sequenceIds = sequence.map((c) => c.courseId);

    if (isEdit) {
      const baselineIds = baseline.map((c) => c.courseId);
      const isChanged =
        JSON.stringify(baselineIds) !== JSON.stringify(sequenceIds);

      submitter.green(sequenceIds.length > 0 && isChanged);
    } else {
      submitter.green(sequenceIds.length > 0);
    }

    submitter.resetError();
    deleter.resetError();
  }, [sequence]);

  const content = (
    <MultiCourse
      state={sequence}
      setState={setSequence}
      setIsFootDisabled={setIsFootDisabled}
    />
  );

  const handleCreate = () => {
    submitter.startLoading();
    cargo
      .post(
        `/v2/q/${quadId}/r/program/requirement/group/${preqGroupId}/option`,
        { option: { sequence: sequence.map((c) => c.courseId) } }
      )
      .then((res) => {
        const { ok } = res || {};
        if (ok) {
          onCreate();
        } else {
          submitter.takeResponse(res);
        }
      });
  };

  const handleEdit = () => {
    submitter.startLoading();
    cargo
      .put(
        `/v2/q/${quadId}/r/program/requirement/group/option/${preqOptionId}`,
        { option: { sequence: sequence.map((c) => c.courseId) } }
      )
      .then((res) => {
        const { ok } = res || {};
        if (ok) {
          onEdit();
        } else {
          submitter.takeResponse(res);
        }
      });
  };

  const handleDelete = () => {
    deleter.startLoading();
    cargo
      .delete(
        `/v2/q/${quadId}/r/program/requirement/group/option/${preqOptionId}`
      )
      .then((res) => {
        const { ok } = res || {};
        if (ok) {
          onDelete();
        } else {
          deleter.takeResponse(res);
        }
      });
  };

  if (isOpen) {
    return isEdit ? (
      <BasicForm
        title="Edit option sequence"
        isFormOpen={isOpen}
        setIsFormOpen={setIsOpen}
        content={content}
        disableFooter={isFootDisabled}
        handleSubmit={handleEdit}
        submitState={{
          isSubmitLoading: submitState.isLoading,
          isSubmitDisabled: submitState.isDisabled,
          submitError: submitState.error,
        }}
        handleDelete={handleDelete}
        deleteTitle="Delete option?"
        deleteSubtitle="Deleting options will cause issues to elements that depend on it."
        deleteState={{
          ...{
            isDeleteLoading: deleteState.isLoading,
            isDeleteDisabled: deleteState.isDisabled,
            deleteError: deleteState.error,
          },
          isDeleteOpen,
          setIsDeleteOpen,
        }}
      />
    ) : (
      <BasicForm
        title="Add option sequence"
        isFormOpen={isOpen}
        setIsFormOpen={setIsOpen}
        content={content}
        disableFooter={isFootDisabled}
        handleSubmit={handleCreate}
        submitState={{
          isSubmitLoading: submitState.isLoading,
          isSubmitDisabled: submitState.isDisabled,
          submitError: submitState.error,
        }}
      />
    );
  } else {
    return <></>;
  }
};
