import {
  Box,
  FormControl,
  InputLabel,
  Select,
  ThemeProvider,
} from "@mui/material";
import { bubbleSelectTheme } from "@material/themes";
import { ButtonDropdownIcon } from "@material/icons";
import { omit } from "radash";

const DropdownIcon = () => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "flex-end",
      height: "0px",
      position: "relative",
      bottom: 27,
      right: 12,
      pointerEvents: "none",
    }}
  >
    <ButtonDropdownIcon width={16} height={16} />
  </Box>
);

const Object = ({
  label,
  options,
  labelKey,
  valueKey,
  state,
  setState,
  handleChange,
  disabled,
  disableObjectSelect,
}) => {
  const onChange = (() => {
    if (handleChange) {
      return handleChange;
    } else {
      return (event) => {
        if (disableObjectSelect) {
          setState(event.target.value);
        } else {
          setState(options.find((o) => o[valueKey] === event.target.value));
        }
      };
    }
  })();

  return (
    <ThemeProvider theme={bubbleSelectTheme}>
      <FormControl variant="outlined" sx={{ width: "100%" }}>
        {!Boolean(disableObjectSelect ? state : state[valueKey]) && (
          <InputLabel>{label ? label : "Select"}</InputLabel>
        )}
        <Select
          native
          value={state ? (disableObjectSelect ? state : state[valueKey]) : ""}
          disabled={disabled}
          onChange={onChange}
          IconComponent={() => <Box />}
        >
          <option disabled />
          {options.map((o) => (
            <option key={o[valueKey]} value={o[valueKey]}>
              {o[labelKey]}
            </option>
          ))}
        </Select>
        <DropdownIcon />
      </FormControl>
    </ThemeProvider>
  );
};

const Simple = ({
  label,
  options,
  state,
  setState,
  handleChange,
  disabled,
}) => {
  const onChange = handleChange
    ? handleChange
    : (event) => setState(event.target.value);

  return (
    <ThemeProvider theme={bubbleSelectTheme}>
      <FormControl variant="outlined" sx={{ width: "100%" }}>
        {!Boolean(state) && <InputLabel>{label ? label : "Select"}</InputLabel>}
        <Select
          native
          value={state ? state : ""}
          disabled={disabled}
          onChange={onChange}
          IconComponent={() => <Box />}
        >
          <option disabled />
          {options.map((o) => (
            <option key={o} value={o}>
              {o}
            </option>
          ))}
        </Select>
        <DropdownIcon />
      </FormControl>
    </ThemeProvider>
  );
};

const BubbleDropdown = (props) => {
  const { variant } = props || {};

  switch (variant) {
    case "object":
      return <Object {...omit(props, ["variant"])} />;
    default:
      return <Simple {...omit(props, ["variant"])} />;
  }
};

export default BubbleDropdown;
