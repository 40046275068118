import { GlobalProvider } from "@context";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { PageErrorNotice } from "@common/comps";
import { CssBaseline, ThemeProvider } from "@mui/material";

import { Login } from "./features/login";
import { Recovery } from "features/recovery";
import { Wrapper } from "features/wrapper";
import { Enroll } from "./features/enroll";

import {
  Quad,
  Timeline,
  TimelineLanding,
  Projects,
  ProjectsLanding,
  Catalog,
  CatalogLanding,
  Cataloger,
  Patalog,
  PatalogLanding,
  More,
  MoreLanding,
  Admin,
  AdminLanding,
  AdminDepartments,
  AdminCourses,
  CourseManager,
  CreqManager,
  AdminSubjects,
  AdminCores,
  AdminPrograms,
  ProgramManager,
  CertificationManager,
  ConcentrationManager,
  PreqManager,
  PreqManagerLanding,
  GroupOptionsManager,
  GroupAreasManager,
  AdminEditors,
  AdminInstructors,
  Editor,
  EditorLanding,
  EditorConfession,
  EditorEvaluation,
} from "./features/quad";

import {
  Program,
  ProgramLanding,
  ProgramRequirements,
} from "./features/program";

import {
  Course,
  CourseLanding,
  CourseTopics,
  CourseRequisites,
  CourseEvaluation,
} from "./features/course";

import { Post } from "./features/post/Post";

import { Notif } from "features/notif";

import {
  Profile,
  TimelineP,
  Attributes,
  Education,
  Jobs,
} from "./features/profile";

import {
  Settings,
  SettingsLanding,
  SettingsGeneral,
  SettingsUsername,
  SettingsEmail,
  SettingsDefaultQuad,
  SettingsSecurity,
  SettingsPassword,
} from "./features/settings";

import {
  Root,
  RootLanding,
  RootFields,
  RootOrganizations,
  RootTopics,
} from "./features/root";

// CSS
import "@material/styles/appStyles.css";
import "@material/styles/formStyles.css";
import "@material/styles/iconStyles.css";
import "@material/styles/actionStyles.css";
import "@material/styles/popperStyles.css";
import "@material/styles/cardStyles.css";
import "@material/styles/courseStyles.css";
import "@material/styles/programStyles.css";
import "@material/styles/optionsStyles.css";
import "@material/styles/errorStyles.css";
import "@material/styles/filterToolStyles.css";
import "@material/styles/postStyles.css";

import { globalTheme } from "@material/themes";

const App = () => {
  return (
    <ThemeProvider theme={globalTheme}>
      <CssBaseline />
      <Router>
        <GlobalProvider>
          <Routes>
            <Route path="enroll" element={<Enroll />} />
            <Route path="recovery" element={<Recovery />} />
            <Route path="/" element={<Wrapper />}>
              <Route index element={<Login />} />
              <Route path="/q/:quad" element={<Quad />}>
                <Route path="" element={<Timeline />}>
                  <Route index element={<TimelineLanding />} />
                  <Route path="post/:postId" element={<Post />} />
                </Route>
                <Route path="c" element={<Catalog />}>
                  <Route index element={<CatalogLanding />} />
                  <Route path=":courseId" element={<Course />}>
                    <Route index element={<CourseLanding />} />
                    <Route path="topics" element={<CourseTopics />} />
                    <Route path="reqs" element={<CourseRequisites />} />
                    <Route path="evals" element={<CourseEvaluation />} />
                  </Route>
                </Route>
                <Route path="cataloger" element={<Cataloger />} />
                <Route path="p" element={<Patalog />}>
                  <Route index element={<PatalogLanding />} />
                  <Route path=":programId" element={<Program />}>
                    <Route index element={<ProgramLanding />} />
                    <Route path="reqs" element={<ProgramRequirements />} />
                  </Route>
                </Route>
                <Route path="projects" element={<Projects />}>
                  <Route index element={<ProjectsLanding />} />
                </Route>
                <Route path="more" element={<More />}>
                  <Route index element={<MoreLanding />} />
                </Route>
                <Route path="admin" element={<Admin />}>
                  <Route index element={<AdminLanding />} />
                  <Route path="departments" element={<AdminDepartments />} />
                  <Route path="courses" element={<AdminCourses />}>
                    <Route index element={<CourseManager />} />
                    <Route path="requisites" element={<CreqManager />} />
                  </Route>
                  <Route path="subjects" element={<AdminSubjects />} />
                  <Route path="cores" element={<AdminCores />} />
                  <Route path="programs" element={<AdminPrograms />}>
                    <Route index element={<ProgramManager />} />
                    <Route
                      path="concentrations"
                      element={<ConcentrationManager />}
                    />
                    <Route
                      path="certifications"
                      element={<CertificationManager />}
                    />
                    <Route path="requirement" element={<PreqManager />}>
                      <Route index element={<PreqManagerLanding />} />
                      <Route path="options" element={<GroupOptionsManager />} />
                      <Route path="areas" element={<GroupAreasManager />} />
                    </Route>
                  </Route>
                  <Route path="editors" element={<AdminEditors />} />
                  <Route path="instructors" element={<AdminInstructors />} />
                  {/* <Route path="contributions" element={<AdminContrib />}>
                    <Route index element={<ContribLanding />} />
                    <Route path="course" element={<ContribCourse />} />
                  </Route> */}
                </Route>
                <Route path="editor" element={<Editor />}>
                  <Route index element={<EditorLanding />} />
                  <Route path="confessions" element={<EditorConfession />} />
                  <Route path="evaluations" element={<EditorEvaluation />} />
                </Route>
                {/* <Route path="directory" element={<Directory />} /> */}
              </Route>
              <Route path="/u/:username" element={<Profile />}>
                <Route index element={<TimelineP />} />
                <Route path="attributes" element={<Attributes />} />
                <Route path="education" element={<Education />} />
                <Route path="jobs" element={<Jobs />} />
              </Route>
              <Route path="settings" element={<Settings />}>
                <Route index element={<SettingsLanding />} />
                {/* General */}
                <Route path="general" element={<SettingsGeneral />} />
                <Route path="username" element={<SettingsUsername />} />
                <Route path="email" element={<SettingsEmail />} />
                <Route path="default-quad" element={<SettingsDefaultQuad />} />
                {/* Security */}
                <Route path="security" element={<SettingsSecurity />} />
                <Route path="password" element={<SettingsPassword />} />
              </Route>
              <Route path="root" element={<Root />}>
                <Route index element={<RootLanding />} />
                <Route path="fields" element={<RootFields />} />
                <Route path="orgs" element={<RootOrganizations />} />
                <Route path="topics" element={<RootTopics />} />
              </Route>
              <Route path="notifs" element={<Notif />} />

              {/* Default */}

              <Route
                path="*"
                element={
                  <PageErrorNotice
                    type={404}
                    title="No page"
                    headerTitle="Empty"
                    subtitle="No page was specified for this route."
                  />
                }
              />

              {/* To be deprecated */}

              {/* <Route path="notifs" element={<Notif />} /> */}
              {/* <Route index element={<RootMainMenu />} />
                <Route path="org" element={<RootOrgEdit />} />
              <Route path="field" element={<RootFieldEdit />} /> */}
            </Route>
          </Routes>
        </GlobalProvider>
      </Router>
    </ThemeProvider>
  );
};

export default App;
