import { useState, useEffect } from "react";
import { cargo } from "@api";
import { BasicAvatar, Search, Spinner } from "@common/comps";
import { Box, Grid, Typography } from "@mui/material";
import { DefaultOrgAltAvatar } from "@material/images";
import { unique } from "radash";

const Organization = ({ props, defaultAvatar }) => {
  const { orgAvatarSrc, orgName } = props || {};

  return (
    <Grid container direction="row" wrap="nowrap" alignItems="center" gap={1.5}>
      <Grid item>
        <BasicAvatar
          diameter="20px"
          disableClick
          avatarSrc={orgAvatarSrc}
          defaultSrc={defaultAvatar || DefaultOrgAltAvatar}
        />
      </Grid>
      <Grid item>
        <Typography variant="organization">{orgName}</Typography>
      </Grid>
    </Grid>
  );
};

const OrganizationSearch = ({
  initialValue = "",
  defaultAvatar,
  label,
  organization,
  setOrganization,
  uni,
  handleInputChange,
  handleChange,
  disabled,
}) => {
  const [search, setSearch] = useState(
    organization ? organization.orgName : initialValue
  );

  const [isLoading, setIsLoading] = useState(false);
  const [orgOptions, setOrgOptions] = useState([]);

  const [selected, setSelected] = useState(Boolean(organization));

  useEffect(() => {
    if (selected) {
      setSelected(false);
    } else {
      setOrganization(null);
    }

    if (search) {
      setIsLoading(true);
      setOrgOptions([]);
      cargo
        .get(`/v2/organization?term=${search}&isUni=${Boolean(uni)}`)
        .then((res) => {
          setIsLoading(false);
          const { ok, payload } = res || {};
          if (ok) {
            const uniques = unique(payload, (o) => o.orgId);
            const N = uniques.length;
            setOrgOptions(uniques.slice(0, N > 10 ? 10 : N));
          }
        });
    }
  }, [search]);

  const renderOption = (props, option) => {
    if (isLoading) {
      return (
        <Box key={"org-search__spinner"} px={2} py={1}>
          <Spinner />
        </Box>
      );
    } else {
      return (
        <Box component="li" {...props}>
          <Organization props={option} defaultAvatar={defaultAvatar} />
        </Box>
      );
    }
  };

  const onChange = (...args) => {
    const [event, val] = args;

    setOrganization && setOrganization(val);
    setSearch(val.orgName);
    setSelected(true);

    handleChange && handleChange(...args);
  };

  return (
    <Search
      elementId="org-search__search"
      label={label || (uni ? "University" : "Organization")}
      search={search}
      setSearch={setSearch}
      options={orgOptions}
      getOptionLabel={(option) => option.orgName}
      renderOption={renderOption}
      handleInputChange={handleInputChange}
      handleChange={onChange}
      disabled={disabled}
      InputProps={{
        ...(organization && {
          startAdornment: (
            <Box mx={0.5}>
              <BasicAvatar
                diameter={20}
                avatarSrc={organization.orgAvatarSrc}
                defaultSrc={defaultAvatar || DefaultOrgAltAvatar}
                disableClick
              />
            </Box>
          ),
        }),
      }}
    />
  );
};

export default OrganizationSearch;
