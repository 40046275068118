import { useContext, useState, useEffect, useReducer } from "react";
import { GlobalContext, QuadContext } from "@context";
import { BasicForm, MultiCourse } from "@common/comps";
import { cargo } from "@api";

import { Submitter } from "@tools";

export const PreqElectivesBuilder = ({
  unedited,
  isOpen,
  setIsOpen,
  onEdit = () => {},
}) => {
  const { defaultError } = useContext(GlobalContext);
  const { quadId } = useContext(QuadContext);

  /* Content */
  const baseline = unedited && unedited.courses;
  const [courses, setCourses] = useState(baseline);

  const [isFootDisabled, setIsFootDisabled] = useState(false);

  /* Submission */
  const { preqElctId } = unedited || {};

  const [submitState, setSubmitState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState()
  );

  const submitter = new Submitter(submitState, setSubmitState, defaultError);

  useEffect(() => {
    const courseIds = courses.map((c) => c.courseId);

    const baselineIds = baseline.map((c) => c.courseId);
    const isChanged = JSON.stringify(baselineIds) !== JSON.stringify(courseIds);

    submitter.green(courseIds.length > 0 && isChanged);
    submitter.resetError();
  }, [courses]);

  const content = (
    <MultiCourse
      state={courses}
      setState={setCourses}
      setIsFootDisabled={setIsFootDisabled}
    />
  );

  const handleEdit = () => {
    submitter.startLoading();
    cargo
      .put(
        `/v2/q/${quadId}/r/program/requirement/group/area/elective/${preqElctId}`,
        { electives: courses.map((c) => c.courseId) }
      )
      .then((res) => {
        const { ok } = res || {};
        if (ok) {
          onEdit();
        } else {
          submitter.takeResponse(res);
        }
      });
  };

  if (isOpen) {
    return (
      <BasicForm
        title="Edit area courses"
        isFormOpen={isOpen}
        setIsFormOpen={setIsOpen}
        content={content}
        disableFooter={isFootDisabled}
        handleSubmit={handleEdit}
        submitState={{
          isSubmitLoading: submitState.isLoading,
          isSubmitDisabled: submitState.isDisabled,
          submitError: submitState.error,
        }}
      />
    );
  } else {
    return <></>;
  }
};
