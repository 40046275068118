import { createTheme } from "@mui/material";

export const multilineTheme = createTheme({
  components: {
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
        autoComplete: "off",
      },
      styleOverrides: {
        root: {
          height: "215px",
          border: "1px solid",
          borderRadius: "5px",
          borderColor: "var(--input-border-gray)",
          marginBottom: "1px",
          backgroundColor: "transparent",

          "&:hover": {
            backgroundColor: "transparent",
          },

          "&.Mui-focused": {
            position: "relative",
            right: 1,
            border: "2px solid",
            borderColor: "var(--quad-teal)",
            backgroundColor: "transparent",
            paddingRight: "10px",
          },

          "&.Mui-error": {
            border: "2px solid",
            borderColor: "var(--quad-red)",
            marginBottom: "10px",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "var(--quad-gray)",
          fontSize: "16px",

          "&.Mui-focused": {
            color: "var(--quad-teal)",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          position: "absolute",
          bottom: "-10px",
        },
      },
    },
  },
});

export default multilineTheme;
