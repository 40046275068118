import { createTheme } from "@mui/material";

const actionTheme = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableFocusRipple: true,
        disableRipple: true,
      },
      variants: [
        {
          props: { variant: "action" },
          style: {
            textTransform: "none",
            minHeight: 0,
            minWidth: 0,
            padding: "0px",
            backgroundColor: "transparent",

            "&:hover": {
              backgroundColor: "transparent",
            },
          },
        },
        {
          props: { variant: "create" },
          style: {
            textTransform: "none",
            minHeight: 0,
            minWidth: 0,
            padding: "8px",
            backgroundColor: "transparent",
            borderRadius: "50%",

            "&:hover": {
              backgroundColor: "var(--quad-teal-light)",
              transition: "background-color 200ms",
            },
          },
        },
      ],
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: "stat" },
          style: {
            fontSize: "14px",
            fontWeight: "400",
            color: "var(--quad-gray-alt)",
          },
        },
      ],
    },
  },
});

export default actionTheme;
