import { BasicError, Spinner } from "@common/comps";
import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Box,
  Button,
  ThemeProvider,
} from "@mui/material";
import { confirmTheme, buttonTheme } from "@material/themes";

const ConfirmForm = ({
  title,
  subtitle,
  isFormOpen,
  setIsFormOpen,
  color,
  handleSubmit,
  submitState,
  stateless = false,
  submitLabel,
  titleProps,
}) => {
  // Submission
  const {
    isSubmitDisabled,
    isSubmitLoading,
    submitError = {},
  } = submitState || {};

  const submitButton = (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant="filled"
        color={color ? color : "black"}
        onClick={handleSubmit}
        disabled={stateless ? false : isSubmitDisabled}
        sx={{ width: "inherit", height: "inherit" }}
      >
        <Typography variant="filled--white" sx={{ fontSize: "14px" }}>
          {submitLabel}
        </Typography>
      </Button>
    </ThemeProvider>
  );

  const cancelButton = (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant="outlined"
        onClick={(event) => {
          event.stopPropagation();
          setIsFormOpen(false);
        }}
        sx={{ width: "inherit", height: "inherit" }}
      >
        <Typography variant="outlined" sx={{ fontSize: "14px" }}>
          Cancel
        </Typography>
      </Button>
    </ThemeProvider>
  );

  const formContent = (
    <Grid container direction="column" wrap="nowrap">
      <Grid
        item
        sx={{ ...(titleProps ? titleProps : { marginBottom: "10px" }) }}
      >
        <Typography variant="heading">{title}</Typography>
      </Grid>
      {subtitle && (
        <Grid item sx={{ marginBottom: "20px", lineHeight: "20px" }}>
          <Typography variant="subheading">{subtitle}</Typography>
        </Grid>
      )}
      {!stateless && (
        <Grid
          item
          sx={{
            marginBottom: "15px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <BasicError error={submitError} />
          </Box>
        </Grid>
      )}
      <Grid
        item
        sx={{
          height: "45px",
          marginBottom: "15px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        {stateless ? (
          submitButton
        ) : isSubmitLoading ? (
          <Spinner />
        ) : (
          submitButton
        )}
      </Grid>
      <Grid item sx={{ width: "inherit", height: "45px" }}>
        {cancelButton}
      </Grid>
    </Grid>
  );

  return (
    <ThemeProvider theme={confirmTheme}>
      <Dialog
        open={isFormOpen}
        onClose={() => {
          setIsFormOpen(false);
        }}
        transitionDuration={0}
        onClick={(event) => event.stopPropagation()}
      >
        <DialogContent>{formContent}</DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};

export default ConfirmForm;
