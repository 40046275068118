import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Ballot, Scoreman, Synchronizer } from "@tools";
import { Loading, PageErrorNotice } from "@common/comps";
import { CourseProvider, useCatalog, useCourse } from "@context";
import { Grid, ThemeProvider } from "@mui/material";
import { postTheme } from "@material/themes";

import { CourseHeader } from "./comps/header/CourseHeader";
import { CourseHero } from "./comps/hero/CourseHero";

import { CommentSection } from "features/comment";

export const CourseLanding = () => {
  const courseContext = useCourse();
  const { course, setCourse } = courseContext || {};
  const { postId, quad, totalScore, sessOwnerScore } = course || {};

  const catalogContext = useCatalog();
  const { setFeed: setCatalogFeed } = catalogContext || {};

  const VoteSection = () => (
    <Ballot
      variant="full"
      type="post"
      id={postId}
      quad={quad}
      totalScore={totalScore}
      sessOwnerScore={sessOwnerScore}
      alignX="center"
      onVote={(options) => {
        const { type: actionType } = options || {};
        const newScores = Scoreman.getNewScores({
          type: actionType,
          base: course,
        });

        setCourse((prev) => {
          return { ...prev, ...newScores };
        });
        Synchronizer.updateCourseFeed(
          { ...course, ...newScores },
          setCatalogFeed
        );
      }}
    />
  );

  const onCommentCreate = () => {
    setCourse &&
      setCourse((prev) => {
        const { childCount } = prev || {};
        const newChildCount = childCount + 1;
        Synchronizer.updatePostFeed(
          { ...prev, childCount: newChildCount },
          setCatalogFeed
        );
        return { ...prev, childCount: newChildCount };
      });
  };

  const onCommentDelete = (deletedId) => {
    setCourse &&
      setCourse((prev) => {
        const { childCount } = prev || {};
        const newChildCount = childCount - 1;
        Synchronizer.updatePostFeed(
          {
            ...prev,
            childCount: newChildCount > 0 ? newChildCount : 0,
          },
          setCatalogFeed
        );
        return {
          ...prev,
          childCount: newChildCount > 0 ? newChildCount : 0,
        };
      });
  };

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        <CourseHero />
      </Grid>
      <Grid item p={1.5} borderBottom="1px solid var(--border-gray)">
        <VoteSection />
      </Grid>
      <Grid item>
        <CommentSection
          post={{ postId, quad }}
          onCommentCreate={onCommentCreate}
          onCommentDelete={onCommentDelete}
        />
      </Grid>
    </Grid>
  );
};

const CourseContainer = ({ children }) => {
  const { isInitializing, course } = useCourse();

  const content = course ? (
    children
  ) : (
    <PageErrorNotice
      type={404}
      title="Not found"
      subtitle="Could not find course."
      disableHeader
    />
  );

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      sx={{ minHeight: "100vh" }}
    >
      <Grid item sx={{ position: "sticky", top: 0, zIndex: 100 }}>
        <CourseHeader />
      </Grid>
      <Grid item>{isInitializing ? <Loading p={2} /> : content}</Grid>
    </Grid>
  );
};

export const Course = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <ThemeProvider theme={postTheme}>
      <CourseProvider>
        <CourseContainer>
          <Outlet />
        </CourseContainer>
      </CourseProvider>
    </ThemeProvider>
  );
};
