import { useState, useReducer, useContext, useEffect } from "react";
import { cargo } from "@api";
import { GlobalContext, QuadContext } from "@context";
import { BasicForm, CourseSearch } from "@common/comps";

import { Submitter } from "@tools";

export const CreqBuilder = ({
  courseId,
  type,
  isOpen,
  setIsOpen,
  onCreate = () => {},
}) => {
  const { defaultError } = useContext(GlobalContext);
  const { quadId } = useContext(QuadContext);
  const [course, setCourse] = useState("");

  /* Submission */
  const [submitState, setSubmitState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState()
  );

  const submitter = new Submitter(submitState, setSubmitState, defaultError);

  useEffect(() => {
    submitter.green(Boolean(course));
  }, [course]);

  const handleSubmit = () => {
    if (course?.courseId) {
      submitter.startLoading();
      cargo
        .post(`/v2/q/${quadId}/r/course/${courseId}/requisite`, {
          requisite: { reqId: course.courseId, type },
        })
        .then((res) => {
          const { ok, payload } = res || {};
          if (ok) {
            onCreate(payload);
          } else {
            submitter.takeResponse(res);
          }
        });
    }
  };

  const content = <CourseSearch state={course} setState={setCourse} />;

  return (
    <BasicForm
      title={`Add ${type}`}
      isFormOpen={isOpen}
      setIsFormOpen={setIsOpen}
      content={content}
      handleSubmit={handleSubmit}
      submitState={{
        isSubmitLoading: submitState.isLoading,
        isSubmitDisabled: submitState.isDisabled,
        submitError: submitState.error,
      }}
    />
  );
};
