import { useContext, useEffect } from "react";
import { QuadContext } from "@context";
import { MoreHeader, Loading, EmptyLabel } from "@common/comps";
import { useTranche2 } from "@common/hooks";
import { Grid } from "@mui/material";
import { unique } from "radash";

import { ConfessionCard } from "./ConfessionCard";

const ConfessionFeed = () => {
  const { quadId } = useContext(QuadContext);

  const {
    initTranche,
    isLoading,
    feed,
    setFeed,
    isFeedEmpty,
    setFirstId,
    lastCardRef,
  } = useTranche2(`/v2/q/${quadId}/e/p/confession/staged`);

  const uniques = unique(feed, (c) => c.confessStageId);

  useEffect(() => initTranche(), []);

  useEffect(() => {
    if (feed?.length > 0) setFirstId(feed[0].maxConfessStageId);
  }, [feed]);

  const confessionFeed = uniques.map((c, i) => (
    <Grid item key={c.confessStageId}>
      <ConfessionCard
        ref={uniques.length === i + 1 ? lastCardRef : null}
        confess={c}
        onModerate={() =>
          setFeed((prev) =>
            prev.filter((s) => s.confessStageId !== c.confessStageId)
          )
        }
      />
    </Grid>
  ));

  return (
    <Grid container direction="column" wrap="nowrap">
      {isFeedEmpty ? (
        <EmptyLabel label="There are no pending confessions." p={2} />
      ) : (
        confessionFeed
      )}
      {isLoading && <Loading p={2} />}
      <Grid item height={200} />
    </Grid>
  );
};

export const EditorConfession = () => {
  const { quadId } = useContext(QuadContext);

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        <MoreHeader
          title="Moderate confessions"
          enableBack
          backRoute={`/q/${quadId}/editor`}
        />
      </Grid>
      <Grid item>
        <ConfessionFeed />
      </Grid>
    </Grid>
  );
};
