import { createTheme } from "@mui/material";

const tabTheme = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableFocusRipple: true,
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
          minWidth: 0,
          minHeight: 0,
          fontSize: "16px",
          fontWeight: "500",
          letterSpacing: "0.03em",
        },
      },
      variants: [
        {
          props: { variant: "tab--default" },
          style: {
            padding: "12px 20px",
            borderRadius: "3px",
            marginRight: "2px",
            color: "var(--quad-gray-alt)",

            "&:hover": {
              backgroundColor: "var(--button-transparent--hover)",
            },
          },
        },
        {
          props: { variant: "tab--selected" },
          style: {
            padding: "12px 20px",
            borderRadius: "3px",
            marginRight: "2px",
            color: "var(--quad-black)",
            fontWeight: "700",

            "&:hover": {
              backgroundColor: "var(--button-transparent--hover)",
            },
          },
        },
        {
          props: { variant: "mobile--default" },
          style: {
            borderRadius: "0px",
            padding: "12px 20px",
            fontSize: "15px",
            color: "var(--quad-gray-alt)",

            "&:hover": {
              backgroundColor: "var(--button-transparent--hover)",
            },
          },
        },
        {
          props: { variant: "mobile--selected" },
          style: {
            borderRadius: "0px",
            padding: "12px 20px",
            fontSize: "15px",
            color: "var(--quad-black)",
            fontWeight: "700",

            "&:hover": {
              backgroundColor: "var(--button-transparent--hover)",
            },
          },
        },
        {
          props: { variant: "bubble--default" },
          style: {
            border: "1px solid var(--button-border-gray)",
            borderRadius: "9999px",
            padding: "5px 16px",
            backgroundColor: "transparent",
            transition: "background-color 0ms",
            fontSize: "14px",
            fontWeight: "500",
            color: "var(--quad-black)",

            "&:hover": {
              borderColor: "var(--button-border-gray)",
              backgroundColor: "var(--button-transparent--hover)",
              transition: "background-color 50ms",
            },
          },
        },
        {
          props: { variant: "bubble--selected" },
          style: {
            border: "1px solid var(--quad-gray-light)",
            borderRadius: "9999px",
            padding: "5px 16px",
            backgroundColor: "var(--quad-gray-light)",
            transition: "background-color 0ms",
            fontSize: "14px",
            fontWeight: "700",
            color: "var(--quad-gray)",

            "&:hover": {
              border: "1px solid var(--quad-gray-light)",
              backgroundColor: "var(--quad-gray-light)",
              transition: "background-color 50ms",
            },
          },
        },
      ],
    },
  },
});

export default tabTheme;
