import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { QuadContext, ProgramContext } from "@context";
import { Detail, PageLinkButton } from "@common/comps";
import { Grid, Typography, createTheme, ThemeProvider } from "@mui/material";
import {
  ProgramObjectiveIcon,
  ProgramDurationIcon,
  ProgramUnitsIcon,
  ProgramWhoIcon,
  ProgramDeptIcon,
  LinkReqIcon,
} from "@material/icons";

import { ProgramOptions } from "../options/ProgramOptions";

const certLabelTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          letterSpacing: "0.01em",
        },
      },
      variants: [
        {
          props: { variant: "cert-code" },
          style: {
            fontSize: "15px",
            fontWeight: "500",
            color: "var(--quad-teal)",
          },
        },
        {
          props: { variant: "cert-name" },
          style: {
            fontSize: "15px",
            fontWeight: "500",
            color: "var(--quad-gray)",
          },
        },
      ],
    },
  },
});

const CertLabel = () => {
  const { program } = useContext(ProgramContext);
  const { certCode, certName } = program || {};

  return (
    <ThemeProvider theme={certLabelTheme}>
      <Grid container direction="row" wrap="nowrap" gap={1}>
        <Grid item>
          <Typography variant="cert-code">{certCode}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="cert-name">{certName}</Typography>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

const Details = () => {
  const { program } = useContext(ProgramContext);
  const { deptName, programUnits, programDur, programObj, programWho, durUom } =
    program || {};

  const durDetail = programDur
    ? `${programDur} ${durUom}${Number(programDur) > 1 ? "s" : ""}`
    : null;

  return (
    <Grid container direction="column" wrap="nowrap" gap={0.5}>
      {deptName && (
        <Grid item>
          <Detail icon={<ProgramDeptIcon />} label={deptName} />
        </Grid>
      )}
      {programUnits && (
        <Grid item>
          <Detail icon={<ProgramUnitsIcon />} label={`${programUnits} Units`} />
        </Grid>
      )}
      {programDur && (
        <Grid item>
          <Detail icon={<ProgramDurationIcon />} label={durDetail} />
        </Grid>
      )}
      {programObj && (
        <Grid item>
          <Detail icon={<ProgramObjectiveIcon />} label={programObj} />
        </Grid>
      )}
      {programWho && (
        <Grid item>
          <Detail icon={<ProgramWhoIcon />} label={programWho} />
        </Grid>
      )}
    </Grid>
  );
};

const Links = () => {
  const { quadId } = useContext(QuadContext);
  const { program } = useContext(ProgramContext);
  const { programId } = program || {};
  const navigate = useNavigate();

  return (
    <Grid container direction="column" wrap="nowrap" gap={1.25}>
      <Grid item>
        <PageLinkButton
          title="Requisites"
          subtitle="Prereqs and coreqs"
          handleClick={() => navigate(`/q/${quadId}/p/${programId}/reqs`)}
          icon={<LinkReqIcon />}
        />
      </Grid>
    </Grid>
  );
};

export const ProgramHero = () => {
  const { program } = useContext(ProgramContext);
  const { concName, programDesc } = program || {};

  const [refresh, setRefresh] = useState(false);

  const hero = (
    <Grid container direction="column" wrap="nowrap" gap={0.5}>
      <Grid item>
        <CertLabel />
      </Grid>
      <Grid item>
        <Typography variant="program__conc-name">{concName}</Typography>
      </Grid>
      <Grid item>
        {programDesc ? (
          <Typography variant="program__desc">{programDesc}</Typography>
        ) : (
          <Typography variant="program__desc--empty">
            No description added.
          </Typography>
        )}
      </Grid>
    </Grid>
  );

  const content = (
    <Grid container direction="column" wrap="nowrap" gap={1}>
      <Grid item mb={1}>
        {hero}
      </Grid>
      <Grid item mb={1.5}>
        <Details />
      </Grid>
      <Grid item>
        <Links />
      </Grid>
    </Grid>
  );

  const Options = () => (
    <ProgramOptions onEdit={() => setRefresh((prev) => !prev)} />
  );

  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      p={2}
      borderBottom="1px solid var(--border-gray)"
    >
      <Grid item sx={{ width: "100%" }}>
        {content}
      </Grid>
      <Grid
        item
        sx={{ width: "0px", position: "relative", right: 30, bottom: 5 }}
      >
        <Options />
      </Grid>
    </Grid>
  );
};
