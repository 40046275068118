import { createContext, useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { cargo } from "@api";
import { QuadContext } from "@context";

export const ProgramContext = createContext();

export const ProgramProvider = ({ children }) => {
  const { quadId } = useContext(QuadContext);
  const { programId: searchId } = useParams();

  // Init
  const [isInitializing, setIsInitializing] = useState(true);

  // Program
  const [program, setProgram] = useState(null);

  useEffect(() => {
    setIsInitializing(true);

    cargo.get(`/v2/q/${quadId}/program/${searchId}`).then((res) => {
      setIsInitializing(false);
      const { ok, payload } = res || {};
      if (ok) setProgram(payload);
    });
  }, [searchId]);

  return (
    <ProgramContext.Provider value={{ isInitializing, program, setProgram }}>
      {children}
    </ProgramContext.Provider>
  );
};

export const useProgram = () => {
  const context = useContext(ProgramContext);
  if (!context) {
    return null;
  } else {
    return context;
  }
};
