import { createTheme } from "@mui/material";

const textFieldTheme = createTheme({
  components: {
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
        autoComplete: "off",
      },
      styleOverrides: {
        root: {
          border: "1px solid",
          borderRadius: "5px",
          borderColor: "var(--input-border-gray)",
          height: "55px",
          marginBottom: "1px",
          backgroundColor: "transparent",

          "&:hover": {
            backgroundColor: "transparent",
          },

          "&.Mui-focused": {
            position: "relative",
            right: 1,
            border: "2px solid",
            borderColor: "var(--quad-teal)",
            backgroundColor: "transparent",
          },

          "&.Mui-error": {
            border: "2px solid",
            borderColor: "var(--quad-red)",
            marginBottom: "12px",
          },

          "&.Mui-disabled": {
            border: "none",
            backgroundColor: "var(--input-border-gray)",
          },
        },
        input: {
          color: "var(--quad-black)",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "var(--quad-gray)",
          fontSize: "16px",

          "&.Mui-focused": {
            color: "var(--quad-teal)",
          },

          "&.Mui-disabled": {
            color: "var(--quad-gray-alt)",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&.Mui-disabled": {
            WebkitTextFillColor: "var(--quad-gray-alt)",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          position: "absolute",
          bottom: "-8px",
        },
      },
    },
  },
});

export default textFieldTheme;
