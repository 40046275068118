import { useContext } from "react";
import { ProfileContext } from "@context";
import { Opener, Builder } from "@tools";
import { BasicAvatar, Detail } from "@common/comps";
import { Grid, Typography, ThemeProvider } from "@mui/material";
import {
  HlCalendarIcon,
  HlContactIcon,
  HlHometownIcon,
  HlLocationIcon,
  HlAcademiaIcon,
  HlGovIcon,
  HlIndustryIcon,
} from "@material/icons";

import { AvatarCrud } from "./AvatarCrud";
import { profileTheme } from "../../theme";

const User = () => {
  const { profile } = useContext(ProfileContext);
  const { canEdit, avatarSrc, name, username, credential } = profile || {};

  return (
    <Grid container direction="row" wrap="nowrap" gap={2}>
      <Grid item>
        {canEdit ? (
          <AvatarCrud />
        ) : (
          <BasicAvatar disableClick avatarSrc={avatarSrc} diameter={80} />
        )}
      </Grid>
      <Grid item>
        <Grid container direction="column" wrap="nowrap">
          <Grid item>
            <Typography variant="name">{name}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="username">{`/${username}`}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="credential">{credential}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const Highlights = () => {
  const { profile } = useContext(ProfileContext);
  const {
    sector,
    area,
    field,
    contact,
    location,
    hometown,
    joinedMonth,
    joinedYear,
  } = profile || {};

  const d = new Date();
  joinedMonth && d.setMonth(joinedMonth - 1);
  const month = d.toLocaleString("en-US", { month: "long" });

  const getSectorIcon = () => {
    switch (sector) {
      case "academia":
        return <HlAcademiaIcon />;
      case "government":
        return <HlGovIcon />;
      case "industry":
        return <HlIndustryIcon />;
      default:
        <HlIndustryIcon />;
    }
  };

  const listItems = [
    {
      icon: <HlContactIcon />,
      value: contact,
      canRender: Boolean(contact),
      variant: "link",
    },
    {
      icon: <HlLocationIcon />,
      value: location,
      canRender: Boolean(location),
    },
    {
      icon: <HlHometownIcon />,
      value: hometown,
      canRender: Boolean(hometown),
    },
    {
      icon: <HlCalendarIcon />,
      value: `Joined ${month}, ${joinedYear}`,
      canRender: Boolean(joinedMonth && joinedYear),
    },
  ];

  const toRender = listItems.filter((h) => h.canRender);

  const list = toRender.map((h, i) => (
    <Grid item key={i}>
      <Detail
        icon={h.icon}
        iconSize={20}
        label={h.value}
        labelStyle={{ fontSize: "15px", letterSpacing: "0.01em" }}
        labelVariant={h.variant ? h.variant : null}
      />
    </Grid>
  ));

  const fieldLabel = (
    <ThemeProvider theme={profileTheme}>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        gap={0.25}
        sx={{ position: "relative", bottom: 1 }}
      >
        <Grid item>
          <Typography variant="highlight" sx={{ fontWeight: "500" }}>
            {field}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="highlight">{area}</Typography>
        </Grid>
      </Grid>
    </ThemeProvider>
  );

  return (
    <Grid container direction="column" wrap="nowrap" gap={0.5}>
      {field && (
        <Grid item>
          <Detail
            icon={getSectorIcon()}
            iconSize={20}
            labelComponent={fieldLabel}
          />
        </Grid>
      )}
      {list}
    </Grid>
  );
};

export const ProfileHero = () => {
  const { profile } = useContext(ProfileContext);
  const { biography, canEdit } = profile || {};

  const essentialsEditor = (
    <Opener
      variant="outlined"
      label="Edit"
      builder={Builder.userEssentials({
        unedited: profile,
        onEdit: () => window.location.reload(),
      })}
    />
  );

  const highlightEditor = (
    <Opener
      variant="icon"
      icon="edit"
      builder={Builder.userHighlights({
        unedited: profile,
        onEdit: () => window.location.reload(),
      })}
    />
  );

  return (
    <Grid container direction="row" wrap="nowrap" p={2}>
      <Grid item sx={{ width: "100%" }}>
        <Grid container direction="column" wrap="nowrap" gap={2.5}>
          <Grid item>
            <User />
          </Grid>
          {biography && (
            <Grid item sx={{ width: "100%" }}>
              <Typography variant="biography">{biography}</Typography>
            </Grid>
          )}
          <Grid item>
            <Grid container direction="row" wrap="nowrap">
              <Grid item sx={{ width: "100%" }}>
                <Highlights />
              </Grid>
              {canEdit && (
                <Grid
                  item
                  sx={{ width: "0px", position: "relative", right: 35 }}
                >
                  {highlightEditor}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {canEdit && (
        <Grid item sx={{ width: "0px", position: "relative", right: 55 }}>
          {essentialsEditor}
        </Grid>
      )}
    </Grid>
  );
};
