import { useContext } from "react";
import { QuadContext } from "@context";
import { BasicHeader } from "@common/comps";
import { Box } from "@mui/material";

const MoreHeader = ({ title, enableBack, backRoute, handleBack }) => {
  const { quadId } = useContext(QuadContext);

  return (
    <Box p={1}>
      <BasicHeader
        title={title}
        titleVariant="more"
        enableBack={enableBack}
        handleBack={handleBack} // Overwrite default back behavior
        backRoute={backRoute ? backRoute : `/q/${quadId}/more`}
        backButtonProps={{ padding: "8px" }}
        backIconProps={{ width: "16px", height: "16px" }}
      />
    </Box>
  );
};

export default MoreHeader;
