import { useContext } from "react";
import { useParams } from "react-router-dom";
import { ProfileContext } from "@context";
import { PageErrorNotice } from "@common/comps";
import { LargeVacantIcon } from "@material/icons";

export const ProfileErrorNotice = () => {
  const { initError } = useContext(ProfileContext);
  const { type } = initError || {};
  const { username } = useParams();

  const getIcon = () => {
    switch (type) {
      case 404:
        return <LargeVacantIcon width={64} height={64} />;
      default:
        return null;
    }
  };

  const getTitle = () => {
    switch (type) {
      case 404:
        return "No user";
      case 403:
        return "Forbidden";
      default:
        return "Error";
    }
  };

  const getSubtitle = () => {
    switch (type) {
      case 404:
        return `@${username} is vacant.`;
      case 403:
        return "This profile is off-limits.";
      default:
        return "";
    }
  };

  return (
    <PageErrorNotice
      disableHeader
      type={type}
      title={getTitle()}
      subtitle={getSubtitle()}
      icon={getIcon()}
    />
  );
};
