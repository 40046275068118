// MUI
import { createTheme } from "@mui/material";

const instrCardTheme = createTheme({
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: "abbrev" },
          style: {
            fontSize: "14px",
            fontWeight: "700",
            color: "var(--quad-gray)",
            overflowWrap: "anywhere",
            letterSpacing: "0.05em",
          },
        },
        {
          props: { variant: "name" },
          style: {
            fontSize: "15px",
            fontWeight: "400",
            color: "var(--quad-black)",
            overflowWrap: "anywhere",
            letterSpacing: "0.01em",
          },
        },
        {
          props: { variant: "title" },
          style: {
            fontSize: "13px",
            fontWeight: "400",
            color: "var(--quad-gray-alt)",
            overflowWrap: "anywhere",
            letterSpacing: "0.01em",
          },
        },
        {
          props: { variant: "email" },
          style: {
            fontSize: "14px",
            fontWeight: "400",
            color: "var(--quad-teal)",
            overflowWrap: "anywhere",
            letterSpacing: "0.01em",
          },
        },
      ],
    },
  },
});

export default instrCardTheme;
