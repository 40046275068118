import {
  Grid,
  CircularProgress,
  Typography,
  createTheme,
  ThemeProvider,
} from "@mui/material";

const counterTheme = createTheme({
  components: {
    MuiGrid: {
      styleOverrides: {
        root: {
          marginBottom: "0px",
        },
      },
    },
  },
});

const CircularTextCounter = ({ text, limit }) => {
  const getProgressValue = () => {
    if (text) {
      if (text.length <= limit) {
        return (text.length / limit) * 100;
      } else {
        return 100;
      }
    } else {
      return 0;
    }
  };

  const getProgressColor = () => {
    if (text) {
      if (text.length > limit) {
        return "rgb(242, 70, 111)";
      } else {
        return "rgb(0, 185, 204)";
      }
    } else {
      return "rgb(0, 185, 204)";
    }
  };

  const getCountColor = () => {
    if (text) {
      if (text.length > limit) {
        return "rgb(242, 70, 111)";
      } else {
        return "rgb(83, 99, 99)";
      }
    } else {
      return "rgb(83, 99, 99)";
    }
  };

  return (
    <ThemeProvider theme={counterTheme}>
      <Grid container direction="row" wrap="nowrap">
        <Grid
          item
          sx={{
            padding: "0px 8px 0px",
            position: "relative",
          }}
        >
          <CircularProgress
            variant="determinate"
            size={18}
            thickness={5}
            value={100}
            sx={{
              color: "rgb(235, 237, 237)",
              position: "absolute",
              left: 0,
            }}
          />
          <CircularProgress
            variant="determinate"
            size={18}
            thickness={5}
            value={getProgressValue()}
            sx={{
              color: `${getProgressColor()}`,
              position: "absolute",
              zIndex: "2",
              left: 0,
            }}
          />
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
          }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "400",
              color: `${getCountColor()}`,
            }}
          >
            {text ? text.length : 0}
          </Typography>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default CircularTextCounter;
