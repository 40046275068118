import { createContext, useState, useEffect, useContext } from "react";
import { ProgramContext, QuadContext } from "@context";
import { cargo } from "@api";
import { unique } from "radash";

export const ProgramRequirementContext = createContext();

export const ProgramRequirementProvider = ({ children }) => {
  const { quadId } = useContext(QuadContext);
  const { program } = useContext(ProgramContext);
  const { programId } = program || {};
  const [type, setType] = useState("core");

  /* Group feed */
  const [isInitializing, setIsInitializing] = useState(true);
  const [groups, setGroups] = useState([]);

  const initialize = () => {
    setIsInitializing(true);
    setGroups([]);
    cargo
      .get(`/v2/q/${quadId}/program/${programId}/requirement/group/all`)
      .then((res) => {
        const { ok, payload } = res || {};
        if (ok) {
          setIsInitializing(false);
          setGroups(unique(payload.groups, (g) => g.preqGroupId));
        }
      });
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <ProgramRequirementContext.Provider
      value={{ type, setType, initialize, isInitializing, groups, setGroups }}
    >
      {children}
    </ProgramRequirementContext.Provider>
  );
};
