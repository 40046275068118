import { createContext, useContext, useState, useEffect } from "react";
import { cargo } from "@api";
import { QuadContext } from "@context";

export const AdminProgramContext = createContext();

export const AdminProgramProvider = ({ children }) => {
  const { quadId } = useContext(QuadContext);

  /* Init */
  const [isInitializing, setIsInitializing] = useState(true);
  const [concOptions, setConcOptions] = useState([]);

  /* Active */
  const [concentration, setConcentration] = useState("");
  const [program, setProgram] = useState("");

  const initialize = () => {
    setIsInitializing(true);
    cargo
      .post(`/v2/q/${quadId}/program/concentrations`, { filters: {} })
      .then((res) => {
        const { ok, payload } = res || {};
        if (ok) {
          setIsInitializing(false);
          setConcOptions(payload);
        }
      });
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <AdminProgramContext.Provider
      value={{
        isInitializing,
        concOptions,
        concentration,
        setConcentration,
        program,
        setProgram,
      }}
    >
      {children}
    </AdminProgramContext.Provider>
  );
};
