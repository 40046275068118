import { useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { PatalogProvider, PatalogContext } from "@context";
import {
  BubbleDropdown,
  Loading,
  Expandable,
  Clickable,
  EmptyLabel,
} from "@common/comps";
import { Grid, Box, Button, createTheme, ThemeProvider } from "@mui/material";
import { buttonTheme } from "@material/themes";
import { unique } from "radash";

import "./styles.css";

const programTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          display: "flex",
        },
      },
      variants: [
        {
          props: { variant: "cert" },
          style: {
            fontSize: "14px",
            fontWeight: "500",
            color: "var(--quad-black)",
            letterSpacing: "0.02em",
          },
        },
        {
          props: { variant: "dept" },
          style: {
            fontSize: "13px",
            fontWeight: "400",
            color: "var(--quad-gray)",
            letterSpacing: "0.03em",
          },
        },
      ],
    },
  },
});

const Program = ({ program, isFirst, isLast }) => {
  const navigate = useNavigate();
  const { programId, certCode, certName, deptName, quad } = program || {};

  return (
    <ThemeProvider theme={programTheme}>
      <Box
        borderTop={"1px solid var(--border-gray)"}
        borderBottom={isLast && "1px solid var(--border-gray)"}
      >
        <Clickable
          label={`${certName} (${certCode})`}
          subLabel={deptName}
          handleClick={() => navigate(`/q/${quad}/p/${programId}`)}
          containerProps={{ py: 1.5 }}
        />
      </Box>
    </ThemeProvider>
  );
};

const Programs = ({ conc }) => {
  const { programs } = conc || {};

  const uniques = unique(programs, (p) => p.programId);

  return (
    <Grid container direction="column" wrap="nowrap" p={3} pt={0}>
      {uniques.map((p, i) => (
        <Grid item key={p.programId}>
          <Program
            program={p}
            isFirst={i === 0}
            isLast={uniques.length === i + 1}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const Filters = () => {
  const { alphabet, setAlphabet, alphOptions } = useContext(PatalogContext);

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      p={2}
      gap={1.5}
      borderBottom="1px solid var(--border-gray)"
    >
      {alphabet && (
        <Grid item>
          <ThemeProvider theme={buttonTheme}>
            <Button
              variant="outlined--red"
              sx={{ color: "var(--quad-red)" }}
              onClick={() => setAlphabet("")}
            >
              Clear
            </Button>
          </ThemeProvider>
        </Grid>
      )}
      <Grid item sx={{ minWidth: "180px" }}>
        <BubbleDropdown
          label="Beginning with"
          state={alphabet}
          setState={setAlphabet}
          options={alphOptions}
        />
      </Grid>
    </Grid>
  );
};

const PatalogFeed = () => {
  const { isLoading, feed, lastCardRef } = useContext(PatalogContext);

  const isEmpty = !isLoading && feed.length === 0;

  const concentrations = feed.map((c, i) => (
    <Grid item key={c.concId}>
      <Expandable
        fullWidth
        label={c.concName}
        child={<Programs conc={c} />}
        ref={feed.length === i + 1 ? lastCardRef : null}
      />
    </Grid>
  ));

  return (
    <Grid container direction="column" wrap="nowrap">
      {isEmpty ? (
        <EmptyLabel label="No programs found." p={2} />
      ) : (
        concentrations
      )}
      {isLoading && <Loading p={2} />}
    </Grid>
  );
};

export const PatalogLanding = () => {
  const { isInitializing } = useContext(PatalogContext);

  if (isInitializing) {
    return <Loading p={2} />;
  } else {
    return (
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <Filters />
        </Grid>
        {!isInitializing && (
          <Grid item>
            <PatalogFeed />
          </Grid>
        )}
      </Grid>
    );
  }
};

export const Patalog = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <PatalogProvider>
      <Outlet />
    </PatalogProvider>
  );
};
