import { useEffect, useContext } from "react";
import { EmptyLabel, Expandable, Loading, Tab } from "@common/comps";
import { Grid, Box, Typography, ThemeProvider } from "@mui/material";

import {
  ProgramRequirementContext,
  ProgramRequirementProvider,
} from "./context";

import { requirementGroupTheme } from "./themes";

import { CoreGroups } from "./comps/CoreGroups";
import { ElectiveGroups } from "./comps/ElectiveGroups";

const ChoicesBadge = ({ label }) => {
  return (
    <Box
      pl={1.5}
      pr={1.6}
      pt={0.5}
      pb={0.65}
      sx={{
        backgroundColor: "var(--quad-gray)",
        borderRadius: "9999px",
      }}
    >
      <Typography variant="group__choices-badge">{label}</Typography>
    </Box>
  );
};

const GroupChild = ({ g }) => {
  const { type, choiceCourses, choiceOptions, choiceUnits } = g || {};

  const hasChoiceBadge = choiceCourses || choiceOptions || choiceUnits;

  const pluralize = (base, number) => `${base}${number > 1 ? "s" : ""}`;

  const coreChoiceSection = (
    <ChoicesBadge
      label={`${choiceOptions} ${pluralize("option", choiceOptions)}`}
    />
  );

  const electiveChoiceSection = (
    <Grid container direction="row" wrap="nowrap" gap={1}>
      {choiceCourses && (
        <ChoicesBadge
          label={`${choiceCourses} ${pluralize("course", choiceCourses)}`}
        />
      )}
      {choiceUnits && (
        <ChoicesBadge
          label={`${choiceUnits} ${pluralize("unit", choiceUnits)}`}
        />
      )}
    </Grid>
  );

  const choiceSection = (
    <Grid container direction="column" wrap="nowrap">
      {hasChoiceBadge && (
        <Grid item sx={{ display: "flex" }}>
          {type === "core" ? coreChoiceSection : electiveChoiceSection}
        </Grid>
      )}
    </Grid>
  );

  const getFeed = () => {
    switch (type) {
      case "core":
        return <CoreGroups g={g} />;
      case "elct":
        return <ElectiveGroups g={g} />;
      default:
        return <></>;
    }
  };

  return (
    <ThemeProvider theme={requirementGroupTheme}>
      <Grid container direction="column" wrap="nowrap" p={2} pt={0}>
        {hasChoiceBadge && (
          <Grid item pb={2} mb={2} borderBottom="1px solid var(--border-gray)">
            {choiceSection}
          </Grid>
        )}
        <Grid item>{getFeed()}</Grid>
      </Grid>
    </ThemeProvider>
  );
};

const Group = ({ g }) => {
  const { groupName, groupDesc } = g || {};

  return (
    <ThemeProvider theme={requirementGroupTheme}>
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <Typography variant="group__label">{groupName}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="group__sublabel">{groupDesc}</Typography>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

const GroupFeed = () => {
  const { type, groups } = useContext(ProgramRequirementContext);

  const feed = groups.filter((g) => g.type === type);
  const isEmpty = feed.length === 0;

  return (
    <Grid container direction="column" wrap="nowrap">
      {isEmpty ? (
        <EmptyLabel label="No groups added." />
      ) : (
        feed.map((g) => (
          <Grid item key={g.preqGroupId}>
            <Expandable
              heroComponent={<Group g={g} />}
              child={<GroupChild g={g} />}
            />
          </Grid>
        ))
      )}
    </Grid>
  );
};

const Landing = () => {
  const { type, setType, isInitializing } = useContext(
    ProgramRequirementContext
  );

  const navBar = (
    <Grid container direction="row" wrap="nowrap" gap={1}>
      <Grid item>
        <Tab
          variant="bubble"
          label="Core"
          isActive={type === "core"}
          handleClick={() => type !== "core" && setType("core")}
        />
      </Grid>
      <Grid item>
        <Tab
          variant="bubble"
          label="Electives"
          isActive={type === "elct"}
          handleClick={() => type !== "elct" && setType("elct")}
        />
      </Grid>
    </Grid>
  );

  if (isInitializing) {
    return <Loading p={2} />;
  } else {
    return (
      <Grid container direction="column" wrap="nowrap">
        <Grid item p={2} borderBottom="1px solid var(--border-gray)">
          {navBar}
        </Grid>
        <Grid item>
          <GroupFeed />
        </Grid>
        <Grid item height={200} />
      </Grid>
    );
  }
};

export const ProgramRequirements = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <ProgramRequirementProvider>
      <Landing />
    </ProgramRequirementProvider>
  );
};
