import { createContext, useContext, useEffect } from "react";
import { useTranche2 } from "@common/hooks";
import { QuadContext } from "@context";
import { unique } from "radash";

export const TimelineContext = createContext();

export const TimelineProvider = ({ children }) => {
  const { quadId } = useContext(QuadContext);

  const {
    initTranche,
    isLoading,
    lastCardRef,
    setFirstId,
    feed,
    setFeed,
    isFeedEmpty,
  } = useTranche2(`/v2/q/${quadId}/p/posts`);

  useEffect(() => {
    if (feed.length > 0) setFirstId(feed[0].maxPostId);
  }, [feed]);

  return (
    <TimelineContext.Provider
      value={{
        initTranche,
        isLoading,
        lastCardRef,
        feed: unique(feed, (p) => p.postId),
        setFeed,
        isFeedEmpty,
      }}
    >
      {children}
    </TimelineContext.Provider>
  );
};

export const useTimeline = () => {
  const context = useContext(TimelineContext);
  if (!context) {
    return null;
  } else {
    return context;
  }
};
