import { useState, forwardRef, cloneElement, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { ButtonDropdownIcon, ButtonCollapseIcon } from "@material/icons";
import { isString } from "radash";

const expandableTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          display: "flex",
        },
      },
      variants: [
        {
          props: { variant: "label" },
          style: {
            fontSize: "16px",
            fontWeight: "400",
            color: "var(--quad-black)",
            letterSpacing: "0.01em",
          },
        },
        {
          props: { variant: "label2" },
          style: {
            fontSize: "15px",
            fontWeight: "500",
            color: "var(--quad-black)",
            letterSpacing: "0.02em",
          },
        },
        {
          props: { variant: "sublabel2" },
          style: {
            fontSize: "14px",
            fontWeight: "400",
            color: "var(--quad-gray)",
            letterSpacing: "0.02em",
          },
        },
      ],
    },
  },
});

const Label = ({
  isExpanded,
  label,
  subLabel,
  labelSx,
  subLabelSx,
  disableUnderline,
}) => {
  if (subLabel) {
    return (
      <Grid container direction="column" wrap="nowrap">
        <Grid item mb={isExpanded && 1}>
          <Typography
            variant="label2"
            sx={{
              fontWeight: isExpanded && "500",
              textDecoration: isExpanded && !disableUnderline && "underline",
              textDecorationColor: "var(--attachment-border-gray)",
              textDecorationThickness: "2px",
              textUnderlineOffset: "0.5em",

              ...labelSx,
            }}
          >
            {label}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="sublabel2" sx={{ ...subLabelSx }}>
            {subLabel}
          </Typography>
        </Grid>
      </Grid>
    );
  } else {
    return isString(label) ? (
      <Typography
        variant="label"
        sx={{
          fontWeight: isExpanded && "500",
          textDecoration: isExpanded && !disableUnderline && "underline",
          textDecorationColor: "var(--attachment-border-gray)",
          textDecorationThickness: "2px",
          textUnderlineOffset: "0.5em",

          ...labelSx,
        }}
      >
        {label}
      </Typography>
    ) : (
      label
    );
  }
};

const Vanilla = (props) => {
  const { heroComponent, childComponent, disclosureSize, afterDelete } =
    props || {};

  const iconSize = disclosureSize || 18;

  const [isExpanded, setIsExpanded] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  useEffect(() => {
    if (isDeleted) {
      setTimeout(afterDelete, 200);
    }
  }, [isDeleted]);

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      className={isDeleted ? "card--empty--small" : ""}
    >
      {!isDeleted && (
        <>
          <Grid
            item
            onClick={() => setIsExpanded((prev) => !prev)}
            sx={
              !isDeleted && {
                ":hover": {
                  cursor: "pointer",
                },
              }
            }
          >
            <Grid container direction="row" wrap="nowrap" alignItems="center">
              <Grid item sx={{ width: "100%" }}>
                {heroComponent && cloneElement(heroComponent, { isExpanded })}
              </Grid>
              <Grid
                item
                sx={{ display: "flex", position: "relative", right: 5 }}
              >
                {isExpanded ? (
                  <ButtonCollapseIcon width={iconSize} height={iconSize} />
                ) : (
                  <ButtonDropdownIcon width={iconSize} height={iconSize} />
                )}
              </Grid>
            </Grid>
          </Grid>
          {isExpanded && <Grid item>{childComponent}</Grid>}
        </>
      )}
    </Grid>
  );
};

const FullWidth = (props) => {
  const {
    label,
    subLabel,
    labelSx,
    subLabelSx,
    disableUnderline,
    unlinkHoverTransition,
    child,
    heroComponent,
    endComponent,
    afterDelete,
    containerProps,
  } = props || {};

  const [isExpanded, setIsExpanded] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  useEffect(() => {
    if (isDeleted) {
      setTimeout(afterDelete, 200);
    }
  }, [isDeleted]);

  const iconSize = 18;

  const disclosure = (
    <Box
      id={unlinkHoverTransition ? "" : "expandable__full-width__icon"}
      sx={{
        display: "flex",
        padding: "8px",
        borderRadius: "9999px",

        ...(unlinkHoverTransition && {
          ":hover": {
            backgroundColor: "var(--icon-hover-gray)",
            transition: "background-color 200ms",
          },
        }),
      }}
    >
      {isExpanded ? (
        <ButtonCollapseIcon width={iconSize} height={iconSize} />
      ) : (
        <ButtonDropdownIcon width={iconSize} height={iconSize} />
      )}
    </Box>
  );

  const hero = (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      alignItems="center"
      p={2}
      gap={1}
      onClick={() => setIsExpanded((prev) => !prev)}
      {...containerProps}
      sx={{
        ":hover": {
          cursor: "pointer",
        },
      }}
    >
      <Grid item sx={{ width: "100%" }}>
        {heroComponent ? (
          cloneElement(heroComponent, { isExpanded })
        ) : (
          <Label
            isExpanded={isExpanded}
            label={label}
            subLabel={subLabel}
            labelSx={labelSx}
            subLabelSx={subLabelSx}
            disableUnderline={disableUnderline}
          />
        )}
      </Grid>
      {endComponent && (
        <Grid item>
          {cloneElement(endComponent, {
            onDelete: () => {
              setIsDeleted(true);
            },
          })}
        </Grid>
      )}
      <Grid item>{disclosure}</Grid>
    </Grid>
  );

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      borderBottom={isDeleted ? "" : "1px solid var(--border-gray)"}
      className={isDeleted ? "card--empty--small" : ""}
    >
      {!isDeleted && (
        <>
          <Grid
            item
            id="expandable__full-width"
            sx={{
              ...(!unlinkHoverTransition && {
                ":hover #expandable__full-width__icon": {
                  backgroundColor: "var(--icon-hover-gray)",
                  transition: "background-color 200ms",
                },
              }),
            }}
          >
            {hero}
          </Grid>
          {isExpanded && <Grid item>{child}</Grid>}
        </>
      )}
    </Grid>
  );
};

const Expandable = (props) => {
  const { fullWidth, variant } = props || {};

  if (fullWidth) {
    return <FullWidth {...props} />;
  } else {
    switch (variant) {
      case "vanilla":
        return <Vanilla {...props} />;
      default:
        return <FullWidth {...props} />;
    }
  }
};

const Styled = forwardRef((props, ref) => {
  return (
    <article ref={ref}>
      <ThemeProvider theme={expandableTheme}>
        <Expandable {...props} />
      </ThemeProvider>
    </article>
  );
});

export default Styled;
