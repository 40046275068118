import { useState } from "react";
import { BasicAvatar, HoverPopper } from "@common/comps";
import {
  Grid,
  Box,
  Typography,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { CardKeyIcon } from "@material/icons";

const labelTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          letterSpacing: "0.02em",
        },
      },
      variants: [
        {
          props: { variant: "user-label__name" },
          style: {
            fontSize: "13px",
            fontWeight: "700",
            color: "var(--quad-black)",
          },
        },
        {
          props: { variant: "user-label__username" },
          style: {
            fontSize: "13px",
            fontWeight: "500",
            color: "var(--quad-gray)",
          },
        },
      ],
    },
  },
});

const UserLabel = ({ user, customBadge }) => {
  const { avatarSrc, name, username } = user || {};

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopperOpen = (event) => {
    event.stopPropagation();
    const { currentTarget } = event || {};
    setAnchorEl(currentTarget);
  };

  const handlePopperClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const userBadge = (
    <BasicAvatar
      avatarSrc={avatarSrc}
      diameter="18px"
      disableClick
      handleClick={() => {}}
    />
  );

  const popperContent = (
    <ThemeProvider theme={labelTheme}>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        alignItems="center"
        sx={{ padding: "0px 8px" }}
      >
        <Grid item sx={{ marginRight: "8px" }}>
          <Box sx={{ width: "18px", height: "18px", display: "flex" }}>
            <CardKeyIcon />
          </Box>
        </Grid>
        <Grid item>
          <Typography variant="user-label__name">{name}</Typography>
        </Grid>
        <Grid item sx={{ marginLeft: "1px" }}>
          <Typography variant="user-label__username">{`/${username}`}</Typography>
        </Grid>
      </Grid>
    </ThemeProvider>
  );

  return (
    <Box onMouseEnter={handlePopperOpen} onMouseLeave={handlePopperClose}>
      {customBadge ? customBadge : userBadge}
      {anchorEl && <HoverPopper anchorEl={anchorEl} content={popperContent} />}
    </Box>
  );
};

export default UserLabel;
