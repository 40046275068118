import { useState } from "react";
import { cargo } from "@api";
import { getURL } from "@config";
import { PopperOptions, ConfirmForm } from "@common/comps";
import { PopperLogoutIcon } from "@material/icons";

export const Options = () => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  // Logout
  const [isLogoutLoading, setIsLogoutLoading] = useState(false);

  const handleLogout = (event) => {
    event.stopPropagation();
    setIsLogoutLoading(true);
    cargo.delete(`/v2/session`).then((res) => {
      const { redirect = "/" } = res || {};
      window.location.href = getURL(redirect);
    });
  };

  const menuItemsList = [
    {
      key: "left-bar__logout",
      label: "Logout",
      icon: <PopperLogoutIcon />,
      isRed: true,
      handleClick: (event) => {
        event.stopPropagation();
        setIsConfirmOpen(true);
      },
    },
  ];

  return (
    <>
      <PopperOptions
        menuItemsList={menuItemsList}
        type="nav"
        placement="right-start"
        offset={{
          offset: [10, -45],
        }}
      />
      {isConfirmOpen && (
        <ConfirmForm
          title="Confirm logout?"
          isFormOpen={isConfirmOpen}
          setIsFormOpen={setIsConfirmOpen}
          color="red"
          handleSubmit={handleLogout}
          submitState={{
            isSubmitLoading: isLogoutLoading,
          }}
          submitLabel="Logout"
          titleProps={{
            width: "100%",
            marginBottom: "10px",
            display: "flex",
            justifyContent: "center",
          }}
        />
      )}
    </>
  );
};
