import { isString } from "radash";

export const numReducer = (state, value) => {
  if (value === "") {
    return "";
  } else {
    const real = value.match(/^[0-9]+\.?[0-9]*$/)?.[0];
    return real ? real : state;
  }
};

export const intReducer = (state, value) => {
  if (isString(value)) {
    if (value === "") {
      return value;
    } else {
      return /^[1-9]+[0-9]*$/.test(value) ? value : state;
    }
  } else {
    return "";
  }
};
