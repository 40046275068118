import { useContext, useReducer, useState, useEffect } from "react";
import { GlobalContext, QuadContext } from "@context";
import { cargo } from "@api";
import {
  Loading,
  BasicForm,
  EmptyLabel,
  Editable,
  ConfirmForm,
  Submit,
  Dropdown,
} from "@common/comps";
import { Grid, Box, Button, ThemeProvider } from "@mui/material";
import { buttonTheme } from "@material/themes";

import { Submitter } from "@tools";

const Add = ({ concId, reinitialize, setPage, omissions }) => {
  const { defaultError } = useContext(GlobalContext);
  const { quadId } = useContext(QuadContext);
  const [isInitializing, setIsInitializing] = useState(true);
  const [certOptions, setCertOptions] = useState([]);

  useEffect(() => {
    setIsInitializing(true);
    cargo.get(`/v2/q/${quadId}/program/certifications`).then((res) => {
      const { ok, payload } = res || {};
      if (ok) {
        setIsInitializing(false);
        setCertOptions(payload.filter((c) => !omissions.includes(c.certId)));
      }
    });
  }, []);

  const [submitState, setSubmitState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState()
  );

  const submitter = new Submitter(submitState, setSubmitState, defaultError);

  const [selected, setSelected] = useState(null);

  useEffect(() => {
    submitter.resetError();
    submitter.green(Boolean(selected) && !omissions.includes(selected.certId));
  }, [selected]);

  const handleSubmit = () => {
    submitter.startLoading();
    const { certId } = selected || {};
    certId &&
      cargo
        .post(`/v2/q/${quadId}/r/program`, { concId, certId })
        .then((res) => {
          reinitialize();
          setPage("main");
        });
  };

  const backButton = (
    <Button variant="outlined" onClick={() => setPage("main")}>
      Back to main
    </Button>
  );

  const actionBar = (
    <ThemeProvider theme={buttonTheme}>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        justifyContent="space-between"
      >
        <Grid item>{backButton}</Grid>
        <Grid item>
          <Submit
            buttonLabel="Add"
            submitState={submitState}
            handleSubmit={handleSubmit}
          />
        </Grid>
      </Grid>
    </ThemeProvider>
  );

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item pb={2} borderBottom="1px solid var(--border-gray)">
        {actionBar}
      </Grid>
      {isInitializing ? (
        <Loading pt={3} pb={1} />
      ) : (
        <Grid item pt={2}>
          <Dropdown
            variant="object"
            label="Certifications"
            options={certOptions}
            labelKey="certName"
            valueKey="certId"
            state={selected}
            setState={setSelected}
          />
        </Grid>
      )}
    </Grid>
  );
};

const Program = ({ p, setPrograms }) => {
  const { defaultError } = useContext(GlobalContext);
  const { quadId } = useContext(QuadContext);

  const { programId, certName, certCode, deptName } = p || {};

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [deleteState, setDeleteState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState({ isDisabled: false })
  );

  const deleter = new Submitter(deleteState, setDeleteState, defaultError);

  const handleDelete = () => {
    deleter.startLoading();
    cargo.delete(`/v2/q/${quadId}/r/program/${programId}`).then((res) => {
      const { ok } = res || {};
      if (ok) {
        setIsDeleteOpen(false);
        setPrograms((prev) => prev.filter((p) => p.programId !== programId));
      } else {
        deleter.takeResponse(res);
      }
    });
  };

  return (
    <>
      <Box py={0.5}>
        <Editable
          label={`${certName} (${certCode})`}
          subLabel={deptName}
          handleDelete={() => setIsDeleteOpen(true)}
        />
      </Box>
      {isDeleteOpen && (
        <ConfirmForm
          title="Delete program?"
          subtitle="Deleting a program could cause issues to all elements that depend on it."
          isFormOpen={isDeleteOpen}
          setIsFormOpen={setIsDeleteOpen}
          color="red"
          handleSubmit={handleDelete}
          submitLabel="Delete"
          submitState={{
            isSubmitDisabled: deleteState.isDisabled,
            isSubmitLoading: deleteState.isLoading,
            submitError: deleteState.error,
          }}
        />
      )}
    </>
  );
};

const Main = ({ setPage, programs, setPrograms }) => {
  const addButton = (
    <ThemeProvider theme={buttonTheme}>
      <Button variant="filled" color="black" onClick={() => setPage("add")}>
        Add program
      </Button>
    </ThemeProvider>
  );

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item pb={2} borderBottom="1px solid var(--border-gray)">
        {addButton}
      </Grid>
      {programs.length === 0 ? (
        <EmptyLabel label="No programs added." py={2} />
      ) : (
        programs.map((p) => (
          <Grid
            item
            key={p.programId}
            p={1}
            borderBottom="1px solid var(--border-gray)"
          >
            <Program p={p} setPrograms={setPrograms} />
          </Grid>
        ))
      )}
    </Grid>
  );
};

export const ProgramsBuilder = ({ concId, isOpen, setIsOpen }) => {
  const { quadId } = useContext(QuadContext);
  const [isInitializing, setIsInitializing] = useState(true);
  const [page, setPage] = useState("main");

  const [programs, setPrograms] = useState([]);

  const initialize = () => {
    setIsInitializing(true);
    cargo
      .get(`/v2/q/${quadId}/program/all?by=concentration&concId=${concId}`)
      .then((res) => {
        const { ok, payload } = res || {};

        if (ok) {
          setIsInitializing(false);
          setPrograms(payload);
        }
      });
  };

  useEffect(() => {
    initialize();
  }, []);

  const pageRouter = () => {
    switch (page) {
      case "add":
        return (
          <Add
            concId={concId}
            reinitialize={initialize}
            setPage={setPage}
            omissions={programs.map((p) => p.certId)}
          />
        );
      default:
        return (
          <Main
            setPage={setPage}
            programs={programs}
            setPrograms={setPrograms}
          />
        );
    }
  };

  const content = isInitializing ? <Loading p={2} mb={2} /> : pageRouter();

  return (
    <BasicForm
      title="Edit programs"
      isFormOpen={isOpen}
      setIsFormOpen={setIsOpen}
      content={content}
      disableFooter
    />
  );
};
