import { forwardRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import {
  CourseSpringIcon,
  CourseSummerIcon,
  CourseFallIcon,
  CourseWinterIcon,
} from "@material/icons";
import { Detail, CourseLabel } from "@common/comps";

import { RecordOptions } from "./RecordOptions";

const getSemesterIcon = (semester) => {
  switch (semester) {
    case "Spring":
      return <CourseSpringIcon />;
    case "Summer":
      return <CourseSummerIcon />;
    case "Fall":
      return <CourseFallIcon />;
    case "Winter":
      return <CourseWinterIcon />;
    default:
      return <></>;
  }
};

export const RecordCard = forwardRef(({ record, setFeed }, ref) => {
  const navigate = useNavigate();
  const {
    courseRecId,
    courseId,
    quad,
    courseTitle,
    year,
    semester,
    courseUnits,
  } = record || {};

  const [isDeleted, setIsDeleted] = useState(false);

  useEffect(() => {
    if (isDeleted) {
      setTimeout(
        () =>
          setFeed((prev) => prev.filter((r) => r.courseRecId !== courseRecId)),
        200
      );
    }
  }, [isDeleted]);

  const details = (
    <Grid container direction="row" gap={2}>
      <Grid item>
        <Detail
          type="units"
          label={`${Number(courseUnits)} Unit${courseUnits > 1 ? "s" : ""}`}
        />
      </Grid>
      <Grid item>
        <Detail icon={getSemesterIcon(semester)} label={semester} />
      </Grid>
      <Grid item>
        <Detail type="year" label={year} />
      </Grid>
    </Grid>
  );

  const css = {
    ...(isDeleted || {
      "&:hover": {
        backgroundColor: "var(--card-hover-gray)",
        cursor: "pointer",
        transition: "background-color 200ms",
      },
    }),
  };

  const Options = () => (
    <RecordOptions courseRec={record} onDelete={() => setIsDeleted(true)} />
  );

  const card = (
    <Grid
      container
      direction="row"
      className={isDeleted ? "card--empty" : ""}
      borderBottom="1px solid var(--border-gray)"
      wrap="nowrap"
      px={2}
      py={1.5}
      sx={css}
      onClick={() => navigate(`/q/${quad}/c/${courseId}`)}
    >
      {!isDeleted && (
        <>
          <Grid item sx={{ width: "100%" }}>
            <Grid container direction="column" wrap="nowrap">
              <Grid item>
                <CourseLabel variant="borderless" hideTitle course={record} />
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "var(--quad-black)",
                    overflowWrap: "anywhere",
                    letterSpacing: "0.01em",
                  }}
                >
                  {courseTitle}
                </Typography>
              </Grid>
              <Grid item mt={1} mb={1}>
                {details}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Options />
          </Grid>
        </>
      )}
    </Grid>
  );

  return <article ref={ref}>{card}</article>;
});
