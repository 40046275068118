import { useState, useEffect, forwardRef, useContext } from "react";
import { GlobalContext } from "@context";
import { cargo } from "@api";
import { Moderator } from "@tools";
import { Description, BasicRating, BasicAvatar } from "@common/comps";
import { getCardDate } from "@common/utility";
import { Grid, Typography, ThemeProvider } from "@mui/material";
import { CardInstructorIcon } from "@material/icons";
import { AvatarAnon } from "@material/images";
import { cardTheme } from "@material/themes";

export const EvaluationCard = forwardRef(({ e, onModerate }, ref) => {
  const { defaultError } = useContext(GlobalContext);
  const {
    evalStageId,
    instrNameFirst,
    instrNameMiddle,
    instrNameLast,
    createdAt,
    rating,
    text,
    quad,
  } = e || {};

  const [isModerated, setIsModerated] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (isModerated) onModerate();
    }, 200);
  }, [isModerated]);

  const getFullName = () => {
    if (instrNameMiddle) {
      return instrNameFirst + " " + instrNameMiddle + " " + instrNameLast;
    } else {
      return instrNameFirst + " " + instrNameLast;
    }
  };

  const [isApproveOpen, setIsApproveOpen] = useState(false);
  const [isApproveLoading, setIsApproveLoading] = useState(false);
  const [isApproveDisabled, setIsApproveDisabled] = useState(false);
  const [approveError, setApproveError] = useState(defaultError);

  const [isRejectOpen, setIsRejectOpen] = useState(false);
  const [isRejectLoading, setIsRejectLoading] = useState(false);
  const [isRejectDisabled, setIsRejectDisabled] = useState(false);
  const [rejectError, setRejectError] = useState(defaultError);

  const approveParams = {
    title: "Publish evaluation?",
    subtitle:
      "Once published, evaluations can still be deleted by quad editors.",
    isApproveOpen,
    setIsApproveOpen,
    isApproveLoading,
    isApproveDisabled,
    approveError,
  };

  const rejectParams = {
    title: "Reject evaluation?",
    subtitle:
      "This action is irreversible. Rejected evaluations can never be recovered.",
    isRejectOpen,
    setIsRejectOpen,
    isRejectLoading,
    isRejectDisabled,
    rejectError,
  };

  const instrLabel = (
    <Grid container direction="row" wrap="nowrap" alignItems="center" gap={1}>
      <Grid item sx={{ display: "flex" }}>
        <CardInstructorIcon width={18} height={18} />
      </Grid>
      <Grid item>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "400",
            color: "var(--quad-gray)",
          }}
        >
          {getFullName()}
        </Typography>
      </Grid>
    </Grid>
  );

  const handleApprove = (event) => {
    event.stopPropagation();
    if (evalStageId && quad) {
      setIsApproveLoading(true);
      cargo
        .post(`/v2/q/${quad}/e/course/evaluation/anonymous`, { evalStageId })
        .then((res) => {
          const { ok, status, message } = res || {};
          if (ok) {
            setIsModerated && setIsModerated(true);
          } else {
            setIsApproveLoading(false);
            setIsApproveDisabled(true);
            setApproveError({
              isError: true,
              errorText: message,
              type: status,
            });
          }
        });
    }
  };

  const handleReject = (event) => {
    event.stopPropagation();
    setIsRejectLoading(true);
    if (evalStageId && quad) {
      cargo
        .delete(`/v2/q/${quad}/e/course/evaluation/staged/${evalStageId}`)
        .then((res) => {
          const { ok, status, message } = res || {};
          if (ok) {
            setIsModerated && setIsModerated(true);
          } else {
            setIsRejectLoading(false);
            setIsRejectDisabled(true);
            setRejectError({
              isError: true,
              errorText: message,
              type: status,
            });
          }
        });
    }
  };

  const content = (
    <Grid container direction="column" wrap="nowrap" gap={1}>
      <Grid item>
        <Grid container direction="row" wrap="nowrap">
          <Grid item mr={0.8} pt={0.2}>
            <BasicRating readOnly value={rating} />
          </Grid>
          <Grid item>
            <Typography variant="date">{getCardDate(createdAt)}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Description text={text} />
      </Grid>
      <Grid item sx={{ position: "relative", right: 8 }}>
        <Moderator
          handleApprove={handleApprove}
          handleReject={handleReject}
          approveParams={approveParams}
          rejectParams={rejectParams}
        />
      </Grid>
    </Grid>
  );

  const card = (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      sx={{
        borderBottom: isModerated ? "" : `1px solid var(--border-gray)`,
        padding: `${isModerated ? "0px" : "12px 15px"}`,
      }}
      className={isModerated ? "card--empty" : ""}
    >
      {!isModerated && (
        <>
          <Grid item mb={1} sx={{ position: "relative", left: 35 }}>
            {instrLabel}
          </Grid>
          <Grid item>
            <Grid container direction="row" wrap="nowrap" gap={2}>
              {!isModerated && (
                <>
                  <Grid item sx={{ position: "relative", top: 3 }}>
                    <BasicAvatar avatarSrc={AvatarAnon} />
                  </Grid>
                  <Grid item>{content}</Grid>
                </>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );

  return (
    <ThemeProvider theme={cardTheme}>
      <article ref={ref ? ref : null}>{card}</article>
    </ThemeProvider>
  );
});
