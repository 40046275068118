import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CourseContext, CatalogContext } from "@context";
import { Builder } from "@tools";
import { PopperOptions } from "@common/comps";
import { PopperCollectIcon } from "@material/icons";

import { replace } from "radash";

export const CourseOptions = () => {
  const navigate = useNavigate();
  const { course, setCourse } = useContext(CourseContext);
  const { canEdit } = course || {};
  const { subject, setFeed: setCatalogFeed } = useContext(CatalogContext);
  const { canCatalog } = subject || {};

  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isCollectOpen, setIsCollectOpen] = useState(false);

  const menuItems = [
    /*     {
      key: "course-options__edit",
      label: "Edit course",
      icon: <PopperEditIcon />,
      handleClick: (event) => {
        event.stopPropagation();
        setIsEditOpen(true);
      },
      isRestricted: true,
      auth: canEdit || canCatalog,
    }, */
    {
      key: "course-options__collect",
      label: "Collect course",
      icon: <PopperCollectIcon />,
      handleClick: (event) => {
        event.stopPropagation();
        setIsCollectOpen(true);
      },
    },
  ];

  const Collect = () =>
    Builder.courseRecord({
      courseId: course.courseId,
      isOpen: isCollectOpen,
      setIsOpen: setIsCollectOpen,
      onCreate: () => setIsCollectOpen(false),
    });

  const Edit = () =>
    Builder.course({
      courseId: course.courseId,
      subjectId: course.subjectId,
      isOpen: isEditOpen,
      setIsOpen: setIsEditOpen,
      onEdit: (edited) => {
        setCourse(edited);
        setCatalogFeed((prev) =>
          replace(prev, edited, (c) => c.courseId === course.courseId)
        );
      },
      onDelete: () => {
        setCatalogFeed((prev) =>
          prev.filter((c) => c.courseId !== course.courseId)
        );
        navigate(-1);
      },
    });

  return (
    <>
      <PopperOptions menuItemsList={menuItems} />
      {isCollectOpen && <Collect />}
      {isEditOpen && <Edit />}
    </>
  );
};
