import {
  Button,
  createTheme,
  Grid,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { isString } from "radash";
import { buttonTheme } from "@material/themes";
import { useState } from "react";

const descriptionTheme = createTheme({
  components: {
    MuiTypography: {
      variants: [
        {
          props: {
            variant: "body",
          },
          style: {
            fontSize: "15px",
            fontWeight: "400",
            color: "var(--quad-black)",
            whiteSpace: "pre-line",
            overflowWrap: "anywhere",
          },
        },
      ],
    },
  },
});

const Description = ({ text }) => {
  const charLimit = 650;
  const charLength = text ? text.length : 0;

  const [isExpanded, setIsExpanded] = useState(charLength < charLimit);

  if (isString(text) && text.length > 0) {
    return (
      <ThemeProvider theme={descriptionTheme}>
        <Grid
          container
          direction="column"
          wrap="nowrap"
          gap={0.5}
          mb={0.5}
          sx={{ width: "100%" }}
        >
          <Grid
            item
            sx={{
              lineHeight: "1.4em",
              width: "inherit",
            }}
          >
            <Typography variant="body">
              {isExpanded ? text : `${text.substring(0, charLimit + 1)}...`}
            </Typography>
          </Grid>
          {!isExpanded && (
            <Grid item sx={{ width: "inherit" }}>
              <Typography
                onClick={(event) => {
                  event.stopPropagation();
                  setIsExpanded(true);
                }}
                className="link--teal"
              >
                Read more
              </Typography>
            </Grid>
          )}
        </Grid>
      </ThemeProvider>
    );
  }
};

export default Description;
