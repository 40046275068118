import { useContext } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { BasicHeader, PageErrorNotice } from "@common/comps";
import { QuadProvider, QuadContext } from "@context";
import { Grid } from "@mui/material";

import { QuadNav } from "./comps/nav/QuadNav";

const QuadHero = () => {
  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid
        item
        sx={{
          borderBottom: "1px solid var(--border-gray)",
        }}
      >
        <QuadNav />
      </Grid>
    </Grid>
  );
};

const QuadContainer = ({ children }) => {
  const { isLoading, canAccess, quadData } = useContext(QuadContext);
  const { quadShort } = quadData || {};
  const { pathname } = useLocation();

  const pathParts = pathname.split("/");
  const isCoursePage = pathParts[3] === "c" && pathParts[4];
  const isPostPage = pathParts[3] === "post" && pathParts[4];
  const isProgramPage = pathParts[3] === "p" && pathParts[4];
  const fullPage = Boolean(isCoursePage || isPostPage || isProgramPage);

  const content = canAccess ? (
    <Grid item>{children}</Grid>
  ) : (
    <Grid item>
      <PageErrorNotice
        type={403}
        title="Stop there!"
        subtitle="You do not have access to this quad."
      />
    </Grid>
  );

  const canRenderHeader = !(fullPage || !canAccess);
  const canRenderHero = !isLoading && canAccess && !fullPage;

  return (
    <Grid container direction="column" wrap="nowrap">
      {canRenderHeader && (
        <Grid item sx={{ position: "sticky", top: 0, zIndex: 100 }}>
          <BasicHeader title={quadShort} noBack />
        </Grid>
      )}
      {canRenderHero && (
        <Grid item>
          <QuadHero />
        </Grid>
      )}
      {!isLoading && content}
    </Grid>
  );
};

export const Quad = () => {
  return (
    <QuadProvider>
      <QuadContainer>
        <Outlet />
      </QuadContainer>
    </QuadProvider>
  );
};
