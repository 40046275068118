import { useContext, useState, useEffect, useReducer } from "react";
import { GlobalContext, QuadContext } from "@context";
import { cargo } from "@api";
import { BasicForm, Loading, Dropdown, DepartmentSelect } from "@common/comps";
import { Grid } from "@mui/material";
import { isObject, pick } from "radash";

import { Template, Submitter } from "@tools";

export const ProgramBuilder = ({
  programId,
  isOpen,
  setIsOpen,
  onEdit = () => {},
}) => {
  /* Init */
  const { defaultError } = useContext(GlobalContext);
  const { quadId } = useContext(QuadContext);
  const [isInitializing, setIsInitializing] = useState(true);
  const [original, setOriginal] = useState("");

  /* Template */
  const [baseline, setBaseline] = useState(null);
  const [program, setProgram] = useReducer(Template.createReducer(), null);

  const template = new Template(baseline, program, setProgram);

  useEffect(() => {
    setIsInitializing(true);
    cargo
      .get(`/v2/q/${quadId}/r/program/form`)
      .then((res) => {
        const { ok, payload } = res || {};

        if (ok) {
          // setOptions(payload.options);
          return payload.template;
        } else {
          throw new Error("Program form initialization failed.");
        }
      })
      .then((template) => {
        cargo.get(`/v2/q/${quadId}/program/${programId}`).then((res) => {
          const { ok, payload } = res || {};

          if (ok) {
            setIsInitializing(false);
            setOriginal(payload);
            setBaseline(Template.superimpose(template, payload));
            setProgram({
              type: "init",
              value: Template.superimpose(template, payload),
            });
          }
        });
      });
  }, []);

  /* Form section */
  const [hot, setHot] = useState(false);

  /* Submission */
  const [submitState, setSubmitState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState()
  );

  const submitter = new Submitter(submitState, setSubmitState, defaultError);

  useEffect(() => {
    if (hot) setHot(false);

    if (!hot && isObject(program)) {
      const preflight = template.preflight();
      const isChanged = template.isChanged();

      submitter.stock(template.crush());
      submitter.green(preflight && isChanged);
      submitter.resetError();
    }
  }, [program]);

  const content = isInitializing ? (
    <Loading p={2} />
  ) : (
    isObject(program) && (
      <Grid container direction="column" wrap="nowrap" gap={1}>
        <Grid item>
          <DepartmentSelect
            variant="input"
            initialState={pick(original, ["deptId", "deptName"])}
            handleChange={(event, value) =>
              setProgram({ field: "deptId", value: value.deptId })
            }
            handleBlurClear={() => setProgram({ field: "deptId", value: null })}
          />
        </Grid>
        <Grid item>
          {template.prefab().input({
            multiline: true,
            label: "Description",
            field: "description",
            rows: 10,
          })}
        </Grid>
        <Grid item>
          <Grid container direction="row" wrap="nowrap" gap={1}>
            <Grid item sx={{ width: "50%" }}>
              {template.prefab().numberInput({
                label: "Duration",
                field: "duration",
                type: "int",
              })}
            </Grid>
            <Grid item sx={{ width: "50%" }}>
              <Dropdown
                label="Measure"
                options={baseline.durationMeasure.oneOf}
                state={program.durationMeasure.value}
                handleChange={(event) => {
                  setProgram({
                    field: "durationMeasure",
                    value: event.target.value,
                  });
                }}
                error={program.durationMeasure.error}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {template.prefab().numberInput({
            label: "Units",
            field: "units",
            type: "real",
          })}
        </Grid>
        <Grid item>
          {template.prefab().input({
            multiline: true,
            label: "Objectives",
            field: "objectives",
            rows: 10,
          })}
        </Grid>
        <Grid item>
          {template.prefab().input({
            multiline: true,
            label: "For whom",
            field: "intendedFor",
            rows: 10,
          })}
        </Grid>
      </Grid>
    )
  );

  const handleEdit = () => {
    template.resetErrors(defaultError);
    submitter.startLoading();
    cargo
      .put(`/v2/q/${quadId}/r/program/${programId}`, {
        program: template.crush(),
      })
      .then((res) => {
        const { ok, isClientError, errors, payload } = res || {};
        if (ok) {
          onEdit(payload);
        } else {
          setHot(true);
          submitter.takeResponse(res);
          if (isClientError) template.setErrors(errors);
        }
      });
  };

  return (
    isOpen && (
      <>
        <BasicForm
          title="Edit program"
          isFormOpen={isOpen}
          setIsFormOpen={setIsOpen}
          content={content}
          handleSubmit={handleEdit}
          submitState={{
            isSubmitLoading: submitState.isLoading,
            isSubmitDisabled: submitState.isDisabled,
            submitError: submitState.error,
          }}
        />
      </>
    )
  );
};
