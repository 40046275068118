import { useContext, useState, useEffect, useReducer } from "react";
import { GlobalContext, QuadContext } from "@context";
import { cargo } from "@api";
import { BasicForm, Loading, Dropdown } from "@common/comps";
import { isObject } from "radash";
import { Grid, Typography } from "@mui/material";

import { Template, Submitter } from "@tools";

export const PreqGroupBuilder = ({
  programId,
  unedited,
  type,
  isOpen,
  setIsOpen,
  onCreate = () => {},
  onEdit = () => {},
  onDelete = () => {},
}) => {
  /* Init */
  const { defaultError } = useContext(GlobalContext);
  const { quadId } = useContext(QuadContext);
  const isEdit = Boolean(unedited);
  const [isInitializing, setIsInitializing] = useState(true);

  /* Resources */
  const [options, setOptions] = useState({});
  const { typeOptions } = options || {};

  /* Template */
  const [baseline, setBaseline] = useState(null);
  const [group, setGroup] = useReducer(Template.createReducer(), null);

  const template = new Template(baseline, group, setGroup);

  useEffect(() => {
    setIsInitializing(true);
    cargo
      .get(`/v2/q/${quadId}/r/program/requirement/group/form`)
      .then((res) => {
        const { ok, payload } = res || {};
        if (ok) {
          setIsInitializing(false);
          setOptions(payload.options);

          /* Templating */
          const initialTemplate = isEdit
            ? Template.superimpose(payload.template, unedited)
            : Template.superimpose(payload.template, { type });

          if (isEdit) setBaseline(initialTemplate);
          setGroup({ type: "init", value: initialTemplate });
        }
      });
  }, []);

  /* Form section */
  const { preqGroupId } = unedited || {};
  const [hot, setHot] = useState(false);

  /* Submission */
  const [submitState, setSubmitState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState()
  );
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [deleteState, setDeleteState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState({ isDisabled: false })
  );

  const submitter = new Submitter(submitState, setSubmitState, defaultError);
  const deleter = new Submitter(deleteState, setDeleteState, defaultError);

  useEffect(() => {
    if (hot) setHot(false);

    if (!hot && isObject(group)) {
      const preflight = template.preflight();
      const isChanged = template.isChanged();

      submitter.stock(template.crush());
      submitter.green(isEdit ? preflight && isChanged : preflight);
      submitter.resetError();
      deleter.resetError();
    }
  }, [group]);

  const handleCreate = () => {
    template.resetErrors(defaultError);
    submitter.startLoading();
    cargo
      .post(`/v2/q/${quadId}/r/program/${programId}/requirement/group`, {
        group: template.crush(),
      })
      .then((res) => {
        const { ok, isClientError, errors } = res || {};
        if (ok) {
          onCreate();
        } else {
          setHot(true);
          submitter.takeResponse(res);
          if (isClientError) template.setErrors(errors);
        }
      });
  };

  const handleEdit = () => {
    template.resetErrors(defaultError);
    submitter.startLoading();
    cargo
      .put(`/v2/q/${quadId}/r/program/requirement/group/${preqGroupId}`, {
        group: template.crush(),
      })
      .then((res) => {
        const { ok, isClientError, errors } = res || {};
        if (ok) {
          onEdit();
        } else {
          setHot(true);
          submitter.takeResponse(res);
          if (isClientError) template.setErrors(errors);
        }
      });
  };

  const handleDelete = () => {
    template.resetErrors(defaultError);
    deleter.startLoading();
    cargo
      .delete(`/v2/q/${quadId}/r/program/requirement/group/${preqGroupId}`)
      .then((res) => {
        const { ok } = res || {};
        if (ok) {
          onDelete();
        } else {
          deleter.takeResponse(res);
        }
      });
  };

  const getChoicesInput = () => {
    switch (group.type.value) {
      case "elct":
        return (
          <Grid item>
            <Grid container direction="column" wrap="nowrap" gap={1}>
              <Grid item>
                {template.prefab().numberInput({
                  label: "How many courses",
                  field: "choiceOfCourses",
                  type: "int",
                })}
              </Grid>
              <Grid item>
                {template.prefab().numberInput({
                  label: "How many units",
                  field: "choiceOfUnits",
                })}
              </Grid>
            </Grid>
          </Grid>
        );
      default:
        return (
          <Grid item>
            {template.prefab().numberInput({
              label: "How many options",
              field: "choiceOfOptions",
              type: "int",
            })}
          </Grid>
        );
    }
  };

  const content = isInitializing ? (
    <Loading p={2} />
  ) : (
    isObject(group) && (
      <Grid container direction="column" wrap="nowrap" gap={1}>
        <Grid item>
          {template
            .prefab()
            .input({ label: "Custom index", field: "customIndex" })}
        </Grid>
        <Grid item>
          {template.prefab().input({ label: "Group name", field: "name" })}
        </Grid>
        <Grid item>
          <Dropdown
            variant="object"
            label="Type"
            options={typeOptions}
            labelKey="label"
            valueKey="value"
            state={group.type.value}
            handleChange={(event) =>
              setGroup({
                field: "type",
                value: event.target.value,
              })
            }
            error={group.type.error}
            disableObjectSelect
          />
        </Grid>
        <Grid item>
          {template.prefab().input({
            multiline: true,
            label: "Description",
            field: "description",
            rows: 10,
          })}
        </Grid>
        {type && (
          <Grid item borderBottom="1px solid var(--attachment-border-gray)">
            <Typography variant="label">Choices</Typography>
          </Grid>
        )}
        {type && getChoicesInput()}
      </Grid>
    )
  );

  return (
    isOpen && (
      <>
        {isEdit ? (
          <BasicForm
            title="Edit requirement group"
            isFormOpen={isOpen}
            setIsFormOpen={setIsOpen}
            content={content}
            handleSubmit={handleEdit}
            submitState={{
              isSubmitLoading: submitState.isLoading,
              isSubmitDisabled: submitState.isDisabled,
              submitError: submitState.error,
            }}
            handleDelete={handleDelete}
            deleteTitle="Delete core?"
            deleteSubtitle="Deleting cores will cause issues to elements that depend on it."
            deleteState={{
              ...{
                isDeleteLoading: deleteState.isLoading,
                isDeleteDisabled: deleteState.isDisabled,
                deleteError: deleteState.error,
              },
              isDeleteOpen,
              setIsDeleteOpen,
            }}
          />
        ) : (
          <BasicForm
            title="Add requirement group"
            isFormOpen={isOpen}
            setIsFormOpen={setIsOpen}
            content={content}
            handleSubmit={handleCreate}
            submitState={{
              isSubmitLoading: submitState.isLoading,
              isSubmitDisabled: submitState.isDisabled,
              submitError: submitState.error,
            }}
          />
        )}
      </>
    )
  );
};
