import { useContext, useState, useEffect } from "react";
import { cargo } from "@api";
import { QuadContext } from "@context";
import { Opener, Builder } from "@tools";
import { Loadman, Editable, PopperOptions } from "@common/comps";
import { Grid, Box } from "@mui/material";
import { PopperEditIcon } from "@material/icons";
import { replace, unique } from "radash";

const Certification = ({ c, setFeed }) => {
  const { certId, certCode, certName } = c || {};

  const [isEditOpen, setIsEditOpen] = useState(false);

  const menuItemsList = [
    {
      key: "admin-certification__edit",
      label: "Edit certification",
      icon: <PopperEditIcon />,
      handleClick: (event) => {
        event.stopPropagation();
        setIsEditOpen(true);
      },
    },
  ];

  const endComponent = (
    <>
      <PopperOptions menuItemsList={menuItemsList} />
      {isEditOpen &&
        Builder.certification({
          isOpen: isEditOpen,
          setIsOpen: setIsEditOpen,
          unedited: c,
          onEdit: (edited) => {
            setFeed((prev) =>
              replace(prev, edited, (c) => c.certId === certId)
            );
            setIsEditOpen(false);
          },
        })}
    </>
  );

  return (
    <Box px={2} py={1.5} borderBottom="1px solid var(--border-gray)">
      <Editable
        variant="vanilla"
        label={certCode}
        subLabel={certName}
        endComponent={endComponent}
      />
    </Box>
  );
};

const CertificationFeed = () => {
  const { quadId } = useContext(QuadContext);
  const [isLoading, setIsLoading] = useState(true);
  const [feed, setFeed] = useState([]);

  const isEmpty = feed.length === 0;
  const noFeed = isLoading || isEmpty;

  useEffect(() => {
    setIsLoading(true);
    cargo.get(`/v2/q/${quadId}/program/certifications`).then((res) => {
      const { ok, payload } = res || {};
      if (ok) {
        setIsLoading(false);
        setFeed(unique(payload, (c) => c.certId));
      }
    });
  }, []);

  return noFeed ? (
    <Loadman variant="standard" isLoading={isLoading} isEmpty={isEmpty} p={2} />
  ) : (
    <Grid container direction="column" wrap="nowrap">
      {feed.map((c) => (
        <Grid item key={c.certId}>
          <Certification c={c} setFeed={setFeed} />
        </Grid>
      ))}
    </Grid>
  );
};

export const CertificationManager = () => {
  const [fresh, setFresh] = useState(0);

  const Create = () => (
    <Opener
      variant="filled"
      color="black"
      label="Create certification"
      builder={Builder.certification({
        onCreate: () => setFresh((prev) => prev + 1),
      })}
    />
  );

  const Feed = () => <CertificationFeed />;

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item p={2} borderBottom="1px solid var(--border-gray)">
        <Create />
      </Grid>
      <Grid item>
        <Feed />
      </Grid>
    </Grid>
  );
};
