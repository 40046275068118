import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "@context";
import { cargo } from "@api";
import { getURL } from "@config";
import { Input, Submit } from "@common/comps";
import { setFieldErrors } from "@common/utility";
import { Grid, Link } from "@mui/material";

export const SettingsPassword = () => {
  const { defaultError } = useContext(GlobalContext);

  const navigate = useNavigate();

  // Form data
  const [current, setCurrent] = useState("");
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");

  // Field errors
  const [currentError, setCurrentError] = useState(defaultError);
  const [passwordError, setPasswordError] = useState(defaultError);
  const [confirmError, setConfirmError] = useState(defaultError);

  const setErrorMap = {
    current: setCurrentError,
    password: setPasswordError,
    confirm: setConfirmError,
  };

  // Submission
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [submitError, setSubmitError] = useState(defaultError);

  const goodForSubmit = Boolean(
    current && password && confirm && password === confirm
  );

  useEffect(() => {
    setIsSubmitDisabled(!goodForSubmit);
    setSubmitError(defaultError);
    Object.values(setErrorMap).forEach((item) => item(defaultError));
  }, [password, confirm, current]);

  const handleSubmit = () => {
    if (confirm === password) {
      if (current && password) {
        setIsSubmitLoading(true);
        cargo
          .put(`/v2/settings/user/password`, {
            pass: { current, password, confirm },
          })
          .then((res) => {
            setIsSubmitLoading(false);
            const {
              ok,
              status,
              errors = [],
              isClientError,
              message,
            } = res || {};
            if (ok) {
              navigate("/settings/security");
            } else {
              setIsSubmitLoading(false);
              setIsSubmitDisabled(true);
              setSubmitError({
                isError: true,
                errorText: message,
                type: status,
              });
              if (isClientError) setFieldErrors(errors, setErrorMap);
            }
          });
      } else {
        setIsSubmitDisabled(true);
      }
    } else {
      setIsSubmitDisabled(true);
      setPasswordError({
        isError: true,
        type: 400,
        errorText: "Passwords have to match.",
      });
      setConfirmError({
        isError: true,
        type: 400,
        errorText: "Passwords have to match.",
      });
    }
  };

  const recoveryLink = (
    <Link
      underline="none"
      px={1}
      sx={{
        fontSize: "13px",
        fontWeight: "400",
        color: "var(--quad-teal)",

        "&:hover": {
          textDecoration: "underline",
          cursor: "pointer",
        },
      }}
      onClick={() => {
        window.location.href = getURL("/recovery");
      }}
    >
      Forgot password?
    </Link>
  );

  return (
    <Grid container direction="column" p={2} gap={1}>
      <Grid item>
        <Input
          variant="password"
          label="Current password"
          state={current}
          setState={setCurrent}
          error={currentError}
        />
        {recoveryLink}
      </Grid>
      <Grid item>
        <Input
          variant="password"
          label="Password"
          state={password}
          setState={setPassword}
          error={passwordError}
        />
      </Grid>
      <Grid item>
        <Input
          variant="password"
          label="Confirm"
          state={confirm}
          setState={setConfirm}
          error={confirmError}
        />
      </Grid>
      <Grid item py={2}>
        <Submit
          alignX="flex-end"
          buttonLabel="Change"
          handleSubmit={handleSubmit}
          submitState={{
            isDisabled: isSubmitDisabled,
            isLoading: isSubmitLoading,
            error: submitError,
          }}
        />
      </Grid>
    </Grid>
  );
};
