import { Box } from "@mui/material";
import { assign } from "radash";

const Divider = ({ height }) => {
  const boxSx = {
    width: "1px",
    height: height || "1em",
    borderRadius: "9999px",
    backgroundColor: "var(--div-gray)",
  };

  return <Box sx={boxSx} />;
};

export default Divider;
