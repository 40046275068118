import {
  createContext,
  useEffect,
  useState,
  useReducer,
  useContext,
} from "react";
import { cargo } from "@api";
import { getURL } from "@config";
import { assign, pick } from "radash";
import { Grid, Button, ThemeProvider } from "@mui/material";
import { buttonTheme } from "@material/themes";
import { ButtonCloseIcon, GeneralLogoIcon } from "@material/icons";
import { useNavigate } from "react-router-dom";

export const EnrollContext = createContext();

const initialEnrollState = {
  quad: null,
  name: null,
  email: null,
  birthMonth: null,
  birthDay: null,
  birthYear: null,
};

const enrollReducer = (state, action) => {
  const { type, key, value } = action || {};

  const updatables = [...Object.keys(initialEnrollState)];

  if (type === "reset") {
    return initialEnrollState;
  } else if (type === "batchUpdate") {
    const batchUpdate = (obj) => {
      const intersection = pick(obj, Object.keys(initialEnrollState));
      return assign(state, intersection);
    };

    return batchUpdate(value);
  } else {
    if (updatables.includes(key)) {
      const updateProp = (val) => {
        return assign(state, { [key]: val });
      };

      switch (type) {
        case "clear":
          return updateProp(null);
        case "update":
          return updateProp(value);
        default:
          return state;
      }
    } else {
      throw new Error("Invalid property for enroll object.");
    }
  }
};

const FormHeader = () => {
  const navigate = useNavigate();

  const closeButton = (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant="icon"
        onClick={(event) => {
          event.stopPropagation();
          navigate("/");
        }}
      >
        <ButtonCloseIcon width={18} height={18} />
      </Button>
    </ThemeProvider>
  );

  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      justifyContent="space-between"
      alignItems="center"
      p={0.5}
    >
      <Grid item width={0}>
        {closeButton}
      </Grid>
      <Grid item sx={{ display: "flex", position: "relative", top: 2 }}>
        <GeneralLogoIcon width={30} height={30} />
      </Grid>
      <Grid item width={0} />
    </Grid>
  );
};

export const EnrollProvider = ({ children }) => {
  const [isInitializing, setIsInitializing] = useState(true);

  const [page, setPage] = useState("quad"); // Form has several pages

  const [enroll, setEnroll] = useReducer(enrollReducer, initialEnrollState);

  useEffect(() => {
    setIsInitializing(true);
    setEnroll({ type: "reset" });

    cargo.get(`/v2/session/authorization`).then((res) => {
      const { payload: { authorized, redirect = "/" } = {} } = res || {};
      authorized
        ? (window.location.href = getURL(redirect))
        : setIsInitializing(false);
    });
  }, []);

  return (
    <EnrollContext.Provider
      value={{
        isInitializing,
        page,
        setPage,
        enroll,
        setEnroll,
        FormHeader,
      }}
    >
      {children}
    </EnrollContext.Provider>
  );
};

export const useEnroll = () => {
  const context = useContext(EnrollContext);
  if (!context) {
    return null;
  } else {
    return context;
  }
};
