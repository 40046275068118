// MUI
import { createTheme } from "@mui/material";

const attrTheme = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableFocusRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
          minWidth: 0,
          minHeight: 0,
          fontSize: "14px",
          fontWeight: "400",
          color: "var(--quad-black)",
          letterSpacing: "0.02em",
        },
      },
      variants: [
        {
          props: { variant: "attribute--default" },
          style: {
            border: "1px solid var(--button-border-gray)",
            backgroundColor: "transparent",
            borderRadius: "9999px",

            "&:hover": {
              backgroundColor: "var(--button-transparent--hover)",
              transition: "background-color 50ms",
            },
          },
        },
        {
          props: { variant: "attribute--selected" },
          style: {
            border: "1px solid var(--quad-gray-light)",
            backgroundColor: "var(--quad-gray-light)",
            borderRadius: "9999px",
            fontWeight: "500",
            color: "var(--quad-teal)",
            fontWeight: "500",

            "&:hover": {
              border: "1px solid var(--quad-gray-light)",
              backgroundColor: "var(--quad-gray-light)",
              transition: "background-color 50ms",
            },
          },
        },
      ],
    },
  },
});

export default attrTheme;
