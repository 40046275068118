import {
  Badge,
  Clickable,
  CourseLabel,
  Detail,
  EmptyLabel,
} from "@common/comps";
import { getCourseLevelIcon } from "@common/utility";
import { Grid, Box, Typography, ThemeProvider } from "@mui/material";

import { courseRequisiteTheme } from "./themes";
import { CardAlternativeIcon } from "@material/icons";

export const CourseRequisiteAlternatives = ({ req }) => {
  const { alts } = req || {};

  const cornerIcon = (
    <Grid
      item
      pt={0.5}
      px={1}
      borderBottom="1px solid var(--attachment-border-gray)"
    >
      <CardAlternativeIcon width={18} height={18} />
    </Grid>
  );

  const alternativeList = Array.isArray(alts) ? (
    alts.map((c, i) => (
      <Grid
        item
        key={c.courseId}
        borderBottom={i + 1 !== alts.length && "1px solid var(--border-gray)"}
      >
        <Clickable
          variant="line"
          enableSubLabelTransition
          label={`${c.subjectCode} ${c.courseCode}`}
          subLabel={c.courseTitle}
          endComponent={
            <Badge
              variant="outlined"
              label={`${Number(c.courseUnits)} Unit${
                c.courseUnits > 1 ? "s" : ""
              }`}
            />
          }
          containerProps={{
            alignItems: "center",
            p: 1,
          }}
        />
      </Grid>
    ))
  ) : (
    <></>
  );

  if (Array.isArray(alts)) {
    return (
      <Box px={2} pb={2}>
        <Grid
          container
          direction="column"
          wrap="nowrap"
          border="1px solid var(--attachment-border-gray)"
          borderRadius={1}
          px={1}
          py={0.25}
        >
          {cornerIcon}
          {alternativeList}
        </Grid>
      </Box>
    );
  } else {
    return <EmptyLabel label="No pre-approved alternatives." px={2} pb={2} />;
  }
};

export const CourseRequisiteCard = ({ req }) => {
  const { courseTitle, courseUnits, courseLevel, courseLevelName } = req || {};

  const hasHighlights = courseUnits || courseLevel;

  const highlights = (
    <Grid container direction="column" wrap="nowrap" gap={0.5}>
      <Grid item>
        <Detail type="units" label={`${Number(courseUnits)} Units`} />
      </Grid>
      {courseLevel && (
        <Grid item>
          <Detail
            icon={getCourseLevelIcon(courseLevel)}
            label={courseLevelName}
          />
        </Grid>
      )}
    </Grid>
  );

  const content = (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        <CourseLabel variant="borderless" hideTitle course={req} />
      </Grid>
      <Grid item>
        <Typography variant="title">{courseTitle}</Typography>
      </Grid>
      {hasHighlights && (
        <Grid item my={1}>
          {highlights}
        </Grid>
      )}
    </Grid>
  );

  return (
    <ThemeProvider theme={courseRequisiteTheme}>
      <Grid container direction="row" wrap="nowrap">
        <Grid item sx={{ width: "100%" }}>
          {content}
        </Grid>
        <Grid item width={0} />
      </Grid>
    </ThemeProvider>
  );
};
