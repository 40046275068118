import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { cargo } from "@api";
import { QuadContext } from "@context";
import {
  Clickable,
  EmptyLabel,
  Expandable,
  Loadman,
  Badge,
} from "@common/comps";
import { Grid, Box, Typography, ThemeProvider } from "@mui/material";

import { requirementAreaTheme } from "../themes";

const AreaChild = ({ a }) => {
  const navigate = useNavigate();
  const { electives } = a || {};
  const { courses } = electives || {};

  const isEmpty = courses.length === 0;

  if (isEmpty) {
    return (
      <Box borderTop="1px solid var(--border-gray)">
        <EmptyLabel
          label="No courses found."
          labelSx={{ fontSize: "14px" }}
          p={1}
        />
      </Box>
    );
  } else {
    return (
      <Grid
        container
        direction="column"
        wrap="nowrap"
        borderTop="1px solid var(--border-gray)"
      >
        {courses.map((c, i) => (
          <Grid
            key={c.courseId}
            borderBottom={
              i + 1 !== courses.length && "1px solid var(--border-gray)"
            }
          >
            <Clickable
              variant="line"
              label={`${c.subjectCode} ${c.courseCode}`}
              subLabel={c.courseTitle}
              endComponent={
                <Badge
                  variant="outlined"
                  label={`${Number(c.courseUnits)} Unit${
                    c.courseUnits > 1 ? "s" : ""
                  }`}
                />
              }
              handleClick={() => navigate(`/q/${c.quad}/c/${c.courseId}`)}
              containerProps={{
                p: 1,
                alignItems: "center",
              }}
            />
          </Grid>
        ))}
      </Grid>
    );
  }
};

const Area = ({ a, isExpanded }) => {
  const { areaName } = a || {};

  return (
    <ThemeProvider theme={requirementAreaTheme}>
      <Grid container direction="column" wrap="nowrap" p={1}>
        <Grid item>
          <Typography variant="area__label">{areaName}</Typography>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export const ElectiveGroups = ({ g }) => {
  const { quadId } = useContext(QuadContext);
  const { preqGroupId } = g || {};

  /* Feed */
  const [isLoading, setIsLoading] = useState(true);
  const [feed, setFeed] = useState([]);

  const isEmpty = feed.length === 0;
  const noFeed = isLoading || isEmpty;

  const fetchFeed = () => {
    setIsLoading(true);
    setFeed([]);
    cargo
      .get(`/v2/q/${quadId}/program/requirement/group/${preqGroupId}/areas`)
      .then((res) => {
        const { ok, payload } = res || {};

        if (ok) {
          setIsLoading(false);
          setFeed(payload.areas);
        }
      });
  };

  useEffect(() => {
    fetchFeed();
  }, []);

  if (noFeed) {
    return (
      <Loadman isLoading={isLoading} isEmpty={isEmpty} pt={2} mb={1} px={0.5} />
    );
  } else {
    return (
      <Grid container direction="column" wrap="nowrap" gap={1}>
        {feed.map((a) => (
          <Grid
            item
            key={a.preqAreaId}
            border="1px solid var(--attachment-border-gray)"
            borderRadius={2}
            px={1}
            py={0.25}
          >
            <Expandable
              variant="vanilla"
              disclosureSize={16}
              heroComponent={<Area a={a} />}
              childComponent={<AreaChild a={a} />}
            />
          </Grid>
        ))}
      </Grid>
    );
  }
};
