import { createContext, useState, useEffect, useContext } from "react";
import { QuadContext } from "@context";
import { cargo } from "@api";
import { unique } from "radash";

export const PreqContext = createContext();

export const PreqProvider = ({ children, parent }) => {
  const { quadId } = useContext(QuadContext);

  /* Parent */
  const [program, setProgram] = useState(parent);
  const { programId } = program || {};

  /* Init */
  const [isInitializing, setIsInitializing] = useState(true);
  const [groups, setGroups] = useState([]);

  /* Active */
  const [groupType, setGroupType] = useState("core");
  const [group, setGroup] = useState("");

  const initialize = () => {
    setIsInitializing(true);
    setGroups([]);
    cargo
      .get(`/v2/q/${quadId}/program/${programId}/requirement/group/all`)
      .then((res) => {
        const { ok, payload } = res || {};
        if (ok) {
          setIsInitializing(false);
          setGroups(unique(payload.groups, (g) => g.preqGroupId));
        }
      });
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <PreqContext.Provider
      value={{
        initialize,
        isInitializing,
        groups,
        setGroups,
        program,
        group,
        setGroup,
        groupType,
        setGroupType,
      }}
    >
      {children}
    </PreqContext.Provider>
  );
};
