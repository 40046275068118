import { useState, useEffect } from "react";
import { TextField, Autocomplete, ThemeProvider } from "@mui/material";
import { searchTheme } from "@material/themes";

const Search = ({
  elementId,
  label,
  search,
  setSearch,
  options,
  getOptionLabel,
  renderOption,
  handleChange,
  handleInputChange,
  error,
  disabled,
  InputProps,
  onBlur,
}) => {
  const { isError, errorText } = error || {};

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    setIsInitialized(true);
  }, []);

  const renderInput = (params) => {
    return (
      <TextField
        {...params}
        label={label || "Search"}
        variant="filled"
        error={isError}
        helperText={errorText}
        onBlur={onBlur}
        InputProps={Object.assign(params.InputProps, InputProps)}
        InputLabelProps={{
          disableAnimation: !isInitialized,
        }}
      />
    );
  };

  const onInputChange = (...args) => {
    const [event] = args;
    setSearch(event.target.value);

    handleInputChange && handleInputChange(...args);
  };

  return (
    <ThemeProvider theme={searchTheme}>
      <Autocomplete
        id={elementId}
        freeSolo
        disableClearable
        options={options}
        getOptionLabel={getOptionLabel}
        renderInput={renderInput}
        renderOption={renderOption}
        inputValue={search}
        onInputChange={onInputChange} // On text input change
        onChange={handleChange} // On option click
        disabled={disabled}
      />
    </ThemeProvider>
  );
};

export default Search;
