import { useParams, useLocation, useNavigate } from "react-router-dom";
import { BasicIconButton } from "@common/comps";
import {
  QuadIcon,
  QuadSelectedIcon,
  BellIcon,
  BellSelectedIcon,
  ProfileIcon,
  ProfileSelectedIcon,
  SettingsIcon,
  SettingsSelectedIcon,
  NavRootIcon,
  NavRootSelIcon,
} from "@material/icons";
import { Grid } from "@mui/material";

const Nav = ({ isActive, icon, selectedIcon, handleClick }) => {
  return (
    <BasicIconButton
      icon={isActive ? selectedIcon : icon}
      iconSize="30px"
      handleClick={handleClick}
    />
  );
};

const BottomBar = ({ loggedUser, defaultQuad, haveRootAccess }) => {
  const { quad } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const pathParts = pathname.split("/");

  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        position: "sticky",
        bottom: 0,
        borderTop: "1px solid var(--border-gray)",
        backgroundColor: "white",
        padding: "10px 20px",
      }}
    >
      <Grid item>
        <Nav
          isActive={pathParts[1] === "q"}
          icon={<QuadIcon />}
          selectedIcon={<QuadSelectedIcon />}
          handleClick={(event) => {
            event.stopPropagation();
            defaultQuad && navigate(`/q/${defaultQuad}`);
          }}
        />
      </Grid>
      <Grid item>
        <Nav
          isActive={pathParts[1] === "notifs"}
          icon={<BellIcon />}
          selectedIcon={<BellSelectedIcon />}
          handleClick={(event) => {
            event.stopPropagation();
            navigate(`/notifs`);
          }}
        />
      </Grid>
      <Grid item>
        <Nav
          isActive={pathParts[1] === "u" && pathParts[2] === loggedUser}
          icon={<ProfileIcon />}
          selectedIcon={<ProfileSelectedIcon />}
          handleClick={(event) => {
            event.stopPropagation();
            loggedUser && navigate(`/u/${loggedUser}`);
          }}
        />
      </Grid>
      <Grid item>
        <Nav
          isActive={pathParts[1] === "settings"}
          icon={<SettingsIcon />}
          selectedIcon={<SettingsSelectedIcon />}
          handleClick={(event) => {
            event.stopPropagation();
            navigate(`/settings`);
          }}
        />
      </Grid>
      {haveRootAccess && (
        <Grid item>
          <Nav
            isActive={pathParts[1] === "root"}
            icon={<NavRootIcon />}
            selectedIcon={<NavRootSelIcon />}
            handleClick={(event) => {
              event.stopPropagation();
              navigate(`/root`);
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default BottomBar;
