import { useContext, useEffect } from "react";
import { Outlet, useLocation, useParams, useNavigate } from "react-router-dom";
import { ProfileProvider, ProfileContext } from "@context";
import { BasicHeader, Loading, Tab } from "@common/comps";
import { Grid, ThemeProvider } from "@mui/material";
import { isMobile } from "react-device-detect";
import { isObject } from "radash";
import { profileTheme } from "./theme";

import { ProfileHero } from "./comps/hero/ProfileHero";
import { ProfileErrorNotice } from "./comps/error/ProfileErrorNotice";

import "./styles.css";

const ProfileNav = () => {
  const { username } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const tabs = [
    {
      label: "Timeline",
      isActive: pathname === `/u/${username}`,
      handleClick: () => {
        if (pathname === `/u/${username}`) {
          return;
        } else {
          navigate(`/u/${username}`);
        }
      },
    },
    {
      label: "Attributes",
      isActive: pathname === `/u/${username}/attributes`,
      handleClick: () => navigate(`/u/${username}/attributes`),
    },
    {
      label: "Education",
      isActive: pathname === `/u/${username}/education`,
      handleClick: () => navigate(`/u/${username}/education`),
    },
    {
      label: "Jobs",
      isActive: pathname === `/u/${username}/jobs`,
      handleClick: () => navigate(`/u/${username}/jobs`),
    },
  ];

  return isMobile ? (
    <Grid container direction="row" wrap="nowrap">
      {tabs.map((item, i) => (
        <Grid item key={i}>
          <Tab variant="mobile" {...item} />
        </Grid>
      ))}
    </Grid>
  ) : (
    <Grid container direction="row" gap={0.5} p={2}>
      {tabs.map((item, i) => (
        <Grid item key={i}>
          <Tab {...item} />
        </Grid>
      ))}
    </Grid>
  );
};

export const ProfileOutlet = () => {
  const { isInitializing, initError, profile } = useContext(ProfileContext);
  const { isError } = initError || {};

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      sx={{ minHeight: "100vh" }}
      id="profile"
    >
      <Grid item sx={{ position: "sticky", top: 0, zIndex: 100 }}>
        <BasicHeader title="Profile" />
      </Grid>
      {isInitializing ? (
        <Loading p={2} />
      ) : (
        <Grid item>{!isError ? <ProfileHero /> : <ProfileErrorNotice />}</Grid>
      )}
      {!isInitializing && isObject(profile) && (
        <Grid item borderBottom="1px solid var(--border-gray)">
          <ProfileNav />
        </Grid>
      )}
      {!isInitializing && isObject(profile) && (
        <Grid item>
          <Outlet />
        </Grid>
      )}
    </Grid>
  );
};

export const Profile = () => {
  const { state: locationState } = useLocation();
  const { origin } = locationState || {};

  useEffect(() => {
    const selectedOrigins = ["author"];
    if (selectedOrigins.includes(origin)) window.scrollTo({ top: 0 });
  }, []);

  return (
    <ThemeProvider theme={profileTheme}>
      <ProfileProvider>
        <ProfileOutlet />
      </ProfileProvider>
    </ThemeProvider>
  );
};
