import { createTheme } from "@mui/material";

const counterLabelTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        singleline: {
          display: "flex",
          justifyContent: "flex-end",
          position: "relative",
          top: 8,
          right: 12,
          fontSize: "12px",
          fontWeight: "400",
          color: "rgb(83, 99, 99)",
        },
        multiline: {
          display: "flex",
          justifyContent: "flex-end",
          position: "relative",
          top: 8,
          right: 12,
          fontSize: "12px",
          fontWeight: "400",
          color: "rgb(83, 99, 99)",
        },
      },
    },
  },
});

export default counterLabelTheme;
