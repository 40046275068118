import { useContext, useState, useEffect, useReducer } from "react";
import { GlobalContext, QuadContext } from "@context";
import { cargo } from "@api";
import { BasicForm, Dropdown, Loading } from "@common/comps";
import { isObject } from "radash";
import { Grid } from "@mui/material";

import { Template, Submitter } from "@tools";

export const DepartmentBuilder = ({
  unedited,
  isOpen,
  setIsOpen,
  onCreate = () => {},
  onEdit = () => {},
}) => {
  const { defaultError } = useContext(GlobalContext);
  const { quadId } = useContext(QuadContext);
  const isEdit = Boolean(unedited);

  const [isInitializing, setIsInitializing] = useState(true);

  /* Templates */
  const [baseline, setBaseline] = useState(null);
  const [department, setDepartment] = useReducer(
    Template.createReducer(),
    null
  );

  const template = new Template(baseline, department, setDepartment);

  /* Resources */
  const [options, setOptions] = useState({});
  const { typeOptions } = options || {};

  useEffect(() => {
    setIsInitializing(true);
    cargo.get(`/v2/q/${quadId}/r/department/form`).then((res) => {
      const { ok, payload } = res || {};
      if (ok) {
        setIsInitializing(false);
        setOptions(payload.options);

        const initial = isEdit
          ? Template.superimpose(payload.template, unedited)
          : Template.createEmpty(payload.template);

        if (isEdit) setBaseline(initial);
        setDepartment({
          type: "init",
          value: initial,
        });
      }
    });
  }, []);

  /* Form section */
  const { deptId } = unedited || {};
  const [hot, setHot] = useState(false);

  /* Submission */
  const [submitState, setSubmitState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState()
  );

  const submitter = new Submitter(submitState, setSubmitState, defaultError);

  useEffect(() => {
    if (hot) setHot(false);

    if (!hot && isObject(department)) {
      const preflight = template.preflight();
      const isChanged = template.isChanged();

      submitter.stock(template.crush());
      submitter.green(isEdit ? preflight && isChanged : preflight);
      submitter.resetError();
    }
  }, [department]);

  const handleCreate = () => {
    template.resetErrors(defaultError);
    submitter.startLoading();
    cargo
      .post(`/v2/q/${quadId}/r/department`, {
        department: template.crush(),
      })
      .then((res) => {
        const { ok, isClientError, errors, payload } = res || {};
        if (ok) {
          onCreate(payload);
        } else {
          setHot(true);
          submitter.takeResponse(res);
          if (isClientError) template.setErrors(errors);
        }
      });
  };

  const handleEdit = () => {
    template.resetErrors(defaultError);
    submitter.startLoading();
    cargo
      .put(`/v2/q/${quadId}/r/department/${deptId}`, {
        department: template.crush(),
      })
      .then((res) => {
        const { ok, isClientError, errors, payload } = res || {};
        if (ok) {
          onEdit(payload);
        } else {
          setHot(true);
          submitter.takeResponse(res);
          if (isClientError) template.setErrors(errors);
        }
      });
  };

  const content = isInitializing ? (
    <Loading p={2} />
  ) : (
    isObject(department) && (
      <Grid container direction="column" wrap="nowrap" gap={1}>
        <Grid item>
          {template.prefab().input({ label: "Department name", field: "name" })}
        </Grid>
        <Grid item>
          <Dropdown
            label="Type"
            variant="object"
            labelKey="typeName"
            valueKey="typeValue"
            state={department.type.value}
            handleChange={(event) =>
              setDepartment({
                field: "type",
                value: event.target.value,
              })
            }
            options={typeOptions}
            error={department.type.error}
            disableObjectSelect
          />
        </Grid>
        <Grid item>
          {template.prefab().input({
            multiline: true,
            rows: 10,
            label: "Description",
            field: "description",
          })}
        </Grid>
      </Grid>
    )
  );

  return (
    isOpen && (
      <>
        {isEdit ? (
          <BasicForm
            title="Edit department"
            isFormOpen={isOpen}
            setIsFormOpen={setIsOpen}
            content={content}
            handleSubmit={handleEdit}
            submitState={{
              isSubmitLoading: submitState.isLoading,
              isSubmitDisabled: submitState.isDisabled,
              submitError: submitState.error,
            }}
          />
        ) : (
          <BasicForm
            title="Add department"
            isFormOpen={isOpen}
            setIsFormOpen={setIsOpen}
            content={content}
            handleSubmit={handleCreate}
            submitState={{
              isSubmitLoading: submitState.isLoading,
              isSubmitDisabled: submitState.isDisabled,
              submitError: submitState.error,
            }}
          />
        )}
      </>
    )
  );
};
