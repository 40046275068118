import { useContext, useState, useEffect, useReducer } from "react";
import { cargo } from "@api";
import { GlobalContext, QuadContext } from "@context";
import { BasicForm, Dropdown, Loading } from "@common/comps";
import { Grid } from "@mui/material";
import { isObject } from "radash";

import { Template, Submitter } from "@tools";

export const CourseRecordBuilder = ({
  courseId,
  isOpen,
  setIsOpen,
  onCreate = () => {},
}) => {
  const { defaultError } = useContext(GlobalContext);
  const { quadId } = useContext(QuadContext);

  const [isInitializing, setIsInitializing] = useState(true);
  const [options, setOptions] = useState({});
  const { yearOptions } = options || {};
  const [record, setRecord] = useReducer(Template.createReducer(), null);

  const template = new Template(null, record, setRecord);

  const initialize = () => {
    cargo.get(`/v2/q/${quadId}/course/${courseId}/record/form`).then((res) => {
      const { ok, payload } = res || {};
      if (ok) {
        setIsInitializing(false);
        setRecord({
          type: "init",
          value: Template.createEmpty(payload.template),
        });
        setOptions(payload.options);
      }
    });
  };

  useEffect(() => {
    setIsInitializing(true);
    initialize();
  }, []);

  /* Form section */
  const [hot, setHot] = useState(false);

  /* Submission */
  const [submitState, setSubmitState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState()
  );

  const submitter = new Submitter(submitState, setSubmitState, defaultError);

  useEffect(() => {
    if (hot) setHot(false);

    if (!hot && isObject(record)) {
      const preflight = template.preflight();

      submitter.stock(template.crush());
      submitter.green(preflight);
      submitter.resetError();
    }
  }, [record]);

  const content = isInitializing ? (
    <Loading p={2} />
  ) : (
    isObject(record) && (
      <Grid container direction="column" wrap="nowrap" gap={1}>
        <Grid item>
          {template
            .prefab()
            .numberInput({ label: "Hourload", field: "hourload", type: "int" })}
        </Grid>
        <Grid item>
          <Dropdown
            label="Semester"
            options={record.semester.oneOf}
            state={record.semester.value}
            handleChange={(event) => {
              setRecord({
                field: "semester",
                value: event.target.value,
              });
            }}
            error={record.semester.error}
          />
        </Grid>
        <Grid item>
          <Dropdown
            label="Year"
            options={yearOptions}
            state={record.year.value}
            handleChange={(event) => {
              setRecord({ field: "year", value: event.target.value });
            }}
            error={record.year.error}
          />
        </Grid>
      </Grid>
    )
  );

  const handleSubmit = () => {
    template.resetErrors(defaultError);
    submitter.startLoading();
    cargo
      .post(`/v2/q/${quadId}/course/${courseId}/record`, {
        record: template.crush(),
      })
      .then((res) => {
        const { ok, isClientError, errors } = res || {};
        if (ok) {
          onCreate();
        } else {
          setHot(true);
          submitter.takeResponse(res);
          if (isClientError) {
            template.setErrors(errors);
          }
        }
      });
  };

  return (
    isOpen && (
      <BasicForm
        title="Collect course"
        isFormOpen={isOpen}
        setIsFormOpen={setIsOpen}
        content={content}
        handleSubmit={handleSubmit}
        submitState={{
          isSubmitLoading: submitState.isLoading,
          isSubmitDisabled: submitState.isDisabled,
          submitError: submitState.error,
        }}
      />
    )
  );
};
