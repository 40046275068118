import {
  CourseGradIcon,
  CourseUndergradIcon,
  CourseUggIcon,
} from "@material/icons";

const getCourseLevelIcon = (level) => {
  switch (level) {
    case 1:
      return <CourseUndergradIcon />;
    case 2:
      return <CourseUggIcon />;
    case 3:
      return <CourseGradIcon />;
    default:
      return <CourseUndergradIcon />;
  }
};

export default getCourseLevelIcon;
