import { createContext, useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { CatalogContext, QuadContext } from "@context";
import { cargo } from "@api";
import { replace } from "radash";

export const CourseContext = createContext();

export const CourseProvider = ({ children }) => {
  const { quadId } = useContext(QuadContext);
  const { courseId: searchId } = useParams();

  // Init
  const [isInitializing, setIsInitializing] = useState(true);

  // Course
  const [course, setCourse] = useState(null);

  // Resources
  const [creqKeys, setCreqKeys] = useState({});

  // Feeds
  const { setFeed: setCatalogFeed } = useContext(CatalogContext);

  const resetCourse = () => {
    setCourse(null);
  };

  useEffect(() => {
    resetCourse();
    setIsInitializing(true);

    cargo.get(`/v2/q/${quadId}/course/${searchId}`).then((res) => {
      setIsInitializing(false);
      const { ok, payload } = res || {};
      if (ok) {
        setCourse(payload.course);
        setCreqKeys(payload.creqKeys);

        setCatalogFeed &&
          setCatalogFeed((prev) =>
            replace(prev, payload.course, (c) => c.courseId === searchId)
          );
      }
    });
  }, [searchId]);

  return (
    <CourseContext.Provider
      value={{
        isInitializing,
        course,
        setCourse,
        creqKeys,
      }}
    >
      {children}
    </CourseContext.Provider>
  );
};

export const useCourse = () => {
  const context = useContext(CourseContext);
  if (!context) {
    return null;
  } else {
    return context;
  }
};
