import { Button, ThemeProvider } from "@mui/material";
import { buttonTheme } from "@material/themes";

import { getFullName } from "./utility";

export const InstructorButton = ({ candidate, instructor, setInstructor }) => {
  const { instrId: currentId } = instructor || {};
  const { instrId } = candidate || {};

  const isOn = instrId === currentId;
  const variant = isOn ? "switch--on" : "switch--off";

  return (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant={variant}
        onClick={(event) => {
          if (!isOn) {
            event.stopPropagation();
            setInstructor(candidate);
          }
        }}
      >
        {getFullName(candidate)}
      </Button>
    </ThemeProvider>
  );
};
