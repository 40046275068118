import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BasicHeader } from "@common/comps";
import {
  Grid,
  Typography,
  Button,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { Large404Icon, Large403Icon } from "@material/icons";
import { buttonTheme } from "@material/themes";

const pageErrorTheme = createTheme({
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: "header" },
          style: {
            fontSize: "25px",
            fontWeight: "700",
            color: "var(--quad-black)",
          },
        },
        {
          props: { variant: "subheader" },
          style: {
            fontSize: "16px",
            fontWeight: "400",
            color: "var(--quad-gray)",
          },
        },
      ],
    },
  },
});

const PageErrorNotice = ({
  type,
  icon,
  title,
  subtitle,
  headerTitle,
  disableHeader,
  buttonLabel,
  handleSubmit,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const back = (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant="filled"
        color="black"
        onClick={handleSubmit ? handleSubmit : () => navigate(-1)}
      >
        {buttonLabel ? buttonLabel : "Go back"}
      </Button>
    </ThemeProvider>
  );

  const getIcon = () => {
    switch (type) {
      case 404:
        return <Large404Icon width={64} height={64} fill="var(--quad-black)" />;
      case 403:
        return <Large403Icon width={64} height={64} fill="var(--quad-black)" />;
      default:
        return;
    }
  };

  const notice = (
    <Grid container direction="column" wrap="nowrap" alignItems="center">
      <Grid item mb={3}>
        {icon ? icon : getIcon()}
      </Grid>
      <Grid item>
        <Typography variant="header">{title}</Typography>
      </Grid>
      <Grid item mb={4}>
        <Typography variant="subheader">{subtitle}</Typography>
      </Grid>
      <Grid item>{back}</Grid>
    </Grid>
  );

  return (
    <ThemeProvider theme={pageErrorTheme}>
      <Grid container direction="column" wrap="nowrap">
        {!disableHeader && (
          <Grid item sx={{ position: "sticky", top: 0, zIndex: 100 }}>
            <BasicHeader title={headerTitle ? headerTitle : "Error"} noBack />
          </Grid>
        )}
        <Grid
          item
          sx={{
            width: "100%",
            position: "relative",
            top: 200,
          }}
        >
          {notice}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default PageErrorNotice;
