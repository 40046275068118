import { useContext, useState } from "react";
import { masterAPI } from "@api";
import { GlobalContext } from "@context";
import { PopperBinIcon } from "@material/icons";
import { ConfirmForm, PopperOptions } from "@common/comps";
import { Box } from "@mui/material";

export const EditorOptions = ({ editor, onDelete }) => {
  const { defaultError } = useContext(GlobalContext);
  const { username, quad } = editor || {};

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  // Submission
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(false);
  const [deleteError, setDeleteError] = useState(defaultError);

  const handleDelete = (event) => {
    event.stopPropagation();
    if (username && quad) {
      setIsDeleteLoading(true);
      masterAPI
        .post("/quad/admin/dismiss-editor", { username, quad })
        .then((res) => {
          const { ok, message } = res || {};
          if (ok) {
            onDelete();
          } else {
            setIsDeleteLoading(false);
            setIsDeleteDisabled(true);
            setDeleteError({
              isError: true,
              errorText: message,
            });
          }
        });
    }
  };

  const menuItemsList = [
    {
      key: "admin-editors__editors-options__delete",
      label: "Remove editor",
      icon: <PopperBinIcon />,
      isRed: true,
      handleClick: (event) => {
        setIsDeleteOpen(true);
        event.stopPropagation();
      },
    },
  ];

  return (
    <>
      <PopperOptions menuItemsList={menuItemsList} />
      {isDeleteOpen && (
        <Box onClick={(event) => event.stopPropagation()}>
          <ConfirmForm
            title="Remove editor?"
            subtitle="User will no longer be allowed to moderate posts."
            color="red"
            isFormOpen={isDeleteOpen}
            setIsFormOpen={setIsDeleteOpen}
            submitLabel="Remove"
            handleSubmit={handleDelete}
            submitState={{
              isSubmitDisabled: isDeleteDisabled,
              isSubmitLoading: isDeleteLoading,
              submitError: deleteError,
            }}
          />
        </Box>
      )}
    </>
  );
};
