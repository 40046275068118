import { useContext, useState, useEffect, useReducer } from "react";
import { cargo } from "@api";
import { GlobalContext, QuadContext } from "@context";
import { Submitter } from "@tools";
import {
  BasicAvatar,
  BasicForm,
  Submit,
  ResizingTextField,
  CircularTextCounter,
} from "@common/comps";
import { Grid } from "@mui/material";

export const ConfessionPublisher = ({
  isOpen,
  setIsOpen,
  onPublish = () => {},
}) => {
  const { defaultError, session } = useContext(GlobalContext);
  const { quadId } = useContext(QuadContext);
  const { postMax } = session || {};

  const [text, setText] = useState("");

  /* Submission */
  const [submitState, setSubmitState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState()
  );

  const submitter = new Submitter(submitState, setSubmitState, defaultError);

  useEffect(() => {
    submitter.resetError();
    submitter.green(text.length > 0);
  }, [text]);

  const handleSubmit = () => {
    submitter.startLoading();
    cargo.post(`/v2/q/${quadId}/p/confession/staged`, { text }).then((res) => {
      const { ok } = res || {};
      if (ok) {
        onPublish();
      } else {
        submitter.stopLoading();
        submitter.takeResponse(res);
      }
    });
  };

  const content = (
    <Grid container direction="row" wrap="nowrap" gap={2}>
      <Grid item>
        <BasicAvatar isAnon disableClick />
      </Grid>
      <Grid item sx={{ width: "100%", position: "relative", top: 12 }}>
        <ResizingTextField
          wrapperId="resizing-textfield__create-confession__wrapper"
          textareaId="resizing-textfield__create-confession__textarea"
          state={text}
          setState={setText}
          placeholder="Confess something"
          minRows={5}
        />
      </Grid>
    </Grid>
  );

  const footer = (
    <Grid container direction="row" wrap="nowrap" justifyContent="flex-end">
      <Grid item>
        <Grid
          container
          direction="row"
          wrap="nowrap"
          justifyContent="flex-end"
          alignItems="center"
          gap={2}
        >
          {text && (
            <Grid item>
              <CircularTextCounter text={text} limit={postMax} />
            </Grid>
          )}
          <Grid item>
            <Submit
              variant="publish"
              submitState={submitState}
              handleSubmit={handleSubmit}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  if (isOpen) {
    return (
      <BasicForm
        title="Write confession"
        isFormOpen={isOpen}
        setIsFormOpen={setIsOpen}
        content={content}
        footer={footer}
        confirmClose
      />
    );
  } else {
    return <></>;
  }
};
