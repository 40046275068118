import { useEffect, useContext } from "react";
import { Loading, Tab, EmptyLabel, Expandable } from "@common/comps";
import { Grid } from "@mui/material";

import {
  CourseRequisiteCard,
  CourseRequisiteAlternatives,
} from "features/courseRequisite";

import { CourseRequisitesContext, CourseRequisitesProvider } from "./context";

const RequisiteFeed = () => {
  const { type, requisites } = useContext(CourseRequisitesContext);

  const feed = requisites.filter((r) => r.type === type);
  const isEmpty = feed.length === 0;

  if (isEmpty) {
    return <EmptyLabel label={`No ${type}s found.`} p={2} />;
  } else {
    return (
      <Grid container direction="column" wrap="nowrap">
        {feed.map((req) => (
          <Grid item key={req.creqId}>
            <Expandable
              heroComponent={<CourseRequisiteCard req={req} />}
              child={<CourseRequisiteAlternatives req={req} />}
              containerProps={{
                px: 2,
                py: 1,
              }}
            />
          </Grid>
        ))}
      </Grid>
    );
  }
};

const Landing = () => {
  const { type, setType, isInitializing } = useContext(CourseRequisitesContext);

  const navBar = (
    <Grid container direction="row" wrap="nowrap" gap={1}>
      <Grid item>
        <Tab
          variant="bubble"
          label="Prereq"
          isActive={type === "prereq"}
          handleClick={() => type !== "prereq" && setType("prereq")}
        />
      </Grid>
      <Grid item>
        <Tab
          variant="bubble"
          label="Coreq"
          isActive={type === "coreq"}
          handleClick={() => type !== "coreq" && setType("coreq")}
        />
      </Grid>
    </Grid>
  );

  if (isInitializing) {
    return <Loading p={2} />;
  } else {
    return (
      <Grid container direction="column" wrap="nowrap">
        <Grid item p={2} borderBottom="1px solid var(--border-gray)">
          {navBar}
        </Grid>
        <Grid item>
          <RequisiteFeed />
        </Grid>
      </Grid>
    );
  }
};

export const CourseRequisites = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <CourseRequisitesProvider>
      <Landing />
    </CourseRequisitesProvider>
  );
};
