import { createTheme } from "@mui/material";

const cardTheme = createTheme({
  components: {
    MuiTypography: {
      defaultProps: {},
      styleOverrides: {},
      variants: [
        {
          props: { variant: "date" },
          style: {
            fontSize: "15px",
            fontWeight: "400",
            color: "var(--quad-gray)",
          },
        },
        {
          props: { variant: "body" },
          style: {
            fontSize: "15px",
            fontWeight: "400",
            color: "var(--quad-black)",
            whiteSpace: "pre-line",
            overflowWrap: "anywhere",
          },
        },
        {
          props: { variant: "link" },
          style: {
            fontSize: "14px",
            fontWeight: "500",
            color: "var(--quad-gray)",
          },
        },
        {
          props: { variant: "actionStat" },
          style: {
            fontSize: "14px",
            fontWeight: "400",
            color: "var(--quad-gray-alt)",
          },
        },

        {
          props: { variant: "courseTitle" },
          style: {
            fontFamily: "Inter",
            fontSize: "18px",
            fontWeight: "600",
            color: "var(--quad-black)",
            overflowWrap: "anywhere",
          },
        },
        {
          props: { variant: "courseDescription" },
          style: {
            fontSize: "15px",
            fontWeight: "400",
            color: "var(--quad-black)",
            whiteSpace: "pre-line",
            overflowWrap: "anywhere",
          },
        },
        {
          props: { variant: "courseDetails" },
          style: {
            fontSize: "14px",
            fontWeight: "400",
            color: "var(--quad-gray)",
            overflowWrap: "anywhere",
            letterSpacing: "0.00938em",
          },
        },
        {
          props: { variant: "readMore" },
          style: {
            fontSize: "14px",
            fontWeight: "500",
            color: "var(--quad-gray)",
            letterSpacing: "0.00938em",
          },
        },
        {
          props: { variant: "event__label" },
          style: {
            fontSize: "13px",
            fontWeight: "500",
            color: "var(--quad-gray-alt)",
            letterSpacing: "0.0095em",
          },
        },
        {
          props: { variant: "event__schedule" },
          style: {
            fontSize: "14px",
            fontWeight: "400",
            color: "var(--quad-gray)",
            letterSpacing: "0.0095em",
            overflowWrap: "anywhere",
          },
        },
        {
          props: { variant: "event__location" },
          style: {
            fontSize: "14px",
            fontWeight: "400",
            color: "var(--quad-gray)",
            letterSpacing: "0.0095em",
            overflowWrap: "anywhere",
          },
        },
        {
          props: { variant: "event__name" },
          style: {
            fontSize: "18px",
            fontWeight: "500",
            color: "var(--quad-black)",
            overflowWrap: "anywhere",
          },
        },
        {
          props: { variant: "event__description" },
          style: {
            fontSize: "14px",
            fontWeight: "400",
            color: "rgb(83, 99, 99)",
            whiteSpace: "pre-line",
            letterSpacing: "0.015em",
            overflowWrap: "anywhere",
          },
        },
      ],
    },
  },
});

export default cardTheme;
