import { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { QuadContext } from "@context";
import { Tab } from "@common/comps";
import { isMobile } from "react-device-detect";
import { Grid } from "@mui/material";
import { omit } from "radash";

export const QuadNav = () => {
  const { quadId } = useContext(QuadContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const page = pathname.split("/")[3];

  const morePages = ["more", "admin", "editor", "directory", "cataloger"];

  const tabs = [
    {
      label: "Quad",
      isActive: pathname === `/q/${quadId}`,
      handleClick: () => {
        if (pathname !== `/q/${quadId}`) {
          navigate(`/q/${quadId}`);
        }
      },
      canRender: true,
    },
    {
      label: "Projects",
      isActive: pathname.startsWith(`/q/${quadId}/projects`),
      handleClick: () => navigate(`/q/${quadId}/projects`),
      canRender: true,
    },
    {
      label: "Programs",
      isActive: pathname === `/q/${quadId}/p`,
      handleClick: () => navigate(`/q/${quadId}/p`),
      canRender: !isMobile,
    },
    {
      label: "Catalog",
      isActive: pathname === `/q/${quadId}/c`,
      handleClick: () => navigate(`/q/${quadId}/c`),
      canRender: !isMobile,
    },
    {
      label: "More",
      isActive: morePages.includes(page),
      handleClick: () => navigate(`/q/${quadId}/more`),
      canRender: true,
    },
  ];

  if (isMobile) {
    return (
      <Grid container direction="row">
        {tabs.map(
          (item, i) =>
            item.canRender && (
              <Grid item key={i}>
                <Tab variant="mobile" {...omit(item, ["canRender"])} />
              </Grid>
            )
        )}
      </Grid>
    );
  } else {
    return (
      <Grid container direction="row" gap={0.1} p={2}>
        {tabs.map(
          (item, i) =>
            item.canRender && (
              <Grid item key={i}>
                <Tab {...omit(item, ["canRender"])} />
              </Grid>
            )
        )}
      </Grid>
    );
  }
};
