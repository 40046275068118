import { Button, Box, ThemeProvider } from "@mui/material";
import { buttonTheme } from "@material/themes";

const BasicIconButton = ({
  icon = <></>,
  iconSize,
  handleClick,
  padding,
  id,
  className,
}) => {
  return (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant="icon"
        onClick={handleClick}
        sx={{ ...(padding && { padding }) }}
        className={className}
        id={id ? id : "icon-button"}
      >
        <Box
          sx={{
            display: "flex",
            width: iconSize ? iconSize : "18px",
            height: iconSize ? iconSize : "18px",
          }}
        >
          {icon}
        </Box>
      </Button>
    </ThemeProvider>
  );
};

export default BasicIconButton;
