import { useContext } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { GlobalContext } from "@context";
import { Loading } from "@common/comps";
import { isMobile } from "react-device-detect";
import { Grid } from "@mui/material";

import { LeftBar } from "../leftBar";
import BottomBar from "../bottomBar/BottomBar";

export const Wrapper = () => {
  const { isInitializing, session } = useContext(GlobalContext);
  const { sessOwner, defaultQuad, rootAccess } = session || {};

  const { pathname } = useLocation();
  const isLoginPage = pathname === "/";

  const browserUI = (
    <Grid container direction="row" wrap="nowrap" justifyContent="center">
      <Grid item>
        <LeftBar />
      </Grid>
      <Grid
        item
        sx={{
          width: "700px",
          minWidth: "500px",
          minHeight: "100vh",
          borderLeft: "1px solid",
          borderRight: "1px solid",
          borderColor: "var(--border-gray)",
        }}
      >
        <Outlet />
      </Grid>
      <Grid
        item
        sx={{
          width: "60px",
          borderRight: "0px solid",
          borderColor: "var(--border-gray)",
        }}
      />
      <Grid item>
        <Grid item sx={{ width: "0px", minHeight: "110vh" }} />
      </Grid>
    </Grid>
  );

  const mobileUI = (
    <>
      <Grid container direction="column" wrap="nowrap" sx={{ zIndex: 9999 }}>
        <Grid item sx={{ minHeight: "100vh" }}>
          <Outlet />
        </Grid>
      </Grid>
      <BottomBar
        loggedUser={sessOwner}
        defaultQuad={defaultQuad}
        haveRootAccess={rootAccess}
      />
    </>
  );

  const wrapped = session && (isMobile ? mobileUI : browserUI);

  if (isInitializing) {
    return <Loading variant="page" />;
  } else {
    return isLoginPage ? <Outlet /> : wrapped;
  }
};
