import { useState } from "react";
import { Publisher } from "@tools";
import { ReplyProvider, useReply } from "@context";
import { Loadman } from "@common/comps";
import { Grid, Button, ThemeProvider } from "@mui/material";
import { buttonTheme } from "@material/themes";

import { ReplyCard } from "../replyCard/ReplyCard";

export const ReplyLanding = ({ onReplyCreate, onReplyDelete }) => {
  const { comment, isLoading, feed, setFeed, isFeedEmpty, setIsFeedEmpty } =
    useReply();

  const noFeed = isLoading || isFeedEmpty;

  const [fresh, setFresh] = useState(0);

  /* Publisher */
  const [isCreateOpen, setIsCreateOpen] = useState(false);

  const createButton = (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant="outlined"
        onClick={() => setIsCreateOpen(true)}
        sx={{ padding: "2px 12px" }}
      >
        Write reply
      </Button>
    </ThemeProvider>
  );

  const Create = () => (
    <>
      {createButton}
      {isCreateOpen &&
        Publisher.reply({
          comment,
          isOpen: isCreateOpen,
          setIsOpen: setIsCreateOpen,
          onPublish: (created) => {
            setIsCreateOpen(false);
            setIsFeedEmpty(false);
            setFresh((prev) => prev + 1);
            setFeed((prev) => [created, ...prev]);

            /* Callback */
            onReplyCreate();
          },
        })}
    </>
  );

  const replyFeed = (
    <Grid container direction="column" wrap="nowrap" gap={1}>
      {feed.map((reply, i) => (
        <Grid
          item
          key={reply.replyId}
          className={fresh > 0 && i === 0 ? "card--new" : ""}
        >
          <ReplyCard
            reply={reply}
            hasLink={feed.length !== i + 1}
            onDelete={() => {
              setFresh(0);
              setFeed((prev) => {
                const newFeed = prev.filter((r) => r.replyId !== reply.replyId);
                setIsFeedEmpty(newFeed.length === 0);
                return newFeed;
              });

              /* Callback */
              onReplyDelete();
            }}
          />
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      gap={2}
      sx={{ marginTop: "10px" }}
    >
      <Grid item>
        <Create />
      </Grid>
      <Grid item>
        {noFeed ? (
          <Loadman
            isLoading={isLoading}
            isEmpty={isFeedEmpty}
            emptyLabel="No replies found."
            pb={1}
          />
        ) : (
          replyFeed
        )}
      </Grid>
    </Grid>
  );
};

export const ReplySection = ({
  comment,
  onReplyCreate = () => {},
  onReplyDelete = () => {},
}) => {
  return (
    <ReplyProvider comment={comment}>
      <ReplyLanding
        onReplyCreate={onReplyCreate}
        onReplyDelete={onReplyDelete}
      />
    </ReplyProvider>
  );
};

export default ReplySection;
