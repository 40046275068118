import { useEffect, useReducer, useState } from "react";
import { useEnroll, useGlobal } from "@context";
import { Submitter } from "@tools";
import { FormPage, Input } from "@common/comps";
import { Box, Grid, Typography } from "@mui/material";
import { cargo } from "@api";

export const VerifyPage = ({ onComplete = () => {} }) => {
  const { defaultError } = useGlobal();
  const {
    enroll: { email },
    FormHeader,
  } = useEnroll();

  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState(defaultError);

  /* Submission */
  const [submitState, setSubmitState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState()
  );

  const submitter = new Submitter(submitState, setSubmitState, defaultError);

  useEffect(() => {
    setCodeError(defaultError);
    submitter.green(Boolean(code));
  }, [code]);

  const handleSubmit = () => {
    if (code) {
      submitter.startLoading();
      cargo.put(`/v2/enroll/verify?email=${email}`, { code }).then((res) => {
        const { ok, isClientError } = res || {};
        if (ok) {
          onComplete();
        } else {
          submitter.takeResponse(res);
          if (isClientError)
            setCodeError({
              isError: true,
              type: 403,
              errorText: "Invalid code.",
            });
        }
      });
    }
  };

  const content = (
    <Grid container direction="column" wrap="nowrap" minHeight={400}>
      <Grid item>
        <FormHeader />
      </Grid>
      <Grid
        item
        pt={5}
        mb={1}
        sx={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        <Typography variant="fh1">Verify email</Typography>
      </Grid>
      <Grid item mb={1} px={2} sx={{ textAlign: "center" }}>
        <Typography variant="fh2">
          {`A verification code has been sent to ${email}.`}
        </Typography>
      </Grid>
      <Grid item p={2}>
        <Input label="Code" state={code} setState={setCode} error={codeError} />
      </Grid>
    </Grid>
  );

  const footer = (
    <Box p={2} sx={{ width: "100%" }}>
      {Submitter.button({
        variant: "fullWidth",
        buttonSx: {
          fontSize: "15px",
          py: 1.25,
        },
        submitState,
        handleClick: handleSubmit,
      })}
    </Box>
  );

  return <FormPage content={content} footer={footer} />;
};
