import { createTheme } from "@mui/material";

export const confirmTheme = createTheme({
  components: {
    MuiDialog: {
      defaultProps: {},
      styleOverrides: {
        container: {
          alignItems: "center",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "30px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "0px 0px",
          width: "300px",
          minWidth: "250px",
          borderRadius: "15px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        fh1: {
          fontSize: "20px",
          fontWeight: "600",
          color: "rgb(15, 22, 22)",
        },
        fb1: {
          fontSize: "15px",
          fontWeight: "400",
          color: "rgb(83, 99, 99)",
        },
      },
      variants: [
        {
          props: { variant: "heading" },
          style: {
            fontSize: "20px",
            fontWeight: "600",
            color: "var(--quad-black)",
            overflowWrap: "anywhere",
            letterSpacing: "0.00938em",
          },
        },
        {
          props: { variant: "subheading" },
          style: {
            fontSize: "15px",
            fontWeight: "400",
            color: "var(--quad-gray)",
            overflowWrap: "anywhere",
            letterSpacing: "0.00938em",
          },
        },
      ],
    },
    MuiGrid: {
      styleOverrides: {
        item: {
          width: "inherit",
        },
      },
    },
  },
});

export default confirmTheme;
