import { createTheme } from "@mui/material";

export const publishFormTheme = createTheme({
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          maxWidth: "650px",
          overflow: "hidden",
        },
        container: {
          alignItems: "flex-start",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: "15px",
          display: "flex",
          alignItems: "center",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "15px 15px 15px",
          minHeight: "110px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "0px 0px",
          width: "650px",
          minWidth: "450px",
          borderRadius: "10px",
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: "formTitle" },
          style: {
            fontSize: "20px",
            fontWeight: "500",
            color: "var(--quad-black)",
          },
        },
      ],
    },
  },
});

export default publishFormTheme;
