import { useContext, useState, useReducer, useEffect } from "react";
import { cargo } from "@api";
import { QuadContext } from "@context";
import {
  Loading,
  CourseSearch,
  Editable,
  EmptyLabel,
  Dropdown,
} from "@common/comps";
import { Grid, Box, Button, ThemeProvider } from "@mui/material";
import { buttonTheme } from "@material/themes";
import { ButtonBackwardIcon, CardRemoveIcon } from "@material/icons";

const Search = ({ setPage, setSelection, subjectOptions }) => {
  const [course, setCourse] = useState("");

  const [subject, setSubject] = useState("");

  const backButton = (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant="icon"
        sx={{ padding: 1 }}
        onClick={(event) => {
          event.stopPropagation();
          setPage("main");
        }}
      >
        <ButtonBackwardIcon width={18} height={18} />
      </Button>
    </ThemeProvider>
  );

  const addButton = (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant="filled"
        color="black"
        disabled={!Boolean(course)}
        onClick={() => {
          setPage("main");
          setSelection({ type: "add", course });
        }}
      >
        Add to selection
      </Button>
    </ThemeProvider>
  );

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid
        item
        sx={{
          position: "sticky",
          top: 0,
          backgroundColor: "white",
        }}
      >
        <Grid container direction="column" wrap="nowrap">
          <Grid item pb={2} mb={2} borderBottom="1px solid var(--border-gray)">
            <Grid
              container
              direction="row"
              wrap="nowrap"
              alignItems="center"
              gap={1}
            >
              <Grid item>{backButton}</Grid>
              <Grid item>{addButton}</Grid>
            </Grid>
          </Grid>
          <Grid item mb={2}>
            <Dropdown
              variant="object"
              label="Choose subject"
              options={subjectOptions}
              labelKey="subjectName"
              valueKey="subjectId"
              state={subject}
              setState={setSubject}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <CourseSearch subject={subject} state={course} setState={setCourse} />
      </Grid>
    </Grid>
  );
};

const Main = ({ setPage, selection, setSelection }) => {
  const addButton = (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant="outlined"
        onClick={(event) => {
          event.stopPropagation();
          setPage("search");
        }}
      >
        Add course
      </Button>
    </ThemeProvider>
  );

  return (
    <Grid container direction="column" wrap="nowrap" gap={2}>
      <Grid item>{addButton}</Grid>
      <Grid item>
        {selection.length === 0 ? (
          <EmptyLabel label="No course selected." px={0.5} py={1} />
        ) : (
          selection.map((c, i) => (
            <Grid
              item
              key={c.courseId}
              borderTop={i === 0 && "1px solid var(--border-gray)"}
              borderBottom="1px solid var(--border-gray)"
            >
              <Box py={1.5} px={0.5}>
                <Editable
                  label={`${c.subjectCode} ${c.courseCode}`}
                  subLabel={c.courseTitle}
                  endButton={
                    <ThemeProvider theme={buttonTheme}>
                      <Button
                        variant="icon"
                        onClick={() =>
                          setSelection({ type: "remove", course: c })
                        }
                        sx={{
                          padding: 1,
                          ":hover ": {
                            backgroundColor: "var(--quad-pink-light)",

                            "#card-icon--remove": {
                              fill: "var(--quad-red)",
                              transition: "fill 200ms",
                            },
                          },
                        }}
                      >
                        <CardRemoveIcon width={20} height={20} />
                      </Button>
                    </ThemeProvider>
                  }
                />
              </Box>
            </Grid>
          ))
        )}
      </Grid>
    </Grid>
  );
};

const reducer = (state, action) => {
  switch (action.type) {
    case "remove":
      return state.filter((item) => item.courseId !== action.course.courseId);
    case "add":
      return (() => {
        const match = state.some(
          (item) => item.courseId === action.course.courseId
        );

        if (match) {
          return [...state];
        } else {
          return [...state, action.course];
        }
      })();
    default:
      return state;
  }
};

const MultiCourse = ({ state, setState, setIsFootDisabled }) => {
  const { quadId } = useContext(QuadContext);

  const [isInitializing, setIsInitializing] = useState(true);
  const [subjectOptions, setSubjectOptions] = useState([]);

  useEffect(() => {
    setIsInitializing(true);
    cargo.get(`/v2/q/${quadId}/subject/all`).then((res) => {
      const { ok, payload } = res || {};
      if (ok) {
        setIsInitializing(false);
        setSubjectOptions(payload.subjects);
      }
    });
  }, []);

  const [page, setPage] = useState("main");

  const [selection, setSelection] = useReducer(reducer, state ? state : []);

  useEffect(() => {
    setState(selection);
  }, [selection]);

  useEffect(() => {
    setIsFootDisabled(page === "search");
  }, [page]);

  if (isInitializing) {
    return <Loading />;
  } else {
    switch (page) {
      case "search":
        return (
          <Search
            setPage={setPage}
            setSelection={setSelection}
            subjectOptions={subjectOptions}
          />
        );
      default:
        return (
          <Main
            setPage={setPage}
            selection={selection}
            setSelection={setSelection}
          />
        );
    }
  }
};

export default MultiCourse;
