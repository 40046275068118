import { useContext, useState, useEffect, useReducer } from "react";
import { GlobalContext } from "@context";
import { cargo } from "@api";
import { Submitter } from "@tools";
import { Grid, Rating, Box, Typography } from "@mui/material";
import {
  BasicAvatar,
  ResizingTextField,
  Submit,
  CircularTextCounter,
} from "@common/comps";
import {
  CourseStarIcon,
  CourseStarFilledIcon,
  CourseSwitchOnIcon,
  CourseSwitchOffIcon,
} from "@material/icons";

const AnonSwitch = ({ isAnon, setIsAnon }) => {
  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      alignItems="center"
      sx={{ gap: 0.5, "&:hover": { cursor: "pointer" } }}
      id="eval-create__anon-switch"
      onClick={() => setIsAnon((prev) => !prev)}
    >
      <Grid
        item
        sx={{ padding: "3px", borderRadius: "9999px" }}
        id="eval-create__anon-switch__icon-ctr"
      >
        <Box sx={{ display: "flex", width: "20px", height: "20px" }}>
          {isAnon ? <CourseSwitchOnIcon /> : <CourseSwitchOffIcon />}
        </Box>
      </Grid>
      <Grid item sx={{ position: "relative", top: 1 }}>
        <Typography
          sx={{
            fontSize: "15px",
            fontWeight: "400",
            color: isAnon ? "var(--quad-black)" : "var(--quad-gray-stealth)",
            letterSpacing: "0.02em",
          }}
        >
          Anonymous
        </Typography>
      </Grid>
    </Grid>
  );
};

export const EvaluationPublisher = ({
  instructor,
  onPublish,
  onAnonPublish,
}) => {
  const { defaultError, session } = useContext(GlobalContext);
  const { sessOwnerAvatarSrc, postMax } = session || {};
  const { quad, instrId, courseId } = instructor || {};

  const [isInitialized, setIsInitialized] = useState(false);
  const [isAnon, setIsAnon] = useState(false);
  const [stars, setStars] = useState(0);
  const [text, setText] = useState("");

  /* Submission */
  const [submitState, setSubmitState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState()
  );

  const submitter = new Submitter(submitState, setSubmitState, defaultError);

  useEffect(() => {
    submitter.resetError();
    submitter.green(text.length > 0 && stars > 0);
  }, [text, stars]);

  const handleSubmit = () => {
    submitter.startLoading();
    if (isAnon) {
      cargo
        .post(`/v2/q/${quad}/course/${courseId}/evaluation/stage`, {
          evaluation: { instrId, text, rating: stars },
        })
        .then((res) => {
          const { ok, payload } = res || {};
          if (ok) {
            onAnonPublish(payload);
          } else {
            submitter.stopLoading();
            submitter.takeResponse(res);
          }
        });
    } else {
      cargo
        .post(`/v2/q/${quad}/course/${courseId}/evaluation`, {
          evaluation: { instrId, text, rating: stars },
        })
        .then((res) => {
          const { ok, payload } = res || {};
          if (ok) {
            onPublish(payload);
          } else {
            submitter.stopLoading();
            submitter.takeResponse(res);
          }
        });
    }
  };

  const submitSection = (
    <Grid container direction="row" wrap="nowrap" alignItems="center" gap={2}>
      {text && (
        <Grid item>
          <CircularTextCounter text={text} limit={postMax} />
        </Grid>
      )}
      <Grid item>
        <Submit
          variant="publish"
          submitState={submitState}
          handleSubmit={handleSubmit}
        />
      </Grid>
    </Grid>
  );

  const publisher = (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        <Rating
          id="course__create-eval__rating"
          defaultValue={0}
          precision={1}
          onChange={(event, val) => {
            event.stopPropagation();
            val ? setStars(val) : setStars(0);
          }}
          icon={<CourseStarFilledIcon width={20} height={20} />}
          emptyIcon={<CourseStarIcon width={20} height={20} />}
        />
      </Grid>
      <Grid
        item
        borderBottom={isInitialized ? "1px solid var(--border-gray)" : ""}
        py={1.5}
        mb={isInitialized && 1.5}
        onClick={() => setIsInitialized(true)}
      >
        <ResizingTextField
          wrapperId="resizing-textfield__create-eval__wrapper"
          textareaId="resizing-textfield__create-eval__textarea"
          maxHeight="1000px"
          state={text}
          setState={setText}
          placeholder="Write an evaluation"
        />
      </Grid>
      <Grid item>
        <Grid
          container
          direction="row"
          wrap="nowrap"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item sx={{ position: "relative", right: 5 }}>
            <AnonSwitch isAnon={isAnon} setIsAnon={setIsAnon} />
          </Grid>
          <Grid item>{submitSection}</Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const Avatar = () => (
    <BasicAvatar avatarSrc={sessOwnerAvatarSrc} isAnon={isAnon} disableClick />
  );

  return (
    <Grid container direction="row" wrap="nowrap" gap={2}>
      <Grid item>
        <Avatar />
      </Grid>
      <Grid item sx={{ width: "100%" }}>
        {publisher}
      </Grid>
    </Grid>
  );
};
