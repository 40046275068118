import { useContext, useState } from "react";
import { cargo } from "@api";
import { Opener, Builder } from "@tools";
import { QuadContext } from "@context";
import {
  Input,
  MoreHeader,
  Loadman,
  Editable,
  PopperOptions,
} from "@common/comps";
import { Button, Grid, Box, ThemeProvider } from "@mui/material";
import { buttonTheme } from "@material/themes";
import { PopperEditIcon, PopperCourseIcon } from "@material/icons";
import { unique, replace } from "radash";

const Instructor = ({ n, setFeed }) => {
  const { instrNameFirst, instrNameMiddle, instrNameLast, instrTitle } =
    n || {};

  const instrNameFull =
    `${instrNameFirst}` +
    (instrNameMiddle ? ` ${instrNameMiddle}` : "") +
    ` ${instrNameLast}`;

  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isEditCoursesOpen, setIsEditCoursesOpen] = useState(false);

  const menuItemsList = [
    {
      key: "admin-instructors__edit",
      label: "Edit instructor",
      icon: <PopperEditIcon />,
      handleClick: (event) => {
        event.stopPropagation();
        setIsEditOpen(true);
      },
    },
    {
      key: "admin-instructors__edit-courses",
      label: "Edit courses",
      icon: <PopperCourseIcon />,
      handleClick: (event) => {
        event.stopPropagation();
        setIsEditCoursesOpen(true);
      },
    },
  ];

  const endComponent = (
    <>
      <PopperOptions menuItemsList={menuItemsList} />
      {isEditOpen &&
        Builder.instructor({
          isOpen: isEditOpen,
          setIsOpen: setIsEditOpen,
          unedited: n,
          onEdit: (edited) => {
            setFeed((prev) =>
              replace(prev, edited, (n) => n.instrId === n.instrId)
            );
            setIsEditOpen(false);
          },
        })}
      {isEditCoursesOpen &&
        Builder.instructorCourse({
          instrId: n.instrId,
          isOpen: isEditCoursesOpen,
          setIsOpen: setIsEditCoursesOpen,
          onEdit: () => {
            setIsEditCoursesOpen(false);
          },
        })}
    </>
  );

  return (
    <Box px={2} py={1.5} borderBottom="1px solid var(--border-gray)">
      <Editable
        variant="vanilla"
        label={instrNameFull}
        subLabel={instrTitle}
        endComponent={endComponent}
      />
    </Box>
  );
};

const InstructorSearch = ({ handleSearch }) => {
  const [searchFirst, setSearchFirst] = useState("");
  const [searchMiddle, setSearchMiddle] = useState("");
  const [searchLast, setSearchLast] = useState("");

  const searchTerms = {
    first: searchFirst || null,
    middle: searchMiddle || null,
    last: searchLast || null,
  };

  const searchButton = (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant="outlined"
        disabled={!(searchFirst || searchMiddle || searchLast)}
        onClick={() => handleSearch(searchTerms)}
        sx={{
          fontSize: "14px",
          height: "39px",
          borderRadius: "5px",
          width: "100%",
        }}
      >
        Search instructor
      </Button>
    </ThemeProvider>
  );

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item borderBottom="1px solid var(--border-gray)">
        <Grid container direction="column" wrap="nowrap" p={2} gap={1}>
          <Grid item>
            <Input
              variant="vanilla"
              placeholder="First name"
              state={searchFirst}
              setState={setSearchFirst}
              handleEnterDown={() => handleSearch(searchTerms)}
            />
          </Grid>
          <Grid item>
            <Input
              variant="vanilla"
              placeholder="Middle name"
              state={searchMiddle}
              setState={setSearchMiddle}
              handleEnterDown={() => handleSearch(searchTerms)}
            />
          </Grid>
          <Grid item>
            <Input
              variant="vanilla"
              placeholder="Last name"
              state={searchLast}
              setState={setSearchLast}
              handleEnterDown={() => handleSearch(searchTerms)}
            />
          </Grid>
          <Grid item>{searchButton}</Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const AdminInstructors = () => {
  const { quadId } = useContext(QuadContext);

  const [isInitialized, setIsInitialized] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [feed, setFeed] = useState([]);
  const [fresh, setFresh] = useState(0);

  const handleSearch = (searchTerms) => {
    if (!isInitialized) setIsInitialized(true);

    setIsSearching(true);
    cargo
      .post(`/v2/q/${quadId}/instructor/search`, { searchTerms })
      .then((res) => {
        const { ok, payload } = res || {};
        if (ok) {
          setIsSearching(false);
          const uniques = unique(payload.instructors, (n) => n.instrId);
          const N = uniques.length;
          setFeed(uniques.slice(0, N > 50 ? 50 : N));
        }
      });
  };

  const Create = () => (
    <Opener
      variant="filled"
      color="black"
      label="Create instructor"
      builder={Builder.instructor({
        onCreate: () => setFresh((prev) => prev + 1),
      })}
    />
  );

  const instructorFeed = (
    <Grid container direction="column" wrap="nowrap">
      {feed.map((n) => (
        <Grid item key={n.instrId}>
          <Instructor n={n} setFeed={setFeed} />
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        <MoreHeader
          title="Manage instructors"
          enableBack
          backRoute={`/q/${quadId}/admin`}
        />
      </Grid>
      <Grid
        item
        p={2}
        borderTop="1px solid var(--border-gray)"
        borderBottom="1px solid var(--border-gray)"
      >
        <Create />
      </Grid>
      <Grid item>
        <InstructorSearch handleSearch={handleSearch} />
      </Grid>
      {isInitialized && (
        <Grid item>
          <>
            {Boolean(isSearching || feed.length === 0) ? (
              <Loadman
                variant="child"
                p={2}
                isLoading={isSearching}
                isEmpty={feed.length === 0}
                emptyLabel="No instructors found."
              />
            ) : (
              instructorFeed
            )}
          </>
        </Grid>
      )}
    </Grid>
  );
};
