import {
  Box,
  CircularProgress,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { SpinnerSquareIcon } from "@material/icons";
import { omit } from "radash";

import "./spinnerStyle.css";

const spinnerTheme = createTheme({
  components: {
    MuiCircularProgress: {
      styleOverrides: {
        determinate: {
          color: "var(--spinner-teal-light)",
        },
        indeterminate: {
          position: "absolute",
          left: 0,
          color: "var(--quad-teal)",
          animationDuration: "500ms",
        },
      },
    },
  },
});

const Square = ({ size = 25 }) => {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        height: size,
        width: size,
      }}
    >
      <Box
        id="spinner-harvey--indeterminate"
        sx={{ position: "absolute", top: 0, left: 0 }}
      >
        <SpinnerSquareIcon width={size} height={size} />
      </Box>
      <Box
        id="spinner-harvey--determinate"
        sx={{ position: "absolute", top: 0, left: 0 }}
      >
        <SpinnerSquareIcon width={size} height={size} />
      </Box>
    </Box>
  );
};

const Circular = ({ thickness, size }) => {
  return (
    <ThemeProvider theme={spinnerTheme}>
      <Box sx={{ position: "relative", display: "flex" }}>
        <CircularProgress
          variant="determinate"
          thickness={thickness ? thickness : 5}
          size={size ? size : 20}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          thickness={thickness ? thickness : 5}
          size={size ? size : 20}
          disableShrink
        />
      </Box>
    </ThemeProvider>
  );
};

const Spinner = (props) => {
  const { variant } = props || {};

  switch (variant) {
    case "circular":
      return <Circular {...omit(props, ["variant"])} />;
    case "square":
      return <Square {...omit(props, ["variant"])} />;
    default:
      return <Square {...omit(props, ["variant"])} />;
  }
};

export default Spinner;
