import { Grid, Button, ThemeProvider, Typography } from "@mui/material";
import { tabTheme } from "@material/themes";

const Tab = ({ option, state, setState }) => {
  const { tabName, tabValue } = option || {};
  const isSelected = state === tabValue;

  return (
    <ThemeProvider theme={tabTheme}>
      <Button
        variant={isSelected ? "bubble--selected" : "bubble--default"}
        onClick={(event) => {
          event.stopPropagation();
          setState(tabValue);
        }}
      >
        <Typography
          variant={isSelected ? "bubble--selected" : "bubble--default"}
        >
          {tabName}
        </Typography>
      </Button>
    </ThemeProvider>
  );
};

const BasicNav = ({ options, state, setState }) => {
  return (
    <Grid container direction="row" sx={{ gap: 1 }}>
      {options.map((item, i) => (
        <Grid item key={i}>
          <Tab option={item} state={state} setState={setState} />
        </Grid>
      ))}
    </Grid>
  );
};

export default BasicNav;
