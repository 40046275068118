import { useContext } from "react";
import { Outlet } from "react-router-dom";
import { QuadContext, EditorProvider } from "@context";
import { MoreHeader, BasicErrorNotice, SettingsCard } from "@common/comps";
import { Grid } from "@mui/material";
import { SettingsConfessionIcon, SettingsRatingsIcon } from "@material/icons";

export * from "./comps";

const EditorMenu = () => {
  const { quadId } = useContext(QuadContext);

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        <SettingsCard
          label="Confessions"
          subLabel="Approve or reject staged confessions from your quad."
          route={`/q/${quadId}/editor/confessions`}
          icon={<SettingsConfessionIcon />}
        />
        <SettingsCard
          label="Evaluations"
          subLabel="Approve or reject anonymous evaluations."
          route={`/q/${quadId}/editor/evaluations`}
          icon={<SettingsRatingsIcon />}
        />
      </Grid>
    </Grid>
  );
};

export const EditorLanding = () => {
  const { sessionQ } = useContext(QuadContext);
  const { isEditor } = sessionQ || {};

  const content = isEditor ? (
    <EditorMenu />
  ) : (
    <BasicErrorNotice label="This area is restricted." padding="0px 10px" />
  );

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        <MoreHeader title="Editor center" enableBack />
      </Grid>
      <Grid item>{content}</Grid>
    </Grid>
  );
};

export const Editor = () => {
  return (
    <EditorProvider>
      <Outlet />
    </EditorProvider>
  );
};
