import { useEffect } from "react";
import { CourseTopicsProvider } from "./context";

const Landing = () => {};

export const CourseTopics = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <CourseTopicsProvider>
      <Landing />
    </CourseTopicsProvider>
  );
};
