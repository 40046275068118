import { BasicCheckbox, Input } from "@common/comps";

function input({ label, field, counted = true, multiline, rows, disabled }) {
  return (
    <Input
      multiline={multiline}
      label={label}
      state={this.template[field].value}
      handleChange={(event) =>
        this.setTemplate({ field, value: event.target.value })
      }
      counted={counted}
      limit={this.template[field].max}
      error={this.template[field].error}
      rows={rows}
      disabled={disabled}
    />
  );
}

function numberInput({ label, field, type }) {
  return (
    <Input
      variant="number"
      label={label}
      type={type}
      state={this.template[field].value}
      handleChange={(number) => this.setTemplate({ field, value: number })}
      limit={this.template[field].max}
      error={this.template[field].error}
    />
  );
}

function checkbox({ label, field }) {
  return (
    <BasicCheckbox
      label={label}
      checked={this.template[field].value}
      handleChange={(event) => {
        this.setTemplate({ field, value: event.target.checked });
      }}
    />
  );
}

export { input, numberInput, checkbox };
