import { createContext, useContext } from "react";

export const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
  return (
    <SettingsContext.Provider value={{}}>{children}</SettingsContext.Provider>
  );
};

export const useSettings = () => {
  const context = useContext(SettingsContext);
  if (!context) {
    return null;
  } else {
    return context;
  }
};
