import { createTheme } from "@mui/material";

const postTheme = createTheme({
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: "body--short" },
          style: {
            fontSize: "22px",
            fontWeight: "400",
            color: "var(--quad-black)",
            overflowWrap: "anywhere",
            letterSpacing: "0.01em",
          },
        },
        {
          props: { variant: "body--medium" },
          style: {
            fontSize: "18px",
            fontWeight: "400",
            color: "var(--quad-black)",
            overflowWrap: "anywhere",
            letterSpacing: "0.01em",
          },
        },
        {
          props: { variant: "body--long" },
          style: {
            fontSize: "15px",
            fontWeight: "400",
            color: "var(--quad-black)",
            overflowWrap: "anywhere",
          },
        },
        {
          props: { variant: "date" },
          style: {
            fontSize: "15px",
            fontWeight: "400",
            color: "var(--quad-gray)",
          },
        },
        {
          props: { variant: "stats__count" },
          style: {
            fontSize: "15px",
            fontWeight: "500",
            color: "var(--quad-gray)",
          },
        },
        {
          props: { variant: "stats__label" },
          style: {
            fontSize: "15px",
            fontWeight: "400",
            color: "var(--quad-gray)",
          },
        },
        {
          props: { variant: "course__title" },
          style: {
            fontFamily: "Inter",
            fontSize: "22px",
            fontWeight: "600",
            color: "var(--quad-black)",
            overflowWrap: "anywhere",
          },
        },
        {
          props: { variant: "course__desc" },
          style: {
            fontSize: "15px",
            fontWeight: "400",
            color: "var(--quad-black)",
            whiteSpace: "pre-line",
            overflowWrap: "anywhere",
          },
        },
        {
          props: { variant: "course__desc--empty" },
          style: {
            fontSize: "15px",
            fontWeight: "400",
            color: "var(--quad-gray-stealth)",
            whiteSpace: "pre-line",
            overflowWrap: "anywhere",
          },
        },
        {
          props: { variant: "course__details" },
          style: {
            fontSize: "15px",
            fontWeight: "400",
            color: "var(--quad-gray)",
            overflowWrap: "anywhere",
          },
        },
        {
          props: { variant: "course__schedule" },
          style: {
            fontSize: "15px",
            fontWeight: "400",
            color: "var(--quad-black)",
          },
        },
        {
          props: { variant: "program__conc-name" },
          style: {
            fontFamily: "Inter",
            fontSize: "22px",
            fontWeight: "600",
            color: "var(--quad-black)",
            overflowWrap: "anywhere",
          },
        },
        {
          props: { variant: "program__desc" },
          style: {
            fontSize: "15px",
            fontWeight: "400",
            color: "var(--quad-black)",
            whiteSpace: "pre-line",
            overflowWrap: "anywhere",
          },
        },
        {
          props: { variant: "program__desc--empty" },
          style: {
            fontSize: "15px",
            fontWeight: "400",
            color: "var(--quad-gray-stealth)",
            whiteSpace: "pre-line",
            overflowWrap: "anywhere",
          },
        },
      ],
    },
  },
});

export default postTheme;
