import { useContext, useState, useEffect } from "react";
import { cargo } from "@api";
import { ProfileContext } from "@context";
import { Loading } from "@common/comps";
import { getAttributeIcon } from "@common/utility";
import {
  Grid,
  Button,
  Box,
  Popper,
  Paper,
  Typography,
  ThemeProvider,
} from "@mui/material";
import { attrTheme } from "@material/themes";

import { attrPopperTheme } from "./theme";

const AttributePopper = ({
  anchorEl,
  attribute,
  attributeName,
  attributeDescription,
}) => {
  const isPopperOpen = Boolean(anchorEl);

  const header = (
    <Grid container direction="row" wrap="nowrap" alignItems="center" gap={1}>
      <Grid item>
        <Box sx={{ width: "20px", height: "20px" }}>
          {getAttributeIcon(attribute)}
        </Box>
      </Grid>
      <Grid item>
        <Typography variant="popperHeader">{attributeName}</Typography>
      </Grid>
    </Grid>
  );

  return (
    <ThemeProvider theme={attrPopperTheme}>
      <Popper
        open={isPopperOpen}
        anchorEl={anchorEl}
        placement="top"
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 10],
              },
            },
          ],
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        className="profile-attributes__popper"
        sx={{ zIndex: "5" }}
      >
        <Paper>
          <Grid container direction="column" wrap="nowrap" gap={1}>
            <Grid item>{header}</Grid>
            <Grid item sx={{ width: "100%" }}>
              <Typography variant="popperBody">
                {attributeDescription}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Popper>
    </ThemeProvider>
  );
};

const AttributeButton = ({ a }) => {
  const { username } = useContext(ProfileContext);
  const {
    attribute,
    attributeName,
    attributeDescription,
    totalScore,
    sessOwnerScore,
  } = a || {};

  const [scoreT, setScoreT] = useState(totalScore - sessOwnerScore);
  const [upvoted, setUpvoted] = useState(sessOwnerScore === 1);
  const [anchorEl, setAnchorEl] = useState(null);

  const variant = upvoted ? "attribute--selected" : "attribute--default";

  useEffect(() => {
    if (upvoted) {
      cargo.put(`/v2/u/${username}/attribute`, {
        action: "increment",
        attribute,
      });
    } else {
      cargo.put(`/v2/u/${username}/attribute`, {
        action: "neutralize",
        attribute,
      });
    }
  }, [upvoted]);

  const handlePopperOpen = (event) => {
    event.stopPropagation();
    const { currentTarget } = event;
    setAnchorEl(currentTarget);
  };

  const handlePopperClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <ThemeProvider theme={attrTheme}>
      {anchorEl && (
        <AttributePopper
          anchorEl={anchorEl}
          attribute={attribute}
          attributeName={attributeName}
          attributeDescription={attributeDescription}
        />
      )}
      <Button
        variant={variant}
        onClick={() => setUpvoted((prev) => !prev)}
        onMouseEnter={handlePopperOpen}
        onMouseLeave={handlePopperClose}
      >
        <Grid container direction="row" wrap="nowrap" gap={1.25}>
          <Grid item>{attributeName}</Grid>
          <Grid item>
            <Box
              sx={{
                position: "relative",
                top: 4,
                width: "1px",
                height: "60%",
                backgroundColor: "var(--div-gray)",
              }}
            />
          </Grid>
          <Grid item>{totalScore + upvoted ? 1 : 0}</Grid>
        </Grid>
      </Button>
    </ThemeProvider>
  );
};

export const Attributes = () => {
  const { username } = useContext(ProfileContext);

  const [isLoading, setIsLoading] = useState(true);
  const [attributes, setAttributes] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    cargo.get(`/v2/u/${username}/attribute`).then((res) => {
      const { ok, payload } = res || {};
      if (ok) {
        setIsLoading(false);
        setAttributes(payload.attributes);
      }
    });
  }, []);

  const buttons = (
    <Grid container direction="row" gap={1}>
      {attributes.map((a, i) => (
        <Grid item key={a.attribute}>
          <AttributeButton a={a} />
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Grid container direction="column" wrap="nowrap" p={2}>
      {isLoading ? <Loading p={2} /> : <Grid item>{buttons}</Grid>}
    </Grid>
  );
};
