import { useState, useEffect } from "react";
import { Dropdown } from "@common/comps";
import { Grid, ThemeProvider } from "@mui/material";
import { selectTheme } from "@material/themes";

const EditYearsSelect = ({
  startYear,
  endYear,
  setStartYear,
  setEndYear,
  isCurrent,
}) => {
  const [numOfEndYears, setNumOfEndYears] = useState(0);

  useEffect(() => {
    if (startYear) {
      setNumOfEndYears(10);
    }
  }, [startYear]);

  const numOfStartYears = 48 + Math.floor(Date.now() / 31536000000);

  const startYearsArray = [...Array(numOfStartYears).keys()]
    .map((year) => year + (new Date().getFullYear() - 99))
    .reverse();

  const endYearsArray = !numOfEndYears
    ? [...Array(numOfStartYears).keys()]
        .map((year) => year + (new Date().getFullYear() - 99))
        .reverse()
    : [...Array(numOfEndYears).keys()]
        .map((year) => parseInt(startYear) + year + 1)
        .reverse();

  return (
    <ThemeProvider theme={selectTheme}>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        sx={{ width: "inherit", justifyContent: "space-between" }}
      >
        <Grid
          item
          sx={{ width: "inherit", maxWidth: "50%", marginRight: "15px" }}
        >
          <Dropdown
            label="Start Year"
            state={startYear}
            setState={setStartYear}
            options={startYearsArray}
            width="100%"
          />
        </Grid>
        <Grid item sx={{ width: "inherit", maxWidth: "50%" }}>
          <Dropdown
            label="End Year"
            state={endYear}
            setState={setEndYear}
            options={endYearsArray}
            width="100%"
            disabled={isCurrent}
          />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default EditYearsSelect;
