import { useEffect, useReducer } from "react";
import { Input } from "@common/comps";

const reducer = (state, action) => {
  const { type, value } = action || {};

  const toInteger = () => {
    if (value === "") {
      return "";
    } else {
      const int = Number(value);
      return Number.isInteger(int) ? int : state;
    }
  };

  const toReal = () => {
    if (value === "") {
      return "";
    } else {
      const real = value.match(/^[0-9]+\.?[0-9]*$/)?.[0];
      return real ? real : state;
    }
  };

  switch (type) {
    case "int":
      return toInteger();
    case "real":
      return toReal();
    default:
      return toReal();
  }
};

const NumberInput = ({
  type,
  label,
  disabled,
  state,
  setState,
  handleChange,
  error,
}) => {
  const [number, setNumber] = useReducer(reducer, state ? state : "");

  useEffect(() => {
    handleChange ? handleChange(number) : setState(number);
  }, [number]);

  return (
    <Input
      label={label}
      disabled={disabled}
      state={state}
      error={error}
      handleChange={(event) => {
        setNumber({ type, value: event.target.value });
      }}
    />
  );
};

export default NumberInput;
