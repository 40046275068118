import { useContext, useState } from "react";
import { GlobalContext } from "@context";
import { cargo } from "@api";
import { ConfirmForm, PopperOptions } from "@common/comps";
import { PopperBinIcon } from "@material/icons";

export const EvaluationOptions = ({ e, onDelete }) => {
  const { defaultError } = useContext(GlobalContext);
  const { evalId, quad, isAnon, canEdit } = e || {};

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  // Deletion
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(false);
  const [deleteError, setDeleteError] = useState(defaultError);

  const handleDelete = () => {
    if (evalId && quad && Boolean(isAnon === true || isAnon === false)) {
      setIsDeleteLoading(true);
      if (isAnon) {
        cargo
          .delete(`/v2/q/${quad}/e/course/evaluation/${evalId}`)
          .then((res) => {
            const { ok, status, message } = res || {};
            if (ok) {
              onDelete();
            } else {
              setIsDeleteLoading(false);
              setIsDeleteDisabled(true);
              setDeleteError({
                isError: true,
                errorText: message,
                type: status,
              });
            }
          });
      } else {
        cargo
          .delete(`/v2/q/${quad}/course/evaluation/${evalId}`)
          .then((res) => {
            const { ok, status, message } = res || {};
            if (ok) {
              onDelete();
            } else {
              setIsDeleteLoading(false);
              setIsDeleteDisabled(true);
              setDeleteError({
                isError: true,
                errorText: message,
                type: status,
              });
            }
          });
      }
    }
  };

  const menuItemsList = [
    {
      key: "eval-options__delete",
      label: "Delete evaluation",
      icon: <PopperBinIcon />,
      isRed: true,
      handleClick: (event) => {
        setIsDeleteOpen(true);
        event.stopPropagation();
      },
      isRestricted: true,
      auth: canEdit,
    },
  ];

  return (
    <>
      <PopperOptions menuItemsList={menuItemsList} />
      {isDeleteOpen && (
        <ConfirmForm
          title="Delete evaluation?"
          subtitle="This action is irreversible. You will not be able to recover this evaluation."
          color="red"
          isFormOpen={isDeleteOpen}
          setIsFormOpen={setIsDeleteOpen}
          submitLabel="Delete"
          handleSubmit={handleDelete}
          submitState={{
            isSubmitDisabled: isDeleteDisabled,
            isSubmitLoading: isDeleteLoading,
            submitError: deleteError,
          }}
        />
      )}
    </>
  );
};
