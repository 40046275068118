import { Grid, Typography, createTheme, ThemeProvider } from "@mui/material";

const badgeTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          display: "flex",
        },
      },
      variants: [
        {
          props: { variant: "label" },
          style: {
            fontSize: "13px",
            fontWeight: "500",
            color: "white",
            letterSpacing: "0.02em",
          },
        },
      ],
    },
  },
});

const getBgColor = (level) => {
  switch (level) {
    case 2:
      return "var(--quad-program-2)";
    case 3:
      return "var(--quad-program-3)";
    case 4:
      return "var(--quad-program-4)";
    case 5:
      return "var(--quad-program-5)";
    default:
      return "var(--quad-program-1)";
  }
};

export const ProgramBadge = ({ p }) => {
  const { certCode, certLevel, concName } = p || {};

  return (
    <ThemeProvider theme={badgeTheme}>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        alignItems="center"
        px={1.5}
        height="24px"
        borderRadius="9999px"
        gap={0.5}
        sx={{ backgroundColor: getBgColor(certLevel) }}
      >
        <Grid item>
          <Typography variant="label">{certCode}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="label">{concName}</Typography>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
