const filledVariants = [
  {
    props: { variant: "filled", color: "black" },
    style: {
      border: "1px solid var(--quad-black)",
      borderRadius: "9999px",
      padding: "5px 15px",
      backgroundColor: "var(--quad-black)",
      transition: "background-color 0ms",
      fontSize: "13px",
      fontWeight: "500",
      color: "white",

      "&:hover": {
        border: "1px solid var(--button-black--hover)",
        backgroundColor: "var(--button-black--hover)",
      },

      "&.Mui-disabled": {
        border: "1px solid var(--button-black--disabled)",
        backgroundColor: "var(--button-black--disabled)",
        color: "white",
      },
    },
  },
  {
    props: { variant: "filled", color: "moonstone" },
    style: {
      border: "none",
      borderRadius: "9999px",
      padding: "5px 15px",
      backgroundColor: "var(--quad-moonstone)",
      transition: "background-color 0ms",
      fontSize: "13px",
      fontWeight: "500",
      color: "white",

      "&:hover": {
        border: "none",
        backgroundColor: "var(--button-moonstone--hover)",
      },

      "&.Mui-disabled": {
        backgroundColor: "var(--button-black--disabled)",
      },
    },
  },
  {
    props: { variant: "filled", color: "teal" },
    style: {
      border: "none",
      borderRadius: "9999px",
      padding: "5px 15px",
      backgroundColor: "var(--quad-teal)",
      transition: "background-color 50ms",
      fontSize: "13px",
      fontWeight: "500",
      color: "white",

      "&:hover": {
        border: "none",
        backgroundColor: "var(--button-teal--hover)",
      },

      "&.Mui-disabled": {
        backgroundColor: "var(--button-black--disabled)",
      },
    },
  },
  {
    props: { variant: "filled", color: "red" },
    style: {
      border: "none",
      borderRadius: "9999px",
      padding: "5px 15px",
      backgroundColor: "var(--quad-red)",
      transition: "background-color 50ms",
      fontSize: "13px",
      fontWeight: "500",
      color: "white",

      "&:hover": {
        border: "none",
        backgroundColor: "var(--button-red--hover)",
      },

      "&.Mui-disabled": {
        backgroundColor: "var(--button-black--disabled)",
      },
    },
  },
];

const stealthVariants = [
  {
    props: { variant: "stealth--red" },
    style: {
      border: "none",
      borderRadius: "9999px",
      padding: "5px 15px",
      backgroundColor: "transparent",
      transition: "background-color 50ms",

      "&:hover": {
        border: "none",
        backgroundColor: "var(--quad-pink-light)",
      },

      "&.Mui-disabled": {
        backgroundColor: "transparent",
      },
    },
  },
  {
    props: { variant: "stealth--teal" },
    style: {
      padding: "5px 15px",
      border: "none",
      borderRadius: "9999px",
      backgroundColor: "transparent",
      transition: "background-color 50ms",
      fontSize: "13px",
      fontWeight: "500",
      color: "var(--quad-teal)",

      "&:hover": {
        border: "none",
        backgroundColor: "var(--quad-teal-light)",
      },

      "&.Mui-disabled": {
        backgroundColor: "transparent",
      },
    },
  },
  {
    props: { variant: "stealth--pewter" },
    style: {
      border: "none",
      borderRadius: "9999px",
      padding: "5px 15px",
      backgroundColor: "transparent",
      transition: "background-color 50ms",

      "&:hover": {
        border: "none",
        backgroundColor: "var(--quad-pewter-light)",
      },

      "&.Mui-disabled": {
        backgroundColor: "transparent",
      },
    },
  },
  {
    props: { variant: "stealth--gray" },
    style: {
      border: "none",
      borderRadius: "9999px",
      padding: "5px 15px",
      backgroundColor: "transparent",
      transition: "background-color 50ms",

      "&:hover": {
        border: "none",
        backgroundColor: "var(--quad-gray-light)",
      },

      "&.Mui-disabled": {
        backgroundColor: "transparent",
      },
    },
  },
];

const outlinedVariants = [
  {
    props: { variant: "outlined" },
    style: {
      border: "1px solid var(--button-border-gray)",
      borderRadius: "9999px",
      padding: "5px 15px",
      backgroundColor: "transparent",
      transition: "background-color 0ms",
      fontSize: "13px",
      fontWeight: "700",
      color: "var(--quad-gray)",

      "&:hover": {
        borderColor: "var(--button-border-gray)",
        backgroundColor: "var(--button-transparent--hover)",
      },

      "&.Mui-disabled": {
        color: "var(--button-border-gray)",
      },
    },
  },
  {
    props: { variant: "outlined--red" },
    style: {
      border: "1px solid var(--button-border-gray)",
      borderRadius: "9999px",
      padding: "5px 15px",
      backgroundColor: "transparent",
      transition: "background-color 0ms",
      color: "var(--quad-gray)",

      "&:hover": {
        borderColor: "var(--button-border-gray)",
        backgroundColor: "var(--quad-pink-light)",
      },

      "&.Mui-disabled": {
        color: "var(--button-border-gray)",
      },
    },
  },
];

const iconVariants = [
  {
    props: { variant: "icon" },
    style: {
      border: "none",
      borderRadius: "50%",
      padding: "10px",
      backgroundColor: "transparent",

      "&:hover": {
        transition: "background-color 200ms",
        border: "none",
        backgroundColor: "var(--icon-hover-gray)",
      },

      "&.Mui-disabled": {
        backgroundColor: "transparent",
      },
    },
  },
  {
    props: { variant: "icon", color: "teal" },
    style: {
      border: "none",
      borderRadius: "50%",
      padding: "8px",
      backgroundColor: "var(--button-teal)",
      transition: "background-color 0ms",

      "&:hover": {
        border: "none",
        backgroundColor: "var(--button-teal--hover)",
      },

      "&.Mui-disabled": {
        backgroundColor: "var(--button-teal--disabled)",
      },
    },
  },
];

const switchVariants = [
  {
    props: { variant: "switch--off" },
    style: {
      border: "1px solid var(--button-border-gray)",
      backgroundColor: "transparent",
      padding: "0.4em 1.2em",
      borderRadius: "9999px",
      fontSize: "13px",
      fontWeight: "500",
      color: "var(--quad-gray)",

      "&:hover": {
        backgroundColor: "var(--button-transparent--hover)",
        transition: "background-color 0ms",
      },
    },
  },
  {
    props: { variant: "switch--on" },
    style: {
      border: "1px solid var(--quad-black)",
      backgroundColor: "var(--quad-black)",
      padding: "0.4em 1.2em",
      borderRadius: "9999px",
      fontSize: "13px",
      fontWeight: "500",
      color: "white",

      "&:hover": {
        backgroundColor: "var(--button-black--hover)",
        transition: "background-color 0ms",
      },
    },
  },
  {
    props: { variant: "switch--on--gray" },
    style: {
      border: "1px solid var(--quad-gray-light)",
      backgroundColor: "var(--quad-gray-light)",
      padding: "0.4em 1.2em",
      borderRadius: "9999px",
      fontSize: "13px",
      fontWeight: "700",
      color: "var(--quad-gray)",

      "&:hover": {
        backgroundColor: "var(--quad-gray-light)",
        transition: "background-color 0ms",
      },
    },
  },
];

const deprecatedVariants = [
  {
    props: { variant: "outlined--attribute--inactive" },
    style: {
      border: "1px solid var(--button-border-gray)",
      backgroundColor: "red",
      borderRadius: "9999px",

      "&:hover": {
        backgroundColor: "rgb(235, 237, 237)",
        transition: "background-color 50ms",
      },
    },
  },
  {
    props: { variant: "outlined--attribute--active" },
    style: {
      border: "1px solid var(--quad-teal)",
      background: "red",
      borderRadius: "9999px",

      "&:hover": {
        border: "1px solid var(--quad-teal)",
        backgroundColor: "rgb(234, 248, 249)",
        transition: "background-color 50ms",
      },
    },
  },
  {
    props: { variant: "stealth" },
    style: {
      border: "none",
      borderRadius: "9999px",
      padding: "5px 15px",
      backgroundColor: "red",
      transition: "background-color 50ms",

      "&:hover": {
        border: "none",
        backgroundColor: "rgb(235, 237, 237)",
      },

      "&.Mui-disabled": {
        backgroundColor: "transparent",
      },
    },
  },
  {
    props: { variant: "outlined--square" },
    style: {
      border: "1px solid var(--button-border-gray)",
      backgroundColor: "red",
      borderRadius: "5px",
      padding: "3px 12px",

      "&:hover": {
        backgroundColor: "var(--quad-gray-light)",
        transition: "background-color 50ms",
      },
    },
  },
];

const buttonVariants = [
  ...filledVariants,
  ...stealthVariants,
  ...outlinedVariants,
  ...iconVariants,
  ...switchVariants,
  {
    props: { variant: "options" },
    style: {
      border: "none",
      borderRadius: "50%",
      padding: "8px",
      backgroundColor: "transparent",

      "&:hover": {
        transition: "background-color 200ms",
        border: "none",
        backgroundColor: "rgb(225, 229, 229)",
      },

      "&.Mui-disabled": {
        backgroundColor: "transparent",
      },
    },
  },
  {
    props: { variant: "remove" },
    style: {
      border: "none",
      borderRadius: "50%",
      padding: "8px",
      backgroundColor: "transparent",

      "&:hover": {
        transition: "background-color 200ms",
        border: "none",
        backgroundColor: "rgb(247, 228, 234)",
      },

      "&.Mui-disabled": {
        backgroundColor: "transparent",
      },
    },
  },
  /* Deprecated */
  ...deprecatedVariants,
];

export default buttonVariants;
