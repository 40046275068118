import { useState, useEffect } from "react";
import { Opener, Builder } from "@tools";
import { Grid, Avatar, Typography } from "@mui/material";
import { DefaultOrgAltAvatar } from "@material/images";

export const JobCard = ({ job, runRefresh, setFeed }) => {
  const {
    jobId,
    orgAvatarSrc,
    organization,
    position,
    location,
    startYear,
    endYear,
    isCurrent,
    description,
    canEdit,
  } = job || {};

  const [isDeleted, setIsDeleted] = useState(false);

  useEffect(() => {
    if (isDeleted) {
      setTimeout(
        () => setFeed((prev) => prev.filter((j) => j.jobId !== jobId)),
        200
      );
    }
  }, [isDeleted]);

  const getYears = () => {
    if (isCurrent) {
      return `${startYear} - Present`;
    } else {
      if (startYear && endYear) {
        if (startYear === endYear) {
          return `${startYear}`;
        } else {
          return `${startYear} - ${endYear}`;
        }
      } else {
        return `${startYear}`;
      }
    }
  };

  const content = (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        <Typography variant="org__position">{position}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="org__name">{organization}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="org__location">{location}</Typography>
      </Grid>
      <Grid item mt={0.5}>
        <Typography variant="year">{getYears()}</Typography>
      </Grid>
      {description && (
        <Grid item mt={1}>
          <Typography variant="desc">{description}</Typography>
        </Grid>
      )}
    </Grid>
  );

  const Edit = () => (
    <Opener
      variant="icon"
      icon="edit"
      builder={Builder.job({
        unedited: job,
        onEdit: runRefresh,
        onDelete: runRefresh,
      })}
    />
  );

  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      p={2}
      gap={2}
      borderBottom="1px solid var(--border-gray)"
      className={isDeleted ? "card--empty" : ""}
    >
      {!isDeleted && (
        <>
          <Grid item>
            <Avatar
              width={45}
              height={45}
              src={orgAvatarSrc ? orgAvatarSrc : DefaultOrgAltAvatar}
              sx={{ borderRadius: "5px" }}
            />
          </Grid>
          <Grid item sx={{ width: "100%" }}>
            {content}
          </Grid>
          {canEdit && (
            <Grid item sx={{ width: "0px", position: "relative", right: 35 }}>
              <Edit />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};
