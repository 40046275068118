import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { QuadContext } from "@context";
import { Builder, Opener } from "@tools";
import { EmptyLabel, PopperOptions, Editable } from "@common/comps";
import { Grid, Box } from "@mui/material";
import { PopperEditIcon, PopperChildrenIcon } from "@material/icons";

import { PreqContext } from "../context";

const Elective = ({ r }) => {
  const { quadId } = useContext(QuadContext);
  const { initialize: reinitialize } = useContext(PreqContext);
  const { setGroup } = useContext(PreqContext);
  const navigate = useNavigate();
  const [isEditOpen, setIsEditOpen] = useState(false);

  const menuItemsList = [
    {
      key: "admin-preq-elct__edit",
      label: "Edit elective",
      icon: <PopperEditIcon />,
      handleClick: (event) => {
        event.stopPropagation();
        setIsEditOpen(true);
      },
    },
    {
      key: "admin-preq-elct__edit-areas",
      label: "Edit areas",
      icon: <PopperChildrenIcon />,
      handleClick: (event) => {
        event.stopPropagation();
        setGroup(r);
        navigate(`/q/${quadId}/admin/programs/requirement/areas`);
      },
    },
  ];

  const endComponent = (
    <>
      <PopperOptions menuItemsList={menuItemsList} />
      {isEditOpen &&
        Builder.preqGroup({
          unedited: r,
          type: r.type,
          isOpen: isEditOpen,
          setIsOpen: setIsEditOpen,
          onEdit: () => {
            setIsEditOpen();
            reinitialize();
          },
          onDelete: () => {
            reinitialize();
          },
        })}
    </>
  );

  return (
    <Box px={2} py={1.5} borderBottom="1px solid var(--border-gray)">
      <Editable
        variant="vanilla"
        label={`${r.groupName}`}
        subLabel={r.groupDesc}
        endComponent={endComponent}
        alignY="flex-start"
      />
    </Box>
  );
};

export const ElectiveGroupsManager = ({ type }) => {
  const { program, groups, initialize } = useContext(PreqContext);
  const { programId } = program || {};

  const feed = groups.filter((g) => g.type === type);
  const isEmpty = feed.length === 0;

  const Create = () => (
    <Opener
      variant="filled"
      color="black"
      label="Create elective"
      builder={Builder.preqGroup({
        programId,
        type: "elct",
        onCreate: initialize,
      })}
    />
  );

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item p={2} borderBottom="1px solid var(--border-gray)">
        <Create />
      </Grid>
      {isEmpty ? (
        <EmptyLabel label="No electives added." p={2} />
      ) : (
        feed.map((r) => (
          <Grid item key={r.preqGroupId}>
            <Elective r={r} />
          </Grid>
        ))
      )}
    </Grid>
  );
};
