import { cloneElement } from "react";
import { Grid, ThemeProvider, Typography } from "@mui/material";
import { buttonTheme } from "@material/themes";
import { assign, omit } from "radash";

const helperTextBaseSx = {
  fontSize: "14px",
  fontWeight: "400",
  color: "var(--quad-gray-alt)",
  transition: "color 200ms",
};

const Default = ({
  icon,
  iconSelected,
  isSelected,
  helperText,
  onHoverSx,
  iconSize = 18,
  handleClick = () => {},
}) => {
  const defaultIcon =
    icon && cloneElement(icon, { width: iconSize, height: iconSize });

  const selectedIcon =
    iconSelected &&
    cloneElement(iconSelected, { width: iconSize, height: iconSize });

  const { iconSx, helperTextSx } = onHoverSx || {};

  const iconBaseSx = {
    display: "flex",
    borderRadius: "50%",
  };

  const actionSx = {
    "#action__icon-container": {
      ...iconBaseSx,
    },

    "#action__helper-text": {
      ...helperTextBaseSx,
    },

    ":hover": {
      cursor: "pointer",

      "#action__icon-container": {
        ...assign(
          {
            backgroundColor: "var(--icon-hover-gray)",
            transition: "background-color 200ms",
          },
          iconSx
        ),
      },

      "#action__helper-text": {
        ...helperTextSx,
      },
    },
  };

  return (
    <ThemeProvider theme={buttonTheme}>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        alignItems="center"
        gap={1}
        onClick={(...args) => {
          const [event] = args;
          event.stopPropagation();
          handleClick(...args);
        }}
        sx={actionSx}
      >
        <Grid item p={1} id="action__icon-container">
          {isSelected ? selectedIcon : defaultIcon}
        </Grid>
        {helperText && (
          <Grid item mr={1} sx={{ position: "relative", top: 1 }}>
            <Typography id="action__helper-text">{helperText}</Typography>
          </Grid>
        )}
      </Grid>
    </ThemeProvider>
  );
};

const Simple = ({ icon, iconSize = 18, helperText }) => {
  const simpleIcon = cloneElement(icon, { width: iconSize, height: iconSize });

  if (icon) {
    return (
      <Grid container direction="row" wrap="nowrap" alignItems="center" gap={1}>
        <Grid item p={1} sx={{ display: "flex" }}>
          {simpleIcon}
        </Grid>
        {helperText && (
          <Grid item mr={1} sx={{ position: "relative", top: 1 }}>
            <Typography sx={helperTextBaseSx}>{helperText}</Typography>
          </Grid>
        )}
      </Grid>
    );
  } else {
    return <></>;
  }
};

const Action = (props) => {
  const { variant } = props || {};

  const childProps = omit(props, ["variant"]);

  switch (variant) {
    case "simple":
      return <Simple {...childProps} />;
    default:
      return <Default {...childProps} />;
  }
};

export default Action;
