import { createTheme } from "@mui/material";

const labelTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          letterSpacing: "0.02em",
        },
      },
      variants: [
        {
          props: { variant: "req-label" },
          style: {
            fontSize: "12px",
            fontWeight: "500",
            color: "white",
          },
        },
        {
          props: { variant: "req-label--null" },
          style: {
            fontSize: "12px",
            fontWeight: "700",
            color: "var(--quad-gray)",
          },
        },
        {
          props: { variant: "core-label" },
          style: {
            fontSize: "12px",
            fontWeight: "500",
            color: "white",
          },
        },
        {
          props: { variant: "core-label--null" },
          style: {
            fontSize: "12px",
            fontWeight: "700",
            color: "var(--quad-gray)",
          },
        },
      ],
    },
  },
});

export default labelTheme;
