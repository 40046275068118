import { Spinner, EmptyLabel, Loading } from "@common/comps";
import { Box } from "@mui/material";
import { omit } from "radash";

const Standard = ({ isLoading, isEmpty, emptyLabel }) => {
  if (isLoading) {
    return (
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Spinner size={25} />
      </Box>
    );
  } else if (isEmpty) {
    return <EmptyLabel label={emptyLabel} />;
  } else {
    return <></>;
  }
};

const Child = ({ isLoading, isEmpty, emptyLabel }) => {
  if (isLoading) {
    return (
      <Box
        sx={{ width: "100%", display: "flex", justifyContent: "flex-start" }}
      >
        <Spinner size={20} />
      </Box>
    );
  } else if (isEmpty) {
    return <EmptyLabel label={emptyLabel} />;
  } else {
    return <></>;
  }
};

const Custom = ({ isLoading, isEmpty, emptyLabel, alignX, size }) => {
  if (isLoading) {
    return (
      <Box
        sx={{ width: "100%", display: "flex", justifyContent: alignX || "" }}
      >
        <Spinner size={size} />
      </Box>
    );
  } else if (isEmpty) {
    return <EmptyLabel label={emptyLabel} />;
  } else {
    return <></>;
  }
};

const Loadman = (props) => {
  const { variant, isLoading, isEmpty } = props || {};

  const router = () => {
    switch (variant) {
      case "standard":
        return <Standard {...props} />;
      case "child":
        return <Child {...props} />;
      default:
        return <Custom {...props} />;
    }
  };

  if (isLoading || isEmpty) {
    return (
      <Box {...omit(props, ["isLoading", "isEmpty", "emptyLabel", "variant"])}>
        <Box
          sx={{
            minHeight: "22.5px",
            maxHeight: "22.5px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {router()}
        </Box>
      </Box>
    );
  } else {
    return;
  }
};

export default Loadman;
