import { createContext, useContext, useState, useEffect } from "react";
import { cargo } from "@api";
import { GlobalContext, QuadContext } from "@context";
import { useTranche2 } from "@common/hooks";
import { unique } from "radash";

export const PatalogContext = createContext();

export const PatalogProvider = ({ children }) => {
  const { defaultError } = useContext(GlobalContext);
  const { quadId } = useContext(QuadContext);

  // Init
  const [isInitializing, setIsInitializing] = useState(true);
  const [initError, setInitError] = useState(defaultError);

  // Options
  const [alphOptions, setAlphOptions] = useState([]);

  useEffect(() => {
    setIsInitializing(true);
    cargo.get(`/v2/q/${quadId}/program/catalog`).then((res) => {
      const { ok, payload } = res || {};
      if (ok) {
        setIsInitializing(false);
        setAlphOptions(payload.alphOptions);
      }
    });
  }, []);

  // Filters
  const [alphabet, setAlphabet] = useState("");

  const {
    initTranche,
    resetTranche,
    isLoading,
    feed,
    setFeed,
    setFirstId,
    lastCardRef,
  } = useTranche2(`/v2/q/${quadId}/program/concentrations/tranche`, {
    filters: {
      alphabet,
    },
    options: {
      includePrograms: true,
    },
  });

  useEffect(() => {
    resetTranche();
  }, [alphabet]);

  return (
    <PatalogContext.Provider
      value={{
        isInitializing,
        alphOptions,
        alphabet,
        setAlphabet,
        initTranche,
        isLoading,
        lastCardRef,
        setFirstId,
        feed: unique(feed, (c) => c.concId),
        setFeed,
      }}
    >
      {children}
    </PatalogContext.Provider>
  );
};

export const usePatalog = () => {
  const context = useContext(PatalogContext);
  if (!context) {
    return null;
  } else {
    return context;
  }
};
