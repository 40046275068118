import { createContext, useState, useEffect, useContext } from "react";
import { cargo } from "@api";
import { useParams } from "react-router-dom";

export const QuadContext = createContext();

export const QuadProvider = ({ children }) => {
  const { quad } = useParams();

  // Init
  const [isLoading, setIsLoading] = useState(true);

  // Auth
  const [canAccess, setCanAccess] = useState(false);

  // Sess
  const [sessionQ, setSessionQ] = useState(null);

  // Quad data
  const [quadData, setQuadData] = useState(null);
  const { quad: quadId } = quadData || {}; // True quad id

  // Limits
  const [limits, setLimits] = useState({});

  const resetQuad = () => {
    setCanAccess(false);
    setSessionQ(null);
    setQuadData(null);
  };

  useEffect(() => {
    resetQuad();
    setIsLoading(true);

    cargo.get(`/v2/q/${quad}`).then((res) => {
      setIsLoading(false);
      const { ok, payload } = res || {};
      if (ok) {
        setCanAccess(true);
        setQuadData(payload.quadData);
        setSessionQ(payload.session);
        setLimits(payload.limits);
      } else {
        setCanAccess(false);
      }
    });
  }, [quad]);

  return (
    <QuadContext.Provider
      value={{
        isLoading,
        canAccess,
        sessionQ,
        quadData,
        quadId,
        limits,
      }}
    >
      {children}
    </QuadContext.Provider>
  );
};

export const useQuad = () => {
  const context = useContext(QuadContext);
  if (!context) {
    return null;
  } else {
    return context;
  }
};
