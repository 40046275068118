const development = {
  baseURL: "http://localhost:3500",
};

const production = {
  baseURL: "https://api.quadrangle.club",
};

const apiConfig =
  process.env.NODE_ENV === "development" ? development : production;

const getApiUrl = (route) => `${apiConfig.baseURL}${route}`;

const postRequest = async (route, body = {}, signal) => {
  const fetchURL = getApiUrl(route);
  const res = await fetch(fetchURL, {
    method: "POST",
    body: JSON.stringify(body),
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    ...(signal && { signal }),
  });

  const resJSON = await res.json();
  resJSON.status = res.status;
  resJSON.ok = res.ok;
  resJSON.isClientError = res.status >= 400 && res.status <= 499;

  return resJSON;
};

const postFileRequest = async (route, body = {}, signal) => {
  const fetchURL = getApiUrl(route);
  const res = await fetch(fetchURL, {
    method: "POST",
    body: body,
    credentials: "include",
    ...(signal && { signal }),
  });

  const resJSON = await res.json();
  resJSON.status = res.status;
  resJSON.ok = res.ok;
  resJSON.isClientError = res.status >= 400 && res.status <= 499;

  return resJSON;
};

const getRequest = async (route, signal) => {
  const fetchURL = getApiUrl(route);
  const res = await fetch(fetchURL, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    ...(signal && { signal }),
  });

  const resJSON = await res.json();
  resJSON.status = res.status;
  resJSON.ok = res.ok;
  resJSON.isClientError = res.status >= 400 && res.status <= 499;

  return resJSON;
};

const masterAPI = {
  post: postRequest,
  postFile: postFileRequest,
  get: getRequest,
};

export default masterAPI;
