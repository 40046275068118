import { createTheme } from "@mui/material";

export const attrPopperTheme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "0px 0px 5px 0px rgb(205, 209, 209)",
          padding: "16px",
          borderRadius: "8px",
          minWidth: "200px",
          maxWidth: "350px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          letterSpacing: "0.01em",
        },
      },
      variants: [
        {
          props: { variant: "popperHeader" },
          style: {
            fontFamily: "Inter",
            fontSize: "15px",
            fontWeight: "600",
            color: "var(--quad-black)",
          },
        },
        {
          props: { variant: "popperBody" },
          style: {
            fontSize: "14px",
            fontWeight: "400",
            color: "var(--quad-gray)",
          },
        },
      ],
    },
  },
});
