import { useContext } from "react";
import { QuadContext } from "@context";
import { MoreHeader } from "@common/comps";
import { Grid } from "@mui/material";

export const Cataloger = () => {
  const { quadId } = useContext(QuadContext);

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        <MoreHeader
          title="Cataloger"
          enableBack
          backRoute={`/q/${quadId}/more`}
        />
      </Grid>
    </Grid>
  );
};
