import { useContext, useState, useEffect } from "react";
import { cargo } from "@api";
import { ProfileContext } from "@context";
import { Opener, Builder } from "@tools";
import { EmptyLabel, Loading } from "@common/comps";
import { Grid } from "@mui/material";
import { unique } from "radash";

import { JobCard } from "./JobCard";

export const Jobs = () => {
  const { profile } = useContext(ProfileContext);
  const { username, canEdit } = profile || {};

  const [isLoading, setIsLoading] = useState(false);
  const [feed, setFeed] = useState([]);

  const [refresh, setRefresh] = useState(0);

  const runRefresh = () => setRefresh((prev) => prev + 1);

  useEffect(() => {
    setIsLoading(true);
    cargo.get(`/v2/u/${username}/job`).then((res) => {
      setIsLoading(false);
      const { ok, payload } = res || {};
      if (ok) {
        setFeed(payload.jobs);
      }
    });
  }, [refresh]);

  const uniques = unique(feed, (j) => j.jobId);

  const jobFeed = uniques.map((j, i) => (
    <Grid item key={j.jobId}>
      <JobCard job={j} runRefresh={runRefresh} setFeed={setFeed} />
    </Grid>
  ));

  const Create = () => (
    <Opener
      variant="filled"
      color="black"
      label="Add new"
      builder={Builder.job({ onCreate: runRefresh })}
    />
  );

  if (isLoading) {
    return <Loading p={2} />;
  } else {
    if (uniques.length === 0) {
      return <EmptyLabel label="Jobs not found." p={2} />;
    } else {
      return (
        <Grid container direction="column" wrap="nowrap">
          {jobFeed}
          {canEdit && (
            <Grid item sx={{ display: "flex", justifyContent: "center" }} p={2}>
              <Create />
            </Grid>
          )}
        </Grid>
      );
    }
  }
};
