import { createTheme } from "@mui/material";

export const profileTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          display: "flex",
          letterSpacing: "0.01em",
          fullWidth: true,
        },
      },
      variants: [
        {
          props: { variant: "name" },
          style: {
            fontSize: "23px",
            fontWeight: "700",
            color: "var(--quad-black)",
          },
        },
        {
          props: { variant: "username" },
          style: {
            fontSize: "15px",
            fontWeight: "400",
            color: "var(--quad-gray-alt)",
          },
        },
        {
          props: { variant: "credential" },
          style: {
            fontSize: "15px",
            fontWeight: "400",
            color: "var(--quad-gray-alt)",
          },
        },
        {
          props: { variant: "biography" },
          style: {
            fontSize: "15px",
            fontWeight: "400",
            color: "var(--quad-black)",
          },
        },
        {
          props: { variant: "highlight" },
          style: {
            display: "flex",
            fontSize: "15px",
            fontWeight: "400",
            color: "var(--quad-gray)",
          },
        },
        {
          props: { variant: "uni__name" },
          style: {
            fontSize: "15px",
            fontWeight: "500",
            color: "var(--quad-black)",
          },
        },
        {
          props: { variant: "label--white" },
          style: {
            fontSize: "12px",
            fontWeight: "500",
            color: "white",
          },
        },
        {
          props: { variant: "uni__degree" },
          style: {
            fontSize: "14px",
            fontWeight: "400",
            color: "var(--quad-black)",
          },
        },
        {
          props: { variant: "year" },
          style: {
            fontSize: "14px",
            fontWeight: "400",
            color: "var(--quad-gray)",
            letterSpacing: "0.02em",
          },
        },
        {
          props: { variant: "desc" },
          style: {
            fontSize: "15px",
            fontWeight: "400",
            color: "var(--quad-black)",
            whiteSpace: "pre-line",
            overflowWrap: "anywhere",
          },
        },
        {
          props: { variant: "org__position" },
          style: {
            fontSize: "15px",
            fontWeight: "500",
            color: "var(--quad-black)",
          },
        },
        {
          props: { variant: "org__name" },
          style: {
            fontSize: "15px",
            fontWeight: "400",
            color: "var(--quad-black)",
          },
        },
        {
          props: { variant: "org__location" },
          style: {
            fontSize: "15px",
            fontWeight: "400",
            color: "var(--quad-gray)",
          },
        },
      ],
    },
  },
});
