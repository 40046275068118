import { useContext, useState, useEffect, useReducer } from "react";
import { GlobalContext, QuadContext } from "@context";
import { BasicForm, Loading } from "@common/comps";
import { cargo } from "@api";
import { Grid } from "@mui/material";
import { isObject } from "radash";

import { Template, Submitter } from "@tools";

export const PreqAreaBuilder = ({
  preqGroupId,
  unedited,
  isOpen,
  setIsOpen,
  onCreate = () => {},
  onEdit = () => {},
  onDelete = () => {},
}) => {
  const { defaultError } = useContext(GlobalContext);
  const { quadId } = useContext(QuadContext);
  const isEdit = Boolean(unedited);

  const [isInitializing, setIsInitializing] = useState(true);

  /* Templates */
  const [baseline, setBaseline] = useState(null);
  const [area, setArea] = useReducer(Template.createReducer(), null);

  const template = new Template(baseline, area, setArea);

  useEffect(() => {
    setIsInitializing(true);
    cargo
      .get(`/v2/q/${quadId}/r/program/requirement/group/area/form`)
      .then((res) => {
        const { ok, payload } = res || {};

        if (ok) {
          setIsInitializing(false);

          const initial = isEdit
            ? Template.superimpose(payload.template, unedited)
            : Template.createEmpty(payload.template);

          if (isEdit) setBaseline(initial);
          setArea({
            type: "init",
            value: initial,
          });
        }
      });
  }, []);

  /* Form section */
  const { preqAreaId } = unedited || {};
  const [hot, setHot] = useState(false);

  /* Submission */
  const [submitState, setSubmitState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState()
  );
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [deleteState, setDeleteState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState({ isDisabled: false })
  );

  const submitter = new Submitter(submitState, setSubmitState, defaultError);
  const deleter = new Submitter(deleteState, setDeleteState, defaultError);

  useEffect(() => {
    if (hot) setHot(false);

    if (!hot && isObject(area)) {
      const preflight = template.preflight();
      const isChanged = template.isChanged();

      submitter.stock(template.crush());
      submitter.green(isEdit ? preflight && isChanged : preflight);
      submitter.resetError();
      deleter.resetError();
    }
  }, [area]);

  const content = isInitializing ? (
    <Loading p={2} />
  ) : (
    isObject(area) && (
      <Grid container direction="column" wrap="nowrap" gap={1}>
        <Grid item>
          {template.prefab().input({ label: "Area name", field: "name" })}
        </Grid>
        <Grid item>
          {template.prefab().input({
            multiline: true,
            label: "Description",
            field: "description",
            rows: 10,
          })}
        </Grid>
      </Grid>
    )
  );

  const handleCreate = () => {
    template.resetErrors(defaultError);
    submitter.startLoading();
    cargo
      .post(`/v2/q/${quadId}/r/program/requirement/group/${preqGroupId}/area`, {
        area: template.crush(),
      })
      .then((res) => {
        const { ok, isClientError, errors } = res || {};
        if (ok) {
          onCreate();
        } else {
          setHot(true);
          submitter.takeResponse(res);
          if (isClientError) template.setErrors(errors);
        }
      });
  };

  const handleEdit = () => {
    template.resetErrors(defaultError);
    submitter.startLoading();
    cargo
      .put(`/v2/q/${quadId}/r/program/requirement/group/area/${preqAreaId}`, {
        area: template.crush(),
      })
      .then((res) => {
        const { ok, isClientError, errors } = res || {};
        if (ok) {
          onEdit();
        } else {
          setHot(true);
          submitter.takeResponse(res);
          if (isClientError) template.setErrors(errors);
        }
      });
  };

  const handleDelete = () => {
    template.resetErrors(defaultError);
    deleter.startLoading();
    cargo
      .delete(`/v2/q/${quadId}/r/program/requirement/group/area/${preqAreaId}`)
      .then((res) => {
        const { ok } = res || {};
        if (ok) {
          onDelete();
        } else {
          deleter.takeResponse(res);
        }
      });
  };

  if (isOpen) {
    if (isEdit) {
      return (
        <BasicForm
          title="Edit area"
          isFormOpen={isOpen}
          setIsFormOpen={setIsOpen}
          content={content}
          handleSubmit={handleEdit}
          submitState={{
            isSubmitLoading: submitState.isLoading,
            isSubmitDisabled: submitState.isDisabled,
            submitError: submitState.error,
          }}
          handleDelete={handleDelete}
          deleteTitle="Delete area?"
          deleteSubtitle="Deleting areas will cause issues to elements that depend on it."
          deleteState={{
            ...{
              isDeleteLoading: deleteState.isLoading,
              isDeleteDisabled: deleteState.isDisabled,
              deleteError: deleteState.error,
            },
            isDeleteOpen,
            setIsDeleteOpen,
          }}
        />
      );
    } else {
      return (
        <BasicForm
          title="Add area"
          isFormOpen={isOpen}
          setIsFormOpen={setIsOpen}
          content={content}
          handleSubmit={handleCreate}
          submitState={{
            isSubmitLoading: submitState.isLoading,
            isSubmitDisabled: submitState.isDisabled,
            submitError: submitState.error,
          }}
        />
      );
    }
  } else {
    return <></>;
  }
};
