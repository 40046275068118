import { GeneralForward2Icon } from "@material/icons";
import {
  Breadcrumbs,
  Stack,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";

const breadcrumbTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          overflowWrap: "anywhere",
        },
      },
      variants: [
        {
          props: { variant: "link" },
          style: {
            fontSize: "15px",
            fontWeight: "500",
            color: "var(--quad-black)",

            ":hover": {
              cursor: "pointer",
              color: "var(--quad-teal)",
              transition: "color 200ms",
            },
          },
        },
        {
          props: { variant: "active" },
          style: {
            fontSize: "15px",
            fontWeight: "500",
            color: "var(--quad-moonstone)",
          },
        },
      ],
    },
  },
});

const BreadcrumbNav = ({ itemList = [] }) => {
  const breadcrumbs = itemList.map((b, i) => {
    const { label, handleClick } = b || {};
    return (
      <Typography
        variant={itemList.length === i + 1 ? "active" : "link"}
        key={i}
        onClick={handleClick}
      >
        {label}
      </Typography>
    );
  });

  return (
    <ThemeProvider theme={breadcrumbTheme}>
      <Stack
        spacing={2}
        sx={{ "#general-icon--forward-2": { fill: "var(--quad-gray)" } }}
      >
        <Breadcrumbs separator={<GeneralForward2Icon width={15} height={15} />}>
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
    </ThemeProvider>
  );
};

export default BreadcrumbNav;
