import { forwardRef } from "react";
import { GeneralForwardIcon, SettingsForwardIcon } from "@material/icons";
import {
  Grid,
  Box,
  Typography,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { isString } from "radash";

const clickableTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          display: "flex",
        },
      },
      variants: [
        {
          props: { variant: "label" },
          style: {
            fontSize: "14px",
            fontWeight: "500",
            color: "var(--quad-black)",
            letterSpacing: "0.02em",
          },
        },
        {
          props: { variant: "sublabel" },
          style: {
            fontSize: "13px",
            fontWeight: "400",
            color: "var(--quad-gray)",
            letterSpacing: "0.02em",
          },
        },
        {
          props: { variant: "line__label" },
          style: {
            fontSize: "13px",
            fontWeight: "500",
            color: "var(--quad-black)",
            letterSpacing: "0.02em",
          },
        },
        {
          props: { variant: "line__sublabel" },
          style: {
            fontSize: "13px",
            fontWeight: "400",
            color: "var(--quad-gray)",
            letterSpacing: "0.02em",
          },
        },
      ],
    },
  },
});

const Label = ({ label, subLabel }) => (
  <Grid container direction="column" wrap="nowrap">
    <Grid item>
      {isString(label) ? (
        <Typography variant="label">{label}</Typography>
      ) : (
        label
      )}
    </Grid>
    {subLabel && (
      <Grid item>
        <Typography variant="sublabel">{subLabel}</Typography>
      </Grid>
    )}
  </Grid>
);

const Next = ({ label, subLabel, handleClick, containerProps }) => {
  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      alignItems="center"
      {...containerProps}
      onClick={handleClick}
      gap={1.5}
      sx={{
        ":hover": {
          cursor: "pointer",
          backgroundColor: "var(--card-hover-gray)",
          transition: "background-color 200ms",
        },

        "#settings-icon--forward": {
          fill: "var(--quad-gray-alt)",
        },
      }}
    >
      <Grid item sx={{ width: "100%" }}>
        <Label label={label} subLabel={subLabel} />
      </Grid>
      <Grid item sx={{ display: "flex" }}>
        <SettingsForwardIcon width={16} height={16} />
      </Grid>
    </Grid>
  );
};

const Line = ({
  label,
  subLabel,
  enableSubLabelTransition,
  handleClick,
  containerProps,
  endComponent,
}) => {
  const labelComponent = (
    <Grid container direction="row" wrap="nowrap" gap={1}>
      <Grid item>
        <Typography variant="line__label">{label}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="line__sublabel">{subLabel}</Typography>
      </Grid>
    </Grid>
  );

  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      {...containerProps}
      onClick={handleClick}
      sx={{
        ":hover": {
          cursor: "pointer",

          ".MuiTypography-line__label": {
            color: "var(--quad-teal)",
            transition: "color 200ms",
          },

          ...(enableSubLabelTransition && {
            ".MuiTypography-line__sublabel": {
              color: "var(--quad-black)",
              fontWeight: "500",
              transition: "font-weight 200ms, color 200ms",
            },
          }),
        },
      }}
    >
      <Grid item sx={{ width: "100%" }}>
        {labelComponent}
      </Grid>
      {endComponent && <Grid item>{endComponent}</Grid>}
    </Grid>
  );
};

const Default = ({
  label,
  subLabel,
  handleClick,
  containerProps,
  disableEndIcon,
  endComponent,
}) => {
  const iconSize = 16;

  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      alignItems="center"
      {...containerProps}
      className="link-button"
      onClick={handleClick}
      gap={1.5}
      sx={{
        ":hover": {
          cursor: "pointer",

          "#card-icon--bullet": {
            fill: "var(--quad-brick)",
          },

          ".link-button__icon": {
            opacity: 100,
            transition: "opacity 200ms",
          },

          ".MuiTypography-label": {
            color: "var(--quad-teal)",
            transition: "color 200ms",
          },
        },
      }}
    >
      <Grid item sx={{ width: "100%" }}>
        <Label label={label} subLabel={subLabel} />
      </Grid>
      {!disableEndIcon && (
        <Grid item>
          <Box
            className="link-button__icon"
            sx={{
              display: "flex",
              borderRadius: "9999px",
              padding: "8px",
              backgroundColor: "var(--icon-hover-gray)",
              opacity: 0,

              "#general-icon--forward": {
                fill: "var(--quad-gray-alt)",
              },
            }}
          >
            <GeneralForwardIcon width={iconSize} height={iconSize} />
          </Box>
        </Grid>
      )}
      {endComponent && <Grid item>{endComponent}</Grid>}
    </Grid>
  );
};

const Clickable = (props) => {
  const { variant } = props || {};

  switch (variant) {
    case "next":
      return <Next {...props} />;
    case "line":
      return <Line {...props} />;
    default:
      return <Default {...props} />;
  }
};

const Styled = forwardRef((props, ref) => (
  <article ref={ref}>
    <ThemeProvider theme={clickableTheme}>
      <Clickable {...props} />
    </ThemeProvider>
  </article>
));

export default Styled;
