import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { RootProvider } from "@context";
import { BasicHeader, SettingsCard } from "@common/comps";
import { Grid } from "@mui/material";

const RootMenu = () => {
  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        <SettingsCard
          label="Fields"
          subLabel="Edit field data"
          route="fields"
        />
      </Grid>
      <Grid item>
        <SettingsCard
          label="Organizations"
          subLabel="Edit organization data"
          route="orgs"
        />
      </Grid>
      <Grid item>
        <SettingsCard
          label="Topics"
          subLabel="Edit course topics"
          route="topics"
        />
      </Grid>
    </Grid>
  );
};

export const RootLanding = () => <RootMenu />;

export const RootContainer = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const routeLayer = pathname.split("/").length;

  const getTitle = () => {
    switch (pathname.split("/").pop()) {
      case "fields":
        return "Manage fields";
      case "orgs":
        return "Manage organizations";
      case "topics":
        return "Manage course topics";
      default:
        return "Root";
    }
  };

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      sx={{ minHeight: "100vh" }}
    >
      <Grid item sx={{ position: "sticky", top: 0, zIndex: 100 }}>
        <BasicHeader
          title={getTitle()}
          enableBack={routeLayer > 2}
          handleBack={() => navigate(`/root`)}
        />
      </Grid>
      <Grid item>{children}</Grid>
    </Grid>
  );
};

export const Root = () => {
  return (
    <RootProvider>
      <RootContainer>
        <Outlet />
      </RootContainer>
    </RootProvider>
  );
};
