import { useContext, useState, useEffect, useReducer } from "react";
import { GlobalContext, QuadContext } from "@context";
import { masterAPI, cargo } from "@api";
import { BasicForm, Loading } from "@common/comps";
import { isObject } from "radash";
import { Grid } from "@mui/material";

import { Template, Submitter } from "@tools";

export const CoreBuilder = ({
  unedited,
  isOpen,
  setIsOpen,
  onCreate = () => {},
  onEdit = () => {},
  onDelete = () => {},
}) => {
  /* Init */
  const { defaultError } = useContext(GlobalContext);
  const { quadId } = useContext(QuadContext);
  const isEdit = Boolean(unedited);
  const [isInitializing, setIsInitializing] = useState(true);

  /* Template */
  const [baseline, setBaseline] = useState(null);
  const [core, setCore] = useReducer(Template.createReducer(), null);

  const template = new Template(baseline, core, setCore);

  useEffect(() => {
    setIsInitializing(true);
    cargo.get(`/v2/q/${quadId}/r/core/form`).then((res) => {
      const { ok, payload } = res || {};
      if (ok) {
        setIsInitializing(false);

        /* Templating */
        const initialTemplate = isEdit
          ? Template.superimpose(payload.template, unedited)
          : Template.createEmpty(payload.template);

        if (isEdit) setBaseline(initialTemplate);
        setCore({ type: "init", value: initialTemplate });
      }
    });
  }, []);

  /* Form section */
  const { coreId } = unedited || {};
  const [hot, setHot] = useState(false);

  /* Submission */
  const [submitState, setSubmitState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState()
  );
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [deleteState, setDeleteState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState({ isDisabled: false })
  );

  const submitter = new Submitter(submitState, setSubmitState, defaultError);
  const deleter = new Submitter(deleteState, setDeleteState, defaultError);

  useEffect(() => {
    if (hot) setHot(false);

    if (!hot && isObject(core)) {
      const preflight = template.preflight();
      const isChanged = template.isChanged();

      submitter.stock(template.crush());
      submitter.green(isEdit ? preflight && isChanged : preflight);
      submitter.resetError();
      deleter.resetError();
    }
  }, [core]);

  const handleCreate = () => {
    template.resetErrors(defaultError);
    submitter.startLoading();
    cargo
      .post(`/v2/q/${quadId}/r/core`, {
        core: template.crush(),
      })
      .then((res) => {
        const { ok, isClientError, errors, payload } = res || {};
        if (ok) {
          onCreate(payload.created);
        } else {
          setHot(true);
          submitter.takeResponse(res);
          if (isClientError) template.setErrors(errors);
        }
      });
  };

  const handleEdit = () => {
    template.resetErrors(defaultError);
    submitter.startLoading();
    cargo
      .put(`/v2/q/${quadId}/r/core/${coreId}`, {
        core: template.crush(),
      })
      .then((res) => {
        const { ok, isClientError, errors, payload } = res || {};
        if (ok) {
          onEdit(payload.updated);
        } else {
          setHot(true);
          submitter.takeResponse(res);
          if (isClientError) template.setErrors(errors);
        }
      });
  };

  const handleDelete = () => {
    template.resetErrors(defaultError);
    deleter.startLoading();
    cargo.delete(`/v2/q/${quadId}/r/core/${coreId}`).then((res) => {
      const { ok } = res || {};
      if (ok) {
        onDelete();
      } else {
        deleter.takeResponse(res);
      }
    });
  };

  const content = isInitializing ? (
    <Loading p={2} />
  ) : (
    isObject(core) && (
      <Grid container direction="column" wrap="nowrap" gap={1}>
        <Grid item>
          {template.prefab().input({ label: "Core code", field: "code" })}
        </Grid>
        <Grid item>
          {template.prefab().input({ label: "Core name", field: "name" })}
        </Grid>
      </Grid>
    )
  );

  return (
    isOpen && (
      <>
        {isEdit ? (
          <BasicForm
            title="Edit core"
            isFormOpen={isOpen}
            setIsFormOpen={setIsOpen}
            content={content}
            handleSubmit={handleEdit}
            submitState={{
              isSubmitLoading: submitState.isLoading,
              isSubmitDisabled: submitState.isDisabled,
              submitError: submitState.error,
            }}
            handleDelete={handleDelete}
            deleteTitle="Delete core?"
            deleteSubtitle="Deleting cores will cause issues to elements that depend on it."
            deleteState={{
              ...{
                isDeleteLoading: deleteState.isLoading,
                isDeleteDisabled: deleteState.isDisabled,
                deleteError: deleteState.error,
              },
              isDeleteOpen,
              setIsDeleteOpen,
            }}
          />
        ) : (
          <BasicForm
            title="Add core"
            isFormOpen={isOpen}
            setIsFormOpen={setIsOpen}
            content={content}
            handleSubmit={handleCreate}
            submitState={{
              isSubmitLoading: submitState.isLoading,
              isSubmitDisabled: submitState.isDisabled,
              submitError: submitState.error,
            }}
          />
        )}
      </>
    )
  );
};
