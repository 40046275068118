import * as Components from "./comps";

class Builder {
  constructor() {}
  static core(props = {}) {
    return <Components.CoreBuilder {...props} />;
  }
  static course(props = {}) {
    return <Components.CourseBuilder {...props} />;
  }
  static courseCores(props = {}) {
    return <Components.CourseCoresBuilder {...props} />;
  }
  static courseRecord(props = {}) {
    return <Components.CourseRecordBuilder {...props} />;
  }
  static creq(props = {}) {
    return <Components.CreqBuilder {...props} />;
  }
  static creqAlternatives(props = {}) {
    return <Components.CreqAlternativesBuilder {...props} />;
  }
  static department(props = {}) {
    return <Components.DepartmentBuilder {...props} />;
  }
  static education(props = {}) {
    return <Components.EducationBuilder {...props} />;
  }
  static field(props = {}) {
    return <Components.FieldBuilder {...props} />;
  }
  static instructor(props = {}) {
    return <Components.InstructorBuilder {...props} />;
  }
  static instructorCourse(props = {}) {
    return <Components.InstructorCoursesBuilder {...props} />;
  }
  static job(props = {}) {
    return <Components.JobBuilder {...props} />;
  }
  static organization(props = {}) {
    return <Components.OrganizationBuilder {...props} />;
  }
  static certification(props = {}) {
    return <Components.CertificationBuilder {...props} />;
  }
  static concentration(props = {}) {
    return <Components.ConcentrationBuilder {...props} />;
  }
  static programs(props = {}) {
    return <Components.ProgramsBuilder {...props} />;
  }
  static program(props = {}) {
    return <Components.ProgramBuilder {...props} />;
  }
  static preqGroup(props = {}) {
    return <Components.PreqGroupBuilder {...props} />;
  }
  static preqOption(props = {}) {
    return <Components.PreqOptionBuilder {...props} />;
  }
  static preqAlts(props = {}) {
    return <Components.PreqAltsBuilder {...props} />;
  }
  static preqArea(props = {}) {
    return <Components.PreqAreaBuilder {...props} />;
  }
  static preqElectives(props = {}) {
    return <Components.PreqElectivesBuilder {...props} />;
  }
  static project(props = {}) {
    return <Components.ProjectBuilder {...props} />;
  }
  static subject(props = {}) {
    return <Components.SubjectBuilder {...props} />;
  }
  static subjectDepts(props = {}) {
    return <Components.SubjectDeptsBuilder {...props} />;
  }
  static subjectCatalogers(props = {}) {
    return <Components.SubjectCatalogersBuilder {...props} />;
  }
  static topicCategory(props = {}) {
    return <Components.TopicCategoryBuilder {...props} />;
  }
  static userEssentials(props = {}) {
    return <Components.EssentialsBuilder {...props} />;
  }
  static userHighlights(props = {}) {
    return <Components.HighlightsBuilder {...props} />;
  }
}

export { Builder };
export default Builder;
