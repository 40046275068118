import { useContext, useState, useEffect, useReducer } from "react";
import { cargo } from "@api";
import { GlobalContext, QuadContext } from "@context";
import { Submitter } from "@tools";
import {
  BasicAvatar,
  ResizingTextField,
  CircularTextCounter,
  Submit,
} from "@common/comps";
import { Grid } from "@mui/material";

export const CommentPublisher = ({ postId, onPublish = () => {} }) => {
  const { defaultError, session: { sessOwnerAvatarSrc, postMax } = {} } =
    useContext(GlobalContext);
  const { quadId } = useContext(QuadContext);

  const [isInitialized, setIsInitialized] = useState(false);
  const [text, setText] = useState("");

  // Submission
  const [submitState, setSubmitState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState()
  );

  const submitter = new Submitter(submitState, setSubmitState, defaultError);

  useEffect(() => {
    submitter.resetError();
    submitter.green(text.length > 0 && quadId && postId);
  }, [text]);

  const handleSubmit = () => {
    submitter.startLoading();
    cargo
      .post(`/v2/q/${quadId}/p/post/${postId}/comment`, { text })
      .then((res) => {
        const { ok, payload } = res || {};
        if (ok) {
          onPublish(payload);
        } else {
          submitter.stopLoading();
          submitter.takeResponse(res);
        }
      });
  };

  const publish = (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      justifyContent="flex-end"
      alignItems="center"
      gap={2}
    >
      {text && (
        <Grid item>
          <CircularTextCounter text={text} limit={postMax} />
        </Grid>
      )}
      <Grid item>
        <Submit
          variant="publish"
          submitState={submitState}
          handleSubmit={handleSubmit}
        />
      </Grid>
    </Grid>
  );

  const publishContainerSx = {
    ...(isInitialized
      ? {}
      : { height: 0, position: "relative", bottom: 40, pointerEvents: "none" }),
  };

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        <Grid container direction="row" wrap="nowrap" gap={2}>
          <Grid item>
            <BasicAvatar avatarSrc={sessOwnerAvatarSrc} disableClick />
          </Grid>
          <Grid item sx={{ width: "100%", position: "relative", top: 12 }}>
            <Grid container direction="column" wrap="nowrap">
              <Grid
                item
                pb={isInitialized && 2}
                onClick={() => setIsInitialized(true)}
              >
                <ResizingTextField
                  wrapperId="resizing-textfield__create-comment__wrapper"
                  textareaId="resizing-textfield__create-comment__textarea"
                  state={text}
                  setState={setText}
                  placeholder="Write a comment"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={publishContainerSx}>
        {publish}
      </Grid>
    </Grid>
  );
};
