import {
  Grid,
  Box,
  Typography,
  CircularProgress,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { CardPersonIcon } from "@material/icons";
import { getScoreDisplay } from "@common/utility";

const percentageCardTheme = createTheme({
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: "percentage-card__label" },
          style: {
            fontSize: "15px",
            fontWeight: "400",
            color: "var(--quad-black)",
          },
        },
        {
          props: { variant: "percentage-card__stat" },
          style: {
            fontSize: "13px",
            fontWeight: "400",
            color: "var(--quad-gray)",
            letterSpacing: "0.00938em",
          },
        },
        {
          props: { variant: "percentage-card__pax" },
          style: {
            fontSize: "14px",
            fontWeight: "400",
            color: "var(--quad-gray)",
            letterSpacing: "0.00938em",
          },
        },
      ],
    },
  },
});

const PercentageCard = ({ label, percentage, size, thickness, pax }) => {
  const cardDetails = (
    <Grid container direction="row" alignItems="center" wrap="nowrap">
      <Grid
        item
        sx={{ marginRight: "8px", paddingTop: "0px", display: "flex" }}
      >
        <Box sx={{ position: "relative", display: "flex" }}>
          <CircularProgress
            variant="determinate"
            thickness={thickness ? thickness : 5}
            size={size ? size : 20}
            value={100}
            sx={{ color: "var(--quad-gray-light)" }}
          />
          <CircularProgress
            variant="determinate"
            thickness={thickness ? thickness : 5}
            size={size ? size : 20}
            value={percentage}
            sx={{ color: "var(--quad-teal)", position: "absolute", left: 0 }}
          />
        </Box>
      </Grid>
      <Grid
        item
        sx={{ marginRight: "20px", display: "flex", paddingTop: "1px" }}
      >
        <Typography variant="percentage-card__stat">{`${percentage}%`}</Typography>
      </Grid>
      <Grid item sx={{ lineHeight: "20px" }}>
        <Typography variant="percentage-card__label">{label}</Typography>
      </Grid>
    </Grid>
  );

  const personCount = (
    <Grid container direction="row" wrap="nowrap" alignItems="center">
      <Grid item sx={{ marginRight: "8px" }}>
        <Box sx={{ width: "18px", height: "18px" }}>
          <CardPersonIcon />
        </Box>
      </Grid>
      <Grid item>
        <Typography variant="percentage-card__pax">
          {getScoreDisplay(pax)}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <ThemeProvider theme={percentageCardTheme}>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        justifyContent="space-between"
        sx={{
          padding: "12px 16px",
          borderBottom: "1px solid var(--border-gray)",
        }}
      >
        <Grid item>{cardDetails}</Grid>
        <Grid item>{personCount}</Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default PercentageCard;
