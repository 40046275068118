import { GeneralForwardIcon } from "@material/icons";
import {
  Grid,
  Typography,
  Box,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import "@material/styles/pageLinkStyles.css";

const linkButtonTheme = createTheme({
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: "page-link__title" },
          style: {
            fontSize: "15px",
            fontWeight: "500",
            color: "var(--quad-black)",
          },
        },
        {
          props: { variant: "page-link__subtitle" },
          style: {
            fontSize: "15px",
            fontWeight: "400",
            color: "var(--quad-gray)",
          },
        },
      ],
    },
  },
});

const PageLinkButton = ({ title, subtitle, handleClick = () => {}, icon }) => {
  const leftContent = (
    <Grid container direction="row" wrap="nowrap">
      <Grid item sx={{ marginRight: "15px", paddingTop: "5px" }}>
        <Box sx={{ width: "20px", height: "20px" }}>{icon}</Box>
      </Grid>
      <Grid item>
        <Grid container direction="column" wrap="nowrap">
          <Grid item>
            <Typography variant="page-link__title">{title}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="page-link__subtitle">{subtitle}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const rightContent = (
    <Box
      sx={{
        opacity: "0%",
        padding: "8px",
        backgroundColor: "var(--icon-hover-gray)",
        borderRadius: "50%",
      }}
      className="page-link__forward"
    >
      <Box
        sx={{
          width: "15px",
          height: "15px",
          display: "flex",
        }}
      >
        <GeneralForwardIcon />
      </Box>
    </Box>
  );

  return (
    <ThemeProvider theme={linkButtonTheme}>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          border: "1px solid var(--attachment-border-gray)",
          borderRadius: "8px",
          padding: "10px 15px",

          "&:hover": {
            cursor: "pointer",
            border: "1px solid var(--attachment-border-gray--hover)",
            transition: "border 200ms",
            transition: "background-color 200ms",
            backgroundColor: "var(--card-hover-gray)",
          },
        }}
        id="page-link"
        onClick={handleClick}
      >
        {/* Left-aligned */}
        <Grid item sx={{ marginRight: "15px" }}>
          {leftContent}
        </Grid>
        {/* Right-aligned */}
        <Grid item>{rightContent}</Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default PageLinkButton;
