import { useState, useEffect } from "react";
import { Spinner, BasicError } from "@common/comps";
import { Grid, Box, Button, ThemeProvider } from "@mui/material";
import { buttonTheme } from "@material/themes";
import { GeneralSendIcon } from "@material/icons";

const Submit = ({
  variant,
  buttonLabel,
  handleSubmit,
  submitState,
  gap,
  alignX,
}) => {
  const { isDisabled, isLoading, error } = submitState || {};

  const [minWidth, setMinWidth] = useState(0);
  const [minHeight, setMinHeight] = useState(0);

  useEffect(() => {
    const element = document.getElementById("submit__button-container");
    const width = element ? element.offsetWidth : 0;
    const height = element ? element.offsetHeight : 0;
    setMinWidth(width);
    setMinHeight(height);
  }, []);

  const defaultButton = (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant="filled"
        color="black"
        onClick={handleSubmit}
        disabled={isDisabled}
      >
        {buttonLabel ? buttonLabel : "Submit"}
      </Button>
    </ThemeProvider>
  );

  const publishButton = (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant="icon"
        color="teal"
        onClick={handleSubmit}
        disabled={isDisabled}
        sx={{
          padding: "8px",
          "#general-icon--send": {
            fill: "white",
          },
        }}
      >
        <GeneralSendIcon width={20} height={20} />
      </Button>
    </ThemeProvider>
  );

  const getButton = () => {
    switch (variant) {
      case "publish":
        return publishButton;
      default:
        return defaultButton;
    }
  };

  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      alignItems="center"
      justifyContent={alignX}
      gap={gap ? gap : 0}
    >
      <Grid item>
        <BasicError error={error} />
      </Grid>
      <Grid item>
        <Box
          id="submit__button-container"
          sx={{
            minWidth: minWidth ? minWidth : "",
            minHeight: minHeight ? minHeight : "",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isLoading ? <Spinner /> : getButton()}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Submit;
