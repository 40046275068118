import { useContext } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { MoreHeader, Tab, Loading, BubbleDropdown } from "@common/comps";
import { QuadContext } from "@context";
import { Grid } from "@mui/material";

import { AdminProgramContext, AdminProgramProvider } from "./context";

export const AdminProgramsContainer = ({ children }) => {
  /* Context */
  const { quadId } = useContext(QuadContext);
  const { isInitializing, concOptions, concentration, setConcentration } =
    useContext(AdminProgramContext);

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location || {};

  const isProgramsPage = pathname.split("/")[4] === "programs";
  const isConcentrationsPage =
    isProgramsPage && pathname.split("/")[5] === "concentrations";
  const isCertificationsPage =
    isProgramsPage && pathname.split("/")[5] === "certifications";

  const navBar = (
    <Grid container direction="row" wrap="nowrap" gap={1}>
      <Grid item>
        <Tab
          variant="bubble"
          label="Program"
          isActive={
            isProgramsPage && !isConcentrationsPage && !isCertificationsPage
          }
          handleClick={() => navigate(`/q/${quadId}/admin/programs`)}
        />
      </Grid>
      <Grid item>
        <Tab
          variant="bubble"
          label="Concentration"
          isActive={isConcentrationsPage}
          handleClick={() =>
            navigate(`/q/${quadId}/admin/programs/concentrations`)
          }
        />
      </Grid>
      <Grid item>
        <Tab
          variant="bubble"
          label="Certifications"
          isActive={isCertificationsPage}
          handleClick={() =>
            navigate(`/q/${quadId}/admin/programs/certifications`)
          }
        />
      </Grid>
    </Grid>
  );

  const canRenderChildren =
    !isConcentrationsPage && !isCertificationsPage
      ? Boolean(concentration)
      : true;

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        <MoreHeader
          title="Manage programs"
          enableBack
          backRoute={`/q/${quadId}/admin`}
        />
      </Grid>
      <Grid
        item
        p={2}
        borderTop="1px solid var(--border-gray)"
        borderBottom="1px solid var(--border-gray)"
      >
        {navBar}
      </Grid>
      {isInitializing ? (
        <Loading p={2} />
      ) : (
        <Grid item>
          <Grid container direction="column" wrap="nowrap">
            {isProgramsPage &&
              !isConcentrationsPage &&
              !isCertificationsPage && (
                <Grid item p={2} borderBottom="1px solid var(--border-gray)">
                  <BubbleDropdown
                    variant="object"
                    label="Concentrations"
                    state={concentration}
                    setState={setConcentration}
                    options={concOptions}
                    labelKey="concName"
                    valueKey="concId"
                  />
                </Grid>
              )}
            {canRenderChildren && <Grid item>{children}</Grid>}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export const AdminPrograms = () => {
  return (
    <AdminProgramProvider>
      <AdminProgramsContainer>
        <Outlet />
      </AdminProgramsContainer>
    </AdminProgramProvider>
  );
};
