import { useContext, useState, useEffect, useReducer } from "react";
import { GlobalContext, QuadContext } from "@context";
import { cargo } from "@api";
import { BasicForm, Loading, Dropdown } from "@common/comps";
import { isObject } from "radash";
import { Grid } from "@mui/material";

import { Template, Submitter } from "@tools";

export const SubjectBuilder = ({
  unedited,
  isOpen,
  setIsOpen,
  onCreate = () => {},
  onEdit = () => {},
}) => {
  /* Init */
  const { defaultError } = useContext(GlobalContext);
  const { quadId } = useContext(QuadContext);
  const isEdit = Boolean(unedited);
  const [isInitializing, setIsInitializing] = useState(true);

  /* Resources */
  const [options, setOptions] = useState({});
  const { catOptions } = options || {};

  /* Template */
  const [baseline, setBaseline] = useState(null);
  const [subject, setSubject] = useReducer(Template.createReducer(), null);

  const template = new Template(baseline, subject, setSubject);

  useEffect(() => {
    setIsInitializing(true);
    cargo.get(`/v2/q/${quadId}/r/subject/form`).then((res) => {
      const { ok, payload } = res || {};
      if (ok) {
        setIsInitializing(false);
        setOptions(payload.options);

        /* Templating */
        const initialTemplate = isEdit
          ? Template.superimpose(payload.template, unedited)
          : Template.createEmpty(payload.template);

        if (isEdit) setBaseline(initialTemplate);
        setSubject({ type: "init", value: initialTemplate });
      }
    });
  }, []);

  /* Form section */
  const { subjectId } = unedited || {};
  const [hot, setHot] = useState(false);

  /* Submission */
  const [submitState, setSubmitState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState()
  );

  const submitter = new Submitter(submitState, setSubmitState, defaultError);

  useEffect(() => {
    if (hot) setHot(false);

    if (!hot && isObject(subject)) {
      const preflight = template.preflight();
      const isChanged = template.isChanged();

      submitter.stock(template.crush());
      submitter.green(isEdit ? preflight && isChanged : preflight);
      submitter.resetError();
    }
  }, [subject]);

  const handleCreate = () => {
    template.resetErrors(defaultError);
    submitter.startLoading();
    cargo
      .post(`/v2/q/${quadId}/r/subject`, {
        subject: template.crush(),
      })
      .then((res) => {
        const { ok, isClientError, errors, payload } = res || {};
        if (ok) {
          onCreate(payload);
        } else {
          setHot(true);
          submitter.takeResponse(res);
          if (isClientError) template.setErrors(errors);
        }
      });
  };

  const handleEdit = () => {
    template.resetErrors(defaultError);
    submitter.startLoading();
    cargo
      .put(`/v2/q/${quadId}/r/subject/${subjectId}`, {
        subject: template.crush(),
      })
      .then((res) => {
        const { ok, isClientError, errors, payload } = res || {};
        if (ok) {
          onEdit(payload.edited);
        } else {
          setHot(true);
          submitter.takeResponse(res);
          if (isClientError) template.setErrors(errors);
        }
      });
  };

  const content = isInitializing ? (
    <Loading p={2} />
  ) : (
    isObject(subject) && (
      <Grid container direction="column" wrap="nowrap" gap={1}>
        <Grid item>
          {template.prefab().input({ label: "Subject code", field: "code" })}
        </Grid>
        <Grid item>
          {template.prefab().input({ label: "Subject name", field: "name" })}
        </Grid>
        <Grid item>
          <Dropdown
            variant="object"
            label="Category"
            options={catOptions}
            labelKey="subjectCatName"
            valueKey="subjectCatId"
            state={subject.categoryId.value}
            handleChange={(event) =>
              setSubject({
                field: "categoryId",
                value: event.target.value,
              })
            }
            error={subject.categoryId.error}
            disableObjectSelect
          />
        </Grid>
      </Grid>
    )
  );

  return (
    isOpen && (
      <>
        {isEdit ? (
          <BasicForm
            title="Edit subject"
            isFormOpen={isOpen}
            setIsFormOpen={setIsOpen}
            content={content}
            handleSubmit={handleEdit}
            submitState={{
              isSubmitLoading: submitState.isLoading,
              isSubmitDisabled: submitState.isDisabled,
              submitError: submitState.error,
            }}
          />
        ) : (
          <BasicForm
            title="Add subject"
            isFormOpen={isOpen}
            setIsFormOpen={setIsOpen}
            content={content}
            handleSubmit={handleCreate}
            submitState={{
              isSubmitLoading: submitState.isLoading,
              isSubmitDisabled: submitState.isDisabled,
              submitError: submitState.error,
            }}
          />
        )}
      </>
    )
  );
};
