import { useState, useEffect, useContext } from "react";
import { CourseContext } from "@context";
import { cargo } from "@api";
import { Loading, EmptyLabel } from "@common/comps";
import { Grid, Typography } from "@mui/material";

import { InstructorChoose } from "./InstructorChoose";
import { getFullName } from "./utility";
import { EvaluationFeed } from "./EvaluationFeed";

import "./styles.css";

const Landing = ({ options, setInstructor }) => {
  if (options.length === 0) {
    return <EmptyLabel label="No instructors found." p={2} />;
  } else {
    return (
      <Grid container direction="column">
        {options.map((instr) => (
          <Grid
            item
            key={instr.instrId}
            sx={{
              padding: "15px 20px",
              borderBottom: "1px solid var(--border-gray)",
              "&:hover": {
                cursor: "pointer",
                backgroundColor: "var(--card-hover-gray)",
              },
            }}
            onClick={() => setInstructor(instr)}
          >
            <Typography
              sx={{
                fontSize: "15px",
                fontWeight: "400",
                color: "var(--quad-black)",
                letterSpacing: "0.01em",
              }}
            >
              {getFullName(instr)}
            </Typography>
          </Grid>
        ))}
      </Grid>
    );
  }
};

export const CourseEvaluation = () => {
  const { course } = useContext(CourseContext);
  const { courseId, quad } = course || {};

  const [isInitializing, setIsInitializing] = useState(true);
  const [instrOptions, setInstrOptions] = useState([]);

  const [instructor, setInstructor] = useState(null);

  useEffect(() => {
    setIsInitializing(true);
    cargo.get(`/v2/q/${quad}/course/${courseId}/instructors`).then((res) => {
      const { ok, payload } = res || {};
      if (ok) {
        setIsInitializing(false);
        const { affils = [] } = payload || {};
        setInstrOptions(Array.isArray(affils) ? affils : []);
      }
    });
  }, []);

  const Choose = () => {
    return (
      <InstructorChoose
        options={instrOptions}
        instructor={instructor}
        setInstructor={setInstructor}
      />
    );
  };

  const Feed = () => {
    return <EvaluationFeed instructor={instructor} />;
  };

  const { instrId } = instructor || {};

  if (isInitializing) {
    return <Loading p={2} />;
  } else {
    return (
      <Grid container direction="column" wrap="nowrap">
        <Grid
          item
          sx={{
            padding: "15px",
            borderBottom: "1px solid var(--border-gray)",
          }}
        >
          <Choose />
        </Grid>
        {instrId ? (
          <Feed />
        ) : (
          <Landing
            options={instrOptions}
            instructor={instructor}
            setInstructor={setInstructor}
          />
        )}
      </Grid>
    );
  }
};
