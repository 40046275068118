import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Button, ThemeProvider } from "@mui/material";
import { buttonTheme } from "@material/themes";
import { ButtonCloseIcon, GeneralRecoveryIcon } from "@material/icons";

export const RecoveryContext = createContext();

const initialRecoveryState = {
  email: null,
};

const RecoveryHeader = () => {
  const navigate = useNavigate();

  const closeButton = (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant="icon"
        onClick={(event) => {
          event.stopPropagation();
          navigate(-1);
        }}
      >
        <ButtonCloseIcon width={18} height={18} />
      </Button>
    </ThemeProvider>
  );

  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      justifyContent="space-between"
      alignItems="center"
      p={0.5}
    >
      <Grid item width={0}>
        {closeButton}
      </Grid>
      <Grid item sx={{ display: "flex", position: "relative", top: 2 }}>
        <GeneralRecoveryIcon width={30} height={30} />
      </Grid>
      <Grid item width={0} />
    </Grid>
  );
};

export const RecoveryProvider = ({ children }) => {
  const [page, setPage] = useState("account");
  const [recovery, setRecovery] = useState(initialRecoveryState);

  return (
    <RecoveryContext.Provider
      value={{ RecoveryHeader, page, setPage, recovery, setRecovery }}
    >
      {children}
    </RecoveryContext.Provider>
  );
};

export const useRecovery = () => {
  const context = useContext(RecoveryContext);
  if (!context) {
    return null;
  } else {
    return context;
  }
};
