import { createContext, useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useGlobal } from "@context";
import { useTranche2 } from "@common/hooks";
import { cargo } from "@api";

export const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const { defaultError } = useGlobal();
  const { username: search } = useParams();

  // Init
  const [isInitializing, setIsInitializing] = useState(true);
  const [initError, setInitError] = useState(defaultError);

  // Profile
  const [profile, setProfile] = useState(null);
  const { username } = profile || {};

  // Options
  const [options, setOptions] = useState({});

  useEffect(() => {
    setIsInitializing(true);
    cargo.get(`/v2/u/${search}/profile`).then((res) => {
      const { ok, payload, status, message } = res || {};
      setIsInitializing(false);
      if (ok) {
        setProfile(payload.profile);
        setOptions(payload.options);
      } else {
        setInitError({ isError: true, type: status, errorText: message });
      }
    });
  }, [search]);

  const [quad, setQuad] = useState("");

  const {
    initTranche,
    resetTranche,
    isLoading,
    lastCardRef,
    setFirstId,
    feed,
    setFeed,
    isFeedEmpty,
  } = useTranche2(`/v2/u/${search}/profile/q/${quad.quad}/posts`);

  useEffect(() => {
    if (feed.length > 0) {
      setFirstId(feed[0].maxPostId);
    }
  }, [feed]);

  useEffect(() => {
    if (quad) {
      resetTranche();
    }
  }, [quad]);

  return (
    <ProfileContext.Provider
      value={{
        isInitializing,
        initError,
        profile,
        setProfile,
        username,
        options,
        quad,
        setQuad,
        initTranche,
        isLoading,
        lastCardRef,
        feed,
        setFeed,
        isFeedEmpty,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => {
  const context = useContext(ProfileContext);
  if (!context) {
    return null;
  } else {
    return context;
  }
};
