import { useState, useEffect } from "react";
import { Publisher } from "@tools";
import { Loading, EmptyLabel, FeedNoticeCard } from "@common/comps";
import { useTranche2 } from "@common/hooks";
import { Grid } from "@mui/material";
import { unique } from "radash";

import { EvaluationCard } from "./EvaluationCard";

export const EvaluationFeed = ({ instructor }) => {
  const { instrId, courseId, quad } = instructor || {};

  const [fresh, setFresh] = useState(0);
  const [freshA, setFreshA] = useState(0);

  const {
    initTranche,
    isLoading,
    feed,
    setFeed,
    setFirstId,
    lastCardRef,
    isFeedEmpty,
  } = useTranche2(
    `/v2/q/${quad}/course/${courseId}/evaluations/tranche?by=instrId&id=${instrId}`
  );

  useEffect(() => {
    initTranche();
  }, []);

  useEffect(() => {
    if (feed?.length > 0) {
      setFirstId(feed[0].maxEvalId);
    }
  }, [feed]);

  const uniques = unique(feed, (e) => e.evalId);

  const Create = () => {
    return Publisher.evaluation({
      instructor,
      onPublish: (created) => {
        setFeed((prev) => [created, ...prev]);
        setFresh((prev) => prev + 1);
      },
      onAnonPublish: () => {
        setFreshA((prev) => prev + 1);
      },
    });
  };

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item p={2} borderBottom="1px solid var(--border-gray)">
        <Create />
      </Grid>
      <Grid item>
        {freshA > 0 && (
          <FeedNoticeCard
            label={`${freshA} evaluations submitted for moderation.`}
          />
        )}
      </Grid>
      {isFeedEmpty ? (
        <Grid item>
          <EmptyLabel label="No evaluations made for this instructor." p={2} />
        </Grid>
      ) : (
        uniques.map((e, i) => (
          <Grid
            key={e.evalId}
            className={fresh > 0 && i === 0 ? "card--new" : ""}
          >
            <EvaluationCard
              ref={uniques.length === i + 1 ? lastCardRef : null}
              e={e}
              onDelete={() => {
                setFresh(0);
                setFeed((prev) =>
                  prev.filter((item) => item.evalId !== e.evalId)
                );
              }}
            />
          </Grid>
        ))
      )}
      {isLoading && <Loading p={2} />}
      <Grid item height={200} />
    </Grid>
  );
};
