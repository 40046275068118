import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  ThemeProvider,
} from "@mui/material";
import { formPageTheme } from "@material/themes";

const FormPage = ({ content, footer = null }) => {
  const [isFullScreen, setIsFullScreen] = useState(window.innerWidth < 550);

  const getWindowWidth = () => {
    setIsFullScreen(window.innerWidth < 550);
  };

  window.onresize = getWindowWidth;

  return (
    <ThemeProvider theme={formPageTheme}>
      <Dialog open={true} transitionDuration={0} fullScreen={isFullScreen}>
        <DialogContent>{content}</DialogContent>
        {footer && <DialogTitle>{footer}</DialogTitle>}
      </Dialog>
    </ThemeProvider>
  );
};

export default FormPage;
