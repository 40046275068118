import { createTheme } from "@mui/material";

const basicCheckboxTheme = createTheme({
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          marginLeft: "2px",
          padding: "0px",

          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          paddingTop: "2.5px",
          marginLeft: "8px",
          fontSize: "15px",
          fontWeight: "400",
          color: "rgb(15, 22, 22)",
        },
      },
    },
  },
});

export default basicCheckboxTheme;
