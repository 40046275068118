import { useState, useEffect, useContext } from "react";
import { QuadContext } from "@context";
import { cargo } from "@api";
import { Search, BasicAvatar, Spinner } from "@common/comps";
import { Grid, Box, Typography } from "@mui/material";
import { unique } from "radash";

const User = ({ props }) => {
  const { avatarSrc, name, username } = props || {};

  return (
    <Grid container direction="row" wrap="nowrap" alignItems="center">
      <Grid item mr={1}>
        <BasicAvatar diameter="20px" avatarSrc={avatarSrc} disableClick />
      </Grid>
      <Grid item mr={0.2}>
        <Typography variant="name">{name}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="username">{`/${username}`}</Typography>
      </Grid>
    </Grid>
  );
};

const UserSearch = ({ label, user, setUser }) => {
  const { quadId } = useContext(QuadContext);
  const { username, avatarSrc } = user || {};

  const [search, setSearch] = useState(username ? username : "");

  const [isLoading, setIsLoading] = useState(false);
  const [userOptions, setUserOptions] = useState([]);

  const [selected, setSelected] = useState(Boolean(user));

  useEffect(() => {
    if (selected) {
      setSelected(false);
    } else {
      setUser(null);
    }

    if (search) {
      setIsLoading(true);
      setUserOptions([]);
      cargo
        .get(`/v2/q/${quadId}/u/search?by=username&term=${search}`)
        .then((res) => {
          setIsLoading(false);
          const { ok, payload } = res || {};
          if (ok) setUserOptions(unique(payload, (u) => u.username));
        });
    }
  }, [search]);

  const renderOption = (props, option) => {
    if (isLoading) {
      return (
        <Box key={"editor-add__search__spinner"} px={2} py={1}>
          <Spinner />
        </Box>
      );
    } else {
      return (
        <Box component="li" {...props}>
          <User props={option} />
        </Box>
      );
    }
  };

  return (
    <Search
      elementId="user-search__search"
      label={label ? label : "Find user"}
      search={search}
      setSearch={setSearch}
      options={userOptions}
      getOptionLabel={(option) => option.username}
      renderOption={renderOption}
      handleChange={(event, val) => {
        setSearch(val.username);
        setUser(val);
        setSelected(true);
      }}
      InputProps={{
        ...(user && {
          startAdornment: (
            <Box mx={0.5}>
              <BasicAvatar diameter={20} avatarSrc={avatarSrc} disableClick />
            </Box>
          ),
        }),
      }}
    />
  );
};

export default UserSearch;
