import { useState, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { Submitter } from "@tools";
import { useGlobal } from "@context";
import { cargo } from "@api";
import { getURL } from "@config";
import { Input, FormPage } from "@common/comps";
import { Grid, Typography, Button, ThemeProvider } from "@mui/material";
import { buttonTheme } from "@material/themes";
import { GeneralLogoIcon } from "@material/icons";

export const Login = () => {
  const { defaultError } = useGlobal();
  const navigate = useNavigate();

  // Form data
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");

  // Field errors
  const [loginError, setLoginError] = useState(defaultError);
  const [passwordError, setPasswordError] = useState(defaultError);

  // Submission
  const [submitState, setSubmitState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState()
  );

  const submitter = new Submitter(submitState, setSubmitState, defaultError);

  useEffect(() => {
    setLoginError(defaultError);
    setPasswordError(defaultError);
    submitter.green(login && password);
  }, [login, password]);

  const handleLogin = () => {
    if (login && password) {
      submitter.startLoading();
      cargo.post(`/v2/session`, { username: login, password }).then((res) => {
        const { ok, isClientError, errors = [] } = res || {};
        if (ok) {
          window.location.href = getURL("/");
        } else {
          submitter.takeResponse(res);

          if (isClientError) {
            const errorL = errors.find((elem) => elem.field === "login");
            const errorP = errors.find((elem) => elem.field === "password");
            if (errorL) setLoginError({ isError: true, ...errorL });
            if (errorP) setPasswordError({ isError: true, ...errorP });
          }
        }
      });
    }
  };

  const header = (
    <Grid container direction="column" wrap="nowrap" alignItems="center">
      <Grid item>
        <GeneralLogoIcon width={30} height={30} />
      </Grid>
    </Grid>
  );

  const enrollLink = (
    <Grid container direction="row" wrap="nowrap" gap={0.5}>
      <Grid item>
        <Typography variant="footer">New here?</Typography>
      </Grid>
      <Grid item>
        <Typography
          variant="hyperlink"
          onClick={() => {
            navigate("/enroll");
          }}
        >
          Enroll
        </Typography>
      </Grid>
    </Grid>
  );

  const loginForm = (
    <Grid container direction="column" wrap="nowrap" gap={1}>
      <Grid item>
        <Input
          label="Username or email"
          state={login}
          setState={setLogin}
          error={loginError}
          handleEnterDown={handleLogin}
        />
      </Grid>
      <Grid item>
        <Input
          variant="password"
          label="Password"
          state={password}
          setState={setPassword}
          error={passwordError}
          handleEnterDown={handleLogin}
        />
      </Grid>
    </Grid>
  );

  const content = (
    <Grid container direction="column" wrap="nowrap" gap={3} p={3}>
      <Grid item>{header}</Grid>
      <Grid item mb={3} sx={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="fh1">Login to your Quad</Typography>
      </Grid>
      <Grid item>{loginForm}</Grid>
    </Grid>
  );

  const forgetPasswordButton = (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant="outlined"
        onClick={() => navigate("/recovery")}
        sx={{
          width: "inherit",
          height: 40,
          padding: "9.5px",
          fontSize: "14px",
          color: "var(--quad-teal)",
        }}
      >
        Forgot password?
      </Button>
    </ThemeProvider>
  );

  const footer = (
    <Grid container direction="column" wrap="nowrap" gap={1} px={3} pb={3}>
      <Grid item>
        {Submitter.button({
          variant: "fullWidth",
          buttonLabel: "Login",
          buttonSx: {
            fontSize: "14px",
            height: 40,
          },
          submitState,
          handleClick: handleLogin,
        })}
      </Grid>
      <Grid item sx={{ width: "100%" }}>
        {forgetPasswordButton}
      </Grid>
      <Grid item px={1}>
        {enrollLink}
      </Grid>
    </Grid>
  );

  return <FormPage content={content} footer={footer} />;
};
