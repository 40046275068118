import { useContext, useState, useEffect, cloneElement } from "react";
import { cargo } from "@api";
import { QuadContext } from "@context";
import { Loadman, Selectable, Loading, Dropdown } from "@common/comps";
import { Grid, Box } from "@mui/material";
import { unique } from "radash";

const CourseSearch = ({ subject, state, setState, omissions = [] }) => {
  const { quadId } = useContext(QuadContext);
  const { subjectId } = subject || {};

  const [isLoading, setIsLoading] = useState(false);
  const [courseOptions, setCourseOptions] = useState([]);

  const { courseId } = state || {};

  useEffect(() => {
    if (subjectId) {
      setIsLoading(true);
      cargo
        .get(`/v2/q/${quadId}/course/all?subjectId=${subjectId}`)
        .then((res) => {
          setIsLoading(false);
          const { ok, payload = [] } = res || {};
          if (ok)
            setCourseOptions(
              unique(payload, (c) => c.courseId).filter(
                (c) => !omissions.includes(c.courseId)
              )
            );
        });
    }
  }, [subject]);

  if (subjectId) {
    return (
      <Box px={0.5} pb={2}>
        {isLoading || courseOptions.length === 0 ? (
          <Loadman
            variant="standard"
            pt={1}
            isLoading={isLoading}
            isEmpty={courseOptions.length === 0}
          />
        ) : (
          <Grid container direction="column" wrap="nowrap">
            {courseOptions.map((c, i) => (
              <Grid
                item
                key={c.courseId}
                borderTop={i === 0 && "1px solid var(--border-gray)"}
                borderBottom="1px solid var(--border-gray)"
              >
                <Selectable
                  isSelected={courseId === c.courseId}
                  handleClick={() => setState(courseId === c.courseId ? "" : c)}
                  label={`${c.subjectCode} ${c.courseCode}`}
                  subLabel={c.courseTitle}
                  containerProps={{ px: 0.5, py: 1.5 }}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    );
  } else {
    return <></>;
  }
};

const SubjectProvider = ({ children }) => {
  const { quadId } = useContext(QuadContext);

  const [isInitializing, setIsInitializing] = useState(true);
  const [subjectOptions, setSubjectOptions] = useState([]);

  const [subject, setSubject] = useState("");

  useEffect(() => {
    setIsInitializing(true);
    cargo.get(`/v2/q/${quadId}/subject/all`).then((res) => {
      const { ok, payload } = res || {};
      if (ok) {
        setIsInitializing(false);
        setSubjectOptions(payload.subjects);
      }
    });
  }, []);

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid
        item
        height={56}
        mb={2}
        sx={{ display: "flex", alignItems: "center" }}
      >
        {isInitializing ? (
          <Loading />
        ) : (
          <Dropdown
            variant="object"
            label="Choose subject"
            options={subjectOptions}
            labelKey="subjectName"
            valueKey="subjectId"
            state={subject}
            setState={setSubject}
          />
        )}
      </Grid>
      <Grid item>{cloneElement(children, { subject })}</Grid>
    </Grid>
  );
};

const Router = (props) => {
  const isSubjectSupplied = Object.keys(props).includes("subject");

  if (isSubjectSupplied) {
    return <CourseSearch {...props} />;
  } else {
    return (
      <SubjectProvider>
        <CourseSearch {...props} />
      </SubjectProvider>
    );
  }
};

export default Router;
