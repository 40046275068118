import { mapEntries, assign, pick } from "radash";

import { Preflight } from "./Preflight";
import { input, numberInput, checkbox } from "./prefab";

const initValue = (value) => {
  switch (typeof value) {
    case "boolean":
      return Boolean(value === true || value === false) ? value : false;
    default:
      return value === null || value === undefined ? "" : String(value);
  }
};

class Template {
  constructor(baseline, template, setTemplate) {
    this.baseline = baseline;
    this.template = template;
    this.setTemplate = setTemplate;
  }
  static createReducer() {
    return (state, action) => {
      const { type, field, key, value } = action || {};
      switch (type) {
        case "init":
          return action.value;
        case "sweep":
          return mapEntries(state, (k, v) => [k, assign(v, { [key]: value })]);
        default:
          return mapEntries(state, (k, v) => [
            k,
            k === field ? assign(v, { [key || "value"]: value }) : v,
          ]);
      }
    };
  }
  static createEmpty(template) {
    return mapEntries(template, (k, v) => [
      k,
      assign(v, { value: initValue(v.value) }),
    ]);
  }
  static superimpose(template, object) {
    // This method assigns values to a template
    const values = pick(
      object,
      Object.keys(template).map((v) => template[v].name)
    );

    return mapEntries(template, (k, v) => {
      const initialized = initValue(values[v.name]);
      return [k, assign(v, { value: initialized })];
    });
  }
  preflight() {
    // Run preflight checks on data

    const modified = mapEntries(this.template, (k, v) => {
      const { requiredIf } = v || {};

      if (requiredIf) {
        const [requiredKey, requiredValue] = requiredIf;

        return [
          k,
          assign(v, {
            required:
              Boolean(this.template[requiredKey].value) === requiredValue,
          }),
        ];
      } else {
        return [k, v];
      }
    }); // Apply conditionals

    const checkList = mapEntries(modified, (k, v) => {
      const { type, oneOf, value, required } = v || {};

      if (oneOf) return [k, required ? oneOf.includes(value) : true];

      switch (type) {
        case "string":
          return [k, Preflight.string(v)];
        case "number":
          return [k, Preflight.number(v)];
        case "boolean":
          return [k, Preflight.boolean(v)];
        default:
          return [k, true]; // True for undefined checks
      }
    });

    return !Object.values(checkList).includes(false);
  }
  isChanged() {
    const objectA = mapEntries(this.baseline, (k, v) => [k, v.value]);
    const objectB = mapEntries(this.template, (k, v) => [k, v.value]);

    return JSON.stringify(objectA) !== JSON.stringify(objectB);
  }
  crush() {
    return mapEntries(this.template, (k, v) => [
      k,
      v.value === "" ? null : v.value,
    ]);
  }
  setErrors(errors = []) {
    const fieldErrors = errors.filter((e) => e.type === "field");

    fieldErrors.forEach((f) => {
      this.setTemplate({
        field: f.field,
        key: "error",
        value: { isError: true, errorText: f.errorText },
      });
    });
  }
  resetErrors(defaultError) {
    this.setTemplate({ type: "sweep", key: "error", value: defaultError });
  }
  prefab() {
    return new Prefab(this.baseline, this.template, this.setTemplate);
  }
}

class Prefab extends Template {
  constructor(...args) {
    super(...args);
  }
}

Prefab.prototype.input = input;
Prefab.prototype.numberInput = numberInput;
Prefab.prototype.checkbox = checkbox;

export { Template };
export default Template;
