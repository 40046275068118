import { useEffect } from "react";
import { PostProvider, usePost, useTimeline } from "@context";
import { Synchronizer } from "@tools";
import { Loading, PageErrorNotice, BasicHeader } from "@common/comps";
import { Grid, ThemeProvider } from "@mui/material";
import { postTheme } from "@material/themes";

import { PostHero } from "./comps/PostHero";

import { CommentSection } from "features/comment";

const PostLanding = () => {
  const postContext = usePost();
  const { post: { postId, quad } = {}, setPost } = postContext || {};
  const timelineContext = useTimeline();
  const { setFeed: setTimelineFeed } = timelineContext || {};

  const onCommentCreate = () => {
    setPost &&
      setPost((prev) => {
        const { childCount } = prev || {};
        const newChildCount = childCount + 1;
        Synchronizer.updatePostFeed(
          { ...prev, childCount: newChildCount },
          setTimelineFeed
        );
        return { ...prev, childCount: newChildCount };
      });
  };

  const onCommentDelete = (deletedId) => {
    setPost &&
      setPost((prev) => {
        const { childCount } = prev || {};
        const newChildCount = childCount - 1;
        Synchronizer.updatePostFeed(
          { ...prev, childCount: newChildCount > 0 ? newChildCount : 0 },
          setTimelineFeed
        );
        return { ...prev, childCount: newChildCount > 0 ? newChildCount : 0 };
      });
  };

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        <PostHero />
      </Grid>
      <Grid item>
        <CommentSection
          post={{ postId, quad }}
          onCommentCreate={onCommentCreate}
          onCommentDelete={onCommentDelete}
        />
      </Grid>
    </Grid>
  );
};

const PostContainer = () => {
  const { isInitializing, post } = usePost();

  const landing = post ? (
    <PostLanding />
  ) : (
    <PageErrorNotice
      type={404}
      title="Not found"
      subtitle="Could not find the post."
      disableHeader
    />
  );

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      sx={{ minHeight: "100vh" }}
    >
      <Grid item sx={{ position: "sticky", top: 0, zIndex: 100 }}>
        <BasicHeader title="Thread" enableBack />
      </Grid>
      <Grid item>{isInitializing ? <Loading p={2} /> : landing}</Grid>
    </Grid>
  );
};

export const Post = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <ThemeProvider theme={postTheme}>
      <PostProvider>
        <PostContainer />
      </PostProvider>
    </ThemeProvider>
  );
};
