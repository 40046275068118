import { useContext, useState, useEffect } from "react";
import { cargo } from "@api";
import { Opener, Builder } from "@tools";
import { QuadContext } from "@context";
import {
  Editable,
  Expandable,
  Loadman,
  PopperOptions,
  EmptyLabel,
} from "@common/comps";
import { Grid, Box } from "@mui/material";
import { PopperCourseIcon, PopperEditIcon } from "@material/icons";

import { PreqContext } from "../context";

const Area = ({ a, reinitialize }) => {
  const { areaName, electives } = a || {};
  const { courses } = electives || {};

  const isEmpty = courses.length === 0;

  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isEditCoursesOpen, setIsEditCoursesOpen] = useState(false);

  const menuItemsList = [
    {
      key: "admin-preq-area__edit",
      label: "Edit area",
      icon: <PopperEditIcon />,
      handleClick: (event) => {
        event.stopPropagation();
        setIsEditOpen(true);
      },
    },
    {
      key: "admin-preq-area__edit-electives",
      label: "Edit courses",
      icon: <PopperCourseIcon />,
      handleClick: (event) => {
        event.stopPropagation();
        setIsEditCoursesOpen(true);
      },
    },
  ];

  const endComponent = (
    <>
      <PopperOptions menuItemsList={menuItemsList} />
      {isEditOpen &&
        Builder.preqArea({
          unedited: a,
          isOpen: isEditOpen,
          setIsOpen: setIsEditOpen,
          onEdit: (edited) => {
            setIsEditOpen(false);
            reinitialize();
          },
          onDelete: () => {
            reinitialize();
          },
        })}
      {isEditCoursesOpen &&
        Builder.preqElectives({
          unedited: electives,
          isOpen: isEditCoursesOpen,
          setIsOpen: setIsEditCoursesOpen,
          onEdit: (edited) => {
            setIsEditCoursesOpen(false);
            reinitialize();
          },
          onDelete: () => {
            reinitialize();
          },
        })}
    </>
  );

  const childComponent = (
    <>
      <Grid container direction="column" wrap="nowrap" p={2} pt={0}>
        {isEmpty ? (
          <EmptyLabel label="No courses" />
        ) : (
          courses.map((c, i) => (
            <Grid item key={c.courseId}>
              <Box
                p={1}
                borderTop={i === 0 && "1px solid var(--border-gray)"}
                borderBottom="1px solid var(--border-gray)"
              >
                <Editable
                  label={`${c.subjectCode} ${c.courseCode}`}
                  subLabel={c.courseTitle}
                />
              </Box>
            </Grid>
          ))
        )}
      </Grid>
    </>
  );

  return (
    <Expandable
      label={areaName}
      labelSx={{ fontSize: "15px", textUnderlineOffset: "0.8em" }}
      unlinkHoverTransition
      endComponent={endComponent}
      child={childComponent}
    />
  );
};

export const GroupAreasManager = () => {
  const { quadId } = useContext(QuadContext);
  const { group } = useContext(PreqContext);
  const { preqGroupId } = group || {};

  const [isLoading, setIsLoading] = useState(true);
  const [feed, setFeed] = useState([]);

  const isEmpty = feed.length === 0;
  const noFeed = isLoading || isEmpty;

  const fetchFeed = () => {
    setIsLoading(true);
    cargo
      .get(`/v2/q/${quadId}/program/requirement/group/${preqGroupId}/areas`)
      .then((res) => {
        const { ok, payload } = res || {};

        if (ok) {
          setIsLoading(false);
          setFeed(payload.areas);
        }
      });
  };

  useEffect(() => {
    fetchFeed();
  }, []);

  const Create = () => (
    <Opener
      variant="filled"
      color="black"
      label="Create area"
      builder={Builder.preqArea({
        preqGroupId,
        onCreate: fetchFeed,
      })}
    />
  );

  return noFeed ? (
    <Loadman
      isLoading={isLoading}
      isEmpty={isEmpty}
      emptyLabel="No options added."
      p={2}
    />
  ) : (
    <Grid container direction="column" wrap="nowrap">
      <Grid item p={2} borderBottom="1px solid var(--border-gray)">
        <Create />
      </Grid>
      {feed.map((a) => (
        <Grid item key={a.preqAreaId}>
          <Area a={a} reinitialize={fetchFeed} />
        </Grid>
      ))}
    </Grid>
  );
};
