import { useState, useEffect, useReducer } from "react";
import { cargo } from "@api";
import { useGlobal, useRecovery } from "@context";
import { Submitter } from "@tools";
import { Box, Grid, Typography } from "@mui/material";
import { FormPage, BasicCheckbox, Input } from "@common/comps";

export const NewPasswordPage = ({ onComplete = () => {} }) => {
  const { defaultError } = useGlobal();
  const {
    recovery: { email },
    RecoveryHeader,
  } = useRecovery();

  const [isRevealed, setIsRevealed] = useState(false);

  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");

  const [passwordError, setPasswordError] = useState(defaultError);
  const [confirmError, setConfirmError] = useState(defaultError);

  /* Submission */
  const [submitState, setSubmitState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState()
  );

  const submitter = new Submitter(submitState, setSubmitState, defaultError);

  useEffect(() => {
    setPasswordError(defaultError);
    setConfirmError(defaultError);
    const dataCheck = password && confirm && password === confirm;
    submitter.green(dataCheck);
    submitter.resetError();
  }, [password, confirm]);

  const handleSubmit = () => {
    submitter.startLoading();
    cargo
      .put(`/v2/recovery/password/${email}`, { password, confirm })
      .then((res) => {
        const { ok, isClientError, errors, status } = res || {};
        if (ok) {
          onComplete();
        } else {
          submitter.takeResponse(res);
          if (isClientError) {
            const error = errors[0];
            setPasswordError({
              isError: true,
              errorText: error.errorText,
              type: status,
            });
            setConfirmError({
              isError: true,
              errorText: error.errorText,
              type: status,
            });
          }
        }
      });
  };

  const content = (
    <Grid container direction="column" wrap="nowrap" minHeight={400}>
      <Grid item>
        <RecoveryHeader />
      </Grid>
      <Grid
        item
        py={5}
        sx={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        <Typography variant="fh1">Create password</Typography>
      </Grid>
      <Grid item px={2}>
        <Grid container direction="column" wrap="nowrap" gap={1}>
          <Grid item>
            <Input
              label="Password"
              variant="password"
              isHidden={!isRevealed}
              state={password}
              setState={setPassword}
              error={passwordError}
            />
          </Grid>
          <Grid item mb={1}>
            <Input
              label="Confirm"
              variant="password"
              isHidden={!isRevealed}
              state={confirm}
              setState={setConfirm}
              error={confirmError}
            />
          </Grid>
          <Grid item px={1.5}>
            <BasicCheckbox
              label="Show password"
              checked={isRevealed}
              setChecked={setIsRevealed}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const footer = (
    <Box p={2} sx={{ width: "100%" }}>
      {Submitter.button({
        variant: "fullWidth",
        buttonSx: {
          fontSize: "15px",
          py: 1.25,
        },
        submitState,
        handleClick: handleSubmit,
      })}
    </Box>
  );

  return <FormPage content={content} footer={footer} />;
};
