import { useContext, useState, useEffect } from "react";
import { GlobalContext, ProgramContext } from "@context";
import { cargo } from "@api";
import { BasicForm, YearsPairSelect, BasicCheckbox } from "@common/comps";
import { Grid } from "@mui/material";

export const ProgramCollect = ({ onCollect, isFormOpen, setIsFormOpen }) => {
  const { defaultError } = useContext(GlobalContext);
  const { program } = useContext(ProgramContext);
  const { programId, quad } = program || {};

  // Form data
  const [startYear, setStartYear] = useState("");
  const [endYear, setEndYear] = useState("");
  const [isCurrent, setIsCurrent] = useState(false);

  // Submission
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [submitError, setSubmitError] = useState(defaultError);

  const endYearCheck = isCurrent ? true : Boolean(endYear);
  const isCurrentCheck = isCurrent === false || isCurrent === true;

  const goodForCollect = Boolean(
    programId && startYear && endYearCheck && isCurrentCheck
  );

  useEffect(() => {
    setIsSubmitDisabled(!goodForCollect);
    setSubmitError(defaultError);
    if (isCurrent) setEndYear("");
  }, [startYear, endYear, isCurrent]);

  const handleSubmit = (event) => {
    event.stopPropagation();
    if (goodForCollect) {
      setIsSubmitLoading(true);
      cargo
        .post(`/v2/q/${quad}/program/collection`, {
          program: {
            programId,
            startYear,
            endYear,
            isCurrent,
          },
        })
        .then((res) => {
          const { ok, status, message } = res || {};
          if (ok) {
            onCollect();
          } else {
            setIsSubmitLoading(false);
            setIsSubmitDisabled(true);
            setSubmitError({
              isError: true,
              errorText: message,
              type: status,
            });
          }
        });
    } else {
      setIsSubmitDisabled(true);
      setSubmitError({
        isError: true,
        type: 400,
      });
    }
  };

  const formContent = (
    <Grid container direction="column" wrap="nowrap">
      <Grid item sx={{ width: "100%", marginBottom: "5px" }}>
        <YearsPairSelect
          startYear={startYear}
          setStartYear={setStartYear}
          endYear={endYear}
          setEndYear={setEndYear}
          isCurrent={isCurrent}
        />
      </Grid>
      <Grid
        item
        sx={{
          marginLeft: "15px",
          padding: "5px 0px",
        }}
      >
        <BasicCheckbox
          label="I currently go here"
          checked={isCurrent}
          setChecked={setIsCurrent}
          setState={setEndYear}
          value=""
        />
      </Grid>
    </Grid>
  );

  return (
    isFormOpen && (
      <BasicForm
        title="Collect program"
        isFormOpen={isFormOpen}
        setIsFormOpen={setIsFormOpen}
        content={formContent}
        handleSubmit={handleSubmit}
        submitState={{
          isSubmitDisabled,
          isSubmitLoading,
          submitError,
        }}
      />
    )
  );
};
