import { useContext } from "react";
import { GlobalContext } from "@context";
import { SettingsCard } from "@common/comps";
import { Grid } from "@mui/material";

export const SettingsGeneral = () => {
  const { session } = useContext(GlobalContext);
  const { sessOwner, sessOwnerEmail, defaultQuad } = session || {};

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        <SettingsCard
          label="Username"
          subLabel={`/${sessOwner}`}
          route={"/settings/username"}
        />
      </Grid>
      <Grid item>
        <SettingsCard
          label="Email"
          subLabel={`${sessOwnerEmail}`}
          route={"/settings/email"}
        />
      </Grid>
      <Grid item>
        <SettingsCard
          label="Home Quad"
          subLabel={`q/${defaultQuad}`}
          route={"/settings/default-quad"}
        />
      </Grid>
    </Grid>
  );
};
