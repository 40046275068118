import { useContext, useEffect } from "react";
import { QuadContext } from "@context";
import { EmptyLabel, MoreHeader, Loading } from "@common/comps";
import { useTranche2 } from "@common/hooks";
import { Grid } from "@mui/material";
import { unique } from "radash";

import { EditorCard } from "./EditorCard";
import { EditorAdd } from "./EditorAdd";

const EditorsFeed = () => {
  const { quadId } = useContext(QuadContext);

  const {
    initTranche,
    isLoading,
    feed,
    setFeed,
    isFeedEmpty,
    fresh,
    setFresh,
    setFirstId,
    lastCardRef,
  } = useTranche2(`/v2/q/${quadId}/e/editor/tranche`);

  useEffect(() => initTranche(), []);

  useEffect(() => {
    if (feed.length > 0) {
      setFirstId(feed[0].maxAffilId);
    }
  }, [feed]);

  const uniques = unique(feed, (e) => e.affilId);

  const editorsFeed = uniques.map((editor, i) => (
    <Grid
      item
      key={editor.affilId}
      className={fresh > 0 && i === 0 ? "card--new" : ""}
    >
      <EditorCard
        ref={uniques.length === i + 1 ? lastCardRef : null}
        editor={editor}
        setFeed={setFeed}
        setFresh={setFresh}
      />
    </Grid>
  ));

  const Add = () => {
    return (
      <EditorAdd
        onCreate={(created) => {
          setFresh((prev) => prev + 1);
          created && setFeed((prev) => [created, ...prev]);
        }}
      />
    );
  };

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item borderBottom="1px solid var(--border-gray)" p={2}>
        <Add />
      </Grid>
      {isFeedEmpty ? (
        <EmptyLabel label="Editors have not been appointed" p={2} />
      ) : (
        editorsFeed
      )}
      {isLoading && <Loading p={2} />}
      <Grid item height={200} />
    </Grid>
  );
};

export const AdminEditors = () => {
  const { quadId } = useContext(QuadContext);

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        <MoreHeader
          title="Manage editors"
          enableBack
          backRoute={`/q/${quadId}/admin`}
        />
      </Grid>
      <Grid item>
        <EditorsFeed />
      </Grid>
    </Grid>
  );
};
