import { Grid, Box, Typography } from "@mui/material";
import {
  ErrorGeneralIcon,
  ErrorForbiddenIcon,
  ErrorConflictIcon,
  ErrorServerIcon,
  ErrorDataIcon,
  ErrorUnauthorizedIcon,
  Error404Icon,
} from "@material/icons";

const ErrorIcon = (type) => {
  switch (Number(type)) {
    case 400:
      return <ErrorDataIcon />;
    case 401:
      return <ErrorUnauthorizedIcon />;
    case 403:
      return <ErrorForbiddenIcon />;
    case 404:
      return <Error404Icon />;
    case 409:
      return <ErrorConflictIcon />;
    case 500:
      return <ErrorServerIcon />;
    default:
      return <ErrorGeneralIcon />;
  }
};

const Error = ({ error, labelProps, hideLabel }) => {
  const { isError, type, errorText } = error || {};

  if (isError) {
    return (
      <Grid container direction="row" wrap="nowrap" alignItems="center" gap={1}>
        <Grid item>
          <Box
            sx={{
              display: "flex",
              width: "22px",
              height: "22px",

              ".error-icon": { fill: "var(--quad-red)" },
            }}
          >
            <ErrorIcon type={type} />
          </Box>
        </Grid>
        {errorText && !hideLabel && (
          <Grid item>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: "var(--quad-red)",
                letterSpacing: "0.02em",
              }}
              {...labelProps}
            >
              {errorText}
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  } else {
    return <></>;
  }
};

export default Error;
