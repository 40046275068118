import { useState, useReducer } from "react";
import { cargo } from "@api";
import { Opener, Builder } from "@tools";
import { SearchBar, Spinner, EmptyLabel, Editable, Tab } from "@common/comps";
import { intReducer } from "@common/reducers";
import { Grid, Box, Typography } from "@mui/material";
import { unique } from "radash";

const Feed = ({ feed, onEdit }) => {
  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item borderBottom="1px solid var(--border-gray)" />
      {feed.map((f, i) => (
        <Grid
          item
          key={f.fieldId}
          borderBottom="1px solid var(--border-gray)"
          p={2}
          py={1.5}
        >
          <Editable
            variant="vanilla"
            label={f.fieldName}
            subLabel={
              f.naics || (
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "var(--quad-gray-stealth)",
                    letterSpacing: "0.02em",
                  }}
                >
                  N/A
                </Typography>
              )
            }
            endComponent={
              <Opener
                variant="outlined"
                label="Edit"
                builder={Builder.field({
                  unedited: f,
                  onEdit,
                })}
              />
            }
          />
        </Grid>
      ))}
    </Grid>
  );
};

export const RootFields = () => {
  const [isInitialized, setIsInitialized] = useState(false);

  const [type, setType] = useState("name");

  const [search, setSearch] = useState("");
  const [searchNaics, setSearchNaics] = useReducer(intReducer, "");

  const [isLoading, setIsLoading] = useState(false);
  const [feed, setFeed] = useState([]);

  const getSearchProps = () => {
    switch (type) {
      case "naics":
        return { searchTerm: searchNaics, searchType: "naics" };
      default:
        return { searchTerm: search, searchType: "name" };
    }
  };

  const handleSearch = () => {
    if (!isInitialized) setIsInitialized(true);

    if ((type === "name" && search) || (type === "naics" && searchNaics)) {
      setIsLoading(true);
      const searchParams = getSearchProps();
      cargo
        .get(
          `/v2/fields?type=${searchParams.searchType}&term=${searchParams.searchTerm}`
        )
        .then((res) => {
          setIsLoading(false);
          const { ok, payload } = res || {};
          if (ok) {
            const uniques = unique(payload, (f) => f.fieldId);
            const N = uniques.length;
            setFeed(uniques.slice(0, N > 50 ? 50 : N));
          }
        });
    }
  };

  const nav = (
    <Grid container direction="row" wrap="nowrap" gap={1}>
      <Grid item>
        <Tab
          variant="bubble"
          label="By name"
          isActive={type === "name"}
          handleClick={() => type !== "name" && setType("name")}
        />
      </Grid>
      <Grid item>
        <Tab
          variant="bubble"
          label="By NAICS"
          isActive={type === "naics"}
          handleClick={() => type !== "naics" && setType("naics")}
        />
      </Grid>
    </Grid>
  );

  const makeSearchBar = () => {
    switch (type) {
      case "naics":
        return (
          <SearchBar
            label="Search by NAICS"
            search={searchNaics}
            setSearch={setSearchNaics}
            handleSearch={handleSearch}
          />
        );
      default:
        return (
          <SearchBar
            label="Search by name"
            search={search}
            setSearch={setSearch}
            handleSearch={handleSearch}
          />
        );
    }
  };

  const Crud = () => (
    <Opener
      variant="filled"
      color="black"
      label="Create field"
      builder={Builder.field({
        onCreate: handleSearch,
      })}
    />
  );

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item p={2} borderBottom="1px solid var(--border-gray)">
        <Crud />
      </Grid>
      <Grid item p={2} borderBottom="1px solid var(--border-gray)">
        {nav}
      </Grid>
      <Grid item p={2}>
        {makeSearchBar()}
      </Grid>
      <Grid item>
        {isInitialized && (isLoading || feed.length === 0) ? (
          <Box px={2.5} py={1.5}>
            {isLoading ? <Spinner /> : <EmptyLabel label="No fields found." />}
          </Box>
        ) : (
          <Feed feed={feed} onEdit={handleSearch} />
        )}
      </Grid>
    </Grid>
  );
};
