import { useState, useEffect, useRef, useCallback, useContext } from "react";
import { cargo } from "@api";
import { GlobalContext } from "@context";

const useTranche2 = (url, params, deps = []) => {
  const { defaultError } = useContext(GlobalContext);
  const [tranche, setTranche] = useState(0);
  const [firstId, setFirstId] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [feed, setFeed] = useState([]);
  const [feedError, setFeedError] = useState(defaultError);
  const [fresh, setFresh] = useState(0);
  const [hasNextTranche, setHasNextTranche] = useState(false);

  const [trigger, setTrigger] = useState(false);

  const initTranche = () => {
    setTranche(1);
  };

  const resetTranche = () => {
    setFeed([]);
    setTrigger((prev) => !prev);
    setTranche(1);
    setFirstId("");
    setHasNextTranche(true);
  };

  useEffect(() => {
    if (tranche > 0) {
      // Resetting
      setIsLoading(true);

      // Abort when function unmounts
      const controller = new AbortController();
      const { signal } = controller;

      cargo
        .post(url, { firstId, tranche, ...params }, { signal })
        .then((res) => {
          const { ok, payload = [] } = res || {};
          if (ok) {
            setFeed((prev) => [...prev, ...payload]);
            setIsLoading(false);
            setHasNextTranche(Boolean(payload.length === 30));
          } else {
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          if (signal.aborted) return;
          setFeedError({
            isError: true,
            errorText: err.message,
          });
        });

      return () => controller.abort();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [tranche, trigger, ...deps]);

  const intObserver = useRef();

  const lastCardRef = useCallback(
    (card) => {
      if (isLoading) return;

      if (intObserver.current) intObserver.current.disconnect();

      intObserver.current = new IntersectionObserver(
        (cards) => {
          if (cards[0].isIntersecting && hasNextTranche) {
            setTranche((prev) => prev + 1);
          }
        },
        { threshold: 1.0 }
      );

      if (card) intObserver.current.observe(card);
    },
    [isLoading, hasNextTranche]
  );

  return {
    initTranche,
    resetTranche,
    isLoading,
    lastCardRef,
    setFirstId,
    feed,
    setFeed,
    fresh,
    setFresh,
    isFeedEmpty: !hasNextTranche && !isLoading && feed.length === 0,
    feedError,
  };
};

export default useTranche2;
