import { useState, useEffect } from "react";
import { Ballot, Scoreman } from "@tools";
import { BasicAvatar, Author, Description, Action } from "@common/comps";
import { Grid, Box, ThemeProvider } from "@mui/material";
import { cardTheme } from "@material/themes";

import { SubreplyOptions } from "../subreplyOptions/SubreplyOptions";
import { ActionActivityIcon } from "@material/icons";

export const SubreplyCard = ({ subreply, onDelete }) => {
  const [subreplyData, setSubreplyData] = useState(subreply);
  const [isDeleted, setIsDeleted] = useState(false);

  const {
    subreplyId,
    quad,
    scoreUpTotal,
    scoreDownTotal,
    totalScore,
    sessOwnerScore,
    username,
    avatarSrc,
    text,
  } = subreplyData || {};

  /* States for activityScore updates */
  const [baseSessOwnerScore, setBaseSessOwnerScore] = useState(sessOwnerScore);
  const [baseActivityScore, setBaseActivityScore] = useState(
    scoreUpTotal + scoreDownTotal
  );
  const [activityScore, setActivityScore] = useState(baseActivityScore);

  useEffect(() => {
    setTimeout(() => {
      if (isDeleted) onDelete();
    }, 200);
  }, [isDeleted]);

  const avatar = (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      alignItems="center"
      sx={{ width: "45px" }}
    >
      <Grid item>
        <BasicAvatar
          username={username}
          avatarSrc={avatarSrc}
          diameter="35px"
        />
      </Grid>
      <Grid item py={0.5} sx={{ height: "100%" }}>
        <Box
          sx={{
            width: "2px",
            height: "100%",
            borderRadius: "9999px",
            backgroundColor: "var(--avatar-link-gray)",
          }}
        />
      </Grid>
    </Grid>
  );

  const onVote = (action) => {
    const { type } = action || {};
    if (type) {
      const newScores = Scoreman.getNewScores({
        ...action,
        base: subreplyData,
      });
      setSubreplyData((prev) => {
        return { ...prev, ...newScores };
      });
    }
  };

  const updateActivityScore = (action) => {
    setActivityScore((prev) => {
      const newActivityScore = Scoreman.getNewActivityScore({
        ...action,
        baseSessOwnerScore,
        baseScore: baseActivityScore,
      });

      return newActivityScore;
    });
  };

  const Vote = () => (
    <Ballot
      type="subreply"
      id={subreplyId}
      quad={quad}
      totalScore={totalScore}
      sessOwnerScore={sessOwnerScore}
      onVote={onVote}
      updateActivityScore={updateActivityScore}
    />
  );

  const Activity = () => (
    <Action
      variant="simple"
      icon={<ActionActivityIcon />}
      helperText={String(activityScore)}
    />
  );

  const actions = (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      sx={{ position: "relative", right: 8 }}
    >
      <Grid item>
        <Vote />
      </Grid>
    </Grid>
  );

  const content = (
    <Grid container direction="column" wrap="nowrap" gap={0.5}>
      <Grid item>
        <Author data={subreply} clickableName />
      </Grid>
      <Grid item>
        <Description text={text} />
      </Grid>
      <Grid item>
        <Grid container direction="row" wrap="nowrap">
          <Grid item>{actions}</Grid>
          <Grid item>
            <Activity />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <ThemeProvider theme={cardTheme}>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        gap={2}
        className={isDeleted ? "card--empty" : ""}
      >
        {!isDeleted && (
          <>
            <Grid item sx={{ display: "flex" }}>
              {avatar}
            </Grid>
            <Grid item mb={1} sx={{ width: "100%" }}>
              {content}
            </Grid>
            <Grid item sx={{ width: "0px", position: "relative", right: 30 }}>
              <SubreplyOptions
                subreply={subreply}
                onDelete={() => setIsDeleted(true)}
              />
            </Grid>
          </>
        )}
      </Grid>
    </ThemeProvider>
  );
};
