import { createTheme } from "@mui/material";

export const courseRequisiteTheme = createTheme({
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: "title" },
          style: {
            fontSize: "15px",
            fontWeight: "600",
            fontFamily: "Inter",
          },
        },
      ],
    },
  },
});
