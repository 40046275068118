import { forwardRef, useState, useEffect } from "react";
import { Ballot, Scoreman } from "@tools";
import { BasicAvatar, Author, Description, Action } from "@common/comps";
import { Grid, Button, ThemeProvider } from "@mui/material";
import { cardTheme, buttonTheme } from "@material/themes";
import { ActionActivityIcon, ActionReplyIcon } from "@material/icons";

import { CommentOptions } from "../commentOptions/CommentOptions";

import { ReplySection } from "features/reply";

export const CommentCard = forwardRef(({ comment, onDelete }, ref) => {
  const [commentData, setCommentData] = useState(comment);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const {
    commentId,
    quad,
    scoreUpTotal,
    scoreDownTotal,
    totalScore,
    sessOwnerScore,
    childCount,
    username,
    avatarSrc,
    text,
  } = commentData || {};

  const [replyCount, setReplyCount] = useState(childCount);

  /* Required for activityScore updates */
  const [baseSessOwnerScore, setBaseSessOwnerScore] = useState(sessOwnerScore);
  const [baseActivityScore, setBaseActivityScore] = useState(
    scoreUpTotal + scoreDownTotal
  );
  const [activityScore, setActivityScore] = useState(baseActivityScore);

  useEffect(() => {
    setTimeout(() => {
      if (isDeleted) onDelete();
    }, 200);
  }, [isDeleted]);

  const loadButton = (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant="outlined"
        onClick={() => setIsExpanded(true)}
        sx={{ padding: "2px 12px", fontSize: "13px", fontWeight: "700" }}
      >
        Load replies
      </Button>
    </ThemeProvider>
  );

  const onVote = (action) => {
    const { type } = action || {};
    if (type) {
      const newScores = Scoreman.getNewScores({
        ...action,
        base: commentData,
      });
      setCommentData((prev) => {
        return { ...prev, ...newScores };
      });
    } else {
      return;
    }
  };

  const updateActivityScore = (action) => {
    setActivityScore((prev) => {
      const newActivityScore = Scoreman.getNewActivityScore({
        ...action,
        baseSessOwnerScore,
        baseScore: baseActivityScore,
      });

      return newActivityScore;
    });
  };

  const Vote = () => (
    <Ballot
      type="comment"
      id={commentId}
      quad={quad}
      totalScore={totalScore}
      sessOwnerScore={sessOwnerScore}
      onVote={onVote}
      updateActivityScore={updateActivityScore}
    />
  );

  const Activity = () => (
    <Action
      variant="simple"
      icon={<ActionActivityIcon />}
      helperText={String(activityScore)}
    />
  );

  const Respond = () => (
    <Action
      icon={<ActionReplyIcon />}
      helperText={String(replyCount)}
      handleClick={() => setIsExpanded(true)}
      onHoverSx={{
        iconSx: {
          backgroundColor: "var(--quad-green-light)",

          "#action-icon--reply": {
            fill: "var(--quad-green)",
            transition: "fill 200ms",
          },
        },
        helperTextSx: {
          color: "var(--quad-green)",
        },
      }}
    />
  );

  const actions = (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      gap={1}
      sx={{ position: "relative", right: 8 }}
    >
      <Grid item>
        <Vote />
      </Grid>
      <Grid item>
        <Activity />
      </Grid>
      <Grid item>
        <Respond />
      </Grid>
    </Grid>
  );

  const content = (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        <Author data={comment} clickableName />
      </Grid>
      <Grid item>
        <Description text={text} />
      </Grid>
      <Grid item>{actions}</Grid>
      {!isExpanded && childCount > 0 && (
        <Grid item sx={{ margin: "10px 0px 5px" }}>
          {loadButton}
        </Grid>
      )}
      <Grid item>
        {isExpanded && (
          <ReplySection
            comment={comment}
            onReplyCreate={() => {
              setReplyCount((prev) => prev + 1);
            }}
            onReplyDelete={() => {
              setReplyCount((prev) => (prev > 0 ? prev - 1 : 0));
            }}
          />
        )}
      </Grid>
    </Grid>
  );

  const cardSx = {
    borderBottom: isDeleted ? "" : `1px solid var(--border-gray)`,
    padding: isDeleted ? "0px" : "12px 15px",
  };

  const card = (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      className={isDeleted ? "card--empty" : ""}
      sx={cardSx}
    >
      {!isDeleted && (
        <>
          <Grid item mr={2}>
            <BasicAvatar username={username} avatarSrc={avatarSrc} />
          </Grid>
          <Grid item sx={{ width: "100%" }}>
            {content}
          </Grid>
          <Grid item sx={{ width: "0px", position: "relative", right: 30 }}>
            <CommentOptions
              comment={comment}
              onDelete={() => setIsDeleted(true)}
            />
          </Grid>
        </>
      )}
    </Grid>
  );

  return (
    <ThemeProvider theme={cardTheme}>
      <article ref={ref ? ref : null}>{card}</article>
    </ThemeProvider>
  );
});
