import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { BasicHeader, CourseLabel } from "@common/comps";
import { CourseContext } from "@context";
import { Box } from "@mui/material";
import {
  ButtonBackwardIcon,
  ButtonMainIcon,
  BadgeCourseIcon,
} from "@material/icons";

export const CourseHeader = () => {
  const { course } = useContext(CourseContext);
  const { pathname } = useLocation();

  const isLanding = pathname.split("/").length === 5;

  const getTitle = () => {
    switch (pathname.split("/").pop()) {
      case "reqs":
        return "Requisites";
      default:
        return "Course";
    }
  };

  return (
    <BasicHeader
      title={getTitle()}
      titleIcon={<BadgeCourseIcon />}
      enableBack
      backIcon={isLanding ? <ButtonBackwardIcon /> : <ButtonMainIcon />}
      content2={
        !isLanding && (
          <Box px={2} pb={2} borderBottom="1px solid var(--border-gray)">
            <CourseLabel variant="filled" course={course} />
          </Box>
        )
      }
    />
  );
};
