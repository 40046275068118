import { Grid, Box, Typography } from "@mui/material";
import { ErrorForbiddenIcon } from "@material/icons";

const BasicErrorNotice = ({ label, icon, padding }) => {
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      id="basic-error-notice"
      sx={{ ...(padding && { padding }) }}
    >
      <Grid item>
        <Box sx={{ padding: "8px" }}>
          <Box sx={{ width: "20px", height: "20px" }}>
            {icon ? icon : <ErrorForbiddenIcon />}
          </Box>
        </Box>
      </Grid>
      <Grid item sx={{ paddingTop: "2px" }}>
        <Typography
          sx={{
            fontSize: "15px",
            fontWeight: "400",
            color: "var(--error-gray)",
            marginLeft: "5px",
          }}
        >
          {label}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default BasicErrorNotice;
