import { useContext, useState, useEffect } from "react";
import { cargo } from "@api";
import { QuadContext } from "@context";
import { Opener, Builder } from "@tools";
import { Editable, Loadman, PopperOptions } from "@common/comps";
import { Box, Grid } from "@mui/material";
import { PopperEditIcon, PopperProgramIcon } from "@material/icons";
import { replace } from "radash";

const Concentration = ({ c, setFeed }) => {
  const { concId, concName } = c || {};

  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isEditProgramOpen, setIsEditProgramOpen] = useState(false);

  const menuItemsList = [
    {
      key: "admin-concentration__edit",
      label: "Edit concentration",
      icon: <PopperEditIcon />,
      handleClick: (event) => {
        event.stopPropagation();
        setIsEditOpen(true);
      },
    },
    {
      key: "admin-concentration__edit-programs",
      label: "Edit programs",
      icon: <PopperProgramIcon />,
      handleClick: (event) => {
        event.stopPropagation();
        setIsEditProgramOpen(true);
      },
    },
  ];

  const endComponent = (
    <>
      <PopperOptions menuItemsList={menuItemsList} />
      {isEditOpen &&
        Builder.concentration({
          isOpen: isEditOpen,
          setIsOpen: setIsEditOpen,
          unedited: c,
          onEdit: (edited) => {
            setFeed((prev) =>
              replace(prev, edited, (c) => c.concId === concId)
            );
            setIsEditOpen(false);
          },
        })}
      {isEditProgramOpen &&
        Builder.programs({
          concId,
          isOpen: isEditProgramOpen,
          setIsOpen: setIsEditProgramOpen,
          onEdit: () => {
            setIsEditProgramOpen(false);
          },
        })}
    </>
  );

  return (
    <Box px={2} py={1.5} borderBottom="1px solid var(--border-gray)">
      <Editable
        variant="vanilla"
        label={concName}
        endComponent={endComponent}
      />
    </Box>
  );
};

const ConcentrationFeed = () => {
  const { quadId } = useContext(QuadContext);
  const [isLoading, setIsLoading] = useState(true);
  const [feed, setFeed] = useState([]);

  const isEmpty = feed.length === 0;
  const noFeed = isLoading || isEmpty;

  useEffect(() => {
    setIsLoading(true);
    cargo
      .post(`/v2/q/${quadId}/program/concentrations`, { filters: {} })
      .then((res) => {
        const { ok, payload } = res || {};
        if (ok) {
          setIsLoading(false);
          setFeed(payload);
        }
      });
  }, []);

  return noFeed ? (
    <Loadman variant="standard" isLoading={isLoading} isEmpty={isEmpty} p={2} />
  ) : (
    <Grid container direction="column" wrap="nowrap">
      {feed.map((c) => (
        <Grid item key={c.concId}>
          <Concentration c={c} setFeed={setFeed} />
        </Grid>
      ))}
    </Grid>
  );
};

export const ConcentrationManager = () => {
  const [fresh, setFresh] = useState(0);

  const Create = () => (
    <Opener
      variant="filled"
      color="black"
      label="Create concentration"
      builder={Builder.concentration({
        onCreate: () => setFresh((prev) => prev + 1),
      })}
    />
  );

  const Feed = () => <ConcentrationFeed />;

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item p={2} borderBottom="1px solid var(--border-gray)">
        <Create />
      </Grid>
      <Grid item>
        <Feed />
      </Grid>
    </Grid>
  );
};
