import { useState } from "react";
import { BasicIconButton, BasicForm } from "@common/comps";
import { Grid, Button, Typography, ThemeProvider } from "@mui/material";
import { ButtonBackwardIcon } from "@material/icons";
import { buttonTheme } from "@material/themes";

import { InstructorButton } from "./InstructorButton";
import { getFullName } from "./utility";

export const InstructorChoose = ({ options, instructor, setInstructor }) => {
  // Form state
  const [isFormOpen, setIsFormOpen] = useState(false);

  const { instrId } = instructor || {};

  const getChooseLabel = (name) => {
    const limit = 30;
    const isLong = name.length >= limit;
    return isLong ? `${name.substring(0, limit)}...` : name;
  };

  const chooseButton = (
    <ThemeProvider theme={buttonTheme}>
      <Button variant="outlined" onClick={() => setIsFormOpen(true)}>
        <Typography variant="outlined--teal">
          {instrId
            ? getChooseLabel(getFullName(instructor))
            : "Choose an instructor"}
        </Typography>
      </Button>
    </ThemeProvider>
  );

  const content = (
    <Grid
      container
      direction="row"
      sx={{ padding: "0px 10px", marginBottom: "20px", gap: 1 }}
    >
      {options.map((c) => (
        <Grid item key={c.instrId}>
          <InstructorButton
            candidate={c}
            instructor={instructor}
            setInstructor={setInstructor}
          />
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Grid container direction="row" sx={{ gap: 1 }}>
      {instrId && (
        <Grid item>
          <BasicIconButton
            icon={<ButtonBackwardIcon />}
            handleClick={() => setInstructor(null)}
            padding="8px"
          />
        </Grid>
      )}
      <Grid item>
        {chooseButton}
        {isFormOpen && (
          <BasicForm
            title="Choose instructor"
            isFormOpen={isFormOpen}
            setIsFormOpen={setIsFormOpen}
            content={content}
            disableFooter
          />
        )}
      </Grid>
    </Grid>
  );
};
