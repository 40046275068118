import { Rating } from "@mui/material";
import { CourseStarIcon, CourseStarFilledIcon } from "@material/icons";

import "@material/styles/ratingStyles.css";

const ReadOnly = ({ value, size }) => {
  const iconSize = size ? size : 20;

  return (
    <Rating
      id="basic-rating--ro"
      defaultValue={0}
      precision={1}
      value={value}
      readOnly
      icon={<CourseStarFilledIcon width={iconSize} height={iconSize} />}
      emptyIcon={<CourseStarIcon width={iconSize} height={iconSize} />}
    />
  );
};

const BasicRating = (props) => {
  const { readOnly } = props || {};

  if (readOnly) {
    return <ReadOnly {...props} />;
  } else {
    return <></>;
  }
};

export default BasicRating;
