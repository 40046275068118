import { useState } from "react";
import { cargo, masterAPI } from "@api";
import { Opener, Builder } from "@tools";
import {
  SearchBar,
  Spinner,
  Editable,
  BasicAvatar,
  EmptyLabel,
} from "@common/comps";
import { Grid, Box } from "@mui/material";
import { DefaultOrgAltAvatar } from "@material/images";
import { unique } from "radash";

const Feed = ({ feed, onEdit }) => {
  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item borderBottom="1px solid var(--border-gray)" />
      {feed.map((o, i) => (
        <Grid
          item
          key={o.orgId}
          borderBottom="1px solid var(--border-gray)"
          p={2}
          py={1.5}
        >
          <Editable
            variant="vanilla"
            label={o.orgName}
            startContainerProps={{
              display: "flex",
              alignItems: "center",
            }}
            startComponent={
              <BasicAvatar
                disableClick
                diameter={22}
                avatarSrc={o.orgAvatarSrc}
                defaultSrc={DefaultOrgAltAvatar}
              />
            }
            endComponent={
              <Opener
                variant="outlined"
                label="Edit"
                builder={Builder.organization({
                  unedited: o,
                  onEdit,
                })}
              />
            }
          />
        </Grid>
      ))}
    </Grid>
  );
};

export const RootOrganizations = () => {
  const [isInitialized, setIsInitialized] = useState(false);

  const [search, setSearch] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [feed, setFeed] = useState([]);

  const handleSearch = () => {
    if (!isInitialized) setIsInitialized(true);

    if (search) {
      setIsLoading(true);
      cargo.get(`/v2/organization?term=${search}`).then((res) => {
        setIsLoading(false);
        const { ok, payload } = res || {};

        if (ok) {
          const uniques = unique(payload, (o) => o.orgId);
          const N = uniques.length;
          setFeed(uniques.slice(0, N > 50 ? 50 : N));
        }
      });
    }
  };

  const Crud = () => (
    <Opener
      variant="filled"
      color="black"
      label="Create organization"
      builder={Builder.organization({ onCreate: handleSearch })}
    />
  );

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item p={2} borderBottom="1px solid var(--border-gray)">
        <Crud />
      </Grid>
      <Grid item p={2}>
        <SearchBar
          label="Search by name"
          search={search}
          setSearch={setSearch}
          handleSearch={handleSearch}
        />
      </Grid>
      <Grid item>
        {isInitialized && (isLoading || feed.length === 0) ? (
          <Box px={2.5} py={1.5}>
            {isLoading ? (
              <Spinner />
            ) : (
              <EmptyLabel label="No organizations found." />
            )}
          </Box>
        ) : (
          <Feed feed={feed} onEdit={handleSearch} />
        )}
      </Grid>
    </Grid>
  );
};
