import { useEffect } from "react";
import { useComment, CommentProvider } from "@context";
import { Loading, EmptyLabel } from "@common/comps";
import { Grid } from "@mui/material";

import { CommentCard } from "../commentCard/CommentCard";
import { Publisher } from "@tools";

export const CommentLanding = ({ onCommentCreate, onCommentDelete }) => {
  const {
    post,
    initTranche,
    isLoading,
    feed,
    setFeed,
    fresh,
    setFresh,
    isFeedEmpty,
    lastCardRef,
  } = useComment();

  const { postId } = post || {};

  useEffect(() => initTranche(), []); // Initiate retrieval of comments

  const commentFeed = feed.map((comment, i) => (
    <Grid
      item
      key={comment.commentId}
      className={fresh > 0 && i === 0 ? "card--new" : ""}
    >
      <CommentCard
        ref={feed.length === i + 1 ? lastCardRef : null}
        comment={comment}
        onDelete={() => {
          setFresh(0);
          setFeed((prev) =>
            prev.filter((c) => c.commentId !== comment.commentId)
          );

          /* Callbacks */
          onCommentDelete(comment.commentId);
        }}
      />
    </Grid>
  ));

  const Create = () =>
    Publisher.comment({
      postId,
      onPublish: (created) => {
        setFresh((prev) => prev + 1);
        setFeed((prev) => [created, ...prev]);

        /* Callbacks */
        onCommentCreate();
      },
    });

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item p={2} borderBottom="1px solid var(--border-gray)">
        <Create />
      </Grid>
      <Grid item>
        {isFeedEmpty ? (
          <EmptyLabel label="There are no comments." p={2} />
        ) : (
          commentFeed
        )}
      </Grid>
      <Grid item>{isLoading && <Loading p={2} />}</Grid>
      <Grid item height={200} />
    </Grid>
  );
};

export const CommentSection = ({
  post,
  onCommentCreate = () => {},
  onCommentDelete = () => {},
}) => {
  return (
    <CommentProvider post={post}>
      <CommentLanding
        onCommentCreate={onCommentCreate}
        onCommentDelete={onCommentDelete}
      />
    </CommentProvider>
  );
};

export default CommentSection;
