import { createTheme } from "@mui/material";

export const notifTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          display: "flex",
        },
      },
      variants: [
        {
          props: { variant: "label" },
          style: {
            fontSize: "15px",
            fontWeight: "400",
            color: "var(--quad-black)",
            letterSpacing: "0.01em",
          },
        },
      ],
    },
  },
});
