import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { cargo } from "@api";
import { QuadContext } from "@context";
import { Builder } from "@tools";
import { Editable, Loadman, PopperOptions } from "@common/comps";
import { Grid, Box } from "@mui/material";
import { PopperEditIcon, PopperReqIcon } from "@material/icons";
import { replace } from "radash";

import { AdminProgramContext } from "../../context";

export const Program = ({ p, setFeed }) => {
  const navigate = useNavigate();
  const { quadId } = useContext(QuadContext);
  const { setProgram } = useContext(AdminProgramContext);
  const { programId, certCode, certName } = p || {};

  const [isEditOpen, setIsEditOpen] = useState(false);

  const menuItemsList = [
    {
      key: "admin-program__edit",
      label: "Edit program",
      icon: <PopperEditIcon />,
      handleClick: (event) => {
        event.stopPropagation();
        setIsEditOpen(true);
      },
    },
    {
      key: "admin-program__edit-requirements",
      label: "Edit requirements",
      icon: <PopperReqIcon />,
      handleClick: (event) => {
        event.stopPropagation();
        setProgram(p);
        navigate(`/q/${quadId}/admin/programs/requirement`);
      },
    },
  ];

  const endComponent = (
    <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
      <PopperOptions menuItemsList={menuItemsList} />
      {isEditOpen &&
        Builder.program({
          programId,
          isOpen: isEditOpen,
          setIsOpen: setIsEditOpen,
          onEdit: (edited) => {
            setFeed((prev) =>
              replace(prev, edited, (p) => p.programId === programId)
            );
            setIsEditOpen(false);
          },
        })}
    </Box>
  );

  return (
    <Box px={2} py={1.5} borderBottom="1px solid var(--border-gray)">
      <Editable
        variant="vanilla"
        label={certCode}
        subLabel={certName}
        endComponent={endComponent}
      />
    </Box>
  );
};

export const ProgramManager = () => {
  const { quadId } = useContext(QuadContext);
  const { concentration } = useContext(AdminProgramContext);
  const { concId } = concentration || {};
  const [isLoading, setIsLoading] = useState(true);
  const [feed, setFeed] = useState([]);

  const isEmpty = feed.length === 0;
  const noFeed = isLoading || isEmpty;

  useEffect(() => {
    setIsLoading(true);
    cargo
      .get(`/v2/q/${quadId}/program/all?by=concentration&concId=${concId}`)
      .then((res) => {
        const { ok, payload } = res || {};
        if (ok) {
          setIsLoading(false);
          setFeed(payload);
        }
      });
  }, []);

  return noFeed ? (
    <Loadman variant="standard" isLoading={isLoading} isEmpty={isEmpty} p={2} />
  ) : (
    <Grid container direction="column" wrap="nowrap">
      {feed.map((p) => (
        <Grid item key={p.programId}>
          <Program p={p} setFeed={setFeed} />
        </Grid>
      ))}
    </Grid>
  );
};
