import { SettingsCard } from "@common/comps";
import { Grid } from "@mui/material";

export const SettingsSecurity = () => {
  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        <SettingsCard
          label="Password change"
          subLabel="Change your login password."
          route={"/settings/password"}
        />
      </Grid>
    </Grid>
  );
};
