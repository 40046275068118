import { forwardRef, useState, useEffect } from "react";
import { BasicAvatar, Author } from "@common/comps";
import { Grid } from "@mui/material";

import { EditorOptions } from "./EditorOptions";

export const EditorCard = forwardRef(({ editor, setFeed, setFresh }, ref) => {
  const { affilId, username, avatarSrc } = editor || {};

  const [isDeleted, setIsDeleted] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (isDeleted) {
      setTimeout(
        () => setFeed((prev) => prev.filter((e) => e.affilId !== affilId)),
        200
      );
    }
  }, [isDeleted]);

  const content = (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        <Author data={editor} dateless />
      </Grid>
    </Grid>
  );

  const Options = () => (
    <EditorOptions
      editor={editor}
      onDelete={() => {
        setFresh(0);
        setIsDeleted(true);
      }}
    />
  );

  const card = (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      p={isDeleted ? "" : 2}
      className={isDeleted ? "card--empty--small" : ""}
      borderBottom="1px solid var(--border-gray)"
      alignItems="center"
    >
      {!isDeleted && (
        <>
          <Grid item mr={2}>
            <BasicAvatar
              username={username}
              avatarSrc={avatarSrc}
              diameter="40px"
            />
          </Grid>
          <Grid item width="100%">
            {content}
          </Grid>
          <Grid item>
            <Options />
          </Grid>
        </>
      )}
    </Grid>
  );

  return <article ref={ref ? ref : null}>{card}</article>;
});
