import {
  Grid,
  Typography,
  Box,
  createTheme,
  ThemeProvider,
} from "@mui/material";

const areaTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          letterSpacing: "0.01em",
        },
      },
      variants: [
        {
          props: { variant: "area-code" },
          style: {
            fontSize: "12px",
            fontWeight: "500",
            color: "white",
          },
        },
        {
          props: { variant: "area-name" },
          style: {
            fontSize: "12px",
            fontWeight: "500",
            color: "white",
          },
        },
      ],
    },
  },
});

const CourseAreaLabel = ({ area }) => {
  const { subjAreaCode, subjAreaName } = area || {};

  return (
    <ThemeProvider theme={areaTheme}>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        sx={{
          backgroundColor: "var(--quad-blue)",
          borderRadius: "9999px",
          padding: "2px 12px",
        }}
      >
        <Grid item sx={{ display: "flex" }}>
          <Typography variant="area-code">{subjAreaCode}</Typography>
        </Grid>
        <Grid item sx={{ margin: "0px 5px", display: "flex" }}>
          <Box
            sx={{
              position: "relative",
              top: 4,
              width: "1px",
              height: "10px",
              borderRadius: "9999px",
              backgroundColor: "var(--div-gray)",
              opacity: "50%",
            }}
          />
        </Grid>
        <Grid item sx={{ display: "flex" }}>
          <Typography variant="area-name">{subjAreaName}</Typography>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default CourseAreaLabel;
