import { createContext, useState, useEffect, useContext } from "react";
import { cargo } from "@api";

export const ReplyContext = createContext();

export const ReplyProvider = ({ children, comment }) => {
  const { commentId, quad } = comment || {};

  // Loading
  const [isLoading, setIsLoading] = useState(false);
  const [feed, setFeed] = useState([]);
  const [isFeedEmpty, setIsFeedEmpty] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    cargo.post(`/v2/q/${quad}/p/comment/${commentId}/replies`).then((res) => {
      setIsLoading(false);
      const { ok, payload } = res || {};
      if (ok) {
        setFeed(payload);
        setIsFeedEmpty(payload.length === 0);
      }
    });
  }, []);

  return (
    <ReplyContext.Provider
      value={{
        comment,
        isLoading,
        feed,
        setFeed,
        isFeedEmpty,
        setIsFeedEmpty,
      }}
    >
      {children}
    </ReplyContext.Provider>
  );
};

export const useReply = () => {
  const context = useContext(ReplyContext);
  if (!context) {
    return null;
  } else {
    return context;
  }
};
