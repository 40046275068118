import { createTheme } from "@mui/material";

const globalTheme = createTheme({
  typography: {
    fontFamily: "Roboto",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "rgba(0, 0, 0, 0)",
        },
      },
    },
  },
});

export default globalTheme;
