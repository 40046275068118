import { assign } from "radash";

const developmentURL = "http://localhost:3500";
const productionURL = "https://api.quadrangle.club";

const baseURL =
  process.env.NODE_ENV === "development" ? developmentURL : productionURL;

class Cargo {
  constructor() {
    this.baseURL = baseURL;
    this.defaultOptions = {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    };
  }
  getEndpoint(route) {
    return `${baseURL}${route}`;
  }
  async respond(res) {
    const json = await res.json();

    return {
      ...json,
      status: res.status,
      ok: res.ok,
      isClientError: res.status >= 400 && res.status <= 409,
    };
  }
  async get(route, options) {
    const endpoint = this.getEndpoint(route);

    const res = await fetch(
      endpoint,
      assign({ ...this.defaultOptions, method: "GET" }, options)
    );

    return this.respond(res);
  }
  async post(route, body, options) {
    const endpoint = this.getEndpoint(route);

    const res = await fetch(
      endpoint,
      assign(
        { ...this.defaultOptions, method: "POST", body: JSON.stringify(body) },
        options
      )
    );

    return this.respond(res);
  }
  async put(route, body, options) {
    const endpoint = this.getEndpoint(route);

    const res = await fetch(
      endpoint,
      assign(
        { ...this.defaultOptions, method: "PUT", body: JSON.stringify(body) },
        options
      )
    );

    return this.respond(res);
  }
  async delete(route, options) {
    const endpoint = this.getEndpoint(route);

    const res = await fetch(
      endpoint,
      assign(
        {
          ...this.defaultOptions,
          method: "DELETE",
        },
        options
      )
    );

    return this.respond(res);
  }
}

const cargo = new Cargo();

export { cargo };
export default cargo;
