import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { SettingsProvider } from "@context";
import { BasicHeader, SettingsCard } from "@common/comps";
import { Grid, ThemeProvider } from "@mui/material";
import { SettingsGeneralIcon, SettingsPasswordIcon } from "@material/icons";
import { settingsTheme } from "./theme";

export const SettingsLanding = () => {
  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        <SettingsCard
          icon={<SettingsGeneralIcon />}
          label="General"
          subLabel="Account information like your username and email address"
          route="general"
        />
      </Grid>
      <Grid item>
        <SettingsCard
          icon={<SettingsPasswordIcon />}
          label="Security"
          subLabel="Change your password"
          route="security"
        />
      </Grid>
    </Grid>
  );
};

export const Settings = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const pathSections = pathname.split("/");

  const isMenu = pathSections.length === 2;

  const generalPages = ["username", "email", "default-quad"];
  const isGeneral = generalPages.includes(pathSections[2]);
  const securityPages = ["password"];
  const isSecurity = securityPages.includes(pathSections[2]);
  const layer2Pages = [...generalPages, ...securityPages];
  const isLayer2 = layer2Pages.includes(pathSections[2]);

  const getTitle = () => {
    switch (pathSections[2]) {
      case "general":
        return "General settings";
      case "username":
        return "Change username";
      case "email":
        return "Change email";
      case "default-quad":
        return "Change default quad";
      case "security":
        return "Security settings";
      case "password":
        return "Change password";
      default:
        return "Settings";
    }
  };

  const handleBack = () => {
    if (isLayer2) {
      if (isGeneral) {
        navigate("/settings/general");
      } else if (isSecurity) {
        navigate("/settings/security");
      } else {
        navigate("/settings");
      }
    } else {
      navigate("/settings");
    }
  };

  return (
    <SettingsProvider>
      <ThemeProvider theme={settingsTheme}>
        <Grid
          container
          direction="column"
          wrap="nowrap"
          sx={{ minHeight: "100vh" }}
        >
          <Grid item sx={{ position: "sticky", top: 0 }}>
            <BasicHeader
              title={getTitle()}
              enableBack={!isMenu}
              handleBack={handleBack}
            />
          </Grid>
          <Grid item>
            <Outlet />
          </Grid>
        </Grid>
      </ThemeProvider>
    </SettingsProvider>
  );
};
