import { useContext, useEffect, useState } from "react";
import { masterAPI } from "@api";
import { ProfileContext } from "@context";
import { BasicAvatar, BasicForm, Error } from "@common/comps";
import { Grid, Box, Button, ThemeProvider } from "@mui/material";
import { buttonTheme } from "@material/themes";

const defaultError = { isError: false, errorText: "", type: "" };

export const AvatarCrud = () => {
  const { profile } = useContext(ProfileContext);
  const { avatarSrc } = profile || {};

  const [isFormOpen, setIsFormOpen] = useState(false);

  // Field
  const [avatar, setAvatar] = useState(null);

  // Error
  const [avatarError, setAvatarError] = useState(defaultError);

  // Submission
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [submitError, setSubmitError] = useState(defaultError);

  useEffect(() => {
    setIsSubmitDisabled(!Boolean(avatar));
  }, [avatar]);

  const handleSubmit = () => {
    setIsSubmitLoading(true);

    const formData = new FormData();
    formData.append("avatar", avatar);

    masterAPI.postFile("/profile/change-avatar", formData).then((res) => {
      const { ok, status, message } = res || {};
      if (ok) {
        window.location.reload();
      } else {
        setAvatar(null);
        setAvatarError({ isError: true, type: status, errorText: message });
        setIsSubmitLoading(false);
        setIsSubmitDisabled(true);
      }
    });
  };

  const uploadButton = (
    <ThemeProvider theme={buttonTheme}>
      <Button variant="outlined" component="label">
        <input
          hidden
          accept="image/png, image/jpeg"
          type="file"
          onChange={(event) => {
            if (event.target.files[0].size < Math.pow(2, 20)) {
              setAvatar(event.target.files[0]);
              setAvatarError(defaultError);
            } else {
              setAvatarError({
                isError: true,
                errorText: "File size must be under 2MB.",
                type: 413,
              });
            }
          }}
        />
        Upload
      </Button>
    </ThemeProvider>
  );

  const removeButton = (
    <ThemeProvider theme={buttonTheme}>
      <Button variant="filled" color="red" onClick={() => setAvatar(null)}>
        Remove
      </Button>
    </ThemeProvider>
  );

  const content = (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      alignItems="center"
      gap={2}
      sx={{ position: "relative", left: 5 }}
    >
      <Grid item>
        <BasicAvatar
          avatarSrc={avatar ? URL.createObjectURL(avatar) : avatarSrc}
          disableClick
          diameter={80}
        />
      </Grid>
      <Grid item>{avatar ? removeButton : uploadButton}</Grid>
      {avatarError.isError && (
        <Grid item>
          <Error error={avatarError} />
        </Grid>
      )}
    </Grid>
  );

  return (
    <>
      <Box
        width={80}
        height={80}
        onClick={() => setIsFormOpen(true)}
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <BasicAvatar avatarSrc={avatarSrc} disableClick diameter={80} />
        <Box
          width={80}
          height={80}
          borderRadius={9999}
          sx={{
            position: "absolute",
            top: 0,
            backgroundColor: "var(--quad-black)",
            opacity: 0,

            ":hover": {
              cursor: "pointer",
              opacity: 0.15,
              transition: "opacity 200ms",
            },
          }}
        />
      </Box>
      {isFormOpen && (
        <BasicForm
          title="Change avatar"
          isFormOpen={isFormOpen}
          setIsFormOpen={setIsFormOpen}
          content={content}
          handleSubmit={handleSubmit}
          submitState={{ isSubmitDisabled, isSubmitLoading, submitError }}
        />
      )}
    </>
  );
};
