import { useContext, useState, useEffect } from "react";
import { QuadContext } from "@context";
import { cargo } from "@api";
import { Spinner, Search } from "@common/comps";
import { Box, Typography } from "@mui/material";
import { pick, unique } from "radash";

const Input = ({
  label,
  initialState,
  setState,
  handleChange,
  handleBlurClear,
}) => {
  const { quadId } = useContext(QuadContext);

  const { deptName: initialDeptName } = initialState || {};
  const [search, setSearch] = useState(initialDeptName || "");

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([initialState]);

  useEffect(() => {
    if (search) {
      setIsLoading(true);
      setOptions([]);
      cargo.get(`/v2/q/${quadId}/department?term=${search}`).then((res) => {
        const { ok, payload } = res || {};
        if (ok) {
          setIsLoading(false);
          const list = payload.departments.map((d) => {
            return pick(d, ["deptId", "deptName"]);
          });
          const uniques = unique(list, (d) => d.deptId);
          const N = uniques.length;
          setOptions(uniques.slice(0, N > 10 ? 10 : N));
        }
      });
    }
  }, [search]);

  const renderOption = (props, option) => {
    if (isLoading) {
      return (
        <Box key="department-select__spinner" px={2} py={1}>
          <Spinner />
        </Box>
      );
    } else {
      return (
        <Box component="li" {...props}>
          <Typography variant="department">{option.deptName}</Typography>
        </Box>
      );
    }
  };

  return (
    <Search
      elementId="department-select__input-select"
      label={label || "Department"}
      search={search}
      setSearch={setSearch}
      options={options}
      getOptionLabel={(o) => o.deptName}
      renderOption={renderOption}
      handleChange={(...args) => {
        const [event, value] = args;

        setState && setState(value);
        setSearch(value.deptName);

        handleChange && handleChange(...args);
      }}
      onBlur={() => {
        if (options.some((d) => d.deptName !== search)) {
          setSearch("");
          handleBlurClear();
        }
      }}
    />
  );
};

const Router = (props) => {
  const { variant } = props || {};

  switch (variant) {
    default:
      return <Input {...props} />;
  }
};

export default Router;
