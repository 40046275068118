import { useContext, useState, useEffect } from "react";
import { masterAPI } from "@api";
import { GlobalContext, QuadContext } from "@context";
import { BasicForm, UserSearch } from "@common/comps";
import { Grid, Button, ThemeProvider } from "@mui/material";
import { buttonTheme } from "@material/themes";

export const EditorAdd = ({ onCreate }) => {
  const { defaultError } = useContext(GlobalContext);
  const { quadId } = useContext(QuadContext);

  const [isFormOpen, setIsFormOpen] = useState(false);

  const [user, setUser] = useState("");

  // Submission
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [submitError, setSubmitError] = useState(defaultError);

  const { username } = user || {};

  useEffect(() => {
    setIsSubmitDisabled(!Boolean(username));
  }, [user]);

  const handleSubmit = () => {
    if (username && quadId) {
      setIsSubmitLoading(true);
      masterAPI
        .post("/quad/admin/appoint-editor", {
          username,
          quad: quadId,
        })
        .then((res) => {
          const { ok, status, payload, message } = res || {};
          if (ok) {
            onCreate(payload);
          } else {
            setIsSubmitLoading(false);
            setIsSubmitDisabled(true);
            setSubmitError({
              isError: true,
              errorText: message,
              type: status,
            });
          }
        });
    }
  };

  const content = (
    <Grid container direction="column" wrap="nowrap" mt={0.5}>
      <Grid item>
        <UserSearch user={user} setUser={setUser} />
      </Grid>
    </Grid>
  );

  return (
    <>
      <ThemeProvider theme={buttonTheme}>
        <Button
          variant="filled"
          color="black"
          onClick={() => setIsFormOpen(true)}
        >
          Add editor
        </Button>
      </ThemeProvider>
      {isFormOpen && (
        <BasicForm
          title="Appoint editor"
          isFormOpen={isFormOpen}
          setIsFormOpen={setIsFormOpen}
          content={content}
          handleSubmit={handleSubmit}
          submitState={{
            isSubmitDisabled,
            isSubmitLoading,
            submitError,
          }}
        />
      )}
    </>
  );
};
