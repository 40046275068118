import { createContext, useContext, useState, useEffect } from "react";
import { useTranche2 } from "@common/hooks";
import { QuadContext } from "@context";
import { unique } from "radash";

export const CatalogContext = createContext();

export const CatalogProvider = ({
  children,
  subjectOptions,
  filterOptions,
}) => {
  const { quadId } = useContext(QuadContext);

  // Search params
  const [subject, setSubject] = useState("");
  const { subjectId } = subject || {};

  /* Filters */
  const [level, setLevel] = useState("");
  const [units, setUnits] = useState("");
  const [deptId, setDeptId] = useState("");

  const {
    resetTranche,
    isLoading,
    lastCardRef,
    setFirstId,
    feed,
    setFeed,
    isFeedEmpty,
  } = useTranche2(`/v2/q/${quadId}/course/tranche?subjectId=${subjectId}`, {
    quad: quadId,
    subject,
    filters: {
      ...(level && { courseLevel: level }),
      ...(units && { courseUnits: units }),
      ...(deptId && { deptId }),
    },
  });

  useEffect(() => {
    setFeed([]);
    subject && resetTranche();
  }, [subject, level, units, deptId]);

  useEffect(() => {
    if (feed.length > 0) {
      setFirstId(feed[0].maxCourseId);
    }
  }, [feed]);

  return (
    <CatalogContext.Provider
      value={{
        subjectOptions,
        filterOptions,
        subject,
        setSubject,
        level,
        setLevel,
        units,
        setUnits,
        deptId,
        setDeptId,
        isLoading,
        lastCardRef,
        feed: unique(feed, (c) => c.courseId),
        setFeed,
        isFeedEmpty,
      }}
    >
      {children}
    </CatalogContext.Provider>
  );
};

export const useCatalog = () => {
  const context = useContext(CatalogContext);
  if (!context) {
    return null;
  } else {
    return context;
  }
};
