import { Typography, createTheme, ThemeProvider } from "@mui/material";

const feedLabelTheme = createTheme({
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: "label" },
          style: {
            fontSize: "13px",
            fontWeight: "400",
            color: "var(--quad-gray-stealth)",
            letterSpacing: "0.02em",
          },
        },
      ],
    },
  },
});

const FeedLabel = ({ label }) => {
  return (
    <ThemeProvider theme={feedLabelTheme}>
      <Typography variant="label">{label}</Typography>
    </ThemeProvider>
  );
};

export default FeedLabel;
