import { useState } from "react";
import { ConfirmForm, Submit } from "@common/comps";
import {
  Grid,
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  ThemeProvider,
} from "@mui/material";
import { buttonTheme, formTheme } from "@material/themes";
import { ButtonCloseIcon } from "@material/icons";

const BasicForm = ({
  title = "",
  isFormOpen,
  setIsFormOpen,
  content = <></>,
  contentProps,
  disableFooter,
  footer,
  handleSubmit,
  submitLabel,
  submitState = {},
  handleDelete,
  deleteTitle = "",
  deleteSubtitle = "",
  deleteLabel = "Delete",
  deleteState = {},
  confirmClose,
  hasEndButton,
  endButton,
}) => {
  const fullScreenCutoff = 550;

  const [isFullScreen, setIsFullScreen] = useState(
    window.innerWidth < fullScreenCutoff
  );

  const getWindowWidth = () => {
    setIsFullScreen(window.innerWidth < fullScreenCutoff);
  };

  window.onresize = getWindowWidth;

  // Close
  const [isCloseOpen, setIsCloseOpen] = useState(false);

  // Deletion
  const {
    isDeleteOpen,
    setIsDeleteOpen,
    isDeleteDisabled,
    isDeleteLoading,
    deleteError,
  } = deleteState || {};

  // Submission
  const { isSubmitDisabled, isSubmitLoading, submitError } = submitState || {};

  const handleClose = (event) => {
    if (confirmClose) {
      event.stopPropagation();
      setIsCloseOpen(true);
    } else {
      event.stopPropagation();
      setIsFormOpen(false);
    }
  };

  const closeButton = (
    <ThemeProvider theme={buttonTheme}>
      <Button variant="icon" onClick={handleClose}>
        <ButtonCloseIcon width={18} height={18} />
      </Button>
    </ThemeProvider>
  );

  const titleSection = (
    <Grid container direction="row" wrap="nowrap" alignItems="center" gap={2}>
      <Grid item>{closeButton}</Grid>
      <Grid item>
        <Typography variant="title">{title}</Typography>
      </Grid>
    </Grid>
  );

  const deleteButton = (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant="stealth--red"
        color="red"
        onClick={(event) => {
          event.stopPropagation();
          setIsDeleteOpen(true);
        }}
        sx={{ color: "var(--quad-red)" }}
      >
        {deleteLabel}
      </Button>
    </ThemeProvider>
  );

  const defaultFooter = (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item>{handleDelete && deleteButton}</Grid>
      <Grid item />
      <Grid item>
        <Submit
          handleSubmit={handleSubmit}
          submitLabel={submitLabel ? submitLabel : "Submit"}
          submitState={{
            isDisabled: isSubmitDisabled,
            isLoading: isSubmitLoading,
            error: submitError,
          }}
        />
      </Grid>
    </Grid>
  );

  return (
    <ThemeProvider theme={formTheme}>
      {isFormOpen && (
        <Box
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <Dialog
            open={isFormOpen}
            onClose={handleClose}
            transitionDuration={0}
            fullScreen={isFullScreen}
          >
            <DialogTitle
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              {titleSection}
              {hasEndButton && endButton}
            </DialogTitle>
            <DialogContent {...contentProps}>
              <Box sx={{ position: "relative", top: 2 }}>{content}</Box>
            </DialogContent>
            {!disableFooter && (
              <DialogTitle borderTop="1px solid var(--border-gray)">
                {footer || defaultFooter}
              </DialogTitle>
            )}
          </Dialog>
        </Box>
      )}
      {isDeleteOpen && (
        <Box onClick={(event) => event.stopPropagation()}>
          <ConfirmForm
            title={deleteTitle}
            subtitle={deleteSubtitle}
            color="red"
            isFormOpen={isDeleteOpen}
            setIsFormOpen={setIsDeleteOpen}
            handleSubmit={handleDelete}
            submitState={{
              isSubmitDisabled: isDeleteDisabled,
              isSubmitLoading: isDeleteLoading,
              submitError: deleteError,
            }}
            submitLabel={deleteLabel}
          />
        </Box>
      )}
      {isCloseOpen && confirmClose && (
        <Box onClick={(event) => event.stopPropagation()}>
          <ConfirmForm
            title="Confirm close?"
            subtitle="You will lose all your progress."
            color="black"
            isFormOpen={isCloseOpen}
            setIsFormOpen={setIsCloseOpen}
            handleSubmit={(event) => {
              event.stopPropagation();
              setIsFormOpen(false);
            }}
            submitLabel="Close"
          />
        </Box>
      )}
    </ThemeProvider>
  );
};

export default BasicForm;
