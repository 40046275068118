import { createTheme } from "@mui/material";

const bubbleSelectTheme = createTheme({
  components: {
    MuiNativeSelect: {
      styleOverrides: {
        select: {
          width: "inherit",
          backgroundColor: "transparent",

          "&:focus": {
            backgroundColor: "white",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "5px",

          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "1px solid var(--button-border-gray)",
          },

          ":hover .MuiOutlinedInput-input": {
            backgroundColor: "var(--button-transparent--hover)",
            transition: "background-color 200ms",
          },

          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "1px solid var(--button-border-gray)",
          },
        },
        input: {
          borderRadius: "5px",
          padding: "8px 45px 9px 18px !important",
          fontSize: "14px",
          fontWeight: "500",
          color: "var(--quad-black)",

          ":focus": {
            borderRadius: "5px",
          },
        },
        notchedOutline: {
          border: "1px solid var(--button-border-gray)",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          transform: "none !important",
          padding: "9px 0px 0px 17px",
          color: "var(--quad-gray)",

          "&.Mui-focused": {
            color: "var(--quad-gray)",
          },
        },
      },
    },
  },
});

export default bubbleSelectTheme;
