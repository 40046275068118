import { createTheme } from "@mui/material";

const autoCompleteTheme = createTheme({
  components: {
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          padding: "23px 12px 8px !important",
          border: "1px solid",
          borderRadius: "5px",
          borderColor: "var(--input-border-gray)",
          height: "55px",
          marginBottom: "1px",
          backgroundColor: "transparent",

          "&:hover": {
            backgroundColor: "transparent",
          },

          "&.Mui-focused": {
            position: "relative",
            bottom: 1,
            right: 1,
            border: "2px solid",
            borderColor: "var(--quad-teal)",
            backgroundColor: "transparent",
          },

          "&.Mui-error": {
            border: "2px solid",
            borderColor: "var(--quad-red)",
            marginBottom: "10px",
          },

          "&.Mui-disabled": {
            border: "none",
            backgroundColor: "var(--input-border-gray)",
          },
        },
        input: {
          padding: "0px 30px 0px 0px !important",
          color: "var(--quad-black)",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "var(--quad-gray)",
          fontSize: "16px",

          "&.Mui-focused": {
            color: "var(--quad-teal)",
          },

          "&.Mui-disabled": {
            color: "var(--quad-gray-alt)",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&.Mui-disabled": {
            WebkitTextFillColor: "var(--quad-gray-alt)",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          position: "absolute",
          bottom: "-10px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "0px 0px 5px 0px var(--paper-shadow-gray)",
          borderRadius: "5px",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          padding: "10px 12px",
          backgroundColor: "transparent",

          "&[aria-selected=true]": {
            backgroundColor: "var(--list-teal) !important",

            "&:hover": {
              backgroundColor: "var(--list-hover-teal) !important",
            },

            "&.Mui-focused": {
              backgroundColor: "var(--list-hover-teal--focused) !important",
            },
          },
          "&.Mui-focused": {
            backgroundColor: "var(--list-hover-gray--focused) !important",
          },

          "&:hover": {
            backgroundColor: "var(--list-hover-gray)",
          },
        },
        input: {
          padding: "0px !important",
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: "label" },
          style: {
            fontSize: "15px",
            fontWeight: "400",
            color: "var(--quad-black)",
          },
        },
        {
          props: { variant: "name" },
          style: {
            fontSize: "14px",
            fontWeight: "500",
            color: "var(--quad-black)",
            letterSpacing: "0.02em",
          },
        },
        {
          props: { variant: "username" },
          style: {
            fontSize: "14px",
            fontWeight: "400",
            color: "var(--quad-gray-alt)",
            letterSpacing: "0.02em",
          },
        },
      ],
    },
  },
});

export default autoCompleteTheme;
