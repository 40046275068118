import { useContext, useState } from "react";
import { GlobalContext } from "@context";
import { cargo } from "@api";
import { PopperBinIcon } from "@material/icons";
import { ConfirmForm, PopperOptions } from "@common/comps";
import { Box } from "@mui/material";

export const CommentOptions = ({ comment, onDelete }) => {
  const { defaultError } = useContext(GlobalContext);
  const { commentId, quad, canEdit } = comment || {};

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  // Submission
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(false);
  const [deleteError, setDeleteError] = useState(defaultError);

  const handleDelete = async (event) => {
    event.stopPropagation();
    if (commentId && quad) {
      setIsDeleteLoading(true);
      cargo.delete(`/v2/q/${quad}/p/comment/${commentId}`).then((res) => {
        const { ok, status, message } = res || {};
        if (ok) {
          onDelete();
        } else {
          setIsDeleteLoading(false);
          setIsDeleteDisabled(true);
          setDeleteError({ isError: true, errorText: message, type: status });
        }
      });
    }
  };

  const menuItemsList = [
    {
      key: "comment-options__delete",
      label: "Delete comment",
      icon: <PopperBinIcon />,
      isRed: true,
      handleClick: (event) => {
        setIsDeleteOpen(true);
        event.stopPropagation();
      },
      isRestricted: true,
      auth: canEdit,
    },
  ];

  return (
    <>
      <PopperOptions menuItemsList={menuItemsList} />
      {isDeleteOpen && (
        <Box onClick={(event) => event.stopPropagation()}>
          <ConfirmForm
            title="Delete comment?"
            subtitle="This action is irreversible. You will not be able to recover this post."
            color="red"
            isFormOpen={isDeleteOpen}
            setIsFormOpen={setIsDeleteOpen}
            submitLabel="Delete"
            handleSubmit={handleDelete}
            submitState={{
              isSubmitDisabled: isDeleteDisabled,
              isSubmitLoading: isDeleteLoading,
              submitError: deleteError,
            }}
          />
        </Box>
      )}
    </>
  );
};
