import { forwardRef } from "react";
import {
  Button,
  Grid,
  Box,
  Typography,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { buttonTheme } from "@material/themes";
import { isString } from "radash";

const editableTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          display: "flex",
        },
      },
      variants: [
        {
          props: { variant: "label" },
          style: {
            fontSize: "14px",
            fontWeight: "500",
            color: "var(--quad-black)",
            letterSpacing: "0.02em",
          },
        },
        {
          props: { variant: "sublabel" },
          style: {
            fontSize: "13px",
            fontWeight: "400",
            color: "var(--quad-gray)",
            letterSpacing: "0.02em",
            whiteSpace: "pre-line",
          },
        },
      ],
    },
  },
});

const Label = ({ label, subLabel, labelProps, subLabelProps }) => {
  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        {isString(label) ? (
          <Typography variant="label" {...labelProps}>
            {label}
          </Typography>
        ) : (
          label
        )}
      </Grid>
      {subLabel && (
        <Grid item>
          <Typography variant="sublabel" {...subLabelProps}>
            {subLabel}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

const Vanilla = (props) => {
  const { startComponent, endComponent, startContainerProps } = props || {};

  return (
    <Grid container direction="row" wrap="nowrap" gap={1.5}>
      {startComponent && (
        <Grid item {...startContainerProps}>
          {startComponent}
        </Grid>
      )}
      <Grid
        item
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Label {...props} />
      </Grid>
      {endComponent && <Grid item>{endComponent}</Grid>}
    </Grid>
  );
};

const FullWidth = (props) => {
  const { handleEdit, handleDelete, endButton } = props || {};

  const deleteButton = (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant="filled"
        color="red"
        sx={{ padding: "3px 10px", fontSize: "12px" }}
        onClick={handleDelete}
      >
        Delete
      </Button>
    </ThemeProvider>
  );

  const editButton = (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant="outlined"
        sx={{ padding: "3px 10px", fontSize: "12px" }}
        onClick={handleEdit}
      >
        Edit
      </Button>
    </ThemeProvider>
  );

  const buttonLoader = () => {
    if (endButton) {
      return endButton;
    } else {
      if (handleDelete || handleEdit) {
        if (handleEdit) {
          return editButton;
        } else {
          return deleteButton;
        }
      }
    }
  };

  return (
    <Grid container direction="row" wrap="nowrap" alignItems="center">
      <Grid item sx={{ width: "100%" }}>
        <Label {...props} />
      </Grid>
      <Grid item>{buttonLoader()}</Grid>
    </Grid>
  );
};

const Editable = (props) => {
  const { fullWidth, variant } = props || {};

  if (fullWidth) {
    return <FullWidth {...props} />;
  } else {
    switch (variant) {
      case "vanilla":
        return <Vanilla {...props} />;
      default:
        return <FullWidth {...props} />;
    }
  }
};

const Styled = forwardRef((props, ref) => (
  <article ref={ref}>
    <ThemeProvider theme={editableTheme}>
      <Editable {...props} />
    </ThemeProvider>
  </article>
));

export default Styled;
