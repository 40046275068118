import { useContext, useState, useEffect } from "react";
import { ProfileContext } from "@context";
import { Scoreman, Synchronizer } from "@tools";
import { PostCard } from "features/postcard";
import { BubbleDropdown, EmptyLabel, Loading } from "@common/comps";
import { Grid } from "@mui/material";

const ProfileFeed = () => {
  const { isLoading, feed, setFeed, lastCardRef, isFeedEmpty } =
    useContext(ProfileContext);

  const [fresh, setFresh] = useState(0);

  const postFeed = feed.map((post, i) => (
    <Grid
      item
      key={post.postId}
      className={fresh > 0 && i === 0 ? "card--new" : ""}
    >
      <PostCard
        ref={feed.length === i + 1 ? lastCardRef : null}
        post={post}
        onDelete={() => {
          setFresh(0);
          setFeed((prev) => prev.filter((p) => p.postId !== post.postId));
        }}
        onVote={(options) => {
          const { type: actionType } = options || {};
          const newScores = Scoreman.getNewScores({
            type: actionType,
            base: post,
          });

          Synchronizer.updatePostFeed({ ...post, ...newScores }, setFeed);
        }}
      />
    </Grid>
  ));

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        {isFeedEmpty ? <EmptyLabel label="No posts found." p={2} /> : postFeed}
      </Grid>
      {isLoading && <Loading p={2} />}
      <Grid item height={200} />
    </Grid>
  );
};

export const TimelineP = () => {
  const { options, quad, setQuad } = useContext(ProfileContext);
  const { quadOptions } = options || {};

  useEffect(() => {
    setQuad(quadOptions[0]);
  }, []);

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item borderBottom="1px solid var(--border-gray)" p={2}>
        <Grid container direction="row" wrap="nowrap">
          <Grid item>
            <BubbleDropdown
              variant="object"
              state={quad}
              setState={setQuad}
              options={quadOptions}
              labelKey="quadShort"
              valueKey="quad"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <ProfileFeed />
      </Grid>
    </Grid>
  );
};
