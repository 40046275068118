import { GeneralNullIcon } from "@material/icons";
import { MenuItem, Grid, Box, Typography } from "@mui/material";

const PopperMenuItem = ({
  label,
  icon,
  isRed = false,
  handleClick,
  isEmpty,
}) => {
  return isEmpty ? (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      alignItems="center"
      sx={{ padding: "8px 15px 8px 10px" }}
    >
      <Grid item sx={{ marginRight: "10px" }}>
        <Box sx={{ width: "18px", height: "18px" }}>
          <GeneralNullIcon />
        </Box>
      </Grid>
      <Grid item>
        <Typography
          sx={{
            fontSize: "15px",
            fontWeight: "400",
            color: "rgb(188, 193, 193)",
          }}
        >
          No options
        </Typography>
      </Grid>
    </Grid>
  ) : (
    <MenuItem onClick={handleClick} className={isRed ? "options__red" : ""}>
      <Grid container direction="row" wrap="nowrap" alignItems="center">
        <Grid item sx={{ marginRight: "10px" }}>
          <Box sx={{ width: "18px", height: "18px" }}>{icon}</Box>
        </Grid>
        <Grid item>
          <Typography
            className={isRed ? "options__red__label" : ""}
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              color: "var(--quad-black)",
            }}
          >
            {label}
          </Typography>
        </Grid>
      </Grid>
    </MenuItem>
  );
};

export default PopperMenuItem;
