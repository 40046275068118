import { cloneElement } from "react";
import { Typography, createTheme, ThemeProvider, Grid } from "@mui/material";
import {
  CourseNotesIcon,
  CourseReqIcon,
  CourseUnitsIcon,
  CourseCoreIcon,
  CoursePrioIcon,
  CourseRstrIcon,
  CourseYearIcon,
  CourseAreaIcon,
  CourseDeptIcon,
  CoursePreIcon,
  CourseConsentIcon,
} from "@material/icons";

const css = {
  "#detail__icon": {
    display: "flex",
    alignItems: "center",
  },
};

const detailTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          display: "flex",
          letterSpacing: "0.02em",
        },
      },
      variants: [
        {
          props: { variant: "label" },
          style: {
            fontSize: "14px",
            fontWeight: "400",
            color: "var(--quad-gray)",
          },
        },
        {
          props: { variant: "link" },
          style: {
            fontSize: "14px",
            fontWeight: "400",
            color: "var(--quad-teal)",
            whiteSpace: "pre-line",
            overflowWrap: "anywhere",
            letterSpacing: "0.02em",

            "&:hover": {
              cursor: "pointer",
              textDecoration: "underline",
            },
          },
        },
      ],
    },
  },
});

const getIcon = (type) => {
  switch (type) {
    case "notes":
      return <CourseNotesIcon />;
    case "priority":
      return <CoursePrioIcon />;
    case "restricted":
      return <CourseRstrIcon />;
    case "req":
      return <CourseReqIcon />;
    case "units":
      return <CourseUnitsIcon />;
    case "core":
      return <CourseCoreIcon />;
    case "year":
      return <CourseYearIcon />;
    case "areas":
      return <CourseAreaIcon />;
    case "dept":
      return <CourseDeptIcon />;
    /*     case "perm":
      return <CoursePermIcon />; */
    case "prereq":
      return <CoursePreIcon />;
    case "consent":
      return <CourseConsentIcon />;
    default:
      return <></>;
  }
};

const Label = ({ label, labelComponent, labelStyle, labelVariant }) => {
  if (labelComponent) {
    return labelComponent;
  } else {
    return (
      <Typography variant={labelVariant || "label"} sx={labelStyle}>
        {label}
      </Typography>
    );
  }
};

const Detail = (props) => {
  const { icon, iconSize, type, labelLineHeight } = props || {};

  const size = iconSize ? `${iconSize}px` : "18px";
  const iconDimensions = { width: size, height: size };

  return (
    <ThemeProvider theme={detailTheme}>
      <Grid container direction="row" wrap="nowrap" gap={1} sx={css}>
        <Grid item id="detail__icon" sx={{ height: labelLineHeight || "20px" }}>
          {icon
            ? cloneElement(icon, iconDimensions)
            : cloneElement(getIcon(type), iconDimensions)}
        </Grid>
        <Grid item sx={{ lineHeight: labelLineHeight || "20px" }}>
          <Label {...props} />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Detail;
