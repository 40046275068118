import { useState } from "react";
import { Outlet } from "react-router-dom";
import { ProjectProvider } from "@context";
import { Opener, Builder } from "@tools";
import { Grid, ThemeProvider } from "@mui/material";

import { projectsTheme } from "./theme";

export const ProjectsLanding = () => {
  const [isCreateOpen, setIsCreateOpen] = useState(false);

  const ProjectCreate = () => (
    <Opener
      variant="filled"
      color="black"
      label="Create project"
      builder={Builder.project({
        isOpen: isCreateOpen,
        setIsOpen: setIsCreateOpen,
      })}
    />
  );

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item p={2} borderBottom="1px solid var(--border-gray)">
        <ProjectCreate />
      </Grid>
      <Grid item></Grid>
    </Grid>
  );
};

export const Projects = () => {
  return (
    <ProjectProvider>
      <ThemeProvider theme={projectsTheme}>
        <Outlet />
      </ThemeProvider>
    </ProjectProvider>
  );
};
