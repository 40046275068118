import { createTheme } from "@mui/material";

const searchTheme = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          padding: "8px 12px",

          "&[aria-selected=true]": {
            backgroundColor: "var(--list-teal) !important",

            "&:hover": {
              backgroundColor: "var(--list-hover-teal) !important",
            },

            "&.Mui-focused": {
              backgroundColor: "var(--list-hover-teal--focused) !important",
            },
          },

          "&.Mui-focused": {
            backgroundColor: "var(--list-hover-gray--focused) !important",
          },

          "&:hover": {
            backgroundColor: "var(--list-hover-gray)",
          },
        },
      },
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          outline: "1px solid var(--input-border-gray)",
          borderRadius: "5px",
          backgroundColor: "transparent !important",
          color: "var(--quad-black)",

          "&.Mui-focused": {
            outline: "2px solid var(--quad-teal)",
          },

          "&.Mui-disabled": {
            outline: "none",
            backgroundColor: "var(--input-border-gray) !important",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "16px",
          color: "var(--quad-gray)",

          "&.Mui-focused": {
            color: "var(--quad-teal)",
          },

          "&.Mui-disabled": {
            color: "var(--quad-gray-alt)",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&.Mui-disabled": {
            WebkitTextFillColor: "var(--quad-gray-alt)",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "0px 0px 5px 0px var(--paper-shadow-gray)",
          borderRadius: "5px",
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: "label" },
          style: {
            fontSize: "15px",
            fontWeight: "400",
            color: "var(--quad-black)",
          },
        },
        {
          props: { variant: "name" },
          style: {
            fontSize: "14px",
            fontWeight: "500",
            color: "var(--quad-black)",
            letterSpacing: "0.02em",
          },
        },
        {
          props: { variant: "username" },
          style: {
            fontSize: "14px",
            fontWeight: "400",
            color: "var(--quad-gray-alt)",
            letterSpacing: "0.02em",
          },
        },
        {
          props: { variant: "organization" },
          style: {
            fontSize: "14px",
            fontWeight: "500",
            color: "var(--quad-black)",
            letterSpacing: "0.02em",
          },
        },
        {
          props: { variant: "field" },
          style: {
            fontSize: "14px",
            fontWeight: "500",
            color: "var(--quad-black)",
            letterSpacing: "0.02em",
          },
        },
        {
          props: { variant: "department" },
          style: {
            fontSize: "15px",
            fontWeight: "500",
            color: "var(--quad-black)",
          },
        },
      ],
    },
  },
});

export default searchTheme;
