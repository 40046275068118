import { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { MoreProvider, QuadContext } from "@context";
import { SettingsCard, MoreHeader } from "@common/comps";
import { Grid } from "@mui/material";
import {
  SettingsAdminIcon,
  SettingsEditorIcon,
  SettingsDirectoryIcon,
  SettingsCatalogerIcon,
  SettingsCatalogIcon,
  SettingsProgramIcon,
} from "@material/icons";

export const MoreLanding = () => {
  const { sessionQ, quadId } = useContext(QuadContext);
  const { isAdmin, isEditor, catalogables } = sessionQ || {};

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        <MoreHeader title="More" />
      </Grid>
      <Grid item>
        <SettingsCard
          icon={<SettingsCatalogIcon />}
          label="Catalog"
          subLabel="Find a course."
          route={`/q/${quadId}/c`}
        />
      </Grid>
      <Grid item>
        <SettingsCard
          icon={<SettingsProgramIcon />}
          label="Programs"
          subLabel="Find a program."
          route={`/q/${quadId}/p`}
        />
      </Grid>
      {/*       {catalogables.length > 0 && (
        <Grid item>
        <SettingsCard
        icon={<SettingsCatalogerIcon />}
        label="Cataloger"
        subLabel="Manage the course catalog."
        route={`/q/${quadId}/cataloger`}
        />
        </Grid>
      )} */}
      {isEditor && (
        <Grid item>
          <SettingsCard
            icon={<SettingsEditorIcon />}
            label="Editor Center"
            subLabel="Tools for quad editors."
            route={`/q/${quadId}/editor`}
          />
        </Grid>
      )}
      {isAdmin && (
        <Grid item>
          <SettingsCard
            icon={<SettingsAdminIcon />}
            label="Admin Center"
            subLabel="Tools for quad administrators."
            route={`/q/${quadId}/admin`}
          />
        </Grid>
      )}
    </Grid>
  );
};

export const More = () => {
  return (
    <MoreProvider>
      <Outlet />
    </MoreProvider>
  );
};
