import { assign, isObject, replace } from "radash";

// This will only work with React useState

class Synchronizer {
  constructor() {}
  static updatePostFeed(replacement, setFeed) {
    setFeed((prev) => {
      const { postId } = replacement || {};
      const isInArray = prev.some((elem) => elem.postId === postId);
      if (isInArray) {
        return replace(prev, replacement, (elem) => elem.postId === postId);
      } else {
        return prev;
      }
    });
  }
  static updateCourseFeed(replacement, setFeed) {
    setFeed((prev) => {
      const { courseId } = replacement || {};
      const isInArray = prev.some((elem) => elem.courseId === courseId);
      if (isInArray) {
        return replace(prev, replacement, (elem) => elem.courseId === courseId);
      } else {
        return prev;
      }
    });
  }
}

export { Synchronizer };
export default Synchronizer;
