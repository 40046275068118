import { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Ballot } from "@tools";
import { Loading, PageErrorNotice } from "@common/comps";
import { ProgramProvider, ProgramContext } from "@context";
import { Grid, ThemeProvider } from "@mui/material";
import { postTheme } from "@material/themes";

import { ProgramHeader } from "./comps/header/ProgramHeader";
import { ProgramHero } from "./comps/hero/ProgramHero";

import { CommentSection } from "features/comment";

export const ProgramLanding = () => {
  const { program } = useContext(ProgramContext);
  const { postId, quad, totalScore, sessOwnerScore } = program || {};

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        <ProgramHero />
      </Grid>
      <Grid item p={1.5} borderBottom="1px solid var(--border-gray)">
        <Ballot
          variant="full"
          type="post"
          id={postId}
          quad={quad}
          totalScore={totalScore}
          sessOwnerScore={sessOwnerScore}
          alignX="center"
        />
      </Grid>
      <Grid item>
        <CommentSection post={{ postId, quad }} />
      </Grid>
    </Grid>
  );
};

const ProgramContainer = ({ children }) => {
  const { isInitializing, program } = useContext(ProgramContext);

  const content = program ? (
    children
  ) : (
    <PageErrorNotice
      type={404}
      title="Not found"
      subtitle="Could not find program."
      disableHeader
    />
  );

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      sx={{ minHeight: "100vh" }}
    >
      <Grid item sx={{ position: "sticky", top: 0, zIndex: 100 }}>
        <ProgramHeader />
      </Grid>
      <Grid item>{isInitializing ? <Loading p={2} /> : content}</Grid>
    </Grid>
  );
};

export const Program = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <ThemeProvider theme={postTheme}>
      <ProgramProvider>
        <ProgramContainer>
          <Outlet />
        </ProgramContainer>
      </ProgramProvider>
    </ThemeProvider>
  );
};
