import { useState, useEffect, useReducer } from "react";
import { cargo } from "@api";
import { useGlobal, useQuad } from "@context";
import { BasicForm, Loading, Dropdown } from "@common/comps";
import { Grid, Typography } from "@mui/material";
import { isObject } from "radash";

import { Template, Submitter } from "@tools";

export const CourseBuilder = ({
  courseId,
  subjectId,
  onCreate = () => {},
  onEdit = () => {},
  onDelete = () => {},
  isOpen,
  setIsOpen,
}) => {
  // subjectId required for create
  // courseId required for edit

  const { defaultError } = useGlobal();
  const { quadId } = useQuad();
  const isEdit = Boolean(courseId);

  const [isInitializing, setIsInitializing] = useState(true);

  /* Templates */
  const [baseline, setBaseline] = useState(null);
  const [course, setCourse] = useReducer(Template.createReducer(), null);

  const template = new Template(baseline, course, setCourse);

  /* Resources */
  const [options, setOptions] = useState({});
  const { deptOptions, levelOptions } = options || {};

  useEffect(() => {
    setIsInitializing(true);
    cargo
      .get(`/v2/q/${quadId}/r/course/form?subjectId=${subjectId}`)
      .then((res) => {
        const { ok, payload } = res || {};
        if (ok) {
          setOptions({
            deptOptions: payload.deptOptions,
            levelOptions: payload.levelOptions,
          });
          if (!isEdit) {
            setIsInitializing(false);
            setCourse({
              type: "init",
              value: Template.createEmpty(payload.template),
            });
          }
        }
        return payload.template;
      })
      .then((template) => {
        if (isEdit) {
          cargo.get(`/v2/q/${quadId}/course/${courseId}`).then((res) => {
            const { ok, payload } = res || {};
            if (ok) {
              setIsInitializing(false);

              const initial = Template.superimpose(template, payload.course);

              setBaseline(initial);
              setCourse({
                type: "init",
                value: initial,
              });
            }
          });
        }
      });
  }, []);

  /* Form section */
  const [hot, setHot] = useState(false); // Hot is required because when errors are applied to the course template it runs preflight and change checks.

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [deleteState, setDeleteState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState({ isDisabled: false })
  );
  const [submitState, setSubmitState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState()
  );

  const deleter = new Submitter(deleteState, setDeleteState, defaultError);
  const submitter = new Submitter(submitState, setSubmitState, defaultError);

  useEffect(() => {
    if (hot) setHot(false);

    if (!hot && isObject(course)) {
      const preflight = template.preflight();
      const isChanged = template.isChanged();

      submitter.stock(template.crush());
      submitter.green(isEdit ? preflight && isChanged : preflight);
      submitter.resetError();
      deleter.resetError();
    }
  }, [course]);

  const handleCreate = () => {
    template.resetErrors(defaultError);
    submitter.startLoading();
    cargo
      .post(`/v2/q/${quadId}/r/course`, {
        course: {
          subjectId,
          ...template.crush(course),
        },
      })
      .then((res) => {
        const { ok, isClientError, errors } = res || {};
        if (ok) {
          onCreate();
        } else {
          setHot(true);
          submitter.takeResponse(res);
          if (isClientError) template.setErrors(errors);
        }
      });
  };

  const handleEdit = () => {
    template.resetErrors(defaultError);
    submitter.startLoading();
    cargo
      .put(`/v2/q/${quadId}/r/course/${courseId}`, {
        course: template.crush(course),
      })
      .then((res) => {
        const { ok, isClientError, errors, payload } = res || {};
        if (ok) {
          onEdit(payload);
        } else {
          setHot(true);
          submitter.takeResponse(res);
          if (isClientError) template.setErrors(errors);
        }
      });
  };

  const handleDelete = () => {
    template.resetErrors(defaultError);
    deleter.startLoading();
    cargo.delete(`/v2/q/${quadId}/r/course/${courseId}`).then((res) => {
      const { ok } = res || {};
      if (ok) {
        onDelete();
      } else {
        deleter.takeResponse(res);
      }
    });
  };

  const content = isInitializing ? (
    <Loading p={2} />
  ) : (
    isObject(course) && (
      <Grid
        container
        direction="column"
        wrap="nowrap"
        gap={1}
        sx={{ position: "relative", top: 1 }}
      >
        <Grid item>
          <Typography variant="note">
            Course codes must be unique for each subject
          </Typography>
        </Grid>
        <Grid item>
          {template.prefab().input({
            label: "Code",
            field: "code",
          })}
        </Grid>
        <Grid item>
          {template.prefab().input({
            label: "Title",
            field: "title",
          })}
        </Grid>
        <Grid item>
          {template.prefab().numberInput({
            label: "Units",
            field: "units",
          })}
        </Grid>
        <Grid item>
          <Dropdown
            variant="object"
            label="Level"
            state={course.level.value}
            handleChange={(event) =>
              setCourse({ field: "level", value: event.target.value })
            }
            options={levelOptions}
            labelKey="courseLevelName"
            valueKey="courseLevel"
            disableObjectSelect
          />
        </Grid>
        {deptOptions.length > 0 && (
          <Grid item>
            <Dropdown
              variant="object"
              label="Department"
              state={course.department.value}
              handleChange={(event) =>
                setCourse({ field: "department", value: event.target.value })
              }
              options={deptOptions}
              labelKey="deptName"
              valueKey="deptId"
              disableObjectSelect
            />
          </Grid>
        )}
        <Grid item>
          {template.prefab().input({
            multiline: true,
            label: "Description",
            field: "description",
            rows: 10,
          })}
        </Grid>
        <Grid item>
          {template.prefab().input({
            multiline: true,
            label: "Prereqs (optional)",
            field: "prereq",
            rows: 10,
          })}
        </Grid>
        <Grid item>
          {template.prefab().input({
            multiline: true,
            label: "Restricted to (optional)",
            field: "restricted",
            rows: 4,
          })}
        </Grid>
        <Grid item>
          {template.prefab().input({
            multiline: true,
            label: "Priority for (optional)",
            field: "priority",
            rows: 4,
          })}
        </Grid>
        <Grid item>
          {template.prefab().input({
            multiline: true,
            label: "Consent (optional)",
            field: "consent",
            rows: 4,
          })}
        </Grid>
        <Grid item>
          {template.prefab().input({
            multiline: true,
            label: "Notes (optional)",
            field: "notes",
            rows: 10,
          })}
        </Grid>
      </Grid>
    )
  );

  return (
    isOpen && (
      <>
        {isEdit ? (
          <BasicForm
            title="Edit course"
            isFormOpen={isOpen}
            setIsFormOpen={setIsOpen}
            content={content}
            handleDelete={handleDelete}
            deleteTitle="Delete course?"
            deleteSubtitle="Deleting courses will cause issues to elements that depend on this program."
            deleteState={{
              ...{
                isDeleteLoading: deleteState.isLoading,
                isDeleteDisabled: deleteState.isDisabled,
                deleteError: deleteState.error,
              },
              isDeleteOpen,
              setIsDeleteOpen,
            }}
            submitState={{
              isSubmitLoading: submitState.isLoading,
              isSubmitDisabled: submitState.isDisabled,
              submitError: submitState.error,
            }}
            handleSubmit={handleEdit}
          />
        ) : (
          <BasicForm
            title="Create course"
            isFormOpen={isOpen}
            setIsFormOpen={setIsOpen}
            content={content}
            submitState={{
              isSubmitLoading: submitState.isLoading,
              isSubmitDisabled: submitState.isDisabled,
              submitError: submitState.error,
            }}
            handleSubmit={handleCreate}
          />
        )}
      </>
    )
  );
};
