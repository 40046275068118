import { createContext, useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { cargo } from "@api";
import { useQuad, useTimeline } from "@context";
import { Synchronizer } from "@tools";

export const PostContext = createContext();

export const PostProvider = ({ children }) => {
  const { postId: searchId } = useParams();
  const { quadId } = useQuad();
  const { setFeed } = useTimeline();

  // Init
  const [isInitializing, setIsInitializing] = useState(true);

  // Post
  const [post, setPost] = useState(null);

  useEffect(() => {
    setIsInitializing(true);
    cargo.get(`/v2/q/${quadId}/p/post/${searchId}`).then((res) => {
      setIsInitializing(false);
      const { ok, payload } = res || {};
      if (ok) {
        const { post: retrievedPost } = payload || {};
        setPost(retrievedPost);
        setFeed && Synchronizer.updatePostFeed(retrievedPost, setFeed);
      }
    });
  }, [searchId]);

  return (
    <PostContext.Provider value={{ isInitializing, post, setPost }}>
      {children}
    </PostContext.Provider>
  );
};

export const usePost = () => {
  const context = useContext(PostContext);
  if (!context) {
    return null;
  } else {
    return context;
  }
};
