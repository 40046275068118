import {
  Grid,
  Box,
  createTheme,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { GeneralForwardIcon } from "@material/icons";

const optionTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          display: "flex",
        },
      },
      variants: [
        {
          props: { variant: "label" },
          style: {
            fontSize: "14px",
            fontWeight: "500",
            color: "var(--quad-black)",
            letterSpacing: "0.02em",
          },
        },
        {
          props: { variant: "sublabel" },
          style: {
            fontSize: "13px",
            fontWeight: "400",
            color: "var(--quad-gray)",
            letterSpacing: "0.02em",
          },
        },
      ],
    },
  },
});

const Label = ({ label, subLabel }) => {
  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        <Typography variant="label">{label}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="sublabel">{subLabel}</Typography>
      </Grid>
    </Grid>
  );
};

const Selectable = (props) => {
  const { isSelected, containerProps, handleClick } = props || {};

  return (
    <ThemeProvider theme={optionTheme}>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        gap={2}
        onClick={handleClick}
        alignItems="center"
        {...containerProps}
        sx={{
          ":hover": {
            cursor: "pointer",

            ".MuiTypography-label": {
              color: "var(--quad-teal)",
              transition: "color 200ms",
            },
          },
        }}
      >
        {isSelected && (
          <Grid item>
            <Box
              p={1}
              sx={{
                display: "flex",
                backgroundColor: "var(--quad-black)",
                borderRadius: "9999px",

                "#general-icon--forward": {
                  fill: "white",
                },
              }}
            >
              <GeneralForwardIcon width={15} height={15} />
            </Box>
          </Grid>
        )}
        <Grid item sx={{ width: "100%" }}>
          <Label {...props} />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Selectable;
