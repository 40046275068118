import { createTheme } from "@mui/material";

export const requirementGroupTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          overflowWrap: "anywhere",
        },
      },
      variants: [
        {
          props: { variant: "group__label" },
          style: {
            fontSize: "15px",
            fontWeight: "500",
            letterSpacing: "0.01em",
            color: "var(--quad-black)",
          },
        },
        {
          props: { variant: "group__sublabel" },
          style: {
            fontSize: "14px",
            fontWeight: "400",
            letterSpacing: "0.01em",
            color: "var(--quad-gray)",
            whiteSpace: "pre-line",
          },
        },
        {
          props: { variant: "group__choices-badge" },
          style: {
            display: "flex",
            fontSize: "13px",
            fontWeight: "500",
            letterSpacing: "0.02em",
            color: "white",
          },
        },
      ],
    },
  },
});

export const requirementAreaTheme = createTheme({
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: "area__label" },
          style: {
            fontSize: "14px",
            fontWeight: "500",
            letterSpacing: "0.01em",
            color: "var(--quad-black)",
          },
        },
      ],
    },
  },
});
