import { useNavigate } from "react-router-dom";
import { RecoveryProvider, useRecovery } from "@context";

import { FindAccountPage } from "./comps/FindAccountPage";
import { VerifyResetPage } from "./comps/VerifyResetPage";
import { NewPasswordPage } from "./comps/NewPasswordPage";

const Landing = () => {
  const navigate = useNavigate();
  const { page, setPage } = useRecovery();

  const router = () => {
    switch (page) {
      case "account":
        return <FindAccountPage onComplete={() => setPage("verify")} />;
      case "verify":
        return <VerifyResetPage onComplete={() => setPage("password")} />;
      case "password":
        return (
          <NewPasswordPage
            onComplete={() => {
              navigate("/settings/password");
              window.location.reload();
            }}
          />
        );
      default:
        return <></>;
    }
  };

  return router();
};

export const Recovery = () => {
  return (
    <RecoveryProvider>
      <Landing />
    </RecoveryProvider>
  );
};
