import { useState } from "react";
import { CoursePopper } from "@common/comps";
import {
  Grid,
  Typography,
  Box,
  createTheme,
  ThemeProvider,
} from "@mui/material";

const labelTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          letterSpacing: "0.02em",
        },
      },
      variants: [
        {
          props: { variant: "borderless__subject-code" },
          style: {
            fontSize: "13px",
            fontWeight: "500",
            color: "var(--quad-teal)",
          },
        },
        {
          props: { variant: "borderless__course-code" },
          style: {
            fontSize: "13px",
            fontWeight: "500",
            color: "var(--quad-gray)",
          },
        },
        {
          props: { variant: "borderless__course-title" },
          style: {
            fontSize: "14px",
            fontWeight: "500",
            color: "var(--quad-gray)",
          },
        },
        {
          props: { variant: "filled__subject-code" },
          style: {
            fontSize: "12px",
            fontWeight: "700",
            color: "var(--quad-teal)",
          },
        },
        {
          props: { variant: "filled__course-code" },
          style: {
            fontSize: "12px",
            fontWeight: "700",
            color: "var(--quad-gray)",
          },
        },
        {
          props: { variant: "filled__course-title" },
          style: {
            fontSize: "14px",
            fontWeight: "500",
            color: "var(--quad-gray)",
            overflowWrap: "anywhere",
          },
        },
        {
          props: { variant: "bubble__subject-code" },
          style: {
            fontSize: "12px",
            fontWeight: "700",
            color: "var(--quad-pewter)",
          },
        },
        {
          props: { variant: "bubble__course-code" },
          style: {
            fontSize: "12px",
            fontWeight: "700",
            color: "var(--quad-pewter)",
          },
        },
      ],
    },
  },
});

const Outlined = ({ course }) => {
  const { subjectCode, courseCode, courseTitle } = course || {};
};

const Filled = ({ course, hideTitle, typographyProps }) => {
  const { subjectCode, courseCode, courseTitle } = course || {};
  const { subjectCodeProps, courseCodeProps, courseTitleProps } =
    typographyProps || {};

  const code = (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      alignItems="center"
      sx={{
        backgroundColor: "var(--quad-gray-light)",
        padding: "2px 12px 4px",
        borderRadius: "3px",
      }}
    >
      <Grid item>
        <Typography variant="filled__subject-code" sx={{ ...subjectCodeProps }}>
          {subjectCode}
        </Typography>
      </Grid>
      <Grid item sx={{ marginLeft: "5px" }}>
        <Typography variant="filled__course-code" sx={{ ...courseCodeProps }}>
          {courseCode}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <ThemeProvider theme={labelTheme}>
      <Grid container direction="row" wrap="nowrap" gap={1.5}>
        <Grid item>{code}</Grid>
        {!hideTitle && (
          <Grid item sx={{ position: "relative", top: 2 }}>
            <Typography
              variant="filled__course-title"
              sx={{ ...courseTitleProps }}
            >
              {courseTitle}
            </Typography>
          </Grid>
        )}
      </Grid>
    </ThemeProvider>
  );
};

const Borderless = ({ course, hideTitle, typographyProps }) => {
  const { subjectCode, courseCode, courseTitle } = course || {};
  const { subjectCodeProps, courseCodeProps, courseTitleProps } =
    typographyProps || {};

  return (
    <ThemeProvider theme={labelTheme}>
      <Grid container direction="row" wrap="nowrap" alignItems="center">
        <Grid item>
          <Typography
            variant="borderless__subject-code"
            sx={{ ...subjectCodeProps }}
          >
            {subjectCode}
          </Typography>
        </Grid>
        <Grid item sx={{ marginLeft: "5px" }}>
          <Typography
            variant="borderless__course-code"
            sx={{ ...courseCodeProps }}
          >
            {courseCode}
          </Typography>
        </Grid>
        {!hideTitle && (
          <Grid item sx={{ margin: "0px 10px" }}>
            <Box
              sx={{
                position: "relative",
                top: 1,
                width: "1px",
                height: "0.7em",
                backgroundColor: "var(--div-gray)",
              }}
            />
          </Grid>
        )}
        {!hideTitle && (
          <Grid item>
            <Typography
              variant="borderless__course-title"
              sx={{ ...courseTitleProps }}
            >
              {courseTitle}
            </Typography>
          </Grid>
        )}
      </Grid>
    </ThemeProvider>
  );
};

const Bubble = ({ course, typographyProps }) => {
  const { subjectCode, courseCode } = course || {};
  const { subjectCodeProps, courseCodeProps } = typographyProps || {};

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopperOpen = (event) => {
    event.stopPropagation();
    const { currentTarget } = event || {};
    setAnchorEl(currentTarget);
  };

  const handlePopperClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <ThemeProvider theme={labelTheme}>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        alignItems="center"
        onMouseEnter={handlePopperOpen}
        onMouseLeave={handlePopperClose}
        sx={{
          padding: "0px 12px 0.2em",
          border: "1px solid var(--attachment-border-gray)",
          borderRadius: "9999px",
        }}
      >
        <Grid item>
          <Typography
            variant="bubble__subject-code"
            sx={{ ...subjectCodeProps }}
          >
            {subjectCode}
          </Typography>
        </Grid>
        <Grid item sx={{ margin: "0px 5px" }}>
          <Box
            sx={{
              position: "relative",
              top: 2,
              width: "1px",
              height: "0.6em",
              backgroundColor: "var(--div-gray)",
            }}
          />
        </Grid>
        <Grid item>
          <Typography variant="bubble__course-code" sx={{ ...courseCodeProps }}>
            {courseCode}
          </Typography>
        </Grid>
      </Grid>
      {anchorEl && <CoursePopper anchorEl={anchorEl} course={course} />}
    </ThemeProvider>
  );
};

const CourseLabel = (props) => {
  const { variant } = props || {};

  switch (variant) {
    case "outlined":
      return <Outlined {...props} />;
    case "filled":
      return <Filled {...props} />;
    case "borderless":
      return <Borderless {...props} />;
    case "bubble":
      return <Bubble {...props} />;
    default:
      throw new Error("Please indicate course label variant.");
  }
};

export default CourseLabel;
