import { useContext, useState } from "react";
import { CatalogContext } from "@context";
import { BubbleDropdown, Clickable, EmptyLabel } from "@common/comps";
import { Grid, Button, Box, ThemeProvider } from "@mui/material";
import { buttonTheme } from "@material/themes";
import { unique } from "radash";

const Filters = ({ catOptions, category, setCategory }) => {
  const clearButton = (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant="outlined"
        onClick={(event) => {
          event.stopPropagation();
          setCategory("");
        }}
        sx={{ color: "var(--quad-red)" }}
      >
        Clear
      </Button>
    </ThemeProvider>
  );

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item borderBottom="1px solid var(--border-gray)">
        <Grid container direction="row" gap={1.5} p={2}>
          {category && <Grid item>{clearButton}</Grid>}
          <Grid item sx={{ minWidth: "200px" }}>
            <BubbleDropdown
              variant="object"
              label="Category"
              state={category}
              setState={setCategory}
              options={catOptions}
              labelKey="subjectCatName"
              valueKey="subjectCatId"
              disableObjectSelect
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const CatalogMenu = () => {
  const { subjectOptions, setSubject } = useContext(CatalogContext);

  const catOptions = unique(subjectOptions, (i) => i.subjectCatId).map((i) => {
    return {
      subjectCatId: i.subjectCatId,
      subjectCatName: i.subjectCatName,
    };
  });

  const [category, setCategory] = useState("");

  const feedOptions = category
    ? subjectOptions.filter((i) => i.subjectCatId === category)
    : subjectOptions;

  const subjectFeed = feedOptions.map((s) => (
    <Grid item key={s.subjectId}>
      <Box borderBottom="1px solid var(--border-gray)">
        <Clickable
          label={s.subjectCode}
          subLabel={s.subjectName}
          handleClick={() => {
            window.scrollTo({ top: 0 });
            setSubject(s);
          }}
          containerProps={{ p: 1.5 }}
        />
      </Box>
    </Grid>
  ));

  return (
    <Grid container direction="column" wrap="nowrap">
      {catOptions.length > 0 && (
        <Grid item>
          <Filters
            catOptions={catOptions}
            category={category}
            setCategory={setCategory}
          />
        </Grid>
      )}
      <Grid item>
        <Grid container direction="column">
          {subjectFeed.length === 0 ? (
            <EmptyLabel label="No subjects added." p={2} />
          ) : (
            subjectFeed
          )}
          <Grid item height={200} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CatalogMenu;
