import { createTheme } from "@mui/material";

export const dropdownTheme = createTheme({
  components: {
    MuiSelect: {
      styleOverrides: {
        root: {
          outline: "1px solid var(--input-border-gray)",
          borderRadius: "5px",

          "&.Mui-focused": {
            outline: "2px solid var(--quad-teal)",
          },

          "&.Mui-disabled": {
            outline: "none",
            backgroundColor: `var(--input-border-gray) !important`,
          },

          "&.Mui-error": {
            outline: "2px solid var(--quad-red)",
          },
        },
      },
    },
    MuiNativeSelect: {
      styleOverrides: {
        select: {
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent !important",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "16px",
          color: "var(--quad-gray)",

          "&.Mui-focused": {
            color: "var(--quad-teal)",
          },

          "&.Mui-disabled": {
            color: "var(--quad-gray-alt)",
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: "5px",
          backgroundColor: "transparent",
        },
        input: {
          color: "var(--quad-black)",
        },
      },
    },
  },
});

export default dropdownTheme;
