import { Action, ConfirmForm } from "@common/comps";
import { Grid } from "@mui/material";
import { ActionApproveIcon, ActionRejectIcon } from "@material/icons";

export const Moderator = ({
  handleApprove,
  handleReject,
  approveParams,
  rejectParams,
}) => {
  const {
    title: approveTitle,
    subtitle: approveSubtitle,
    isApproveOpen,
    setIsApproveOpen,
    isApproveLoading,
    isApproveDisabled,
    approveError,
  } = approveParams || {};

  const {
    title: rejectTitle,
    subtitle: rejectSubtitle,
    isRejectOpen,
    setIsRejectOpen,
    isRejectLoading,
    isRejectDisabled,
    rejectError,
  } = rejectParams || {};

  const approveButton = (
    <Action
      icon={<ActionApproveIcon />}
      handleClick={() => setIsApproveOpen(true)}
      onHoverSx={{
        iconSx: {
          backgroundColor: "var(--quad-teal-light)",

          "#action-icon--approve": {
            fill: "var(--quad-teal)",
            transition: "fill 200ms",
          },
        },
      }}
    />
  );

  const rejectButton = (
    <Action
      icon={<ActionRejectIcon />}
      handleClick={() => setIsRejectOpen(true)}
      onHoverSx={{
        iconSx: {
          backgroundColor: "var(--quad-pink-light)",

          "#action-icon--reject": {
            fill: "var(--quad-pink)",
            backgroundColor: "fill 200ms",
          },
        },
      }}
    />
  );

  const approveForm = (
    <ConfirmForm
      title={approveTitle}
      subtitle={approveSubtitle}
      isFormOpen={isApproveOpen}
      setIsFormOpen={setIsApproveOpen}
      color="teal"
      submitLabel="Approve"
      handleSubmit={handleApprove}
      submitState={{
        isSubmitLoading: isApproveLoading,
        isSubmitDisabled: isApproveDisabled,
        submitError: approveError,
      }}
    />
  );

  const rejectForm = (
    <ConfirmForm
      title={rejectTitle}
      subtitle={rejectSubtitle}
      isFormOpen={isRejectOpen}
      setIsFormOpen={setIsRejectOpen}
      color="red"
      submitLabel="Reject"
      handleSubmit={handleReject}
      submitState={{
        isSubmitLoading: isRejectLoading,
        isSubmitDisabled: isRejectDisabled,
        submitError: rejectError,
      }}
    />
  );

  return (
    <Grid container direction="row" wrap="nowrap" gap={1}>
      <Grid item>
        {approveButton}
        {isApproveOpen && approveForm}
      </Grid>
      <Grid item>
        {rejectButton}
        {isRejectOpen && rejectForm}
      </Grid>
    </Grid>
  );
};
