import { forwardRef, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { NotifProvider, NotifContext, GlobalContext } from "@context";
import {
  BasicHeader,
  BubbleDropdown,
  EmptyLabel,
  Loading,
  BasicAvatar,
} from "@common/comps";
import { Grid, Typography, ThemeProvider } from "@mui/material";

import { notifTheme } from "./theme";

const Card = forwardRef(({ n }, ref) => {
  const navigate = useNavigate();
  const { avatarSrc, username, name, quad, postId } = n || {};

  return (
    <article ref={ref}>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        px={2}
        py={1.5}
        gap={2}
        borderBottom="1px solid var(--border-gray)"
        alignItems="center"
        onClick={() => navigate(`/q/${quad}/post/${postId}`)}
        sx={{
          ":hover": {
            cursor: "pointer",
            backgroundColor: "var(--card-hover-gray)",
            transition: "background-color 200ms",
          },
        }}
      >
        <Grid item>
          <BasicAvatar
            username={username}
            avatarSrc={avatarSrc}
            diameter={35}
          />
        </Grid>
        <Grid item>
          <Typography variant="label" gap={0.5}>
            <b>{name}</b> responded to you.
          </Typography>
        </Grid>
      </Grid>
    </article>
  );
});

const Feed = () => {
  const { isLoading, feed, lastCardRef } = useContext(NotifContext);

  if (isLoading) {
    return <Loading p={2} />;
  } else {
    return (
      <Grid container direction="column" wrap="nowrap">
        {feed.length === 0 ? (
          <EmptyLabel label="No notifications found." p={2} />
        ) : (
          feed.map((n, i) => (
            <Grid item key={n.notifId}>
              <Card ref={feed.length === i + 1 ? lastCardRef : null} n={n} />
            </Grid>
          ))
        )}
      </Grid>
    );
  }
};

export const NotifLanding = () => {
  const {
    session: {
      options: { quadOptions },
    },
  } = useContext(GlobalContext);
  const { quadF, setQuadF } = useContext(NotifContext);

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      sx={{ minHeight: "100vh" }}
    >
      <Grid item sx={{ position: "sticky", top: 0, zIndex: 100 }}>
        <BasicHeader title="Notifications" />
      </Grid>
      <Grid item p={2} borderBottom="1px solid var(--border-gray)">
        <BubbleDropdown
          variant="object"
          state={quadF}
          setState={setQuadF}
          options={quadOptions}
          labelKey="quadShort"
          valueKey="quad"
        />
      </Grid>
      <Grid item>
        <Feed />
      </Grid>
    </Grid>
  );
};

export const Notif = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <NotifProvider>
      <ThemeProvider theme={notifTheme}>
        <NotifLanding />
      </ThemeProvider>
    </NotifProvider>
  );
};
