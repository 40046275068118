import { useCourse } from "@context";
import {
  Detail,
  CourseReqLabel,
  CourseCoreLabel,
  Divider,
} from "@common/comps";
import { getCourseLevelIcon } from "@common/utility";
import { Grid, Box } from "@mui/material";

export const CourseDetails = () => {
  const { course } = useCourse();

  const {
    creqs,
    courseCores,
    courseUnits,
    courseLevel,
    courseLevelName,
    deptName,
    coursePre,
    courseRestricted,
    coursePriority,
    courseNotes,
    courseConsent,
  } = course || {};

  const reqLabels =
    Array.isArray(creqs) && creqs.length > 0 ? (
      <Grid container direction="row" gap={0.5}>
        {creqs.map((req) => {
          return (
            <Grid item key={req.creqId}>
              <CourseReqLabel req={req} />
            </Grid>
          );
        })}
      </Grid>
    ) : (
      <Box>
        <CourseReqLabel isNull />
      </Box>
    );

  const coreLabels =
    Array.isArray(courseCores) && courseCores.length > 0 ? (
      <Grid container direction="row" gap={0.5}>
        {courseCores.map((core) => {
          return (
            <Grid item key={core.courseCoreId}>
              <CourseCoreLabel core={core} />
            </Grid>
          );
        })}
      </Grid>
    ) : (
      <Box>
        <CourseCoreLabel isNull />
      </Box>
    );

  const moreLeft = (
    <Grid container direction="column" wrap="nowrap" gap={0.5}>
      <Grid item>
        <Detail type="req" labelComponent={reqLabels} labelLineHeight="24px" />
      </Grid>
      <Grid item>
        <Detail
          type="core"
          labelComponent={coreLabels}
          labelLineHeight="24px"
        />
      </Grid>
    </Grid>
  );

  const moreRight = (
    <Grid container direction="column" wrap="nowrap" gap={0.5}>
      <Grid item>
        <Detail type="units" label={`${Number(courseUnits)} Units`} />
      </Grid>
      <Grid item>
        <Detail
          icon={getCourseLevelIcon(courseLevel)}
          label={courseLevelName}
        />
      </Grid>
    </Grid>
  );

  const more = (
    <Grid container direction="row" wrap="nowrap" gap={1.5}>
      <Grid item sx={{ width: "60%" }}>
        {moreLeft}
      </Grid>

      <Divider height="inherit" />

      <Grid item>{moreRight}</Grid>
    </Grid>
  );

  return (
    <Grid container direction="column" wrap="nowrap" gap={0.5}>
      {deptName && (
        <Grid item>
          <Detail type="dept" iconSize={18} label={deptName} />
        </Grid>
      )}
      {courseConsent && (
        <Grid item>
          <Detail type="consent" iconSize={18} label={courseConsent} />
        </Grid>
      )}
      {coursePre && (
        <Grid item>
          <Detail type="prereq" iconSize={18} label={coursePre} />
        </Grid>
      )}
      {courseRestricted && (
        <Grid item>
          <Detail type="restricted" iconSize={18} label={courseRestricted} />
        </Grid>
      )}
      {coursePriority && (
        <Grid item>
          <Detail type="priority" iconSize={18} label={coursePriority} />
        </Grid>
      )}
      {courseNotes && (
        <Grid item>
          <Detail type="notes" iconSize={18} label={courseNotes} />
        </Grid>
      )}
      <Grid item mt={1}>
        {more}
      </Grid>
    </Grid>
  );
};
