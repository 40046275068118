import { createTheme } from "@mui/material";

const popperTheme = createTheme({
  components: {
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "0px 0px 5px 0px rgb(205, 209, 209)",
          padding: "5px",
          borderRadius: "5px",
        },
      },
    },
    MuiMenuItem: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          borderRadius: "5px",
          padding: "8px 15px 8px 10px",

          "&:hover": {
            backgroundColor: "rgb(245, 247, 247)",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: "15px",
          fontWeight: "400",
          color: "rgb(15, 22, 22)",
          paddingTop: "1px",
        },
      },
    },
  },
});

export default popperTheme;
