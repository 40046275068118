import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  Button,
  Grid,
  Typography,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { dropdownTheme, buttonTheme } from "@material/themes";
import { ButtonDropdownIcon, ButtonBinIcon } from "@material/icons";
import { omit } from "radash";

const DropdownIcon = () => (
  <Box
    sx={{
      pointerEvents: "none",
      position: "absolute",
      top: 20,
      right: 15,
    }}
  >
    <ButtonDropdownIcon width={18} height={18} />
  </Box>
);

const Object = ({
  label,
  state,
  setState,
  handleChange,
  options,
  labelKey,
  valueKey,
  disableObjectSelect,
  disabled,
  error,
}) => {
  const { isError, errorText } = error || {};

  const onChange = handleChange
    ? handleChange
    : (event) => {
        if (disableObjectSelect) {
          setState(event.target.value);
        } else {
          setState(options.find((o) => o[valueKey] === event.target.value));
        }
      };

  return (
    <ThemeProvider theme={dropdownTheme}>
      <FormControl
        variant="filled"
        error={isError}
        disabled={disabled}
        sx={{ width: "100%" }}
      >
        <InputLabel shrink={Boolean(state)}>
          {label ? label : "Select"}
        </InputLabel>
        <Select
          native
          value={state ? (disableObjectSelect ? state : state[valueKey]) : ""}
          disableUnderline
          onChange={onChange}
          IconComponent={() => <Box />}
          sx={{
            width: "100%",
          }}
        >
          <option disabled />
          {options.map((o) => (
            <option key={o[valueKey]} value={o[valueKey]}>
              {o[labelKey]}
            </option>
          ))}
        </Select>
        {isError && errorText && <FormHelperText>{errorText}</FormHelperText>}
        <DropdownIcon />
      </FormControl>
    </ThemeProvider>
  );
};

const Simple = ({
  label,
  options,
  state,
  setState,
  handleChange,
  disabled,
  error,
}) => {
  const { isError, errorText } = error || {};

  const onChange = handleChange
    ? handleChange
    : (event) => setState(event.target.value);

  return (
    <ThemeProvider theme={dropdownTheme}>
      <FormControl
        variant="filled"
        error={isError}
        disabled={disabled}
        sx={{
          width: "100%",
        }}
      >
        <InputLabel shrink={Boolean(state)}>
          {label ? label : "Select"}
        </InputLabel>
        <Select
          native
          value={state ? state : ""}
          disableUnderline
          onChange={onChange}
          IconComponent={() => <Box />}
          sx={{
            width: "100%",
          }}
        >
          <option disabled />
          {options.map((o) => (
            <option key={o} value={o}>
              {o}
            </option>
          ))}
        </Select>
        {isError && errorText && <FormHelperText>{errorText}</FormHelperText>}
        <DropdownIcon />
      </FormControl>
    </ThemeProvider>
  );
};

const labelTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          display: "flex",
        },
      },
      variants: [
        {
          props: { variant: "label" },
          style: {
            fontSize: "16px",
            fontWeight: "400",
            color: "var(--quad-black)",
            letterSpacing: "0.01em",
          },
        },
        {
          props: { variant: "label2" },
          style: {
            fontSize: "15px",
            fontWeight: "500",
            color: "var(--quad-black)",
            letterSpacing: "0.02em",
          },
        },
        {
          props: { variant: "sublabel2" },
          style: {
            fontSize: "14px",
            fontWeight: "400",
            color: "var(--quad-gray)",
            letterSpacing: "0.02em",
          },
        },
      ],
    },
  },
});

const Label = ({ label, subLabel }) => {
  return (
    <ThemeProvider theme={labelTheme}>
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <Typography variant={subLabel ? "label2" : "label"}>
            {label}
          </Typography>
        </Grid>
        {subLabel && (
          <Grid item>
            <Typography variant="sublabel2">{subLabel}</Typography>
          </Grid>
        )}
      </Grid>
    </ThemeProvider>
  );
};

const Card = ({ startAdornment, label, subLabel, handleDelete }) => {
  return (
    <Grid container direction="row" wrap="nowrap" alignItems="center" gap={2}>
      <Grid
        item
        sx={{
          width: "100%",
          border: "1px solid var(--input-border-gray)",
          borderRadius: "5px",
          padding: subLabel ? "10px 12px" : "15px 12px",
        }}
      >
        <Grid
          container
          direction="row"
          wrap="nowrap"
          alignItems="center"
          gap={1}
        >
          {startAdornment && <Grid item>{startAdornment}</Grid>}
          <Grid item>
            <Label label={label} subLabel={subLabel} />
          </Grid>
        </Grid>
      </Grid>
      {handleDelete && (
        <Grid item>
          <ThemeProvider theme={buttonTheme}>
            <Button
              variant="icon"
              onClick={handleDelete}
              sx={{
                ":hover": {
                  backgroundColor: "var(--quad-pink-light)",
                  "#button-icon--bin": {
                    fill: "var(--quad-red)",
                    transition: "fill 50ms",
                  },
                },
              }}
            >
              <ButtonBinIcon width={18} height={18} />
            </Button>
          </ThemeProvider>
        </Grid>
      )}
    </Grid>
  );
};

const Dropdown = (props) => {
  const { card, variant } = props || {};

  if (card) {
    return <Card {...props} />;
  } else {
    switch (variant) {
      case "object":
        return <Object {...omit(props, ["variant"])} />;
      default:
        return <Simple {...omit(props, ["variant"])} />;
    }
  }
};

export default Dropdown;
