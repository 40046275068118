import { createTheme } from "@mui/material";

export const timelineTheme = createTheme({
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: "notice" },
          style: {
            fontSize: "15px",
            fontWeight: "400",
            color: "var(--quad-gray)",
          },
        },
      ],
    },
  },
});
