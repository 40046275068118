import { createTheme } from "@mui/material";

const multipleSelectTheme = createTheme({
  components: {
    MuiSelect: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          color: "var(--quad-black)",
          fontSize: "16px",
          backgroundColor: "transparent",
          border: "1px solid",
          borderColor: "var(--input-border-gray)",
          borderRadius: "5px",

          "&:hover": {
            backgroundColor: "transparent",
          },

          "&.Mui-focused": {
            backgroundColor: "transparent",
            border: "2px solid",
            borderColor: "var(--quad-teal)",
          },

          "&.Mui-disabled": {
            border: "none",
            backgroundColor: `var(--select-bg--disabled) !important`,
          },
        },
        multiple: {
          backgroundColor: "transparent !important",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent !important",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "var(--quad-gray)",
          fontSize: "16px",

          "&.Mui-focused": {
            color: "var(--quad-teal)",
          },

          "&.Mui-disabled": {
            color: "var(--select-label--disabled)",
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        input: {
          paddingRight: "40px !important",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          transition: "none !important",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent !important",
          fontSize: "15px",
          fontWeight: "400",
          color: "var(--quad-black)",

          "&:hover": {
            backgroundColor: "var(--list-hover-gray) !important",
          },

          "&[aria-selected=true]": {
            backgroundColor: "var(--list-teal) !important",

            "&:hover": {
              backgroundColor: "var(--list-hover-teal) !important",
            },

            "&.Mui-focused": {
              backgroundColor: "var(--list-hover-teal--focused) !important",
            },
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "0px 0px 5px 0px var(--paper-shadow-gray)",
          borderRadius: "5px",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          border: "1px solid var(--attachment-border-gray)",
          fontSize: "12px",
          fontWeight: "500",
          color: "var(--quad-gray)",
          letterSpacing: "0.02em",
        },
      },
    },
  },
});

export default multipleSelectTheme;
