import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Opener, Builder } from "@tools";
import { Grid, Avatar, Typography } from "@mui/material";
import { DefaultUniAltAvatar } from "@material/images";

export const EducationCard = ({ entry, setFeed, runRefresh }) => {
  const navigate = useNavigate();

  const {
    eduId,
    uni,
    degree,
    area,
    startYear,
    endYear,
    isCurrent,
    description,
    canEdit,
    orgAvatarSrc,
    programId,
    quad,
  } = entry || {};

  const [isDeleted, setIsDeleted] = useState(false);

  useEffect(() => {
    if (isDeleted) {
      setTimeout(
        () => setFeed((prev) => prev.filter((p) => p.eduId !== eduId)),
        200
      );
    }
  }, [isDeleted]);

  const getYears = () => {
    if (isCurrent) {
      return `${startYear} - Present`;
    } else {
      if (startYear && endYear) {
        return `${startYear} - ${endYear}`;
      } else {
        return `${startYear}`;
      }
    }
  };

  const content = (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        <Typography variant="uni__name">{uni}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="uni__degree" sx={{ letterSpacing: "0.02em" }}>
          {`${degree}` + `${area ? `, ${area}` : ""}`}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="year">{getYears()}</Typography>
      </Grid>
      {description && (
        <Grid item mt={1}>
          <Typography variant="desc">{description}</Typography>
        </Grid>
      )}
    </Grid>
  );

  const Edit = () => (
    <Opener
      variant="icon"
      icon="edit"
      builder={Builder.education({
        unedited: entry,
        onEdit: runRefresh,
        onDelete: runRefresh,
      })}
    />
  );

  const actions = (
    <Grid container direction="row" wrap="nowrap">
      <Grid item>
        <Edit />
      </Grid>
    </Grid>
  );

  const css = {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "var(--card-hover-gray)",
      transition: "background-color 200ms",
    },
  };

  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      p={2}
      gap={2}
      borderBottom="1px solid var(--border-gray)"
      className={isDeleted ? "card--empty" : ""}
      sx={isDeleted ? {} : css}
      onClick={() => {
        !isDeleted && navigate(`/q/${quad}/p/${programId}`);
      }}
    >
      {!isDeleted && (
        <>
          <Grid item>
            <Avatar
              width={45}
              height={45}
              src={orgAvatarSrc ? orgAvatarSrc : DefaultUniAltAvatar}
              sx={{
                borderRadius: "5px",
              }}
            />
          </Grid>
          <Grid item sx={{ width: "100%" }}>
            {content}
          </Grid>
          {canEdit && (
            <Grid item sx={{ width: "0px", position: "relative", right: 35 }}>
              {actions}
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};
