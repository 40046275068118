import { useNavigate } from "react-router-dom";
import { SettingsForwardIcon } from "@material/icons";
import { Grid, Box, Typography } from "@mui/material";
import { isMobile } from "react-device-detect";
import "@material/styles/settingsStyles.css";

const SettingsCard = ({ icon, label, subLabel, route, handleClick, props }) => {
  const navigate = useNavigate();

  const menuDetails = (
    <Grid container direction="row" wrap="nowrap" alignItems="center" gap={4}>
      {icon && (
        <Grid item ml={!isMobile ? 2 : 0}>
          <Box
            sx={{
              display: "flex",
              width: "25px",
              height: "25px",
            }}
          >
            {icon}
          </Box>
        </Grid>
      )}
      <Grid item sx={{ width: "100%" }}>
        <Grid container direction="column" wrap="nowrap">
          <Grid item>
            <Typography
              sx={{
                fontSize: "15px",
                fontWeight: "400",
                color: "var(--quad-black)",
                letterSpacing: "0.01em",
              }}
            >
              {label}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              sx={{
                fontSize: "13px",
                fontWeight: "400",
                color: "var(--quad-gray-alt)",
                letterSpacing: "0.02em",
              }}
            >
              {subLabel}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const settingsSx = {
    "#settings-icon--forward": {
      fill: "var(--quad-gray-alt)",
    },

    "&:hover": {
      backgroundColor: "var(--card-hover-gray)",
      cursor: "pointer",
    },
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      wrap="nowrap"
      p={2}
      pb={2.1}
      sx={settingsSx}
      onClick={() => {
        if (handleClick) {
          handleClick();
        } else {
          typeof route === "string" &&
            navigate(route, {
              state: {
                props,
              },
            });
        }
      }}
      className="settings-card"
    >
      <Grid item sx={{ position: "relative", bottom: 1 }}>
        {menuDetails}
      </Grid>
      <Grid item>
        <SettingsForwardIcon width={15} height={15} />
      </Grid>
    </Grid>
  );
};

export default SettingsCard;
