import { useContext, useState, useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";
import { GlobalContext } from "@context";
import { cargo, masterAPI } from "@api";
import {
  Loading,
  BasicForm,
  OrganizationSearch,
  Dropdown,
  BasicCheckbox,
} from "@common/comps";
import { isObject } from "radash";
import { Grid } from "@mui/material";

import { Template, Submitter } from "@tools";

export const JobBuilder = ({
  unedited,
  isOpen,
  setIsOpen,
  onCreate = () => {},
  onEdit = () => {},
  onDelete = () => {},
}) => {
  const { defaultError } = useContext(GlobalContext);
  const { username } = useParams();
  const isEdit = Boolean(unedited);

  const [isInitializing, setIsInitializing] = useState(true);

  /* Templates */
  const [baseline, setBaseline] = useState(null);
  const [job, setJob] = useReducer(Template.createReducer(), null);

  const template = new Template(baseline, job, setJob);

  /* Resources */
  const [options, setOptions] = useState({});
  const { startOptions, endOptions } = options || {};

  useEffect(() => {
    setIsInitializing(true);
    cargo.get(`/v2/u/${username}/job/form`).then((res) => {
      const { ok, payload } = res || {};
      if (ok) {
        setIsInitializing(false);
        setOptions(payload.options);

        const initial = isEdit
          ? Template.superimpose(payload.template, unedited)
          : Template.createEmpty(payload.template);

        if (isEdit) setBaseline(initial);
        setJob({
          type: "init",
          value: initial,
        });
      }
    });
  }, []);

  /* Form section  */
  const { jobId, orgId, orgAvatarSrc, organization: orgName } = unedited || {};

  const [hot, setHot] = useState(false);
  const [isEndYearDisabled, setIsEndYearDisabled] = useState(
    unedited ? unedited.isCurrent : false
  );
  const [organization, setOrganization] = useState(
    orgId ? { orgId, orgAvatarSrc, orgName } : null
  );

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [deleteState, setDeleteState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState({ isDisabled: false })
  );
  const [submitState, setSubmitState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState()
  );

  const deleter = new Submitter(deleteState, setDeleteState, defaultError);
  const submitter = new Submitter(submitState, setSubmitState, defaultError);

  useEffect(() => {
    if (hot) setHot(false);

    if (!hot && isObject(job)) {
      const preflight = template.preflight();
      const isChanged = template.isChanged();

      submitter.stock(template.crush());
      submitter.green(isEdit ? preflight && isChanged : preflight);
      submitter.resetError();
      deleter.resetError();
    }
  }, [job]);

  const handleCreate = () => {
    template.resetErrors(defaultError);
    submitter.startLoading();
    cargo
      .post(`/v2/u/${username}/job`, {
        job: template.crush(job),
      })
      .then((res) => {
        const { ok, isClientError, errors } = res || {};
        if (ok) {
          onCreate();
        } else {
          setHot(true);
          submitter.takeResponse(res);
          if (isClientError) {
            template.setErrors(errors);
          }
        }
      });
  };

  const handleEdit = () => {
    template.resetErrors(defaultError);
    submitter.startLoading();
    cargo
      .put(`/v2/u/${username}/job/${jobId}`, {
        job: template.crush(job),
      })
      .then((res) => {
        const { ok, isClientError, errors } = res || {};
        if (ok) {
          onEdit();
        } else {
          setHot(true);
          submitter.takeResponse(res);
          if (isClientError) {
            template.setErrors(errors);
          }
        }
      });
  };

  const handleDelete = () => {
    template.resetErrors(defaultError);
    deleter.startLoading();
    cargo.delete(`/v2/u/${username}/job/${jobId}`).then((res) => {
      const { ok } = res || {};
      if (ok) {
        onDelete();
      } else {
        deleter.takeResponse(res);
      }
    });
  };

  const content = isInitializing ? (
    <Loading p={2} />
  ) : (
    isObject(job) && (
      <Grid container direction="column" wrap="nowrap" gap={1}>
        <Grid item>
          <OrganizationSearch
            organization={organization}
            setOrganization={setOrganization}
            handleInputChange={(event) => {
              setJob({
                field: "orgId",
                value: null,
              });
              setJob({
                field: "organization",
                value: event.target.value,
              });
            }}
            handleChange={(event, val) => {
              setJob({ field: "orgId", value: val.orgId });
              setJob({ field: "organization", value: val.orgName });
            }}
          />
        </Grid>
        <Grid item>
          {template.prefab().input({
            label: "Position",
            field: "position",
          })}
        </Grid>
        <Grid item>
          {template.prefab().input({
            label: "Location",
            field: "location",
          })}
        </Grid>
        <Grid item>
          <Grid container direction="row" wrap="nowrap" gap={1}>
            <Grid item sx={{ width: "50%" }}>
              <Dropdown
                label="Start year"
                state={job.startYear.value}
                handleChange={(event) =>
                  setJob({
                    field: "startYear",
                    value: event.target.value,
                  })
                }
                options={startOptions}
                error={job.startYear.error}
              />
            </Grid>
            <Grid item sx={{ width: "50%" }}>
              <Dropdown
                label="End year"
                state={job.endYear.value}
                handleChange={(event) =>
                  setJob({
                    field: "endYear",
                    value: event.target.value,
                  })
                }
                options={endOptions}
                disabled={isEndYearDisabled}
                error={job.endYear.error}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item px={1.5} mb={1}>
          <BasicCheckbox
            label="I current go here"
            checked={job.isCurrent.value}
            handleChange={(event) => {
              setJob({
                field: "isCurrent",
                value: event.target.checked,
              });
              if (event.target.checked)
                setJob({ field: "endYear", value: null });
              setIsEndYearDisabled(event.target.checked);
            }}
          />
        </Grid>
        <Grid item>
          {template.prefab().input({
            multiline: true,
            label: "Description",
            field: "description",
            rows: 10,
          })}
        </Grid>
      </Grid>
    )
  );

  return (
    isOpen && (
      <>
        {isEdit ? (
          <BasicForm
            title="Edit job"
            isFormOpen={isOpen}
            setIsFormOpen={setIsOpen}
            content={content}
            handleDelete={handleDelete}
            deleteTitle="Delete job?"
            deleteSubtitle="This action is irreversible. You will not be able to recover this entry."
            deleteState={{
              ...{
                isDeleteLoading: deleteState.isLoading,
                isDeleteDisabled: deleteState.isDisabled,
                deleteError: deleteState.error,
              },
              isDeleteOpen,
              setIsDeleteOpen,
            }}
            submitState={{
              isSubmitLoading: submitState.isLoading,
              isSubmitDisabled: submitState.isDisabled,
              submitError: submitState.error,
            }}
            handleSubmit={handleEdit}
          />
        ) : (
          <BasicForm
            title="Create job"
            isFormOpen={isOpen}
            setIsFormOpen={setIsOpen}
            content={content}
            submitState={{
              isSubmitLoading: submitState.isLoading,
              isSubmitDisabled: submitState.isDisabled,
              submitError: submitState.error,
            }}
            handleSubmit={handleCreate}
          />
        )}
      </>
    )
  );
};
