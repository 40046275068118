import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CoursePopper } from "@common/comps";
import { Grid, Typography, Box, ThemeProvider } from "@mui/material";
import { labelTheme } from "@material/themes";

const getBubbleColor = (type) => {
  switch (type) {
    case "prereq":
      return "var(--quad-amaranth)";
    case "coreq":
      return "var(--quad-orange)";
    default:
      return "var(--quad-gray-light)";
  }
};

const getBubbleHoverColor = (type) => {
  switch (type) {
    case "prereq":
      return "var(--button-amaranth--hover)";
    case "coreq":
      return "var(--button-orange--hover)";
    default:
      return "var(--quad-gray-light)";
  }
};

const CourseReqLabel = ({ req, isNull = false }) => {
  const { subjectCode, courseCode, type, reqId, quad, alts } = req || {};

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopperOpen = (event) => {
    event.stopPropagation();
    const { currentTarget } = event || {};
    setAnchorEl(currentTarget);
  };

  const handlePopperClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <ThemeProvider theme={labelTheme}>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        alignItems="center"
        sx={{
          height: "24px",
          padding: "0px 12px",
          backgroundColor: getBubbleColor(type),
          borderRadius: "9999px",

          ...(!isNull && {
            "&:hover": {
              cursor: "pointer",
              backgroundColor: getBubbleHoverColor(type),
              transition: "background-color 50ms",
            },
          }),
        }}
        onClick={(event) => {
          event.stopPropagation();
          !isNull && navigate(`/q/${quad}/c/${reqId}`);
        }}
        onMouseEnter={handlePopperOpen}
        onMouseLeave={handlePopperClose}
      >
        {isNull ? (
          <Grid item sx={{ display: "flex" }}>
            <Typography variant="req-label--null">None</Typography>
          </Grid>
        ) : (
          <>
            <Grid item sx={{ display: "flex" }}>
              <Typography variant="req-label">{subjectCode}</Typography>
            </Grid>
            <Grid item>
              <Box
                sx={{
                  margin: "0px 5px",
                  height: "10px",
                  width: "1px",
                  borderRadius: "9999px",
                  backgroundColor: "var(--div-gray)",
                  opacity: "50%",
                }}
              />
            </Grid>
            <Grid item sx={{ display: "flex" }}>
              <Typography variant="req-label">{courseCode}</Typography>
            </Grid>
          </>
        )}
      </Grid>
      {anchorEl && !isNull && (
        <CoursePopper
          anchorEl={anchorEl}
          course={req}
          extraContent={
            Array.isArray(alts) &&
            alts.length > 0 && (
              <Grid item>
                <Typography variant="alt">Alternatives available</Typography>
              </Grid>
            )
          }
        />
      )}
    </ThemeProvider>
  );
};

export default CourseReqLabel;
