import {
  GeneralCheckBoxFalseIcon,
  GeneralCheckBoxTrueIcon,
} from "@material/icons";
import { Checkbox, FormControlLabel, Box, ThemeProvider } from "@mui/material";
import { basicCheckboxTheme } from "@material/themes";

const BasicCheckbox = ({ label, checked, setChecked, handleChange }) => {
  const onChange = handleChange
    ? handleChange
    : (event) => setChecked && setChecked(event.target.checked);

  return (
    <ThemeProvider theme={basicCheckboxTheme}>
      <FormControlLabel
        control={
          <Checkbox
            icon={
              <Box sx={{ width: "18px", height: "18px" }}>
                <GeneralCheckBoxFalseIcon />
              </Box>
            }
            checkedIcon={
              <Box sx={{ width: "18px", height: "18px" }}>
                <GeneralCheckBoxTrueIcon />
              </Box>
            }
            disableRipple
            checked={checked}
            onChange={onChange}
          />
        }
        label={label}
      />
    </ThemeProvider>
  );
};

export default BasicCheckbox;
