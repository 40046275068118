import { useEnroll, EnrollProvider } from "@context";
import { useNavigate } from "react-router-dom";
import { Loading } from "@common/comps";

import { QuadPage } from "./comps/QuadPage";
import { AccountPage } from "./comps/AccountPage";
import { VerifyPage } from "./comps/VerifyPage";
import { PasswordPage } from "./comps/PasswordPage";

export const Landing = () => {
  const navigate = useNavigate();
  const { isInitializing, page, setPage } = useEnroll();

  const router = () => {
    switch (page) {
      case "quad":
        return <QuadPage onComplete={() => setPage("account")} />;
      case "account":
        return <AccountPage onComplete={() => setPage("verify")} />;
      case "verify":
        return <VerifyPage onComplete={() => setPage("password")} />;
      case "password":
        return (
          <PasswordPage
            onComplete={() => {
              navigate("/");
              window.location.reload();
            }}
          />
        );
      default:
        return <></>;
    }
  };

  return isInitializing ? <Loading variant="page" /> : router();
};

export const Enroll = () => {
  return (
    <EnrollProvider>
      <Landing />
    </EnrollProvider>
  );
};
