const getScoreDisplay = (score) => {
  if (score >= -9999 && score <= 9999) {
    return `${score}`;
  } else if (score >= -99999 && score <= 99999) {
    return `${Math.round(score / 100) / 10}K`;
  } else if (score >= -999999 && score <= 999999) {
    return `${Math.floor(score / 1000)}K`;
  } else if (score >= -99999999 && score <= 99999999) {
    return `${Math.round(score / 100000) / 10}M`;
  } else if (score >= -999999999 && score <= 999999999) {
    return `${Math.floor(score / 1000000)}M`;
  }
};

export default getScoreDisplay;
