import { useContext } from "react";
import { Outlet } from "react-router-dom";
import { AdminProvider, QuadContext } from "@context";
import { MoreHeader, BasicErrorNotice, SettingsCard } from "@common/comps";
import { Grid } from "@mui/material";
import {
  SettingsSubjectsIcon,
  SettingsInstructorIcon,
  SettingsQuillIcon,
  SettingsCoreIcon,
  SettingsProgramIcon,
  SettingsContribIcon,
  SettingsDeptIcon,
  SettingsCourseIcon,
} from "@material/icons";

export * from "./comps";

const AdminMenu = () => {
  const { quadId } = useContext(QuadContext);

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        <SettingsCard
          label="Editors"
          subLabel="Editors are in charge of quad content."
          route={`/q/${quadId}/admin/editors`}
          icon={<SettingsQuillIcon />}
        />
      </Grid>
      <Grid item>
        <SettingsCard
          label="Instructors"
          subLabel="Manage course instructors."
          route={`/q/${quadId}/admin/instructors`}
          icon={<SettingsInstructorIcon />}
        />
      </Grid>
      {/*  <Grid item>
        <SettingsCard
          label="Contributions"
          subLabel="Moderate community contributions."
          route={`/q/${quadId}/admin/contributions`}
          icon={<SettingsContribIcon />}
        />
      </Grid> */}
      <Grid item>
        <SettingsCard
          label="Courses"
          subLabel="Manage courses"
          route={`/q/${quadId}/admin/courses`}
          icon={<SettingsCourseIcon />}
        />
      </Grid>
      <Grid item>
        <SettingsCard
          label="Subjects"
          subLabel="Manage subjects."
          route={`/q/${quadId}/admin/subjects`}
          icon={<SettingsSubjectsIcon />}
        />
      </Grid>
      <Grid item>
        <SettingsCard
          label="Cores"
          subLabel="Manage core, general education, or institution requirements."
          route={`/q/${quadId}/admin/cores`}
          icon={<SettingsCoreIcon />}
        />
      </Grid>
      <Grid item>
        <SettingsCard
          label="Programs"
          subLabel="Manage concentrations, certifications and programs."
          route={`/q/${quadId}/admin/programs`}
          icon={<SettingsProgramIcon />}
        />
      </Grid>
      <Grid item>
        <SettingsCard
          label="Departments"
          subLabel="Manage departments, institutes and centers"
          route={`/q/${quadId}/admin/departments`}
          icon={<SettingsDeptIcon />}
        />
      </Grid>
    </Grid>
  );
};

export const AdminLanding = () => {
  const { sessionQ } = useContext(QuadContext);
  const { isAdmin } = sessionQ || {};

  const content = isAdmin ? (
    <AdminMenu />
  ) : (
    <BasicErrorNotice label="This area is restricted." padding="0px 10px" />
  );

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        <MoreHeader title="Admin center" enableBack />
      </Grid>
      <Grid item>{content}</Grid>
    </Grid>
  );
};

export const Admin = () => {
  return (
    <AdminProvider>
      <Outlet />
    </AdminProvider>
  );
};
