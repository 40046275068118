const development = {
  baseURL: "http://localhost:3000",
};

const production = {
  baseURL: "https://quadrangle.club",
};

const urlConfig =
  process.env.NODE_ENV === "development" ? development : production;

export const getURL = (route) => {
  return `${urlConfig.baseURL}${route}`;
};

export default urlConfig;
