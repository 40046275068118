import { createContext, useState, useEffect, useContext } from "react";
import { cargo } from "@api";
import { CourseContext, QuadContext } from "@context";

export const CourseRequisitesContext = createContext();

export const CourseRequisitesProvider = ({ children }) => {
  const { quadId } = useContext(QuadContext);
  const {
    course: { courseId },
  } = useContext(CourseContext);

  /* Init */
  const [isInitializing, setIsInitializing] = useState(true);
  const [requisites, setRequisites] = useState([]);

  /* Tabs */
  const [type, setType] = useState("prereq");

  const initialize = () => {
    setIsInitializing(true);
    cargo.get(`/v2/q/${quadId}/course/${courseId}/requisites`).then((res) => {
      const { ok, payload } = res || {};
      if (ok) {
        setIsInitializing(false);
        setRequisites(payload.requisites);
      }
    });
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <CourseRequisitesContext.Provider
      value={{
        isInitializing,
        requisites,
        setRequisites,
        initialize,
        type,
        setType,
      }}
    >
      {children}
    </CourseRequisitesContext.Provider>
  );
};
