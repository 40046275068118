import { useNavigate } from "react-router-dom";
import { useQuad, useCourse } from "@context";
import { PageLinkButton } from "@common/comps";
import { Grid } from "@mui/material";
import { LinkStarIcon, LinkReqIcon, LinkContentIcon } from "@material/icons";

export const CourseLinks = () => {
  const navigate = useNavigate();
  const { quadId } = useQuad();
  const { course } = useCourse();
  const { courseId } = course || {};

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      id="course-links"
      gap={1.25}
    >
      <Grid item>
        <PageLinkButton
          title="Content"
          subtitle="Course breakdown"
          handleClick={() => navigate(`/q/${quadId}/c/${courseId}/content`)}
          icon={<LinkContentIcon />}
        />
      </Grid>
      <Grid item>
        <PageLinkButton
          title="Requisites"
          subtitle="Prereqs and coreqs"
          handleClick={() => navigate(`/q/${quadId}/c/${courseId}/reqs`)}
          icon={<LinkReqIcon />}
        />
      </Grid>
      <Grid item>
        <PageLinkButton
          title="Evaluations"
          subtitle="Rate the instructors"
          handleClick={() => navigate(`/q/${quadId}/c/${courseId}/evals`)}
          icon={<LinkStarIcon />}
        />
      </Grid>
    </Grid>
  );
};
