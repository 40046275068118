import { useNavigate } from "react-router-dom";
import {
  Grid,
  Box,
  Button,
  Typography,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { buttonTheme } from "@material/themes";

const progButtonTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          display: "flex",
          letterSpacing: "0.02em",
        },
      },
      variants: [
        {
          props: { variant: "certCode" },
          style: {
            fontSize: "13px",
            fontWeight: "500",
            color: "var(--quad-teal)",
          },
        },
        {
          props: { variant: "certName" },
          style: {
            fontSize: "13px",
            fontWeight: "500",
            color: "var(--quad-gray)",
            overflowWrap: "anywhere",
            letterSpacing: "0.01em",
          },
        },
      ],
    },
  },
});

const BasicProgButton = ({
  program,
  handleClick,
  hasEndButton,
  endButtonLabel,
  handleEndButton,
  endButtonVariant,
  endButtonLabelVariant,
  endButtonLabelProps,
}) => {
  const navigate = useNavigate();

  const { certCode, certName, programId, quad } = program || {};

  const endButton = (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant={endButtonVariant ? endButtonVariant : "stealth--teal"}
        sx={{ padding: "2px 10px" }}
        onClick={handleEndButton}
      >
        <Typography
          variant={
            endButtonLabelVariant ? endButtonLabelVariant : "stealth--teal"
          }
          sx={endButtonLabelProps ? endButtonLabelProps : { fontSize: "12px" }}
        >
          {endButtonLabel ? endButtonLabel : "Click"}
        </Typography>
      </Button>
    </ThemeProvider>
  );

  const card = (
    <Grid container direction="row" wrap="nowrap" gap={1}>
      <Grid item>
        <Typography variant="certCode">{certCode}</Typography>
      </Grid>
      <Grid item>
        <Box
          sx={{
            position: "relative",
            top: 2,
            width: "1px",
            height: "80%",
            backgroundColor: "var(--div-gray)",
          }}
        />
      </Grid>
      <Grid item>
        <Typography variant="certName">{certName}</Typography>
      </Grid>
    </Grid>
  );

  return (
    <ThemeProvider theme={progButtonTheme}>
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <Grid
            container
            direction="row"
            wrap="nowrap"
            alignItems="center"
            border="1px solid var(--attachment-border-gray)"
            px={1.5}
            py={1}
            sx={{
              borderRadius: "5px",
              "&:hover": {
                cursor: "pointer",
                backgroundColor: "var(--card-hover-gray)",
                transition: "background-color 200ms",
              },
            }}
            onClick={
              handleClick
                ? handleClick
                : (event) => {
                    event.stopPropagation();
                    navigate(`/q/${quad}/p/${programId}`);
                  }
            }
          >
            <Grid item sx={{ width: "100%" }}>
              {card}
            </Grid>
            {hasEndButton && <Grid item>{endButton}</Grid>}
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default BasicProgButton;
