import { useNavigate } from "react-router-dom";
import { usePost, useTimeline, useGlobal } from "@context";
import { Ballot, Scoreman, Synchronizer } from "@tools";
import { BasicAvatar, Author, PostOptions } from "@common/comps";
import { getScoreDisplay, getPostDate } from "@common/utility";
import { Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { cargo } from "@api";
import { isNumber } from "radash";

const PostBody = () => {
  const { post } = usePost();
  const { text = "" } = post || {};

  const length = text.length;
  const isMedium = length > 350 && length <= 1000;
  const isLong = length > 1000;

  const getVariant = () => {
    if (isLong) {
      return "body--long";
    } else if (isMedium) {
      return "body--medium";
    } else {
      return "body--short";
    }
  };

  return (
    <Typography
      variant={getVariant()}
      sx={{ lineHeight: "1em", whiteSpace: "pre-line" }}
    >
      {text}
    </Typography>
  );
};

export const PostHero = () => {
  const navigate = useNavigate();
  const { refreshInterval } = useGlobal();
  const { setFeed } = useTimeline(); // Feed is updated on post page activities like ballot
  const { post, setPost } = usePost();

  const {
    postId,
    quad,
    username,
    avatarSrc,
    totalScore,
    sessOwnerScore,
    childCount,
    createdAt,
    type,
  } = post || {};

  useEffect(() => {
    const interval = setInterval(() => {
      cargo.get(`/v2/q/${quad}/p/post/${postId}/child-count`).then((res) => {
        const { payload } = res || {};
        const { count } = payload || {};

        if (isNumber(count)) {
          setPost({ ...post, childCount: count });
          Synchronizer.updatePostFeed({ ...post, childCount: count }, setFeed);
        }
      });
    }, refreshInterval);

    return () => clearInterval(interval);
  }, []);

  const hero = (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      sx={{ padding: "10px 15px" }}
    >
      <Grid item sx={{ marginBottom: "15px" }}>
        <Grid container direction="row" wrap="nowrap" alignItems="center">
          <Grid item sx={{ marginRight: "15px" }}>
            <BasicAvatar
              isAnon={type === "confession"}
              username={username}
              avatarSrc={avatarSrc}
            />
          </Grid>
          <Grid item>
            <Author data={post} dateless clickableName size={16} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <PostBody />
      </Grid>
    </Grid>
  );

  const ChildCounter = () => (
    <Grid container direction="row" wrap="nowrap" gap={0.5}>
      <Grid item>
        <Typography variant="stats__count">
          {getScoreDisplay(childCount)}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="stats__label">
          {childCount !== 1 ? "comments" : "comment"}
        </Typography>
      </Grid>
    </Grid>
  );

  const footer = (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      justifyContent="space-between"
      borderBottom="1px solid var(--border-gray)"
      sx={{
        padding: "0px 2px 5px",
      }}
    >
      <Grid item>
        <ChildCounter />
      </Grid>
      <Grid item>
        <Typography variant="date">{getPostDate(createdAt)}</Typography>
      </Grid>
    </Grid>
  );

  const VoteSection = () => (
    <Ballot
      variant="full"
      type="post"
      id={postId}
      quad={quad}
      totalScore={totalScore}
      sessOwnerScore={sessOwnerScore}
      alignX="center"
      onVote={(options) => {
        const { type: actionType } = options || {};
        const newScores = Scoreman.getNewScores({
          type: actionType,
          base: post,
        });

        setPost((prev) => {
          return { ...prev, ...newScores };
        });
        Synchronizer.updatePostFeed({ ...post, ...newScores }, setFeed);
      }}
    />
  );

  const content = (
    <Grid container direction="column" wrap="nowrap">
      <Grid item sx={{ marginBottom: "15px" }}>
        {hero}
      </Grid>
      <Grid item sx={{ padding: "0px 15px" }}>
        {footer}
      </Grid>
      <Grid item p={1.5} borderBottom="1px solid var(--border-gray)">
        <VoteSection />
      </Grid>
    </Grid>
  );

  return (
    <Grid container direction="row" wrap="nowrap">
      <Grid item sx={{ width: "100%" }}>
        {content}
      </Grid>
      <Grid
        item
        sx={{ width: "0px", position: "relative", right: 50, top: 15 }}
      >
        <PostOptions
          post={post}
          onDelete={() => {
            setFeed((prev) => prev.filter((p) => p.postId !== postId));
            navigate(-1);
          }}
        />
      </Grid>
    </Grid>
  );
};
