import { Grid, Typography, Box, ThemeProvider } from "@mui/material";
import { labelTheme } from "@material/themes";

const CourseCoreLabel = ({ core = {}, isNull = false, isShort = false }) => {
  const { coreCode, coreName = "" } = core || {};

  return (
    <ThemeProvider theme={labelTheme}>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        alignItems="center"
        sx={{
          height: "24px",
          padding: "0px 12px",
          backgroundColor: isNull
            ? "var(--quad-gray-light)"
            : "var(--quad-persian-green)",
          borderRadius: "9999px",
        }}
      >
        {isNull ? (
          <Grid item sx={{ display: "flex" }}>
            <Typography variant="core-label--null">None</Typography>
          </Grid>
        ) : (
          <>
            <Grid item sx={{ display: "flex" }}>
              <Typography variant="core-label">{coreCode}</Typography>
            </Grid>
            {!isShort && (
              <>
                <Grid item>
                  <Box
                    sx={{
                      margin: "0px 5px",
                      height: "10px",
                      width: "1px",
                      borderRadius: "9999px",
                      backgroundColor: "var(--div-gray)",
                      opacity: "50%",
                    }}
                  />
                </Grid>
                <Grid item sx={{ display: "flex" }}>
                  <Typography variant="core-label">
                    {coreName.length > 20
                      ? coreName.substring(0, 20) + "..."
                      : coreName}
                  </Typography>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </ThemeProvider>
  );
};

export default CourseCoreLabel;
