import { getMonthName } from "@common/utility";

const getPostDate = (createdAt) => {
  const createdAtArray = createdAt.slice(0, -1).split("T");
  const createdAtDate = createdAtArray[0].split("-");
  const createdAtTime = createdAtArray[1].split(":");

  const dateObject = {
    year: parseInt(createdAtDate[0]),
    month: parseInt(createdAtDate[1]),
    day: parseInt(createdAtDate[2]),
  };

  const timeObject = {
    hour: ((parseInt(createdAtTime[0]) + 11) % 12) + 1,
    minute: createdAtTime[1],
    second: parseInt(createdAtTime[2]),
    period: parseInt(createdAtTime[0]) >= 12 ? "PM" : "AM",
  };

  const datePart = `${getMonthName(dateObject.month, "short")} ${
    dateObject.day
  }, ${dateObject.year}`;

  const timePart = `${timeObject.hour}:${timeObject.minute} ${timeObject.period}`;

  const fullDate = `${timePart} · ${datePart}`;

  return fullDate;
};

export default getPostDate;
