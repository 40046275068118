const getErrorType = (subtype) => {
  switch (subtype) {
    case "data":
      return 400;
    case "unique":
      return 409;
    default:
      return 400;
  }
};

const setFieldErrors = (errors = [], setErrorMap = {}) => {
  /* Accepts an array of error objects in the following form--
  
  const nameError = {
    type: "field",
    subtype: "data",
    field: "name",
    errorText: `Name must be less than ${nameCharLimit} characters.`,
  }; */

  const runCheck =
    Array.isArray(errors) &&
    typeof setErrorMap === "object" &&
    setErrorMap.constructor === Object &&
    errors.length > 0 &&
    Object.keys(setErrorMap).length > 0;

  if (runCheck) {
    const errorKeys = Object.keys(setErrorMap);

    errors.forEach((item) => {
      const { type, field, errorText } = item || {};
      if (type === "field") {
        if (errorKeys.includes(field)) {
          setErrorMap[field]({
            isError: true,
            errorText,
            type: getErrorType(),
          });
        }
      }
    });
  } else {
    return;
  }
};

export default setFieldErrors;
