import { useState, useEffect, useReducer } from "react";
import { useEnroll, useGlobal } from "@context";
import { Submitter } from "@tools";
import { FormPage, Dropdown, Loading } from "@common/comps";
import { Grid, Typography, Box } from "@mui/material";
import { cargo } from "@api";

export const QuadPage = ({ onComplete = () => {} }) => {
  const { defaultError } = useGlobal();
  const { enroll, setEnroll, FormHeader } = useEnroll();
  const { quad } = enroll || {};

  const [isInitializing, setIsInitializing] = useState(true);
  const [quadOptions, setQuadOptions] = useState([]);

  useEffect(() => {
    setIsInitializing(true);
    cargo.get(`/v2/enroll/quads`).then((res) => {
      const { ok, payload } = res || {};
      if (ok) {
        setIsInitializing(false);
        setQuadOptions(payload.quadOptions);
      }
    });
  }, []);

  /* Submission */
  const [submitState, setSubmitState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState()
  );

  const submitter = new Submitter(submitState, setSubmitState, defaultError);

  useEffect(() => {
    if (quad) {
      submitter.resetError();
      submitter.green(Boolean(quad));
    }
  }, [quad]);

  const handleSubmit = () => {
    if (quad) onComplete();
  };

  const content = (
    <Grid container direction="column" wrap="nowrap" minHeight={400}>
      <Grid item>
        <FormHeader />
      </Grid>
      <Grid
        item
        py={5}
        sx={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        <Typography variant="fh1">Choose your quad</Typography>
      </Grid>
      <Grid item px={2}>
        {isInitializing ? (
          <Loading px={2} />
        ) : (
          <Dropdown
            label="Quad"
            variant="object"
            options={quadOptions}
            labelKey="quadName"
            valueKey="quad"
            state={enroll.quad}
            handleChange={(event) =>
              setEnroll({
                type: "update",
                key: "quad",
                value: event.target.value,
              })
            }
          />
        )}
      </Grid>
    </Grid>
  );

  const footer = (
    <Box p={2} sx={{ width: "100%" }}>
      {Submitter.button({
        variant: "fullWidth",
        buttonSx: {
          fontSize: "15px",
          py: 1.25,
        },
        submitState,
        handleClick: handleSubmit,
      })}
    </Box>
  );

  return <FormPage content={content} footer={footer} />;
};
