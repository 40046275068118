import { useState } from "react";
import {
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  ThemeProvider,
} from "@mui/material";
import { formTheme, buttonTheme } from "@material/themes";
import { ButtonCloseIcon } from "@material/icons";

const Slide = (props) => {
  const { isOpen, setIsOpen, title, content } = props || {};

  const [isFullScreen, setIsFullScreen] = useState(window.innerWidth < 550);

  const getWindowWidth = () => setIsFullScreen(window.innerWidth < 550);
  window.onresize = getWindowWidth;

  const handleClose = () => setIsOpen(false);

  const closeButton = (
    <ThemeProvider theme={buttonTheme}>
      <Button variant="icon" onClick={handleClose}>
        <ButtonCloseIcon width={18} height={18} />
      </Button>
    </ThemeProvider>
  );

  const titleSection = (
    <Grid container direction="row" wrap="nowrap" alignItems="center" gap={2}>
      <Grid item>{closeButton}</Grid>
      <Grid item>
        <Typography variant="title">{title}</Typography>
      </Grid>
    </Grid>
  );

  if (isOpen) {
    return (
      <ThemeProvider theme={formTheme}>
        <Box onClick={(event) => event.stopPropagation()}>
          <Dialog
            open={isOpen}
            onClose={handleClose}
            transitionDuration={0}
            fullScreen={isFullScreen}
          >
            <DialogTitle>{titleSection}</DialogTitle>
            <DialogContent>
              <Box sx={{ position: "relative", top: 2 }}>{content}</Box>
            </DialogContent>
          </Dialog>
        </Box>
      </ThemeProvider>
    );
  }
};

export default Slide;
