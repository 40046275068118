import { useContext, useState, useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";
import { GlobalContext } from "@context";
import { cargo } from "@api";
import { BasicForm, Loading } from "@common/comps";
import { isObject } from "radash";
import { Grid } from "@mui/material";

import { Template, Submitter } from "@tools";

export const EssentialsBuilder = ({
  unedited,
  isOpen,
  setIsOpen,
  onEdit = () => {},
}) => {
  const { defaultError } = useContext(GlobalContext);
  const { username } = useParams();
  const [isInitializing, setIsInitializing] = useState(true);

  /* Template */
  const [baseline, setBaseline] = useState(null);
  const [essentials, setEssentials] = useReducer(
    Template.createReducer(),
    null
  );
  const template = new Template(baseline, essentials, setEssentials);

  useEffect(() => {
    setIsInitializing(true);
    cargo.get(`/v2/u/${username}/essentials/form`).then((res) => {
      const { ok, payload } = res || {};
      if (ok) {
        setIsInitializing(false);

        const initial = Template.superimpose(payload.template, unedited);

        setBaseline(initial);
        setEssentials({ type: "init", value: initial });
      }
    });
  }, []);

  /* Form section */
  const [hot, setHot] = useState(false);
  const [submitState, setSubmitState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState()
  );

  const submitter = new Submitter(submitState, setSubmitState, defaultError);

  useEffect(() => {
    if (hot) setHot(false);

    if (!hot && isObject(essentials)) {
      const preflight = template.preflight();
      const isChanged = template.isChanged();

      submitter.stock(template.crush());
      submitter.green(preflight && isChanged);
      submitter.resetError();
    }
  }, [essentials]);

  const handleSubmit = () => {
    template.resetErrors(defaultError);
    submitter.startLoading();
    cargo
      .put(`/v2/u/${username}/essentials`, {
        essentials: template.crush(essentials),
      })
      .then((res) => {
        const { ok, isClientError, errors } = res || {};
        if (ok) {
          onEdit();
        } else {
          setHot(true);
          submitter.takeResponse(res);
          if (isClientError) {
            template.setErrors(errors);
          }
        }
      });
  };

  const content = isInitializing ? (
    <Loading p={2} />
  ) : (
    isObject(essentials) && (
      <Grid container direction="column" wrap="nowrap" gap={1}>
        <Grid item>
          {template.prefab().input({
            label: "Name",
            field: "name",
          })}
        </Grid>
        <Grid item>
          {template.prefab().input({
            label: "Credential",
            field: "credential",
          })}
        </Grid>
        <Grid item>
          {template.prefab().input({
            multiline: true,
            label: "Biography",
            field: "biography",
            rows: 5,
          })}
        </Grid>
      </Grid>
    )
  );

  return (
    isOpen && (
      <BasicForm
        title="Edit essentials"
        isFormOpen={isOpen}
        setIsFormOpen={setIsOpen}
        content={content}
        submitState={{
          isSubmitLoading: submitState.isLoading,
          isSubmitDisabled: submitState.isDisabled,
          submitError: submitState.error,
        }}
        handleSubmit={handleSubmit}
      />
    )
  );
};
