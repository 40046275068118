import { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { cargo } from "@api";
import { QuadContext } from "@context";
import { BubbleDropdown, MoreHeader, Loading } from "@common/comps";
import { Grid, ThemeProvider } from "@mui/material";

import { adminCourseTheme } from "./theme";
import { AdminCourseProvider } from "./context";

const Container = ({ children, subject, setSubject, subjectOptions }) => {
  const { quadId } = useContext(QuadContext);

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        <MoreHeader
          title="Manage courses"
          enableBack
          backRoute={`/q/${quadId}/admin`}
        />
      </Grid>
      <Grid item p={2} pt={0} borderBottom="1px solid var(--border-gray)">
        <BubbleDropdown
          variant="object"
          label="Subject"
          state={subject}
          setState={setSubject}
          options={subjectOptions}
          labelKey="subjectName"
          valueKey="subjectId"
        />
      </Grid>
      <Grid item>{children}</Grid>
    </Grid>
  );
};

export const AdminCourses = () => {
  const { quadId } = useContext(QuadContext);

  const [isInitializing, setIsInitializing] = useState(true);
  const [subjectOptions, setSubjectOptions] = useState([]);

  const [subject, setSubject] = useState("");

  const initialize = () => {
    setIsInitializing(true);
    cargo.get(`/v2/q/${quadId}/subject/all`).then((res) => {
      const { ok, payload } = res || {};
      if (ok) {
        setIsInitializing(false);
        setSubjectOptions(payload.subjects);
      }
    });
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <ThemeProvider theme={adminCourseTheme}>
      {isInitializing ? (
        <Loading p={2} />
      ) : (
        <Container
          subject={subject}
          setSubject={setSubject}
          subjectOptions={subjectOptions}
        >
          {subject && (
            <AdminCourseProvider subject={subject}>
              <Outlet />
            </AdminCourseProvider>
          )}
        </Container>
      )}
    </ThemeProvider>
  );
};
