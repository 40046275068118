import { createContext, useContext } from "react";

export const CourseTopicsContext = createContext();

export const CourseTopicsProvider = ({ children }) => {
  return (
    <CourseTopicsContext.Provider value={{}}>
      {children}
    </CourseTopicsContext.Provider>
  );
};

export const useCourseTopics = () => {
  const context = useContext(CourseTopicsContext);
  if (!context) {
    return null;
  } else {
    return context;
  }
};
