const labelVariants = [
  {
    props: { variant: "buttonLabel", color: "light" },
    style: {
      fontSize: "14px",
      fontWeight: "500",
      color: "white",
    },
  },
  {
    props: { variant: "buttonLabel", color: "dark" },
    style: {
      fontSize: "14px",
      fontWeight: "500",
      color: "rgb(15, 22, 22)",
    },
  },
  {
    props: { variant: "outlined" },
    style: {
      fontSize: "13px",
      fontWeight: "700",
      color: "var(--quad-gray)",
      letterSpacing: "0.00938em",
    },
  },
  {
    props: { variant: "outlined--teal" },
    style: {
      fontSize: "13px",
      fontWeight: "500",
      color: "var(--quad-teal)",
      letterSpacing: "0.00938em",
    },
  },
  {
    props: { variant: "outlined--red" },
    style: {
      fontSize: "13px",
      fontWeight: "500",
      color: "var(--quad-red)",
      letterSpacing: "0.00938em",
    },
  },
  {
    props: { variant: "outlined--square" },
    style: {
      fontSize: "14px",
      fontWeight: "500",
      color: "var(--quad-black)",
    },
  },
  {
    props: { variant: "filled--white" },
    style: {
      fontSize: "13px",
      fontWeight: "500",
      color: "white",
      letterSpacing: "0.00938em",
    },
  },
  {
    props: { variant: "filled--gray" },
    style: {
      fontSize: "13px",
      fontWeight: "500",
      color: "var(--quad-gray)",
      letterSpacing: "0.00938em",
    },
  },
  {
    props: { variant: "stealth--red" },
    style: {
      fontSize: "14px",
      fontWeight: "500",
      color: "var(--quad-red)",
      letterSpacing: "0.00938em",
    },
  },
  {
    props: { variant: "stealth--teal" },
    style: {
      fontSize: "14px",
      fontWeight: "500",
      color: "var(--quad-teal)",
      letterSpacing: "0.00938em",
    },
  },
  {
    props: { variant: "stealth--pewter" },
    style: {
      fontSize: "14px",
      fontWeight: "500",
      color: "var(--quad-pewter)",
      letterSpacing: "0.00938em",
    },
  },
  /* Deprecated */
  {
    props: { variant: "outlined--attribute" },
    style: {
      fontSize: "20px",
      fontWeight: "400",
      color: "red",
    },
  },
];

export default labelVariants;
