import { useState, useEffect } from "react";
import "@material/styles/inputStyles.css";

const ResizingTextField = ({
  debounced,
  maxHeight,
  timeout,
  minRows,
  state,
  setState,
  placeholder,
  wrapperId,
  textareaId,
}) => {
  const [text, setText] = useState(state);

  useEffect(() => {
    if (debounced) {
      const debounce = setTimeout(
        () => {
          setState(text);
        },
        timeout ? timeout : 100
      );

      return () => clearTimeout(debounce);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  useEffect(() => {
    const textAreaElem = document.getElementById(textareaId);
    const wrapperElem = document.getElementById(wrapperId);
    if (maxHeight && textAreaElem && wrapperElem) {
      textAreaElem.parentNode.dataset.replicatedValue = state ? state : null;
      textAreaElem.style.setProperty("max-height", `${maxHeight}`);
      wrapperElem.style.setProperty("max-height", `${maxHeight}`);
    }
  }, []);

  useEffect(() => {
    const textAreaElem = document.getElementById(textareaId);
    const wrapperElem = document.getElementById(wrapperId);
    if (maxHeight && textAreaElem && wrapperElem) {
      textAreaElem.offsetHeight >= parseInt(maxHeight)
        ? textAreaElem.style.setProperty("overflow", "auto")
        : textAreaElem.style.setProperty("overflow", "hidden");

      wrapperElem.style.setProperty("overflow", "hidden");
    }
  });

  return (
    <div id={wrapperId}>
      <textarea
        placeholder={placeholder}
        id={textareaId}
        value={debounced ? text : state}
        onChange={(event) => {
          event.target.parentNode.dataset.replicatedValue = event.target.value;
          if (debounced) {
            setText(event.target.value);
          } else {
            setState(event.target.value);
          }
        }}
        spellCheck={false}
        rows={minRows}
      />
    </div>
  );
};

export default ResizingTextField;
