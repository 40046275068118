import { Grid, Box, Button, ThemeProvider } from "@mui/material";
import { tabTheme } from "@material/themes";
import { cloneElement } from "react";

const Underline = ({ top }) => (
  <Box
    sx={{
      position: "relative",
      top,
      borderRadius: "9999px",
      width: "100%",
      height: "inherit",
      backgroundColor: "var(--quad-teal)",
    }}
  />
);

const Bubble = ({ label, isActive, handleClick = () => {}, buttonSx }) => {
  const variant = isActive ? "bubble--selected" : "bubble--default";

  return (
    <ThemeProvider theme={tabTheme}>
      <Button variant={variant} onClick={handleClick} sx={buttonSx}>
        {label}
      </Button>
    </ThemeProvider>
  );
};

const Default = ({
  label,
  isActive,
  handleClick = () => {},
  buttonSx,
  icon,
  iconContainerProps,
  activeIconStroke,
  defaultIconStroke,
  activeIconStrokeWidth,
  defaultIconStrokeWidth,
}) => {
  const variant = isActive ? "tab--selected" : "tab--default";

  const tabLabel = (
    <Grid container direction="row" wrap="nowrap" alignItems="center" gap={0.5}>
      {icon && (
        <Grid item {...iconContainerProps} sx={{ display: "flex" }}>
          {cloneElement(icon, {
            width: 20,
            height: 20,
            fill: "transparent",
            stroke: isActive ? activeIconStroke : defaultIconStroke,
            strokeWidth: isActive
              ? activeIconStrokeWidth
              : defaultIconStrokeWidth,
          })}
        </Grid>
      )}
      <Grid item>{label}</Grid>
    </Grid>
  );

  return (
    <ThemeProvider theme={tabTheme}>
      <Button variant={variant} onClick={handleClick} sx={buttonSx}>
        <Grid item>
          <Grid container direction="column" wrap="nowrap">
            <Grid item>{tabLabel}</Grid>
            <Grid item>
              <Grid item sx={{ height: "4px" }}>
                {isActive && <Underline top={12} />}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Button>
    </ThemeProvider>
  );
};

const Mobile = ({ label, isActive, handleClick = () => {}, buttonSx }) => {
  const variant = isActive ? "mobile--selected" : "mobile--default";

  return (
    <ThemeProvider theme={tabTheme}>
      <Button variant={variant} onClick={handleClick} sx={buttonSx}>
        <Grid container direction="column" wrap="nowrap">
          <Grid item>{label}</Grid>
          <Grid item sx={{ height: "4px" }}>
            {isActive && <Underline top={12} />}
          </Grid>
        </Grid>
      </Button>
    </ThemeProvider>
  );
};

const Tab = (props) => {
  const { variant } = props || {};

  switch (variant) {
    case "mobile":
      return <Mobile {...props} />;
    case "bubble":
      return <Bubble {...props} />;
    default:
      return <Default {...props} />;
  }
};

export default Tab;
