import { useState, useEffect, forwardRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Ballot, Scoreman } from "@tools";
import {
  Author,
  BasicAvatar,
  Description,
  PostOptions,
  Action,
} from "@common/comps";
import { Grid, ThemeProvider } from "@mui/material";
import { ActionActivityIcon, ActionCommentIcon } from "@material/icons";
import { cardTheme } from "@material/themes";

export const PostCard = forwardRef(
  ({ post, onDelete, onVote = () => {} }, ref) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const [isDeleted, setIsDeleted] = useState(false);

    const {
      postId,
      avatarSrc,
      username,
      type,
      text,
      quad,
      scoreUpTotal,
      scoreDownTotal,
      totalScore,
      sessOwnerScore,
      childCount,
    } = post || {};

    /* States for activityScore updates */
    const [baseSessOwnerScore, setBaseSessOwnerScore] =
      useState(sessOwnerScore);
    const [baseActivityScore, setBaseActivityScore] = useState(
      scoreUpTotal + scoreDownTotal
    );
    const [activityScore, setActivityScore] = useState(baseActivityScore);

    useEffect(() => {
      setTimeout(() => {
        if (isDeleted) onDelete();
      }, 200);
    }, [isDeleted]);

    const handleClick = (event) => {
      const selection = window.getSelection();
      if (selection.type === "Range") {
        event.stopPropagation();
      } else {
        navigate(`/q/${quad}/post/${postId}`);
      }
    };

    const avatar = (
      <BasicAvatar
        username={username}
        avatarSrc={avatarSrc}
        isAnon={type === "confession"}
        handleClick={(event) => {
          event.stopPropagation();
          if (pathname === `/u/${username}`) {
            window.scrollTo({ top: 0 });
          } else {
            navigate(`/u/${username}`);
          }
        }}
      />
    );

    const Activity = () => (
      <Action
        variant="simple"
        icon={<ActionActivityIcon />}
        helperText={String(activityScore)}
      />
    );

    const updateActivityScore = (action) => {
      setActivityScore((prev) => {
        const newActivityScore = Scoreman.getNewActivityScore({
          ...action,
          baseSessOwnerScore,
          baseScore: baseActivityScore,
        });

        return newActivityScore;
      });
    };

    const Vote = () => (
      <Ballot
        type="post"
        id={postId}
        quad={quad}
        totalScore={totalScore}
        sessOwnerScore={sessOwnerScore}
        onVote={onVote}
        updateActivityScore={updateActivityScore}
      />
    );

    const actions = (
      <Grid
        container
        direction="row"
        wrap="nowrap"
        gap={1}
        sx={{ position: "relative", right: 8 }}
      >
        <Grid item>
          <Vote />
        </Grid>
        <Grid item>
          <Activity />
        </Grid>
        <Grid item>
          <Action
            icon={<ActionCommentIcon />}
            helperText={String(childCount)}
            handleClick={() => navigate(`/q/${quad}/post/${postId}`)}
            onHoverSx={{
              iconSx: {
                backgroundColor: "var(--quad-green-light)",

                "#action-icon--comment": {
                  fill: "var(--quad-green)",
                  transition: "fill 200ms",
                },
              },
              helperTextSx: {
                color: "var(--quad-green)",
              },
            }}
          />
        </Grid>
      </Grid>
    );

    const content = (
      <Grid container direction="column" wrap="nowrap">
        <Grid item py={type === "confession" && 0.5}>
          <Author data={post} clickableName />
        </Grid>
        <Grid item>
          <Description text={text} />
        </Grid>
        <Grid item>{actions}</Grid>
      </Grid>
    );

    const options = (
      <PostOptions post={post} onDelete={() => setIsDeleted(true)} />
    );

    const cardSx = {
      padding: `${isDeleted ? "0px" : "12px 16px"}`,
      borderBottom: isDeleted ? "" : `1px solid var(--border-gray)`,

      "&:hover": {
        cursor: isDeleted ? "" : "pointer",
        backgroundColor: isDeleted ? "" : "var(--card-hover-gray)",
        transition: "background-color 200ms",
      },
    };

    const card = (
      <Grid
        container
        direction="row"
        wrap="nowrap"
        onClick={handleClick}
        gap={2}
        className={isDeleted ? "card--empty" : ""}
        sx={cardSx}
      >
        {!isDeleted && (
          <>
            <Grid item>{avatar}</Grid>
            <Grid item sx={{ width: "100%" }}>
              {content}
            </Grid>
            <Grid item sx={{ width: "0px", position: "relative", right: 30 }}>
              {options}
            </Grid>
          </>
        )}
      </Grid>
    );

    return (
      <ThemeProvider theme={cardTheme}>
        <article ref={ref ? ref : null}>{card}</article>
      </ThemeProvider>
    );
  }
);

export default PostCard;
