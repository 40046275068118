import { useContext, useEffect } from "react";
import { QuadContext } from "@context";
import { useTranche2 } from "@common/hooks";
import { MoreHeader, EmptyLabel, Loading } from "@common/comps";
import { Grid } from "@mui/material";
import { unique } from "radash";

import { EvaluationCard } from "./EvaluationCard";

const EvaluationFeed = () => {
  const { quadId } = useContext(QuadContext);

  const {
    initTranche,
    isLoading,
    feed,
    setFeed,
    isFeedEmpty,
    setFirstId,
    lastCardRef,
  } = useTranche2(`/v2/q/${quadId}/e/course/evaluations/staged/tranche`);

  useEffect(() => initTranche(), []);

  useEffect(() => {
    if (feed.length > 0) setFirstId(feed[0].maxEvalStageId);
  }, [feed]);

  const uniques = unique(feed, (e) => e.evalStageId);

  const evaluationFeed = uniques.map((e, i) => (
    <Grid item key={e.evalStageId}>
      <EvaluationCard
        ref={uniques.length === i + 1 ? lastCardRef : null}
        e={e}
        onModerate={() =>
          setFeed((prev) => prev.filter((s) => s.evalStageId !== e.evalStageId))
        }
      />
    </Grid>
  ));

  return (
    <Grid container direction="column" wrap="nowrap">
      {isFeedEmpty ? (
        <EmptyLabel label="There are no pending evaluations." p={2} />
      ) : (
        evaluationFeed
      )}
      {isLoading && <Loading p={2} />}
      <Grid item height={200} />
    </Grid>
  );
};

export const EditorEvaluation = () => {
  const { quadId } = useContext(QuadContext);

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        <MoreHeader
          title="Moderate evaluations"
          enableBack
          backRoute={`/q/${quadId}/editor`}
        />
      </Grid>
      <Grid item>
        <EvaluationFeed />
      </Grid>
    </Grid>
  );
};
