import { forwardRef } from "react";
import { Grid, Typography, createTheme, ThemeProvider } from "@mui/material";
import { isString } from "radash";

const basicCardTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          display: "flex",
        },
      },
      variants: [
        {
          props: { variant: "label" },
          style: {
            fontSize: "14px",
            fontWeight: "500",
            color: "var(--quad-black)",
            letterSpacing: "0.02em",
          },
        },
        {
          props: { variant: "sublabel" },
          style: {
            fontSize: "13px",
            fontWeight: "400",
            color: "var(--quad-gray)",
            letterSpacing: "0.03em",
          },
        },
      ],
    },
  },
});

const BasicCard = forwardRef(({ label, subLabel }, ref) => {
  const content = (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        {isString(label) ? (
          <Typography variant="label">{label}</Typography>
        ) : (
          label
        )}
      </Grid>
      {subLabel && (
        <Grid item>
          <Typography variant="sublabel">{subLabel}</Typography>
        </Grid>
      )}
    </Grid>
  );

  const card = (
    <Grid container direction="row" wrap="nowrap">
      <Grid item>{content}</Grid>
    </Grid>
  );

  return (
    <article ref={ref}>
      <ThemeProvider theme={basicCardTheme}>{card}</ThemeProvider>
    </article>
  );
});

export default BasicCard;
