import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Opener, Builder } from "@tools";
import { QuadContext } from "@context";
import {
  Tab,
  SearchBar,
  Loadman,
  PopperOptions,
  Editable,
} from "@common/comps";
import { Grid, Box, Typography } from "@mui/material";
import {
  PopperEditIcon,
  PopperCoreIcon,
  PopperReqIcon,
  PopperContentIcon,
} from "@material/icons";
import { replace } from "radash";

import { AdminCourseContext } from "../../context";

const Course = ({ course }) => {
  const { quadId } = useContext(QuadContext);
  const navigate = useNavigate();
  const { subject, setCourses, setContextCourse } =
    useContext(AdminCourseContext);

  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isCoreOpen, setIsCoreOpen] = useState(false);
  const [isContentOpen, setIsContentOpen] = useState(false);

  const menuItemsList = [
    {
      key: "admin-course__edit",
      label: "Edit course",
      icon: <PopperEditIcon />,
      handleClick: (event) => {
        event.stopPropagation();
        setIsEditOpen(true);
      },
    },
    {
      key: "admin-course__edit-content",
      label: "Edit course content",
      icon: <PopperContentIcon />,
      handleClick: (event) => {
        event.stopPropagation();
        setIsContentOpen(true);
      },
    },
    {
      key: "admin-course__edit-cores",
      label: "Edit core requirements",
      icon: <PopperCoreIcon />,
      handleClick: (event) => {
        event.stopPropagation();
        setIsCoreOpen(true);
      },
    },
    {
      key: "admin-course__edit-requisites",
      label: "Edit course requisites",
      icon: <PopperReqIcon />,
      handleClick: (event) => {
        event.stopPropagation();
        setContextCourse(course);
        navigate(`/q/${quadId}/admin/courses/requisites`);
      },
    },
  ];

  const endComponent = (
    <Box sx={{ height: "100%", display: "flex", alignItems: "center" }}>
      <PopperOptions menuItemsList={menuItemsList} />
      {isEditOpen &&
        Builder.course({
          courseId: course.courseId,
          subjectId: course.subjectId,
          isOpen: isEditOpen,
          setIsOpen: setIsEditOpen,
          onEdit: (edited) => {
            setCourses((prev) =>
              replace(prev, edited, (c) => c.courseId === course.courseId)
            );
            setIsEditOpen(false);
          },
          onDelete: () =>
            setCourses((prev) =>
              prev.filter((c) => c.courseId !== course.courseId)
            ),
        })}
      {isCoreOpen &&
        Builder.courseCores({
          course,
          isOpen: isCoreOpen,
          setIsOpen: setIsCoreOpen,
          onEdit: () => setIsCoreOpen(false),
        })}
    </Box>
  );

  return (
    <Box px={2} py={1}>
      <Editable
        variant="vanilla"
        label={`${subject.subjectCode} ${course.courseCode}`}
        subLabel={course.courseTitle}
        endComponent={endComponent}
      />
    </Box>
  );
};

const CourseFeed = () => {
  const { isLoading, courses } = useContext(AdminCourseContext);

  const isEmpty = courses.length === 0;
  const noFeed = isLoading || isEmpty;

  if (noFeed) {
    return (
      <Loadman
        isLoading={isLoading}
        isEmpty={isEmpty}
        emptyLabel="No courses found."
        p={2}
      />
    );
  } else {
    return (
      <Grid container direction="column" wrap="nowrap">
        {courses.map((c) => (
          <Grid
            item
            key={c.courseId}
            borderBottom="1px solid var(--border-gray)"
          >
            <Course course={c} />
          </Grid>
        ))}
        <Grid item height={200} />
      </Grid>
    );
  }
};

const SearchTypeSelect = () => {
  const { searchType, setSearchType } = useContext(AdminCourseContext);

  return (
    <Grid container direction="row" wrap="nowrap" alignItems="center" gap={1}>
      <Grid item mx={0.5}>
        <Typography variant="section-title">Search by</Typography>
      </Grid>
      <Grid item>
        <Tab
          variant="bubble"
          label="Title"
          isActive={searchType === "title"}
          handleClick={() => searchType !== "title" && setSearchType("title")}
          buttonSx={{
            fontSize: "13px",
          }}
        />
      </Grid>
      <Grid item>
        <Tab
          variant="bubble"
          label="Code"
          isActive={searchType === "code"}
          handleClick={() => searchType !== "code" && setSearchType("code")}
          buttonSx={{
            fontSize: "13px",
          }}
        />
      </Grid>
    </Grid>
  );
};

export const CourseManager = () => {
  const { searchCourse, searchType, subject, searchCount, setSearchCount } =
    useContext(AdminCourseContext);

  const [searchTerm, setSearchTerm] = useState("");

  const { subjectId } = subject || {};

  const Create = () => (
    <Opener
      variant="filled"
      color="black"
      label="Create course"
      builder={Builder.course({
        subjectId,
        onCreate: () => searchCourse(searchTerm),
      })}
    />
  );

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item p={2} borderBottom="1px solid var(--border-gray)">
        <Create />
      </Grid>
      <Grid item p={2} borderBottom="1px solid var(--border-gray)">
        <SearchTypeSelect />
      </Grid>
      <Grid item p={2} borderBottom="1px solid var(--border-gray)">
        <SearchBar
          label={`Search by course ${searchType}`}
          search={searchTerm}
          setSearch={setSearchTerm}
          handleSearch={() => {
            setSearchCount((prev) => prev + 1);
            searchCourse(searchTerm);
          }}
        />
      </Grid>
      <Grid item>{searchCount > 0 && <CourseFeed />}</Grid>
    </Grid>
  );
};
