import { InputBase, createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          width: "100%",
          border: "1px solid var(--button-border-gray)",
          borderRadius: "5px",

          ".Mui-disabled": {
            backgroundColor: "var(--attachment-border-gray)",
          },
        },
        input: {
          padding: "8px 12px",
          color: "var(--quad-black)",

          "::placeholder": {
            color: "var(--quad-gray)",
          },
        },
      },
    },
  },
});

const VanillaInput = ({
  placeholder,
  state,
  setState,
  disabled,
  handleEnterDown = () => {},
  sx,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <InputBase
        placeholder={placeholder}
        value={state}
        onChange={(event) => setState(event.target.value)}
        disabled={disabled}
        onKeyDown={(event) => {
          if (event.key === "Enter") handleEnterDown();
        }}
        inputProps={{
          spellCheck: false,
        }}
        sx={sx}
      />
    </ThemeProvider>
  );
};

export default VanillaInput;
