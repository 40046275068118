// MUI
import { createTheme } from "@mui/material";

const formPageTheme = createTheme({
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: "fh1" },
          style: {
            fontSize: "28px",
            fontWeight: "500",
            color: "rgb(15, 22, 22)",
          },
        },
        {
          props: { variant: "fh2" },
          style: {
            fontSize: "15px",
            fontWeight: "400",
            color: "var(--quad-gray)",
          },
        },
        {
          props: { variant: "footer" },
          style: {
            fontSize: "14px",
            fontWeight: "400",
            color: "var(--quad-gray)",
          },
        },
        {
          props: { variant: "hyperlink" },
          style: {
            fontSize: "14px",
            fontWeight: "400",
            color: "var(--quad-teal)",

            "&:hover": {
              cursor: "pointer",
              textDecoration: "underline",
            },
          },
        },
      ],
    },
    MuiDialog: {
      styleOverrides: {
        container: {
          alignItems: "center",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: "15px",
          display: "flex",
          alignItems: "center",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "15px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "0px 0px",
          borderRadius: "10px",
          width: "480px",
          minWidth: "300px",
          minHeight: "300px",
        },
      },
    },
  },
});

export default formPageTheme;
