import {
  Grid,
  Typography,
  Box,
  createTheme,
  ThemeProvider,
} from "@mui/material";

const labelTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          letterSpacing: "0.02em",
        },
      },
      variants: [
        {
          props: { variant: "filled__cert-code" },
          style: {
            fontSize: "12px",
            fontWeight: "700",
            color: "var(--quad-teal)",
          },
        },
        {
          props: { variant: "filled__conc-name" },
          style: {
            fontSize: "14px",
            fontWeight: "500",
            color: "var(--quad-gray)",
            overflowWrap: "anywhere",
          },
        },
        {
          props: { variant: "borderless__cert-code" },
          style: {
            fontSize: "14px",
            fontWeight: "700",
            color: "var(--quad-teal)",
          },
        },
        {
          props: { variant: "borderless__conc-name" },
          style: {
            fontSize: "14px",
            fontWeight: "500",
            color: "var(--quad-gray)",
            overflowWrap: "anywhere",
          },
        },
      ],
    },
  },
});

const Filled = ({ program, typographyProps }) => {
  const { certCode, concName } = program || {};
  const { certCodeProps, concNameProps } = typographyProps || {};

  return (
    <ThemeProvider theme={labelTheme}>
      <Grid container direction="row" wrap="nowrap" alignItems="center">
        <Grid
          item
          sx={{
            marginRight: "8px",
            borderRadius: "5px",
            padding: "0.05em 0.6em 0.2em",
            backgroundColor: "var(--quad-gray-light)",
          }}
        >
          <Typography variant="filled__cert-code" sx={{ ...certCodeProps }}>
            {certCode}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="filled__conc-name" sx={{ ...concNameProps }}>
            {concName}
          </Typography>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

const Borderless = ({ program, typographyProps }) => {
  const { certCode, concName } = program || {};
  const { certCodeProps, concNameProps } = typographyProps || {};

  return (
    <ThemeProvider theme={labelTheme}>
      <Grid container direction="row" wrap="nowrap" alignItems="center">
        <Grid item>
          <Typography variant="borderless__cert-code" sx={{ ...certCodeProps }}>
            {certCode}
          </Typography>
        </Grid>
        <Grid item sx={{ margin: "0px 10px" }}>
          <Box
            sx={{
              position: "relative",
              top: 1,
              width: "1px",
              height: "0.8em",
              backgroundColor: "var(--div-gray)",
            }}
          />
        </Grid>
        <Grid item>
          <Typography variant="borderless__conc-name" sx={{ ...concNameProps }}>
            {concName}
          </Typography>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

const ProgramLabel = ({ variant, program, typographyProps }) => {
  switch (variant) {
    case "filled":
      return <Filled program={program} typographyProps={typographyProps} />;
    case "borderless":
      return <Borderless program={program} typographyProps={typographyProps} />;

    default:
      throw new Error("Please indicate program label variant.");
  }
};

export default ProgramLabel;
