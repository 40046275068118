import { Chip, createTheme, ThemeProvider } from "@mui/material";

const chipTheme = createTheme({
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: "var(--quad-gray-light)",
          fontSize: "13px",
          fontWeight: "500",
          color: "var(--quad-gray)",
          letterSpacing: "0.01em",
        },
        deleteIcon: {
          color: "var(--quad-gray)",
          transition: "color 50ms",
          fontSize: "20px",

          "&:hover": {
            color: "var(--button-gray--hover)",
          },
        },
      },
    },
  },
});

const BasicChip = ({ label, handleDelete }) => {
  return (
    <ThemeProvider theme={chipTheme}>
      <Chip label={label} onDelete={handleDelete} />
    </ThemeProvider>
  );
};

export default BasicChip;
