import { createContext, useContext } from "react";

export const AdminContext = createContext();

export const AdminProvider = ({ children }) => {
  return <AdminContext.Provider value={{}}>{children}</AdminContext.Provider>;
};

export const useAdmin = () => {
  const context = useContext(AdminContext);
  if (!context) {
    return null;
  } else {
    return context;
  }
};
