import { isNumber, isObject } from "radash";

const actionables = ["unvote", "upvote", "downvote"];

class Scoreman {
  constructor() {}
  static getNewScores(action) {
    const { type, base } = action || {}; // base must be an object

    if (actionables.includes(type) && isObject(base)) {
      const { totalScore, sessOwnerScore } = base || {};

      const baseScores = { totalScore, sessOwnerScore };

      if (isNumber(totalScore) && isNumber(sessOwnerScore)) {
        const getUnvoteScores = () => {
          const newUserScore = 0;
          // User can unvote when they have previously upvoted or downvoted
          if (sessOwnerScore === 1) {
            return { totalScore: totalScore - 1, sessOwnerScore: newUserScore };
          } else if (sessOwnerScore === -1) {
            return { totalScore: totalScore + 1, sessOwnerScore: newUserScore };
          } else {
            return baseScores;
          }
        };

        const getUpvoteScores = () => {
          const newUserScore = 1;
          // User can upvote when they have previously downvoted or not voted
          if (sessOwnerScore === -1) {
            return { totalScore: totalScore + 2, sessOwnerScore: newUserScore };
          } else if (sessOwnerScore === 0) {
            return { totalScore: totalScore + 1, sessOwnerScore: newUserScore };
          } else {
            return baseScores;
          }
        };

        const getDownvoteScores = () => {
          const newUserScore = -1;
          // User can downvote when they have previously upvoted or not voted
          if (sessOwnerScore === 1) {
            return { totalScore: totalScore - 2, sessOwnerScore: newUserScore };
          } else if (sessOwnerScore === 0) {
            return { totalScore: totalScore - 1, sessOwnerScore: newUserScore };
          } else {
            return baseScores;
          }
        };

        switch (type) {
          case "unvote":
            return getUnvoteScores();
          case "upvote":
            return getUpvoteScores();
          case "downvote":
            return getDownvoteScores();
          default:
            return;
        }
      } else {
        return;
      }
    } else {
      return;
    }
  }
  static getNewActivityScore(action) {
    const { type, baseSessOwnerScore, baseScore } = action || {};

    const increment = () => baseScore + 1;
    const decrement = () => (baseScore >= 1 ? baseScore - 1 : 0);

    if (actionables.includes(type)) {
      if (baseSessOwnerScore === 0) {
        switch (type) {
          case "upvote":
            return increment();
          case "downvote":
            return increment();
          default:
            return baseScore;
        }
      } else if (baseSessOwnerScore === 1 || baseSessOwnerScore === -1) {
        switch (type) {
          case "unvote":
            return decrement();
          default:
            return baseScore;
        }
      } else {
        return baseScore;
      }
    } else {
      return baseScore;
    }
  }
}

export { Scoreman };
export default Scoreman;
