import {
  AttributeGeneralIcon,
  AttributeOptimistIcon,
  AttributeAdventurerIcon,
  AttributeLionheartIcon,
  AttributeStraightArrowIcon,
  AttributeEngineerIcon,
  AttributeSocialButterflyIcon,
  AttributeAltruistIcon,
  AttributeCaregiverIcon,
  AttributeDiplomatIcon,
  AttributeVirtuosoIcon,
  AttributeSoldierIcon,
  AttributeHighFlyerIcon,
  AttributeOrganizerIcon,
  AttributeCommanderIcon,
  AttributeEntrepreneurIcon,
  AttributeMachineIcon,
  AttributeEmpathIcon,
  AttributeIdealistIcon,
  AttributeEstheteIcon,
  AttributeScientistIcon,
  AttributePhilosopherIcon,
  AttributeProgressiveIcon,
  AttributeConfidantIcon,
  AttributeWorkhorseIcon,
  AttributeInventorIcon,
  AttributeAdvocateIcon,
  AttributeEpicureIcon,
  AttributeStrategistIcon,
  AttributeGoGetterIcon,
  AttributeTechnologistIcon,
  AttributePerfectionistIcon,
  AttributeLiteratiIcon,
  AttributeStoryTellerIcon,
  AttributeRationalistIcon,
  AttributeSunshineIcon,
  AttributeDaredevilIcon,
  AttributeBluebellIcon,
  AttributeCoolHeadIcon,
} from "@material/icons";

const getAttributeIcon = (attribute) => {
  switch (attribute) {
    case "optm":
      return <AttributeOptimistIcon />;
    case "advt":
      return <AttributeAdventurerIcon />;
    case "lion":
      return <AttributeLionheartIcon />;
    case "arrw":
      return <AttributeStraightArrowIcon />;
    case "engr":
      return <AttributeEngineerIcon />;
    case "butf":
      return <AttributeSocialButterflyIcon />;
    case "altr":
      return <AttributeAltruistIcon />;
    case "care":
      return <AttributeCaregiverIcon />;
    case "dipl":
      return <AttributeDiplomatIcon />;
    case "virt":
      return <AttributeVirtuosoIcon />;
    case "sold":
      return <AttributeSoldierIcon />;
    case "flyr":
      return <AttributeHighFlyerIcon />;
    case "orgz":
      return <AttributeOrganizerIcon />;
    case "comd":
      return <AttributeCommanderIcon />;
    case "entr":
      return <AttributeEntrepreneurIcon />;
    case "mach":
      return <AttributeMachineIcon />;
    case "empa":
      return <AttributeEmpathIcon />;
    case "idea":
      return <AttributeIdealistIcon />;
    case "esth":
      return <AttributeEstheteIcon />;
    case "scie":
      return <AttributeScientistIcon />;
    case "phil":
      return <AttributePhilosopherIcon />;
    case "prog":
      return <AttributeProgressiveIcon />;
    case "conf":
      return <AttributeConfidantIcon />;
    case "work":
      return <AttributeWorkhorseIcon />;
    case "inve":
      return <AttributeInventorIcon />;
    case "advc":
      return <AttributeAdvocateIcon />;
    case "epic":
      return <AttributeEpicureIcon />;
    case "stra":
      return <AttributeStrategistIcon />;
    case "gogt":
      return <AttributeGoGetterIcon />;
    case "tech":
      return <AttributeTechnologistIcon />;
    case "perf":
      return <AttributePerfectionistIcon />;
    case "litr":
      return <AttributeLiteratiIcon />;
    case "strt":
      return <AttributeStoryTellerIcon />;
    case "rati":
      return <AttributeRationalistIcon />;
    case "suns":
      return <AttributeSunshineIcon />;
    case "dare":
      return <AttributeDaredevilIcon />;
    case "blue":
      return <AttributeBluebellIcon />;
    case "cool":
      return <AttributeCoolHeadIcon />;
    default:
      return <AttributeGeneralIcon />;
  }
};

export default getAttributeIcon;
