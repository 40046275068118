import { Button, Box, ThemeProvider } from "@mui/material";
import { buttonTheme } from "@material/themes";

const IconButton = ({
  icon = <></>,
  buttonProps,
  iconProps,
  handleClick = () => {},
}) => {
  const defaultProps = { display: "flex", width: "18px", height: "18px" };

  return (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant="icon"
        onClick={handleClick}
        sx={buttonProps ? buttonProps : {}}
      >
        <Box sx={iconProps ? iconProps : defaultProps}>{icon}</Box>
      </Button>
    </ThemeProvider>
  );
};

export default IconButton;
