import { useContext, useState, useEffect } from "react";
import { BasicNav, BasicForm, Dropdown } from "@common/comps";
import { CatalogContext } from "@context";
import { Grid, Typography, Button, ThemeProvider } from "@mui/material";
import { buttonTheme } from "@material/themes";

const AlphaFilter = ({ option, alpha, setAlpha }) => {
  return (
    <ThemeProvider theme={buttonTheme}>
      {alpha === option ? (
        <Button
          variant="filled"
          color="black"
          sx={{ width: "35px", height: "35px" }}
          onClick={(event) => {
            event.stopPropagation();
            setAlpha("");
          }}
        >
          <Typography variant="outlined--white" sx={{ fontSize: "15px" }}>
            {option}
          </Typography>
        </Button>
      ) : (
        <Button
          variant="outlined"
          sx={{ width: "35px", height: "35px" }}
          onClick={(event) => {
            event.stopPropagation();
            setAlpha(option);
          }}
        >
          <Typography variant="outlined" sx={{ fontSize: "15px" }}>
            {option}
          </Typography>
        </Button>
      )}
    </ThemeProvider>
  );
};

const CatFilter = ({ option, catId, setCatId }) => {
  const { subjectCatId, subjectCatName } = option || {};

  return (
    <ThemeProvider theme={buttonTheme}>
      {subjectCatId === catId ? (
        <Button
          variant="filled"
          color="black"
          onClick={(event) => {
            event.stopPropagation();
            setCatId("");
          }}
        >
          <Typography variant="outlined--white" sx={{ fontSize: "13px" }}>
            {subjectCatName}
          </Typography>
        </Button>
      ) : (
        <Button
          variant="outlined"
          onClick={(event) => {
            event.stopPropagation();
            setCatId(subjectCatId);
          }}
        >
          <Typography variant="outlined" sx={{ fontSize: "13px" }}>
            {subjectCatName}
          </Typography>
        </Button>
      )}
    </ThemeProvider>
  );
};

export const SubjectChoose = () => {
  const { subjectOptions, filterOptions, subject, setSubject } =
    useContext(CatalogContext);

  const { alphaOptions = [], catOptions = [] } = filterOptions || {};
  const { subjectName } = subject || {};

  // Form state
  const [isFormOpen, setIsFormOpen] = useState(false);

  // Page
  const [page, setPage] = useState("alpha");

  // Form data
  const [selected, setSelected] = useState(subject);

  // Filters
  const [alpha, setAlpha] = useState("");
  const [catId, setCatId] = useState("");

  // Submission
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  useEffect(() => {
    setIsSubmitDisabled(!selected);
  }, [selected]);

  const getChooseLabel = () => {
    const labelCharLimit = 30;
    if (subjectName) {
      if (subjectName.length > labelCharLimit) {
        return `${subjectName.substring(0, labelCharLimit)}...`;
      } else {
        return subjectName;
      }
    } else {
      return "Choose subject";
    }
  };

  const chooseButton = (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant="outlined"
        sx={{ color: "var(--quad-teal)", fontWeight: "500" }}
        onClick={(event) => {
          event.stopPropagation();
          setIsFormOpen(true);
        }}
      >
        {getChooseLabel()}
      </Button>
    </ThemeProvider>
  );

  const options =
    alpha || catId
      ? subjectOptions.filter((item) => {
          switch (page) {
            case "alpha":
              return item.firstLetter === alpha;
            case "cat":
              return item.subjectCatId === catId;
            default:
              return true;
          }
        })
      : subjectOptions;

  const alphaFilters = (
    <Grid container direction="row" sx={{ gap: 1 }}>
      {alphaOptions.map((item) => (
        <Grid item key={item} sx={{ marginBottom: "0px" }}>
          <AlphaFilter option={item} alpha={alpha} setAlpha={setAlpha} />
        </Grid>
      ))}
    </Grid>
  );

  const catFilters = (
    <Grid container direction="row" sx={{ gap: 1 }}>
      {catOptions.map((item) => (
        <Grid item key={item.subjectCatId} sx={{ marginBottom: "0px" }}>
          <CatFilter option={item} catId={catId} setCatId={setCatId} />
        </Grid>
      ))}
    </Grid>
  );

  const content = (
    <Grid container direction="column" wrap="nowrap" gap={1}>
      <Grid
        item
        pb={1.5}
        sx={{
          borderBottom: "1px solid var(--border-gray)",
        }}
      >
        <BasicNav
          options={[
            { tabName: "Alphabet", tabValue: "alpha" },
            { tabName: "Category", tabValue: "cat" },
          ]}
          state={page}
          setState={setPage}
        />
      </Grid>
      <Grid item sx={{ padding: "10px 0px" }}>
        {page === "cat" ? catFilters : alphaFilters}
      </Grid>
      <Grid item>
        <Dropdown
          label="Subjects"
          state={selected}
          setState={setSelected}
          options={options}
          variant="object"
          labelKey="subjectName"
          valueKey="subjectId"
        />
      </Grid>
    </Grid>
  );

  return (
    <>
      {chooseButton}
      <BasicForm
        title="Choose subject"
        isFormOpen={isFormOpen}
        setIsFormOpen={setIsFormOpen}
        content={content}
        submitLabel="Choose"
        handleSubmit={(event) => {
          event.stopPropagation();
          setSubject(selected);
        }}
        submitState={{
          isSubmitDisabled,
        }}
      />
    </>
  );
};
