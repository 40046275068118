import { createTheme } from "@mui/material";

export const selectTheme = createTheme({
  components: {
    MuiSelect: {
      styleOverrides: {
        root: {
          color: "var(--quad-black)",
          fontSize: "16px",
          backgroundColor: "transparent",
          border: "1px solid",
          borderColor: "var(--input-border-gray)",
          borderRadius: "5px",
          height: "56px",

          "&:hover": {
            backgroundColor: "transparent",
          },

          "&.Mui-focused": {
            backgroundColor: "transparent",
            border: "2px solid",
            borderColor: "var(--quad-teal)",
          },

          "&.Mui-disabled": {
            border: "none",
            backgroundColor: `rgb(235, 237, 237) !important`,
          },
        },
      },
    },
    MuiNativeSelect: {
      styleOverrides: {
        select: {
          backgroundColor: "transparent",

          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent !important",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "var(--quad-gray)",
          fontSize: "16px",

          "&.Mui-focused": {
            color: "var(--quad-teal)",
          },

          "&.Mui-disabled": {
            color: "rgb(175, 186, 185)",
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        input: {
          paddingRight: "40px !important",
        },
      },
    },
  },
});

export default selectTheme;
