import { useReducer, useState, useEffect } from "react";
import { cargo } from "@api";
import { useGlobal, useEnroll } from "@context";
import { Submitter, Template } from "@tools";
import { FormPage, Loading, Dropdown } from "@common/comps";
import { Grid, Box, Typography } from "@mui/material";
import { isObject } from "radash";

const makeSequence = (start, end) => {
  return [...Array(end).keys()].map((i) => start + i);
};

const updateDayOptions = (account, setDayOptions) => {
  const month = account.birthMonth.value;

  const monthsOf30 = ["April", "June", "September", "November"];

  if (monthsOf30.includes(month)) {
    setDayOptions(makeSequence(1, 30));
  } else if (month === "February") {
    const year = account.birthYear.value;
    if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
      setDayOptions(makeSequence(1, 29));
    } else {
      setDayOptions(makeSequence(1, 28));
    }
  } else {
    setDayOptions(makeSequence(1, 31));
  }
};

const updateYearOptions = (account, setYearOptions) => {
  const month = account.birthMonth.value;
  const day = account.birthDay.value;

  if (month === "February" && day === "29") {
    const currentYear = new Date().getFullYear();

    setYearOptions(
      makeSequence(1900, currentYear - 1900)
        .filter((y) => (y % 4 === 0 && y % 100 !== 0) || y % 400 === 0)
        .reverse()
    );
  } else {
    setYearOptions(
      makeSequence(1900, new Date().getFullYear() - 1900).reverse()
    );
  }
};

export const AccountPage = ({ onComplete = () => {} }) => {
  const { defaultError } = useGlobal();
  const { enroll, setEnroll, FormHeader } = useEnroll();
  const { quad } = enroll || {};

  const [isInitializing, setIsInitializing] = useState(true);
  const [account, setAccount] = useReducer(Template.createReducer(), null);

  const template = new Template(null, account, setAccount);

  useEffect(() => {
    setIsInitializing(true);
    cargo.get("/v2/enroll/account-form").then((res) => {
      const { ok, payload } = res || {};
      if (ok) {
        setIsInitializing(false);
        setAccount({
          type: "init",
          value: Template.createEmpty(payload.template),
        });
      }
    });
  }, []);

  /* Submission */
  const [submitState, setSubmitState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState()
  );

  const submitter = new Submitter(submitState, setSubmitState, defaultError);

  /* Form section */
  const [hot, setHot] = useState(false);

  useEffect(() => {
    if (hot) setHot(false);

    if (!hot && isObject(account)) {
      const preflight = template.preflight();
      submitter.stock(template.crush());
      submitter.green(preflight);
      submitter.resetError();
    }
  }, [account]);

  const handleSubmit = () => {
    template.resetErrors(defaultError);
    submitter.startLoading();
    cargo
      .post(`/v2/enroll/verify?quad=${quad}`, {
        account: submitter.state.payload,
      })
      .then((res) => {
        const { ok, isClientError, errors } = res || {};
        if (ok) {
          onComplete();
          setEnroll({ type: "batchUpdate", value: submitter.state.payload });
        } else {
          setHot(true);
          submitter.takeResponse(res);
          if (isClientError) template.setErrors(errors);
        }
      });
  };

  /* Form options */
  const [monthOptions, setMonthOptions] = useState([]);
  const [dayOptions, setDayOptions] = useState([]);
  const [yearOptions, setYearOptions] = useState(
    makeSequence(1900, new Date().getFullYear() - 1900).reverse()
  );

  useEffect(() => {
    if (account) {
      setMonthOptions(account.birthMonth.oneOf);
      updateDayOptions(account, setDayOptions);
      updateYearOptions(account, setYearOptions);
    }
  }, [account]);

  const form = isObject(account) && (
    <Grid container direction="column" wrap="nowrap" gap={1}>
      <Grid item>
        {template.prefab().input({
          label: "Name",
          field: "name",
        })}
      </Grid>
      <Grid item>
        {template.prefab().input({
          label: "Email",
          field: "email",
        })}
      </Grid>
      <Grid item>
        <Grid container direction="row" wrap="nowrap" gap={1}>
          <Grid item sx={{ width: "40%" }}>
            <Dropdown
              label="Month"
              options={monthOptions}
              state={account.birthMonth.value}
              handleChange={(event) =>
                setAccount({ field: "birthMonth", value: event.target.value })
              }
            />
          </Grid>
          <Grid item sx={{ width: "30%" }}>
            <Dropdown
              label="Day"
              options={dayOptions}
              state={account.birthDay.value}
              handleChange={(event) =>
                setAccount({ field: "birthDay", value: event.target.value })
              }
            />
          </Grid>
          <Grid item sx={{ width: "30%" }}>
            <Dropdown
              label="Year"
              options={yearOptions}
              state={account.birthYear.value}
              handleChange={(event) =>
                setAccount({
                  field: "birthYear",
                  value: Number(event.target.value),
                })
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const content = (
    <Grid container direction="column" wrap="nowrap" minHeight={400}>
      <Grid item>
        <FormHeader />
      </Grid>
      <Grid
        item
        py={5}
        sx={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        <Typography variant="fh1">Create account</Typography>
      </Grid>
      <Grid item px={2}>
        {isInitializing ? <Loading p={2} /> : form}
      </Grid>
    </Grid>
  );

  const footer = (
    <Box p={2} sx={{ width: "100%" }}>
      {Submitter.button({
        variant: "fullWidth",
        buttonSx: {
          fontSize: "15px",
          py: 1.25,
        },
        submitState,
        handleClick: handleSubmit,
      })}
    </Box>
  );

  return <FormPage content={content} footer={footer} />;
};
