import { createContext, useContext } from "react";

export const MoreContext = createContext();

export const MoreProvider = ({ children }) => {
  return <MoreContext.Provider value={{}}>{children}</MoreContext.Provider>;
};

export const useMore = () => {
  const context = useContext(MoreContext);
  if (!context) {
    return null;
  } else {
    return context;
  }
};
