import { createContext, useContext } from "react";

export const EditorContext = createContext();

export const EditorProvider = ({ children }) => {
  return <EditorContext.Provider value={{}}>{children}</EditorContext.Provider>;
};

export const useEditor = () => {
  const context = useContext(EditorContext);
  if (!context) {
    return null;
  } else {
    return context;
  }
};
