import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { BasicHeader, ProgramLabel } from "@common/comps";
import { ProgramContext } from "@context";
import { Box } from "@mui/material";
import {
  ButtonBackwardIcon,
  ButtonMainIcon,
  BadgeCertIcon,
} from "@material/icons";

export const ProgramHeader = () => {
  const { program } = useContext(ProgramContext);
  const { pathname } = useLocation();

  const isLanding = pathname.split("/").length === 5;

  const getTitle = () => {
    switch (pathname.split("/").pop()) {
      default:
        return "Program";
    }
  };

  return (
    <BasicHeader
      title={getTitle()}
      titleIcon={<BadgeCertIcon />}
      enableBack
      backIcon={isLanding ? <ButtonBackwardIcon /> : <ButtonMainIcon />}
      content2={
        Boolean(!isLanding && program) && (
          <Box
            sx={{
              padding: "5px 16px 10px",
              borderBottom: "1px solid var(--border-gray)",
            }}
          >
            <ProgramLabel variant="borderless" program={program} />
          </Box>
        )
      }
    />
  );
};
