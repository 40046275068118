import { useEffect, useReducer, useState } from "react";
import { cargo } from "@api";
import { useGlobal, useRecovery } from "@context";
import { Submitter } from "@tools";
import { FormPage, Input } from "@common/comps";
import { Box, Grid, Typography } from "@mui/material";
import { assign } from "radash";

export const FindAccountPage = ({ onComplete = () => {} }) => {
  const { defaultError } = useGlobal();
  const { RecoveryHeader, setRecovery } = useRecovery();

  const [email, setEmail] = useState("");

  const [emailError, setEmailError] = useState(defaultError);

  /* Submission */
  const [submitState, setSubmitState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState()
  );

  const submitter = new Submitter(submitState, setSubmitState, defaultError);

  useEffect(() => {
    submitter.green(Boolean(email));
    submitter.resetError();
  }, [email]);

  const handleSubmit = () => {
    submitter.startLoading();
    email &&
      cargo.post(`/v2/recovery/session/${email}`).then((res) => {
        const { ok, isClientError, errors } = res || {};
        if (ok) {
          onComplete();
          setRecovery((prev) => assign(prev, { email }));
        } else {
          submitter.takeResponse(res);
          const error = errors[0];
          if (isClientError)
            setEmailError({
              isError: true,
              type: 400,
              errorText: error.errorText,
            });
        }
      });
  };

  const content = (
    <Grid container direction="column" wrap="nowrap" minHeight={400}>
      <Grid item>
        <RecoveryHeader />
      </Grid>
      <Grid
        item
        py={5}
        sx={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        <Typography variant="fh1">Find account</Typography>
      </Grid>
      <Grid item px={2}>
        <Input
          label="Email"
          state={email}
          setState={setEmail}
          error={emailError}
        />
      </Grid>
    </Grid>
  );

  const footer = (
    <Box p={2} sx={{ width: "100%" }}>
      {Submitter.button({
        variant: "fullWidth",
        buttonSx: {
          fontSize: "15px",
          py: 1.25,
        },
        submitState,
        handleClick: handleSubmit,
      })}
    </Box>
  );

  return <FormPage content={content} footer={footer} />;
};
