import { useState, useEffect, forwardRef, useContext } from "react";
import { cargo } from "@api";
import { Moderator } from "@tools";
import { BasicAvatar, Description } from "@common/comps";
import { getCardDate } from "@common/utility";
import { AvatarAnon } from "@material/images";
import { Grid, Typography, ThemeProvider } from "@mui/material";
import { cardTheme } from "@material/themes";
import { GlobalContext } from "@context";

export const ConfessionCard = forwardRef(({ confess, onModerate }, ref) => {
  const { defaultError } = useContext(GlobalContext);
  const { confessStageId, createdAt, text, quad } = confess || {};

  const [isModerated, setIsModerated] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (isModerated) onModerate();
    }, 200);
  }, [isModerated]);

  const [isApproveOpen, setIsApproveOpen] = useState(false);
  const [isApproveLoading, setIsApproveLoading] = useState(false);
  const [isApproveDisabled, setIsApproveDisabled] = useState(false);
  const [approveError, setApproveError] = useState(defaultError);

  const [isRejectOpen, setIsRejectOpen] = useState(false);
  const [isRejectLoading, setIsRejectLoading] = useState(false);
  const [isRejectDisabled, setIsRejectDisabled] = useState(false);
  const [rejectError, setRejectError] = useState(defaultError);

  const approveParams = {
    title: "Publish confession?",
    subtitle:
      "Once published, confessions can still be deleted by quad editors.",
    isApproveOpen,
    setIsApproveOpen,
    isApproveLoading,
    isApproveDisabled,
    approveError,
  };

  const rejectParams = {
    title: "Reject confession?",
    subtitle:
      "This action is irreversible. Rejected confessions can never be recovered.",
    isRejectOpen,
    setIsRejectOpen,
    isRejectLoading,
    isRejectDisabled,
    rejectError,
  };

  const goodForAction = confessStageId && quad;

  const handleApprove = (event) => {
    event.stopPropagation();
    if (goodForAction) {
      setIsApproveLoading(true);
      cargo
        .put(`/v2/q/${quad}/e/p/confession/staged/${confessStageId}`, {
          action: "approve",
        })
        .then((res) => {
          const { ok, status, message } = res || {};
          if (ok) {
            setIsModerated && setIsModerated(true);
          } else {
            setIsApproveLoading(false);
            setIsApproveDisabled(true);
            setApproveError({
              isError: true,
              errorText: message,
              type: status,
            });
          }
        });
    }
  };

  const handleReject = (event) => {
    event.stopPropagation();
    if (goodForAction) {
      cargo
        .put(`/v2/q/${quad}/e/p/confession/staged/${confessStageId}`, {
          action: "reject",
        })
        .then((res) => {
          const { ok, status, message } = res || {};
          if (ok) {
            setIsModerated && setIsModerated(true);
          } else {
            setIsRejectLoading(false);
            setIsRejectDisabled(true);
            setRejectError({
              isError: true,
              errorText: message,
              type: status,
            });
          }
        });
    }
  };

  const content = (
    <Grid container direction="column" wrap="nowrap" gap={1}>
      <Grid item>
        <Typography variant="date">{getCardDate(createdAt)}</Typography>
      </Grid>
      <Grid item>
        <Description text={text} />
      </Grid>
      <Grid item>
        <Moderator
          handleApprove={handleApprove}
          handleReject={handleReject}
          approveParams={approveParams}
          rejectParams={rejectParams}
        />
      </Grid>
    </Grid>
  );

  const card = (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      className={isModerated ? "card--empty" : ""}
      gap={2}
      sx={{
        borderBottom: isModerated ? "" : `1px solid var(--border-gray)`,
        padding: `${isModerated ? "0px" : "12px 15px"}`,
      }}
    >
      {!isModerated && (
        <>
          <Grid item sx={{ position: "relative", top: 2 }}>
            <BasicAvatar avatarSrc={AvatarAnon} />
          </Grid>
          <Grid item sx={{ width: "100%" }}>
            {content}
          </Grid>
        </>
      )}
    </Grid>
  );

  return (
    <ThemeProvider theme={cardTheme}>
      <article ref={ref ? ref : null}>{card}</article>
    </ThemeProvider>
  );
});
