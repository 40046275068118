import { createContext, useContext } from "react";

export const ProjectContext = createContext();

export const ProjectProvider = ({ children }) => {
  return (
    <ProjectContext.Provider value={{}}>{children}</ProjectContext.Provider>
  );
};

export const useProject = () => {
  const context = useContext(ProjectContext);
  if (!context) {
    return null;
  } else {
    return context;
  }
};
