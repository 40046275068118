import { useState } from "react";
import { Publisher } from "@tools";
import { SubreplyProvider, useSubreply } from "@context";
import {
  BasicForm,
  BasicAvatar,
  Author,
  Description,
  Loadman,
} from "@common/comps";
import { Grid, Box, createTheme, ThemeProvider } from "@mui/material";
import { cardTheme } from "@material/themes";

import "./styles.css";

import { SubreplyCard } from "../subreplyCard/SubreplyCard";
import { LoadButton, WriteButton } from "./comps/buttons";

const SubreplyLead = () => {
  const { reply } = useSubreply();
  const { username, avatarSrc, text } = reply || {};

  const avatar = (
    <Grid container direction="column" wrap="nowrap" alignItems="center">
      <Grid item>
        <BasicAvatar username={username} avatarSrc={avatarSrc} />
      </Grid>
      <Grid item pt={0.5} sx={{ height: "100%" }}>
        <Box
          sx={{
            width: "2px",
            height: "100%",
            borderRadius: "9999px",
            backgroundColor: "var(--avatar-link-gray)",
          }}
        />
      </Grid>
    </Grid>
  );

  return (
    <ThemeProvider theme={cardTheme}>
      <Grid container direction="row" wrap="nowrap">
        <Grid item sx={{ marginRight: "15px", display: "flex" }}>
          {avatar}
        </Grid>
        <Grid item>
          <Grid container direction="column" wrap="nowrap">
            <Grid item>
              <Author data={reply} />
            </Grid>
            <Grid item>
              <Description text={text} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

const SubreplyFeed = ({ fresh, setFresh, onSubreplyDelete }) => {
  const { isLoading, feed, setFeed, isFeedEmpty, setIsFeedEmpty } =
    useSubreply();

  const noFeed = isLoading || isFeedEmpty;

  const subreplyFeed = (
    <Grid container direction="column" wrap="nowrap">
      {feed.map((subreply, i) => (
        <Grid
          item
          key={subreply.subreplyId}
          className={fresh > 0 && i + 1 === feed.length ? "card--new" : ""}
        >
          <SubreplyCard
            subreply={subreply}
            onDelete={() => {
              setFresh(0);
              setFeed((prev) => {
                const newFeed = prev.filter(
                  (s) => s.subreplyId !== subreply.subreplyId
                );
                setIsFeedEmpty(newFeed.length === 0);
                return newFeed;
              });

              /* External callbacks */
              onSubreplyDelete(subreply.subreplyId);
            }}
          />
        </Grid>
      ))}
    </Grid>
  );

  if (noFeed) {
    return (
      <Loadman
        isLoading={isLoading}
        isEmpty={isFeedEmpty}
        emptyLabel="No subreplies found."
        px={1.5}
        mb={2.5}
      />
    );
  } else {
    return subreplyFeed;
  }
};

const subreplyContentTheme = createTheme();

export const SubreplyLanding = ({
  isFormOpen,
  setIsFormOpen,
  onSubreplyCreate,
  onSubreplyDelete,
}) => {
  const { reply, isLoaded, setIsLoaded, setFeed, setIsFeedEmpty } =
    useSubreply();
  const { replyId } = reply || {};

  const [subreplyText, setSubreplyText] = useState("");
  const [fresh, setFresh] = useState(0);

  const writeButton = (
    <WriteButton
      handleClick={(event) => {
        event.stopPropagation();
        document
          .getElementById("resizing-textfield__create-subreply__textarea")
          .focus();
      }}
    />
  );

  const loadButton = (
    <LoadButton
      handleClick={(event) => {
        event.stopPropagation();
        setIsLoaded(true);
      }}
    />
  );

  const content = (
    <ThemeProvider theme={subreplyContentTheme}>
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <SubreplyLead />
        </Grid>
        <Grid item py={1.5} px={0.5}>
          {isLoaded ? writeButton : loadButton}
        </Grid>
        <Grid item>
          <SubreplyFeed
            fresh={fresh}
            setFresh={setFresh}
            onSubreplyDelete={onSubreplyDelete}
          />
        </Grid>
      </Grid>
    </ThemeProvider>
  );

  return (
    isFormOpen && (
      <BasicForm
        title="Write subreply"
        isFormOpen={isFormOpen}
        setIsFormOpen={setIsFormOpen}
        content={content}
        footer={Publisher.subreply({
          replyId,
          text: subreplyText,
          setText: setSubreplyText,
          onPublish: (created) => {
            setIsFeedEmpty(false);
            setFresh((prev) => prev + 1);
            isLoaded && setFeed((prev) => [...prev, created]);

            setSubreplyText("");

            /* External callbacks */
            onSubreplyCreate();
          },
        })}
        contentProps={{ sx: { padding: "16px 16px 5px" } }}
      />
    )
  );
};

export const SubreplySection = ({
  reply,
  isFormOpen,
  setIsFormOpen,
  onSubreplyCreate = () => {},
  onSubreplyDelete = () => {},
}) => {
  return (
    <SubreplyProvider reply={reply}>
      <SubreplyLanding
        isFormOpen={isFormOpen}
        setIsFormOpen={setIsFormOpen}
        onSubreplyCreate={onSubreplyCreate}
        onSubreplyDelete={onSubreplyDelete}
      />
    </SubreplyProvider>
  );
};

export default SubreplySection;
