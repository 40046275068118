import * as Components from "./comps";

class Publisher {
  constructor() {}
  static post(props = {}) {
    return <Components.PostPublisher {...props} />;
  }
  static confession(props = {}) {
    return <Components.ConfessionPublisher {...props} />;
  }
  static comment(props = {}) {
    return <Components.CommentPublisher {...props} />;
  }
  static reply(props = {}) {
    return <Components.ReplyPublisher {...props} />;
  }
  static subreply(props = {}) {
    return <Components.SubreplyPublisher {...props} />;
  }
  static evaluation(props = {}) {
    return <Components.EvaluationPublisher {...props} />;
  }
}

export { Publisher };
export default Publisher;
