import {
  Grid,
  Typography,
  Box,
  createTheme,
  ThemeProvider,
} from "@mui/material";

const labelTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          display: "flex",
          letterSpacing: "0.02em",
        },
      },
      variants: [
        {
          props: { variant: "code" },
          style: {
            fontSize: "14px",
            fontWeight: "500",
            color: "var(--quad-teal)",
          },
        },
        {
          props: { variant: "name" },
          style: {
            fontSize: "14px",
            fontWeight: "500",
            color: "var(--quad-gray)",
          },
        },
      ],
    },
  },
});

const Default = ({ subject }) => {
  const { subjectCode, subjectName } = subject || {};

  return (
    <ThemeProvider theme={labelTheme}>
      <Grid container direction="row" wrap="nowrap" gap={1}>
        <Grid item>
          <Typography variant="code">{subjectCode}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="name">{subjectName}</Typography>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

const SubjectLabel = (props) => {
  const { variant } = props || {};

  switch (variant) {
    default:
      return <Default {...props} />;
  }
};

export default SubjectLabel;
