import { useContext, useState, useEffect, useReducer } from "react";
import { GlobalContext, QuadContext } from "@context";
import { cargo } from "@api";
import { BasicForm, MultiCourse } from "@common/comps";

import { Submitter } from "@tools";

export const PreqAltsBuilder = ({
  optionCourse,
  isOpen,
  setIsOpen,
  onEdit = () => {},
}) => {
  /* Init */
  const { defaultError } = useContext(GlobalContext);
  const { quadId } = useContext(QuadContext);
  const { preqOptCourseId, alternatives: baseline } = optionCourse || {};

  /* Template */
  const [alternatives, setAlternatives] = useState(baseline);

  /* Form state */
  const [isFootDisabled, setIsFootDisabled] = useState(false);

  /* Submission */
  const [submitState, setSubmitState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState()
  );

  const submitter = new Submitter(submitState, setSubmitState, defaultError);

  useEffect(() => {
    const baselineIds = baseline.filter((c) => c.courseId);
    const alternativeIds = alternatives.filter((c) => c.courseId);

    const isChanged =
      JSON.stringify(baselineIds) !== JSON.stringify(alternativeIds);

    submitter.green(isChanged);
  }, [alternatives]);

  const content = (
    <MultiCourse
      state={alternatives}
      setState={setAlternatives}
      setIsFootDisabled={setIsFootDisabled}
    />
  );

  const handleEdit = () => {
    submitter.startLoading();
    cargo
      .put(
        `/v2/q/${quadId}/r/program/requirement/group/option/course/${preqOptCourseId}`,
        { alternatives: alternatives.map((c) => c.courseId) }
      )
      .then((res) => {
        const { ok, payload } = res || {};
        if (ok) {
          onEdit(payload.alternatives);
        } else {
          submitter.takeResponse(res);
        }
      });
  };

  if (isOpen) {
    return (
      <BasicForm
        title="Edit course alternatives"
        isFormOpen={isOpen}
        setIsFormOpen={setIsOpen}
        content={content}
        disableFooter={isFootDisabled}
        handleSubmit={handleEdit}
        submitState={{
          isSubmitLoading: submitState.isLoading,
          isSubmitDisabled: submitState.isDisabled,
          submitError: submitState.error,
        }}
      />
    );
  }
};
