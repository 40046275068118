import { createTheme } from "@mui/material";

const inputTheme = createTheme({
  components: {
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
        autoComplete: "off",
      },
      styleOverrides: {
        root: {
          outline: "1px solid var(--input-border-gray)",
          borderRadius: "5px",
          backgroundColor: "transparent",

          "&:hover": {
            backgroundColor: "transparent",
          },

          "&.Mui-focused": {
            outline: "2px solid var(--quad-teal)",
            backgroundColor: "transparent",
          },

          "&.Mui-error": {
            outline: "2px solid var(--quad-red)",
          },

          "&.Mui-disabled": {
            outline: "none",
            backgroundColor: "var(--input-border-gray)",
          },
        },
        input: {
          color: "var(--quad-black)",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "16px",
          color: "var(--quad-gray)",

          "&.Mui-focused": {
            color: "var(--quad-teal)",
          },

          "&.Mui-disabled": {
            color: "var(--quad-gray-alt)",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&.Mui-disabled": {
            WebkitTextFillColor: "var(--quad-gray-alt)",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          paddingTop: "2px", // Space between textfield and helperText
          lineHeight: "15px",
          color: "var(--quad-gray-alt)",
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: "counter" },
          style: {
            display: "flex",
            fontSize: "12px",
            fontWeight: "400",
            color: "var(--quad-gray-alt)",
            letterSpacing: "0.02em",
          },
        },
      ],
    },
  },
});

export default inputTheme;
