import { useContext, useState, useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";
import { GlobalContext } from "@context";
import { cargo } from "@api";
import { BasicForm, Loading, FieldSearch, Dropdown } from "@common/comps";
import { isObject } from "radash";
import { Grid } from "@mui/material";

import { Template, Submitter } from "@tools";

export const HighlightsBuilder = ({
  unedited,
  isOpen,
  setIsOpen,
  onEdit = () => {},
}) => {
  /* Init */
  const { defaultError } = useContext(GlobalContext);
  const { username } = useParams();
  const [isInitializing, setIsInitializing] = useState(true);

  /* Resources */
  const [options, setOptions] = useState({});
  const { sectOptions } = options || {};

  /* Template */
  const [baseline, setBaseline] = useState(null);
  const [highlights, setHighlights] = useReducer(
    Template.createReducer(),
    null
  );

  const template = new Template(baseline, highlights, setHighlights);

  useEffect(() => {
    setIsInitializing(true);
    cargo.get(`/v2/u/${username}/highlights/form`).then((res) => {
      const { ok, payload } = res || {};
      if (ok) {
        setIsInitializing(false);
        setOptions(payload.options);

        /* Templating */
        const initialTemplate = Template.superimpose(
          payload.template,
          unedited
        );
        setBaseline(initialTemplate);
        setHighlights({ type: "init", value: initialTemplate });
      }
    });
  }, []);

  /*  Form section */
  const { fieldId, field: fieldName } = unedited || {};

  const [hot, setHot] = useState(false);
  const [field, setField] = useState(fieldId ? { fieldId, fieldName } : null);

  /* Submissions */
  const [submitState, setSubmitState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState()
  );

  const submitter = new Submitter(submitState, setSubmitState, defaultError);

  useEffect(() => {
    if (hot) setHot(false);

    if (!hot && isObject(highlights)) {
      const preflight = template.preflight();
      const isChanged = template.isChanged();

      submitter.stock(template.crush());
      submitter.green(preflight && isChanged);
      submitter.resetError();
    }
  }, [highlights]);

  const handleSubmit = () => {
    template.resetErrors(defaultError);
    submitter.startLoading();
    cargo
      .put(`/v2/u/${username}/highlights`, { highlights: template.crush() })
      .then((res) => {
        const { ok, isClientError, errors } = res || {};
        if (ok) {
          onEdit();
        } else {
          setHot(true);
          submitter.takeResponse(res);
          if (isClientError) template.setErrors(errors);
        }
      });
  };

  const content = isInitializing ? (
    <Loading p={2} />
  ) : (
    isObject(highlights) && (
      <Grid container direction="column" wrap="nowrap" gap={1}>
        <Grid item>
          <FieldSearch
            initialValue={highlights.field.value}
            field={field}
            setField={setField}
            handleInputChange={(event) => {
              setHighlights({
                field: "fieldId",
                value: null,
              });
              setHighlights({
                field: "field",
                value: event.target.value,
              });
            }}
            handleChange={(event, val) => {
              setHighlights({ field: "fieldId", value: val.fieldId });
              setHighlights({ field: "field", value: val.fieldName });
            }}
          />
        </Grid>
        <Grid item>
          <Dropdown
            variant="object"
            label="Sector"
            state={highlights.sector.value}
            handleChange={(event) =>
              setHighlights({ field: "sector", value: event.target.value })
            }
            options={sectOptions}
            error={highlights.sector.error}
            disableObjectSelect
            labelKey="sectorName"
            valueKey="sectorValue"
          />
        </Grid>
        <Grid item>
          {template.prefab().input({
            label: "Area",
            field: "area",
          })}
        </Grid>
        <Grid item>
          {template.prefab().input({
            label: "Location",
            field: "location",
          })}
        </Grid>
        <Grid item>
          {template.prefab().input({
            label: "Hometown",
            field: "hometown",
          })}
        </Grid>
        <Grid item>
          {template.prefab().input({ label: "Email", field: "contact" })}
        </Grid>
      </Grid>
    )
  );

  return (
    isOpen && (
      <BasicForm
        title="Edit essentials"
        isFormOpen={isOpen}
        setIsFormOpen={setIsOpen}
        content={content}
        submitState={{
          isSubmitLoading: submitState.isLoading,
          isSubmitDisabled: submitState.isDisabled,
          submitError: submitState.error,
        }}
        handleSubmit={handleSubmit}
      />
    )
  );
};
