import { useState, useEffect, useReducer } from "react";
import { useGlobal, useQuad } from "@context";
import { cargo } from "@api";
import { Submitter } from "@tools";
import {
  BasicAvatar,
  ResizingTextField,
  CircularTextCounter,
  Submit,
} from "@common/comps";
import { Grid } from "@mui/material";

export const SubreplyPublisher = ({
  replyId,
  text,
  setText,
  onPublish = () => {},
}) => {
  const { defaultError, session: { sessOwnerAvatarSrc, postMax } = {} } =
    useGlobal();
  const { quadId } = useQuad();

  // Submission
  const [submitState, setSubmitState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState()
  );

  const submitter = new Submitter(submitState, setSubmitState, defaultError);

  useEffect(() => {
    submitter.resetError();
    submitter.green(text.length > 0 && quadId && replyId);
  }, [text]);

  const handleSubmit = () => {
    submitter.startLoading();
    cargo
      .post(`/v2/q/${quadId}/p/reply/${replyId}/subreply`, { text })
      .then((res) => {
        const { ok, payload } = res || {};
        if (ok) {
          onPublish(payload);
          submitter.stopLoading();
          submitter.green(false);
        } else {
          submitter.stopLoading();
          submitter.takeResponse(res);
        }
      });
  };

  const submitSection = (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      justifyContent="flex-end"
      alignItems="center"
      gap={2}
    >
      {text && (
        <Grid item>
          <CircularTextCounter text={text} limit={postMax} />
        </Grid>
      )}
      <Grid item>
        <Submit
          variant="publish"
          submitState={submitState}
          handleSubmit={handleSubmit}
        />
      </Grid>
    </Grid>
  );

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item mb={3}>
        <Grid container direction="row" wrap="nowrap" gap={2}>
          <Grid item>
            <BasicAvatar avatarSrc={sessOwnerAvatarSrc} disableClick />
          </Grid>
          <Grid item sx={{ width: "100%", position: "relative", top: 12 }}>
            <ResizingTextField
              wrapperId="resizing-textfield__create-subreply__wrapper"
              textareaId="resizing-textfield__create-subreply__textarea"
              minRows={6}
              maxHeight="400px"
              state={text}
              setState={setText}
              placeholder="Write a subreply"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>{submitSection}</Grid>
    </Grid>
  );
};
