import { createContext, useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { cargo } from "@api";
import { unique } from "radash";
import { QuadContext } from "@context";

export const AdminCourseContext = createContext();

export const AdminCourseProvider = ({ children, subject }) => {
  const { quadId } = useContext(QuadContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [searchType, setSearchType] = useState("title");
  const [searchCount, setSearchCount] = useState(0);

  /* Courses */
  const [isLoading, setIsLoading] = useState(true);
  const [courses, setCourses] = useState([]);

  const feedMax = 50;

  const [contextCourse, setContextCourse] = useState("");

  const searchCourse = (searchTerm) => {
    const { subjectId } = subject || {};

    if (subjectId && searchTerm) {
      setIsLoading(true);
      cargo
        .get(
          `/v2/q/${quadId}/course/search?by=${searchType}&subjectId=${subject.subjectId}&term=${searchTerm}`
        )
        .then((res) => {
          const { ok, payload } = res || {};
          if (ok) {
            setIsLoading(false);
            const uniques = unique(payload, (c) => c.courseId);
            const N = uniques.length;
            setCourses(uniques.slice(0, N > feedMax ? feedMax : N));
          }
        });
    }
  };

  const refreshContext = () => {
    setCourses([]);
    setContextCourse("");
    setSearchType("title");
    setSearchCount(0);
  };

  useEffect(() => {
    if (pathname === `/q/${quadId}/admin/courses`) {
      refreshContext();
    } else {
      refreshContext();
      navigate(`/q/${quadId}/admin/courses`);
    }
  }, [subject]);

  return (
    <AdminCourseContext.Provider
      value={{
        subject,
        searchType,
        setSearchType,
        searchCount,
        setSearchCount,
        searchCourse,
        isLoading,
        courses,
        setCourses,
        contextCourse,
        setContextCourse,
      }}
    >
      {children}
    </AdminCourseContext.Provider>
  );
};
