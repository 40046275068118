import { createTheme } from "@mui/material";

// Variants
import buttonVariants from "./buttonVariants";
import labelVariants from "./labelVariants";

const buttonTheme = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableFocusRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
          minWidth: 0,
          minHeight: 0,

          fontSize: "14px",
          fontWeight: "500",
        },
      },
      variants: [...buttonVariants],
    },
    MuiTypography: {
      defaultProps: {},
      styleOverrides: {},
      variants: [...labelVariants],
    },
  },
});

export default buttonTheme;
