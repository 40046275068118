import { useState, useEffect, useReducer } from "react";
import { useGlobal, useQuad } from "@context";
import { Submitter } from "@tools";
import {
  BasicAvatar,
  BasicForm,
  CircularTextCounter,
  Submit,
  Author,
  Description,
  ResizingTextField,
} from "@common/comps";
import { Grid, Box, ThemeProvider } from "@mui/material";
import { cardTheme } from "@material/themes";
import { cargo } from "@api";

const ReplyLead = ({ comment }) => {
  const { username, avatarSrc, text } = comment || {};

  const avatar = (
    <Grid container direction="column" wrap="nowrap" alignItems="center">
      <Grid item>
        <BasicAvatar username={username} avatarSrc={avatarSrc} />
      </Grid>
      <Grid item sx={{ height: "100%" }}>
        <Box
          sx={{
            position: "relative",
            top: 5,
            width: "2px",
            height: "95%",
            borderRadius: "9999px",
            backgroundColor: "var(--avatar-link-gray)",
          }}
        />
      </Grid>
    </Grid>
  );

  const content = (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        <Author data={comment} />
      </Grid>
      <Grid item>
        <Description text={text} />
      </Grid>
    </Grid>
  );

  return (
    <ThemeProvider theme={cardTheme}>
      <Grid container direction="row" wrap="nowrap" gap={2}>
        <Grid item sx={{ display: "flex" }}>
          {avatar}
        </Grid>
        <Grid item>{content}</Grid>
      </Grid>
    </ThemeProvider>
  );
};

export const ReplyPublisher = ({
  comment,
  isOpen,
  setIsOpen,
  onPublish = () => {},
}) => {
  const { defaultError, session: { sessOwnerAvatarSrc, postMax } = {} } =
    useGlobal();
  const { quadId } = useQuad();

  const [text, setText] = useState("");

  // Submission
  const [submitState, setSubmitState] = useReducer(
    Submitter.createReducer(),
    Submitter.initialState()
  );

  const submitter = new Submitter(submitState, setSubmitState, defaultError);

  useEffect(() => {
    submitter.resetError();
    submitter.green(text.length > 0);
  }, [text]);

  const handleSubmit = () => {
    submitter.startLoading();
    const { commentId } = comment || {};
    cargo
      .post(`/v2/q/${quadId}/p/comment/${commentId}/reply`, { text })
      .then((res) => {
        const { ok, payload } = res || {};
        if (ok) {
          onPublish(payload);
        } else {
          submitter.stopLoading();
          submitter.takeResponse(res);
        }
      });
  };

  const creator = (
    <Grid container direction="row" wrap="nowrap" gap={2}>
      <Grid item>
        <BasicAvatar avatarSrc={sessOwnerAvatarSrc} />
      </Grid>
      <Grid item sx={{ width: "100%", position: "relative", top: 12 }}>
        <ResizingTextField
          wrapperId="resizing-textfield__create-reply__wrapper"
          textareaId="resizing-textfield__create-reply__textarea"
          minRows={10}
          state={text}
          setState={setText}
          placeholder="Write a reply"
        />
      </Grid>
    </Grid>
  );

  const content = (
    <Grid container direction="column" wrap="nowrap" gap={1.15}>
      <Grid item>
        <ReplyLead comment={comment} />
      </Grid>
      <Grid item>{creator}</Grid>
    </Grid>
  );

  const footer = (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      justifyContent="flex-end"
      alignItems="center"
      gap={2}
    >
      {text && (
        <Grid item>
          <CircularTextCounter text={text} limit={postMax} />
        </Grid>
      )}
      <Grid item>
        <Submit
          variant="publish"
          submitState={submitState}
          handleSubmit={handleSubmit}
        />
      </Grid>
    </Grid>
  );

  if (isOpen) {
    return (
      <BasicForm
        title="Write reply"
        isFormOpen={isOpen}
        setIsFormOpen={setIsOpen}
        content={content}
        footer={footer}
      />
    );
  }
};
