import { useContext, useState, useEffect, forwardRef } from "react";
import { cargo } from "@api";
import { QuadContext } from "@context";
import { Opener, Builder } from "@tools";
import {
  BubbleDropdown,
  Loading,
  MoreHeader,
  EmptyLabel,
  Editable,
  PopperOptions,
} from "@common/comps";
import { useTranche2 } from "@common/hooks";
import { Grid, Box, Button, ThemeProvider } from "@mui/material";
import { buttonTheme } from "@material/themes";
import {
  PopperEditIcon,
  PopperDeptIcon,
  PopperCatalogerIcon,
} from "@material/icons";
import { replace, unique } from "radash";

const Subject = forwardRef(({ s, onEdit = () => {} }, ref) => {
  const { subjectName, subjectCode } = s || {};

  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isEditDeptOpen, setIsEditDeptOpen] = useState(false);
  const [isEditCatalogerOpen, setIsEditCatalogerOpen] = useState(false);

  const menuItemsList = [
    {
      key: "admin-subject__edit",
      label: "Edit subject",
      icon: <PopperEditIcon />,
      handleClick: (event) => {
        event.stopPropagation();
        setIsEditOpen(true);
      },
    },
    {
      key: "admin-subject__edit-departments",
      label: "Edit departments",
      icon: <PopperDeptIcon />,
      handleClick: (event) => {
        event.stopPropagation();
        setIsEditDeptOpen(true);
      },
    },
    {
      key: "admin-subject__edit-cataloger",
      label: "Edit cataloger",
      icon: <PopperCatalogerIcon />,
      handleClick: (event) => {
        event.stopPropagation();
        setIsEditCatalogerOpen(true);
      },
    },
  ];

  const endComponent = (
    <Box sx={{ height: "100%", display: "flex", alignItems: "center" }}>
      <PopperOptions menuItemsList={menuItemsList} />
      {isEditOpen &&
        Builder.subject({
          isOpen: isEditOpen,
          setIsOpen: setIsEditOpen,
          unedited: s,
          onEdit: (edited) => {
            setIsEditOpen(false);
            onEdit(edited);
          },
        })}
      {isEditDeptOpen &&
        Builder.subjectDepts({
          subject: s,
          isOpen: isEditDeptOpen,
          setIsOpen: setIsEditDeptOpen,
          onEdit: () => setIsEditDeptOpen(false),
        })}
      {isEditCatalogerOpen &&
        Builder.subjectCatalogers({
          subject: s,
          isOpen: isEditCatalogerOpen,
          setIsOpen: setIsEditCatalogerOpen,
          onEdit: () => setIsEditCatalogerOpen(false),
        })}
    </Box>
  );

  return (
    <article ref={ref}>
      <Box px={2} py={1.5} borderBottom="1px solid var(--border-gray)">
        <Editable
          variant="vanilla"
          label={subjectCode}
          subLabel={subjectName}
          endComponent={endComponent}
        />
      </Box>
    </article>
  );
});

export const SubjectFeed = ({ alphabet, category }) => {
  const { quadId } = useContext(QuadContext);

  const {
    initTranche,
    isLoading,
    setFeed,
    feed,
    isFeedEmpty,
    setFirstId,
    lastCardRef,
  } = useTranche2(`/v2/q/${quadId}/subject/tranche`, {
    filters: { alphabet, category },
  });

  useEffect(() => initTranche(), []);

  useEffect(() => {
    if (feed.length > 0) setFirstId(feed[0].maxSubjectId);
  }, [feed]);

  const uniques = unique(feed, (s) => s.subjectId);

  return (
    <Grid container direction="column" wrap="nowrap">
      {isFeedEmpty ? (
        <EmptyLabel label="No subjects found." p={2} />
      ) : (
        uniques.map((s, i) => (
          <Grid item key={s.subjectId}>
            <Subject
              ref={uniques.length === i + 1 ? lastCardRef : null}
              s={s}
              onEdit={(edited) => {
                setFeed((prev) =>
                  replace(prev, edited, (p) => p.subjectId === s.subjectId)
                );
              }}
            />
          </Grid>
        ))
      )}
      {isLoading && <Loading p={2} />}
      <Grid item>
        <Box sx={{ height: "200px" }} />
      </Grid>
    </Grid>
  );
};

export const AdminSubjects = () => {
  const { quadId } = useContext(QuadContext);

  const [isLoading, setIsLoading] = useState(true);
  const [alphOptions, setAlphOptions] = useState([]);
  const [catOptions, setCatOptions] = useState([]);

  const [refresh, setRefresh] = useState(0);
  const runRefresh = () => setRefresh((prev) => prev + 1);

  const [alphabet, setAlphabet] = useState("");
  const [category, setCategory] = useState("");

  useEffect(() => {
    setIsLoading(true);
    cargo.get(`/v2/q/${quadId}/subject/filters`).then((res) => {
      const { ok, payload } = res || {};
      if (ok) {
        setIsLoading(false);

        const { catOptions = [], alphOptions = [] } = payload || {};
        setAlphOptions(alphOptions);
        setCatOptions(catOptions);
      }
    });
  }, []);

  const Create = () => (
    <Opener
      variant="filled"
      color="black"
      label="Create subject"
      builder={Builder.subject({ onCreate: runRefresh })}
    />
  );

  const Feed = () => {
    return <SubjectFeed alphabet={alphabet} category={category} />;
  };

  const clearButton = (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant="outlined"
        onClick={() => {
          setAlphabet("");
          setCategory("");
        }}
        sx={{ height: "37.13px", color: "var(--quad-red)" }}
      >
        Clear
      </Button>
    </ThemeProvider>
  );

  const filters = (
    <Grid container direction="row" gap={2}>
      {(alphabet || category) && <Grid item>{clearButton}</Grid>}
      <Grid item sx={{ minWidth: "180px" }}>
        <BubbleDropdown
          label="Beginning with"
          state={alphabet}
          setState={setAlphabet}
          options={alphOptions}
        />
      </Grid>
      <Grid item>
        <BubbleDropdown
          variant="object"
          label="Field of study"
          state={category}
          setState={setCategory}
          options={catOptions}
          labelKey="subjectCatName"
          valueKey="subjectCatId"
          disableObjectSelect
        />
      </Grid>
    </Grid>
  );

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        <MoreHeader
          title="Manage subjects"
          enableBack
          backRoute={`/q/${quadId}/admin`}
        />
      </Grid>
      <Grid
        item
        borderTop="1px solid var(--border-gray)"
        borderBottom="1px solid var(--border-gray)"
        p={2}
      >
        <Create />
      </Grid>
      <Grid
        item
        borderBottom="1px solid var(--border-gray)"
        p={2}
        sx={{
          minHeight: "68px",
        }}
      >
        {isLoading ? <Loading p={2} /> : filters}
      </Grid>
      <Grid item>{!isLoading && <Feed />}</Grid>
    </Grid>
  );
};
