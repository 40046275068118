import { useContext, useState, useEffect } from "react";
import { cargo } from "@api";
import { GlobalContext } from "@context";
import { getURL } from "@config";
import { Input, Submit } from "@common/comps";
import { setFieldErrors } from "@common/utility";
import { Grid, Typography } from "@mui/material";

export const SettingsUsername = () => {
  const { session, defaultError } = useContext(GlobalContext);
  const { sessOwner } = session || {};

  const [username, setUsername] = useState(sessOwner);

  const [error, setError] = useState(defaultError);

  const setErrorMap = {
    username: setError,
  };

  // Submission
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [submitError, setSubmitError] = useState(defaultError);

  const goodForSubmit = Boolean(sessOwner !== username && username);

  useEffect(() => {
    setIsSubmitDisabled(!goodForSubmit);
    setSubmitError(defaultError);
    Object.values(setErrorMap).forEach((item) => item(defaultError));
  }, [username]);

  const handleSubmit = () => {
    setIsSubmitLoading(true);
    if (goodForSubmit) {
      cargo.put(`/v2/settings/user/username`, { username }).then((res) => {
        const { ok, status, errors = [], isClientError, message } = res || {};
        if (ok) {
          window.location.href = getURL("/settings/general");
        } else {
          setIsSubmitLoading(false);
          setIsSubmitDisabled(true);
          setSubmitError({ isError: true, errorText: message, type: status });
          if (isClientError) setFieldErrors(errors, setErrorMap);
        }
      });
    } else {
      setIsSubmitDisabled(true);
      setSubmitError({
        isError: true,
        type: 400,
      });
    }
  };

  return (
    <Grid container direction="column" wrap="nowrap" p={2}>
      <Grid item>
        <Input
          label="Username"
          state={username}
          setState={setUsername}
          error={error}
        />
      </Grid>
      <Grid item px={1}>
        <Typography variant="field__footer">
          Username are between 4 and 20 characters
        </Typography>
      </Grid>
      <Grid item mt={2}>
        <Submit
          alignX="flex-end"
          handleSubmit={handleSubmit}
          submitState={{
            isDisabled: isSubmitDisabled,
            isLoading: isSubmitLoading,
            error: submitError,
          }}
        />
      </Grid>
    </Grid>
  );
};
