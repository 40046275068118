import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { QuadContext } from "@context";
import { cargo } from "@api";
import { Clickable, EmptyLabel, Loadman, Slide, Badge } from "@common/comps";
import { Grid, Box, Button, ThemeProvider } from "@mui/material";
import { buttonTheme } from "@material/themes";
import { ButtonAltIcon } from "@material/icons";

const OptionCourseAlternatives = ({ c }) => {
  const navigate = useNavigate();
  const { alternatives } = c || {};

  const isEmpty = alternatives.length === 0;

  if (isEmpty) {
    return <EmptyLabel label="No alternatives found." py={2} mb={1} />;
  } else {
    return (
      <Grid container direction="column" wrap="nowrap">
        {alternatives.map((c, i) => (
          <Grid
            item
            key={c.courseId}
            borderTop={i === 0 && "1px solid var(--border-gray)"}
            borderBottom="1px solid var(--border-gray)"
          >
            <Clickable
              label={`${c.subjectCode} ${c.courseCode}`}
              subLabel={c.courseTitle}
              handleClick={() => navigate(`/q/${c.quad}/c/${c.courseId}`)}
              containerProps={{
                p: 1,
              }}
            />
          </Grid>
        ))}
      </Grid>
    );
  }
};

const OptionCourse = ({ c, isLast }) => {
  const navigate = useNavigate();

  const { subjectCode, courseCode, courseTitle, courseUnits, quad, courseId } =
    c || {};

  const [isAltOpen, setIsAltOpen] = useState(false);

  const expandButton = (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant="icon"
        onClick={(event) => {
          event.stopPropagation();
          setIsAltOpen(true);
        }}
        sx={{
          padding: "3px",
        }}
      >
        <ButtonAltIcon width={20} height={20} />
      </Button>
    </ThemeProvider>
  );

  const endComponent = (
    <Grid container direction="row" wrap="nowrap" alignItems="center" gap={1}>
      <Grid item>
        <Badge
          variant="outlined"
          label={`${Number(courseUnits)} Unit${courseUnits > 1 ? "s" : ""}`}
        />
      </Grid>
      <Grid item>
        {expandButton}
        {isAltOpen && (
          <Slide
            title="Alternatives"
            isOpen={isAltOpen}
            setIsOpen={setIsAltOpen}
            content={<OptionCourseAlternatives c={c} />}
          />
        )}
      </Grid>
    </Grid>
  );

  return (
    <Box borderBottom={!isLast && "1px solid var(--border-gray)"}>
      <Clickable
        variant="line"
        label={`${subjectCode} ${courseCode}`}
        subLabel={courseTitle}
        handleClick={() => navigate(`/q/${quad}/c/${courseId}`)}
        endComponent={endComponent}
        containerProps={{
          p: 1,
          alignItems: "center",
        }}
      />
    </Box>
  );
};

const Option = ({ o }) => {
  const { sequence } = o || {};

  return (
    <Grid container direction="column" wrap="nowrap">
      {sequence.map((c, i) => (
        <Grid item key={c.preqOptCourseId}>
          <OptionCourse c={c} isLast={i + 1 === sequence.length} />
        </Grid>
      ))}
    </Grid>
  );
};

export const CoreGroups = ({ g }) => {
  const { quadId } = useContext(QuadContext);
  const { preqGroupId } = g || {};

  /* Feed */
  const [isLoading, setIsLoading] = useState(true);
  const [feed, setFeed] = useState([]);

  const isEmpty = feed.length === 0;
  const noFeed = isLoading || isEmpty;

  const fetchFeed = () => {
    setIsLoading(true);
    setFeed([]);
    cargo
      .get(`/v2/q/${quadId}/program/requirement/group/${preqGroupId}/options`)
      .then((res) => {
        const { ok, payload } = res || {};

        if (ok) {
          setIsLoading(false);
          setFeed(payload.options);
        }
      });
  };

  useEffect(() => {
    fetchFeed();
  }, []);

  if (noFeed) {
    return (
      <Loadman isLoading={isLoading} isEmpty={isEmpty} pt={2} mb={1} px={0.5} />
    );
  } else {
    return (
      <Grid container direction="column" wrap="nowrap" gap={1}>
        {feed.map((o) => (
          <Grid
            item
            key={o.preqOptionId}
            border="1px solid var(--attachment-border-gray)"
            borderRadius={2}
            px={1}
            py={0.25}
          >
            <Option o={o} />
          </Grid>
        ))}
      </Grid>
    );
  }
};
