import { cloneElement, useState } from "react";
import { Button, ThemeProvider } from "@mui/material";
import { buttonTheme } from "@material/themes";
import { ButtonEditIcon } from "@material/icons";
import { isString } from "radash";

const FilledButton = ({ label, color, setIsOpen, buttonSx }) => {
  return (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant="filled"
        color={color || "black"}
        sx={buttonSx}
        onClick={(event) => {
          event.stopPropagation();
          setIsOpen(true);
        }}
      >
        {label}
      </Button>
    </ThemeProvider>
  );
};

const OutlinedButton = ({ label, setIsOpen, buttonSx }) => {
  return (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant="outlined"
        sx={buttonSx}
        onClick={(event) => {
          event.stopPropagation();
          setIsOpen(true);
        }}
      >
        {label || "Button"}
      </Button>
    </ThemeProvider>
  );
};

const IconButton = ({ icon, setIsOpen, buttonSx }) => {
  const iconProps = { width: 18, height: 18 };

  const getIcon = () => {
    if (isString) {
      switch (icon) {
        case "edit":
          return <ButtonEditIcon {...iconProps} />;
        default:
          return <></>;
      }
    } else {
      return icon;
    }
  };

  return (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant="icon"
        sx={buttonSx}
        onClick={(event) => {
          event.stopPropagation();
          setIsOpen(true);
        }}
      >
        {getIcon()}
      </Button>
    </ThemeProvider>
  );
};

const OpenButton = (props) => {
  const { variant } = props || {};

  switch (variant) {
    case "filled":
      return <FilledButton {...props} />;
    case "icon":
      return <IconButton {...props} />;
    default:
      return <OutlinedButton {...props} />;
  }
};

const Opener = (props) => {
  const { builder } = props || {};
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <OpenButton {...props} setIsOpen={setIsOpen} />
      {isOpen && builder && cloneElement(builder, { isOpen, setIsOpen })}
    </>
  );
};

export { Opener };
export default Opener;
