import { BasicForm } from "@common/comps";
import { useGlobal, useQuad } from "@context";

export const ProjectBuilder = ({ isOpen, setIsOpen, onCreate = () => {} }) => {
  const { defaultError } = useGlobal();
  const { quadId } = useQuad();

  const handleSubmit = () => {};

  const content = <></>;

  if (isOpen) {
    return (
      <BasicForm
        title="Create project"
        isFormOpen={isOpen}
        setIsFormOpen={setIsOpen}
        content={content}
        handleSubmit={handleSubmit}
      />
    );
  } else {
    return <></>;
  }
};
