import { isNumber, isString } from "radash";

class Preflight {
  constructor() {}
  static boolean(t) {
    const { name, type, value } = t || {};

    if (type === "boolean") {
      return value === true || value === false;
    } else {
      throw new Error(`Incorrect preflight used for ${name}`);
    }
  }
  static string(t) {
    const { name, type, value, min = 1, max, required } = t || {};

    if (type === "string") {
      if (!isString(value)) return false;

      const minCheck = min ? value.length >= min : true;
      const maxCheck = max ? value.length <= max : true;

      if (required) {
        return minCheck && maxCheck;
      } else {
        return (
          Boolean(value === "" || value === null || value === undefined) ||
          Boolean(minCheck && maxCheck)
        );
      }
    } else {
      throw new Error(`Incorrect preflight used for ${name}`);
    }
  }
  static number(t) {
    const { name, type, value, min, max, required } = t || {};

    if (type === "number") {
      const num = Number(value);

      if (!isNumber(num)) return false;

      const minCheck = min ? num >= min : true;
      const maxCheck = max ? num <= max : true;

      if (required) {
        return minCheck && maxCheck;
      } else {
        return (
          Boolean(value === "" || value === null || value === undefined) ||
          Boolean(minCheck && maxCheck)
        );
      }
    } else {
      throw new Error(`Incorrect preflight used for ${name}`);
    }
  }
}

export { Preflight };
