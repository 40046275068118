import { useContext, useState, useEffect } from "react";
import { cargo } from "@api";
import { ProfileContext } from "@context";
import { Opener, Builder } from "@tools";
import { Loading, EmptyLabel, Tab, BubbleDropdown } from "@common/comps";
import { useTranche2 } from "@common/hooks";
import { Grid, Button, ThemeProvider } from "@mui/material";
import { buttonTheme } from "@material/themes";

import { EducationCard } from "./EducationCard";
import { unique } from "radash";

import { RecordCard } from "./RecordCard";

const CoursesFilters = ({
  currentQuad,
  setCurrentQuad,
  semester,
  setSemester,
  year,
  setYear,
}) => {
  const { options } = useContext(ProfileContext);
  const { quadOptions, semOptions, yearOptions } = options || {};

  const clearButton = (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant="outlined--red"
        sx={{ color: "var(--quad-red)" }}
        onClick={() => {
          setCurrentQuad(quadOptions[0]);
          setSemester("");
          setYear("");
        }}
      >
        Clear
      </Button>
    </ThemeProvider>
  );

  return (
    <Grid container direction="row" gap={1.5} p={2}>
      {(semester || year) && <Grid item>{clearButton}</Grid>}
      <Grid item>
        <BubbleDropdown
          variant="object"
          state={currentQuad}
          setState={setCurrentQuad}
          options={quadOptions}
          labelKey="quadShort"
          valueKey="quad"
        />
      </Grid>
      <Grid item>
        <BubbleDropdown
          label="Year"
          state={year}
          setState={setYear}
          options={yearOptions}
        />
      </Grid>
      <Grid item>
        <BubbleDropdown
          label="Semester"
          state={semester}
          setState={setSemester}
          options={semOptions}
        />
      </Grid>
    </Grid>
  );
};

const CoursesFeed = ({ currentQuad, semester, year }) => {
  const { username } = useContext(ProfileContext);
  const { quad } = currentQuad || {};

  const {
    initTranche,
    isLoading,
    feed,
    setFeed,
    isFeedEmpty,
    setFirstId,
    lastCardRef,
  } = useTranche2(`/v2/u/${username}/q/${quad}/course/records`, {
    semester,
    year,
  });

  useEffect(() => {
    initTranche();
  }, []);

  useEffect(() => {
    if (feed.length > 0) {
      setFirstId(feed[0].maxCourseRecId);
    }
  }, [feed]);

  const uniques = unique(feed, (r) => r.courseRecId);

  if (isLoading) {
    return <Loading p={2} />;
  } else {
    if (isFeedEmpty) {
      return <EmptyLabel label="No courses found." p={2} />;
    } else {
      return (
        <Grid container direction="column" wrap="nowrap">
          {uniques.map((r, i) => (
            <Grid item key={r.courseRecId}>
              <RecordCard
                ref={uniques.length === i + 1 ? lastCardRef : null}
                record={r}
                setFeed={setFeed}
              />
            </Grid>
          ))}
        </Grid>
      );
    }
  }
};

const Courses = () => {
  const { options } = useContext(ProfileContext);
  const { quadOptions } = options || {};

  // Filters
  const [currentQuad, setCurrentQuad] = useState(quadOptions[0]);
  const [semester, setSemester] = useState("");
  const [year, setYear] = useState("");

  const Feed = () => (
    <CoursesFeed currentQuad={currentQuad} semester={semester} year={year} />
  );

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item borderBottom="1px solid var(--border-gray)">
        <CoursesFilters
          currentQuad={currentQuad}
          setCurrentQuad={setCurrentQuad}
          semester={semester}
          setSemester={setSemester}
          year={year}
          setYear={setYear}
        />
      </Grid>
      <Grid item>
        <Feed />
      </Grid>
    </Grid>
  );
};

const Programs = ({ feedP, setFeedP, runRefresh }) => {
  const uniques = unique(feedP, (e) => e.eduId);

  const programFeed = uniques.map((e, i) => (
    <Grid key={e.eduId}>
      <EducationCard entry={e} setFeed={setFeedP} runRefresh={runRefresh} />
    </Grid>
  ));

  if (uniques.length === 0) {
    return <EmptyLabel label="No programs found." p={2} />;
  } else {
    return (
      <Grid container direction="column">
        {programFeed}
      </Grid>
    );
  }
};

export const Education = () => {
  const { profile } = useContext(ProfileContext);
  const { username, canEdit } = profile || {};

  const [page, setPage] = useState("program");

  const [isLoading, setIsLoading] = useState(true);
  const [feedP, setFeedP] = useState([]);
  const [refresh, setRefresh] = useState(0);

  const runRefresh = () => setRefresh((prev) => prev + 1);

  useEffect(() => {
    setIsLoading(true);
    cargo.get(`/v2/u/${username}/education`).then((res) => {
      setIsLoading(false);
      const { ok, payload } = res || {};
      if (ok) {
        setFeedP(payload.education);
      }
    });
  }, [refresh]);

  const Create = () => (
    <Opener
      variant="filled"
      color="black"
      label="Add new"
      builder={Builder.education({ onCreate: runRefresh })}
    />
  );

  const loader = () => {
    if (isLoading) {
      return <Loading p={2} />;
    } else {
      switch (page) {
        case "course":
          return <Courses />;
        default:
          return (
            <Grid container direction="column" wrap="nowrap">
              <Grid item>
                <Programs
                  feedP={feedP}
                  setFeedP={setFeedP}
                  runRefresh={runRefresh}
                />
              </Grid>
              {canEdit && (
                <Grid
                  item
                  p={2}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Create />
                </Grid>
              )}
            </Grid>
          );
      }
    }
  };

  const tabs = [
    {
      label: "Programs",
      isActive: page === "program",
      handleClick: () => page !== "program" && setPage("program"),
    },
    {
      label: "Courses",
      isActive: page === "course",
      handleClick: () => page !== "course" && setPage("course"),
    },
  ];

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item borderBottom="1px solid var(--border-gray)">
        <Grid container direction="row" wrap="nowrap" p={2} gap={1}>
          {tabs.map((t, i) => (
            <Grid item key={i}>
              <Tab {...t} variant="bubble" />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item>{loader()}</Grid>
    </Grid>
  );
};
